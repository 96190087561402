<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Ingreso de Recogidas</h5>

                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Mensajero:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrAsignado" [searchKeyword]="keywordAsginado"
                                            placeholder="Buscar Mensajero" (selected)='selectEventAsginado($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedAsginado($event)' [itemTemplate]="itemAsginado"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="validationForm.controls['Mensajero']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemAsginado let-item>
                                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Fecha:</label>
                                    <input class="form-control digits" type="datetime-local"
                                        id="example-datetime-local-input"
                                        [formControl]="validationForm.controls['FechaDil']" rdequired>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-sm-3">
                                    <label class="col-form-label pt-0">*Observaciones</label>
                                    <textarea class="form-control" id="validationCustom03" rows="2"
                                        placeholder="Todas las inidicaciones para la recogida"
                                        [formControl]="validationForm.controls['Observaciones']" required></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <input type='text' class="form-control" placeholder='Filtrar Doctor'
                                        (keyup)='updateFilterDoctor($event)' />
                                </div>
                            </div>

                            <div class="row">
                                <div class="card-body custom-datatable">
                                    <ngx-datatable class="bootstrap" [rows]="arrOdontologo" [columnMode]="'force'"
                                        [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="5"
                                        [scrollbarH]="true" (select)="onSelect($event)" [selectionType]="SelectionType.single"
                                        (activate)="onActivate($event)" >
                                 
                                        <ngx-datatable-column name="Nombre" prop="ChrNombreCompleto">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Direccioon" prop="ChrDirreccion">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Telefono" prop="ChrTelefono"></ngx-datatable-column>

                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="card-body btn-showcase">
                                <button class="btn btn-success" type="button" placement="top" ngbTooltip="Guardar"
                                    [disabled]="(!validationForm.valid)" (click)="Guardar()">Guardar</button>
                                <button class="btn btn-danger" type="button" placement="top" ngbTooltip="Cancelar"
                                    (click)="cancelar()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>

        </div>
    </div>