import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticatorService } from '../../../shared/services/authenticator.service';
import { ToastrService } from 'ngx-toastr'
import { ListarPermisosNgRoot, ListarPermisosNg } from 'src/app/shared/models/listarLogin';
import Swal from 'sweetalert2';

type UserFields = 'user' | 'password';
type FormErrors = { [u in UserFields]: string };

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  entrando: any;
  idUsuario: string

  Cargando: string;


  constructor(
    public authenticatorService: AuthenticatorService,
    private toastaService: ToastrService,
    private fb: FormBuilder,
    private router: Router) {
    this.loginForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.Cargando = 'none'

  }

  ngOnInit() {
    this.entrando = false;
    sessionStorage.clear();
    localStorage.clear();
  }

  async login() {

    this.entrando = true;

    this.Cargando = 'Block'

    await this.authenticatorService.ngLogin(this.loginForm.value).subscribe((res: any) => {
      if (res) {        
        if (res != 'False') {
          this.idUsuario = JSON.parse(sessionStorage.getItem('idUsuario'))
          this.router.navigate(['/listarempresas'])

          this.Cargando = 'none'

        } else {
          this.Cargando = 'none'
          this.entrando = false;
          Swal.fire({
            title: "Informacion...!!",
            html: ' <div class="well well-sm ContenedorGris"><p>' + 'Tu licencia se cuentra vencida o no cuenta con una licencia activa, para seguir disfrutanto los servicios comunicate con el administrador del sistema' + '</p></div>', 
            icon: 'warning'
          });
        }

      }


    }, error => {
      console.log(error);
           
      this.entrando = false;
      this.Cargando = 'none'
      Swal.fire({
        title: "Error...!!",
        html: ' <div class="well well-sm ContenedorGris"><br /><p>' + error.error.message + '</p></div>', icon: 'error'
      });
    });

  }

}
