import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
    providedIn: 'root'
})
export class DtdentalServices {

    basicHeaders: any;
    loginHeaders: any;
    tokendt: string;
    clientdt: string

    constructor(private http: HttpClient) {
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': "application/json"
        })
    }

    login(): Promise<any> {
        const body = { user: { email: "adentlab@mail.com", password: "adentlab123" } }
        const res: any = this.http.post(Enviroment.url_dtDental + 'users/sign_in', body, { headers: this.basicHeaders, observe: 'response', responseType: 'arraybuffer' }).toPromise();
        return res
    }

    getAllPetitions(): Promise<any> {

        this.tokendt = sessionStorage.getItem('tokendt');
        this.clientdt = sessionStorage.getItem('clientdt');
       
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'uid': 'adentlab@mail.com',
            'client': this.clientdt,
            'access-token': this.tokendt
        })

        return this.http.get<any>(Enviroment.url_dtDental + 'tenant_labs', { headers: this.basicHeaders }).toPromise();
    }

    UpdatePetitionsStatus(idPetitions, idStatus): Promise<any> {

        this.tokendt = sessionStorage.getItem('tokendt');
        this.clientdt = sessionStorage.getItem('clientdt');
       
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'uid': 'adentlab@mail.com',
            'client': this.clientdt,
            'access-token': this.tokendt
        })
        const bodyreq = { tenant_lab: { status: idStatus } }

        return this.http.put<any>(Enviroment.url_dtDental + 'tenant_labs/' + idPetitions , bodyreq, { headers: this.basicHeaders }).toPromise();
    }

    getAllOrder(): Promise<any> {

        this.tokendt = sessionStorage.getItem('tokendt');
        this.clientdt = sessionStorage.getItem('clientdt');
       
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'uid': 'adentlab@mail.com',
            'client': this.clientdt,
            'access-token': this.tokendt
        })

        return this.http.get<any>(Enviroment.url_dtDental + 'orders', { headers: this.basicHeaders }).toPromise();
    }

    UpdateOrder(idOrder, body): Promise<any> {

        this.tokendt = sessionStorage.getItem('tokendt');
        this.clientdt = sessionStorage.getItem('clientdt');
       
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'uid': 'adentlab@mail.com',
            'client': this.clientdt,
            'access-token': this.tokendt
        })
        
        return this.http.post<any>(Enviroment.url_dtDental + 'orders/' + idOrder, body, { headers: this.basicHeaders }).toPromise();
    }

}
