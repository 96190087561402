import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import 'rxjs/Rx';


@Injectable({
  providedIn: 'root'
})

export class InventoryService {

  basicHeaders: any;
  basicHeadersCloud: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  getAllProductos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarProductos', body, { headers: this.basicHeaders }).toPromise();
  }

  getDtllInventario(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarDtllInveProduccion', body, { headers: this.basicHeaders }).toPromise();
  }

  InsertarInventario(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarInventario', body, { headers: this.basicHeaders }).toPromise();
  }

  ListardtllInveProc(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListardtllInveProc', body, { headers: this.basicHeaders }).toPromise();
  }

}
