<div class="dark-body-only">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
      <div class="page-sidebar" id="pages-sidebar" [class.open]="navServices.collapseSidebar"
        [ngClass]="customizer.data.settings.sidebar_backround"
        [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <main>
          <router-outlet></router-outlet>
        </main>


        <!-- Container-fluid starts-->
     <!--    <div class="container-fluid">
          <div class="row"> 

            <div class="col-xl-8 xl-100">
              <div class="card">
                <div class="card-header">
                  <h5>Productos ingresados en el mes actual</h5>
        
                </div>
                <div class="card-body">
                  <div class="table-responsive shopping-table text-center">
                    <table class="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Producto</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">¿Activo?</th>
                          <th scope="col">Precio Unitario</th>
                          <th scope="col">Premio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let trabajos of arrTrabajos; let indice=index">
                          <td>{{indice + 1}}</td>
                          <td>{{trabajos.ChrNombreTrabajo}}</td>
                          <td>{{trabajos.Cantidad_Trabajos}}</td>
                          <td>
                            <button class="btn btn-primary btn-pill">Active</button>
                          </td>
                          <td>{{trabajos.DblPrecioVenta | currency: 'COP'}}</td>
                          <td *ngIf="indice === 0">
                            <div class="selling-update text-center">
                              <app-feather-icons [icon]="'award'"></app-feather-icons>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
        
                </div>
              </div>
            </div>

            <div class="col-xl-4 xl-100">
              <div class="card">
                <div class="card-header">
                  <h5>Total Trabajos</h5><span class="d-block fonts-dashboard">Todos los trabajos del mes actual en comparativa con el mes anterior</span>
                </div>
                <div class="card-body">
                  <div class="knob-block text-center">
                    <div class="knob" id="profit"></div>
                  </div>
                  <div class="show-value d-flex">
                    <div class="value-left">
                      <div class="square bg-primary d-inline-block"></div><span>Total Trabajos Actuales</span>
                    </div>
                    <div class="value-right">
                      <div class="square bg-light d-inline-block"></div><span>Total Trabajos mes anterior: {{IngAnteriores}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 xl-50 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <div class="number-widgets">
                    <div class="media">
                      <div class="media-body align-self-center">
                        <h6 class="mb-0">En el Laboratorio</h6>
                      </div>
                      <div class="knob-block text-center">
                        <div class="knob" id="profitlab"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 xl-50 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <div class="number-widgets">
                    <div class="media">
                      <div class="media-body align-self-center">
                        <h6 class="mb-0">Periodo de Prueba</h6>
                      </div>
                      <div class="knob-block text-center">
                        <div class="knob" id="profittpp"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 xl-50 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <div class="number-widgets">
                    <div class="media">
                      <div class="media-body align-self-center">
                        <h6 class="mb-0">Facturados</h6>
                      </div>
                      <div class="knob-block text-center">
                        <div class="knob" id="profitfac"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 xl-50 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <div class="number-widgets">
                    <div class="media">
                      <div class="media-body align-self-center">
                        <h6 class="mb-0">Anulados</h6>
                      </div>
                      <div class="knob-block text-center">
                        <div class="knob" id="profitanu"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Container-fluid Ends-->

      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>