import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  basicHeaders: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  getAllHistorial(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarHistorialNg', body, { headers: this.basicHeaders }).toPromise();
  }

  
  getAllProcesos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarProcesos', body, { headers: this.basicHeaders }).toPromise();
  }

  saveHistorialNg(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarDtllHistoricoNg', body, { headers: this.basicHeaders }).toPromise();
  }

  saveHistorial(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarDtllHistoricoAli', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateDllRealiza(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarDtllHistoricoRealiza', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateDllPedido(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarDetallePedido', body, { headers: this.basicHeaders }).toPromise();
  }

  IngresoTratamiento(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarPedidosAlineadores', body, { headers: this.basicHeaders }).toPromise();
  }

  Actualizar_Tratamientos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarTratamiento', body, { headers: this.basicHeaders }).toPromise();
  }

  getAllTratamientos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarTratamientolNg', body, { headers: this.basicHeaders }).toPromise();
  }

  getPedidosPacientes(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'searchListarDetallePedido', body, { headers: this.basicHeaders }).toPromise();
  }

  getAllEjecucion(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarEjecucionNg', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateEstadoTratamiento(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdateEstadoTratamiento', body, { headers: this.basicHeaders }).toPromise();
  }

  getPrecioAlinieadores(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListaPrecioAlineadores', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdatePrecioPlaneAnulado(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdatePrecioPlaneAnulado', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdatePrecioHistorico(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdatePrecioHistorico', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateNgPlaneacion(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdateNgPlaneacion', body, { headers: this.basicHeaders }).toPromise();
  }

  ListarCajas(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarCajas', body, { headers: this.basicHeaders }).toPromise();
  }

  ListarProcesos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarProcesosTrabajos', body, { headers: this.basicHeaders }).toPromise();
  }

  Insertar_Pedido(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarPedidos', body, { headers: this.basicHeaders }).toPromise();
  }

  Insertar_DtllHisto_Precio(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarDtllHistoricoPrecio', body, { headers: this.basicHeaders }).toPromise();
  }

  Listar_Ultimo_Historial(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarUltHistorial', body, { headers: this.basicHeaders }).toPromise();
  }

  Update_Dtll_Ali_Paci(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdateDtllAliPaci', body, { headers: this.basicHeaders }).toPromise();
  }

  get_all_cajas(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'get_All_Cajas', body, { headers: this.basicHeaders }).toPromise();
  }

  update_Caja(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarCaja', body, { headers: this.basicHeaders }).toPromise();
  }

  searchListarDetallePedidoNg(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'searchListarDetallePedidoNg', body, { headers: this.basicHeaders }).toPromise();
  }

  getEstados(): Promise<any> {
    return this.http.get<any>(Enviroment.url_backend + 'ListarEstados', { headers: this.basicHeaders }).toPromise();
  }

  ValidarToken(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ValidarToken', body, { headers: this.basicHeaders }).toPromise();
  }
  
  UpdateInserDtllPediNG(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdateInserDtllPediNG', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateDtllPediRealizaAll(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarDtllHistoricoRealizaAll', body, { headers: this.basicHeaders }).toPromise();
  }

  getAllPreOrdenes(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarAllPreOrden', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdatePreOrdenes(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualziarPreOrden', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateHistorialDesc(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'UpdateHistorialDesc', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateFirmaCalidad(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualziarFirmaCalidad', body, { headers: this.basicHeaders }).toPromise();
  }
  
  UpdateNoAproHist(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualziarNoApHistorial', body, { headers: this.basicHeaders }).toPromise();
  }





}
