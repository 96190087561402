import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { MensajeriaService } from '../../../shared/services/mensajeria.service'

@Component({
  selector: 'app-signature-pab',
  templateUrl: './signature-pab.component.html',
  styleUrls: ['./signature-pab.component.scss']
})
export class SignaturePabComponent implements OnInit, AfterViewInit {

  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;
  signaturePad: any;
  firma: any;
  ruta: any;
  IdEmpresa: string;
  Cargando: string;

  Trabajo: string;
  Doctor: string;
  Paciente: string;

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions, private mensajeriaService: MensajeriaService) {
    this.ruta = this.options.initialState;
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));

    this.Cargando = 'none'
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)"
    });
  }
  ngOnInit() {
  }

  limpiarfirma() {
    this.signaturePad.clear();
  }

  cancelarfirma() {
    this.bsModalRef.hide();
  }

  descargar(dataURL: any, nombre: any) {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL);
    } else {
      const blob = this.URLtoBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = nombre;
      this.firma = blob;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  URLtoBlob(dataURL: any) {
    const partes = dataURL.split(';base64,');
    const contentType = partes[0].split(':')[1];
    const raw = window.atob(partes[1]);
    const rawL = raw.length;
    const array = new Uint8Array(rawL);
    for (let i = 0; i < rawL; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return new Blob([array], { type: contentType })
  }

  async guardarfirma() {
    try {
        if (this.signaturePad.isEmpty()) {
          Swal.fire({
            title: "Debe firmar el documento",
            icon: 'warning'
          });
      } else {
       
        this.Cargando = 'Block'

        const blob = this.URLtoBlob(this.signaturePad.toDataURL('image/jpeg', 0.5));
        const body = new FormData();
        body.append('imagen', blob, this.ruta.IdHistorial + '.jpg');
        body.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Firmas');
        body.append('idHistorial', this.ruta.IdHistorial);
        
        await this.mensajeriaService.Cargar_Firma_S3(body).subscribe(async res => {
          await this.mensajeriaService.UpdateMensajeria(
            {
              idAc: 4,
              Oberva: "",
              Cumplida: 1,
              idmensajeria: this.ruta.idtblmensajeria
            }).then(result => {
              this.Cargando = 'none'
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success',
                timer: 5000
              });
              this.bsModalRef.hide();
            })
        })
      }
    } catch (error) {
      this.Cargando = 'none'
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }
}
