import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'fresado',
    loadChildren: () => import('../../fresado/fresado.module').then(m => m.FresadoModule)
  }
];