import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  basicHeaders: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  getAll(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'NgAgenda', body, { headers: this.basicHeaders }).toPromise();
  }

  getAllPlenacion(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'NgPlenacion', body, { headers: this.basicHeaders }).toPromise();
  }

}
