import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ListarSeleEmpresa, ListarSeleEmpresaRoot } from '../../../shared/models/listarSelecEmpresas';
import { ListarEmpresasService } from '../../../shared/services/listar-empresas.service'
import { AuthenticatorService } from '../../../shared/services/authenticator.service';
import { Router } from '@angular/router';
import { ListarPermisosNgRoot, ListarPermisosNg } from 'src/app/shared/models/listarLogin';
import { TercerosService } from 'src/app/shared/services/terceros.service';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}


@Component({
  selector: 'app-listarempresas',
  templateUrl: './listar-empresas.component.html',
  styleUrls: ['./listar-empresas.component.scss']
})

export class ListarempresasComponent implements OnInit {

  public selectCompanyForm: FormGroup;
  arrEmpresas: any[];
  idUsuario: string
  arrSucursales: any[];
  arrNew: any[];
  IdEmpresa: string;
  menusalida: ListarPermisosNg;


  constructor(
    private listarEmpresaServices: ListarEmpresasService,
    private toastaService: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private _TerceroService: TercerosService,
  ) {
    this.selectCompanyForm = this.fb.group({
      empresa: ['', Validators.required],
      sucursal: ['', Validators.required],
    })

    this.arrEmpresas = [];
    this.arrSucursales = [];
    this.arrNew = [];
    this.idUsuario = JSON.parse(sessionStorage.getItem('idUsuario'))
  }

  async ngOnInit() {
    try {

      await this._TerceroService.getPermisosNG({
        IdUsuario: this.idUsuario
      }).then((menu: ListarPermisosNgRoot) => {

        var menuglobal = menu.Listar_Permisos_Ng

        menuglobal.push({
          path: '/login',
          icon: 'log-out',
          isChildren: false,
          title: 'Salida Segura',
          type: 'link'
        });
        sessionStorage.setItem('menu', JSON.stringify(menuglobal))   
      })

      const res: any = await this.listarEmpresaServices.getAll({ id: this.idUsuario })
      this.arrEmpresas = res.ListarSeleEmpresa
      this.arrSucursales = res.ListarSeleEmpresa

      if (this.arrEmpresas.length === 1) {
        sessionStorage.setItem('IdEmpresa', this.arrEmpresas[0].idTlbEmpresas);
        sessionStorage.setItem('IdSucursal', this.arrEmpresas[0].idTblSucursal);
        sessionStorage.setItem('ChrNonEmp', this.arrEmpresas[0].ChrNonEmp);

        sessionStorage.setItem('ApiDian', this.arrEmpresas[0].ChrApi_TokenFE);
        sessionStorage.setItem('NitEmpresa', this.arrEmpresas[0].ChrNunNit);

        sessionStorage.setItem('Logo', this.arrEmpresas[0].ChrImagen);
        sessionStorage.setItem('IdToken', this.arrEmpresas[0].TokenIdWeb);
        // sessionStorage.setItem('Rol', this.arrEmpresas[0].ChrRol);
        
        this.router.navigate(['/home/dashboard'])
      }
    } catch (error) {
      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  onChange($event: any) {
    sessionStorage.setItem('IdEmpresa', $event.target.value);
    sessionStorage.setItem('IdSucursal', $event.target.value);

    const nomEmpresa = this.arrEmpresas.filter(callback => callback.idTlbEmpresas == ($event.target.value))
    sessionStorage.setItem('ChrNonEmp', nomEmpresa[0].ChrNonEmp);
    sessionStorage.setItem('ApiDian', nomEmpresa[0].ChrApi_TokenFE);
    sessionStorage.setItem('NitEmpresa', nomEmpresa[0].ChrNunNit);
    sessionStorage.setItem('Logo', nomEmpresa[0].ChrImagen);

    sessionStorage.setItem('IdToken', nomEmpresa[0].TokenIdWeb);
    // sessionStorage.setItem('Rol', nomEmpresa[0].ChrRol);

    this.arrNew = this.arrSucursales.filter(callback => callback.idTlbEmpresas == ($event.target.value))

  }

  async onClick() {
    /* try {
      this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
      

    } catch (error) {
      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    } */


    this.router.navigate(['/home/dashboard'])
  }

}
