import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/General/login/login.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { content } from "./shared/routes/content-routes";
import { AdminGuard } from './shared/guard/admin.guard';
import { ListarempresasComponent } from './components/General/listar-empresas/listar-empresas.component';
import { OrdenTrabajoComponent } from './components/Pedidos/orden-trabajo/orden-trabajo.component';
import { FresadoComponent } from './fresado/fresado.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'orden/:IdEmpresa',
    component: OrdenTrabajoComponent
  },
  {
    path: 'orden-fresado/:IdEmpresa',
    component: FresadoComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'listarempresas',
    component: ListarempresasComponent,
    canActivate: [AdminGuard]
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '**',
    redirectTo: 'home/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
