<!-- Container-fluid starts-->
<div class="container-fluid product-wrapper" [class.sidebaron]="sidebaron">
    <div class="product-grid">
        <div class="card">
            <div class="card-body">

                <form class="theme-form">
                    <div class="form-group row">
                        <b><label class="col-sm-12 col-form-label">Usuario:</label></b>
                        <div class="col-xl-6">
                            <form class="form-group">
                                <div class="form-group mb-0">
                                    <ng-autocomplete [data]="arrUsuarios" [searchKeyword]="keywordUsuario"
                                        placeholder="Buscar de Usuario..." (selected)='selectEventUsuario($event)'
                                        (inputChanged)='onChangeSearch($event)' (inputCleared)='onFocused($event)'
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                        [formControl]="FormPermison.controls['IdUsuario']" required
                                        [isLoading]='isloading'>
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.ChrNombreCompleto"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>

                                </div>
                            </form>
                        </div>
                    </div>


                    <div class="form-group row">
                        <b><label class="col-sm-12 col-form-label">Menu:</label></b>
                        <div class="col-sm-5">
                            <select class="form-control btn-pill digits" [formControl]="FormPermison.controls['IdMenu']"
                                required (change)="onChangeMenu($event)">
                                <option *ngFor="let menu of arrMenu" value={{menu.idmenu}}>
                                    {{menu.namemenu}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <b><label class="col-sm-12 col-form-label">Sub Menu:</label></b>
                        <div class="col-sm-5">
                            <select class="form-control btn-pill digits"
                                [formControl]="FormPermison.controls['IdSubMenu']" required>
                                <option *ngFor="let submenu of arrSubMenu" value={{submenu.idsubmenu}}>
                                    {{submenu.submenuname}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <button [disabled]="(!FormPermison.valid)" class="btn btn-primary mr-1"
                        (click)="guardar(FormPermison)">Guardar</button>

                </form>
            </div>
        </div>


        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable">

                    <ngx-datatable class="bootstrap" [rows]="arrMenuUsuario" [columnMode]="'force'"
                        [headerHeight]="'auto'" [footerHeight]="50" [rowHeight]="40" [limit]="10" [scrollbarH]="true">

                        <ngx-datatable-column name="Usuario" prop="ChrNombreCompleto"></ngx-datatable-column>
                        <ngx-datatable-column name="Menu" prop="namemenu">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="SubMenu" prop="submenuname"></ngx-datatable-column>

                        <ngx-datatable-column name="Activo">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <label class="toggle">
                                    <input class="toggle-checkbox" (click)="updatePermisos(row)" type="checkbox"
                                        [checked]="row.blnactivo">
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label"></span>
                                </label>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>


        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->