import { Injectable } from '@angular/core';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  basicHeaders: any;

  mapa: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 6.242556828409396;
  lng = -75.58483620006804;
  zoom = 13.5;
  markerDriver: any = null;

  constructor(private http: HttpClient) {
    mapboxgl.accessToken = Enviroment.mapPK

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  bulidMap(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.mapa = new mapboxgl.Map({
          container: 'map',
          style: this.style,
          zoom: this.zoom,
          center: [this.lng, this.lat]
        })

        this.mapa.addControl(new mapboxgl.NavigationControl());
        this.mapa.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccurary: true
          },
          trackUserLocation: true
        }))

        resolve(this.mapa);
      } catch (e) {
        reject(e)
      }
    });
  }

  addMarker(coords: any[]): void {

    /* 
        if(this.markerDriver){      
          this.markerDriver.remove();  
        } */

    for (let i = 0; coords.length; i++) {
      const el = document.createElement('div')
      el.className = 'marker';
      this.markerDriver = new mapboxgl.Marker(el)
        .setLngLat(coords[i])
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<h5>${coords[i].NombreEmpresa}</h5><p>${coords[i].nombreUsuario}</p>`
          )
        )
        .addTo(this.mapa)
    } 

  /*   const el = document.createElement('div')
    el.className = 'marker';

    
        this.markerDriver = new mapboxgl.Marker(el)
          .setLngLat(coords)
          .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h5>${sessionStorage.getItem('ChrNonEmp')}</h5><p>${sessionStorage.getItem('ChrNombreCompleto')}</p>`
            )
          )
          .addTo(this.mapa)

 */
  }

  /*  realtime() {
     this.mapa.on('load', async () => {
       const geojson = this.getLocation();
       this.mapa.addSource('iss', {
         type: 'geojson',
         data: geojson
       });
       // Add the rocket symbol layer to the map.
       this.mapa.addLayer({
         'id': 'iss',
         'type': 'symbol',
         'source': 'iss',
         'layout': {
           // This icon is a part of the Mapbox Streets style.
           // To view all images available in a Mapbox style, open
           // the style in Mapbox Studio and click the "Images" tab.
           // To add a new image to the style at runtime see
           // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
           'icon-image': 'rocket-15'
         }
       });
     });
 
 
 
   }
  */





  /*   getCoords(body): Promise<any> {
  
      return this.http.post<any>(Enviroment.url_backend + 'NgAgenda', body, { headers: this.basicHeaders }).toPromise();
    } */
  /*   coordActuales():void {
      navigator.geolocation.getCurrentPosition(successLocation, errorLocation, { enableHighAccuracy: true })
      function successLocation(position) {
        const el = document.createElement('div')
        el.className = 'marker';
        this.markerDriver = new mapboxgl.Marker(el)
          .setLngLat([position.coords.longitude, position.coords.latitude])
          .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>'Nada'</h3><p>'PabloFernade'</p>`
            )
          )
          .addTo(this.mapa)
      }
      function errorLocation() {
      }
   */
  //}



}
