import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ListarTipoDocumentoRoot, ListarTipoDocumento } from '../../../shared/models/terceros';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-ing-pacientes',
  templateUrl: './ing-pacientes.component.html',
  styleUrls: ['./ing-pacientes.component.scss']
})
export class IngPacientesComponent implements OnInit {

  FormIngPacientes: FormGroup;

  Cargando: string;
  boton: boolean
  IdEmpresa: string;
  IdSucursal: string;

  consecutivo: string;
  IdDtllOdontologo: any;

  arrTipoDoc: ListarTipoDocumento[];

  constructor(private terceroService: TercerosService,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,) {

    this.FormIngPacientes = this.fb.group({
      IdTipoDoc: ['', Validators.required],
      Identi: ['', Validators.required],
      Nombre: ['', Validators.required],

      IdEmpresa: [''],
      IdSucursal: [''],
      IdDtllOdontologo: ['']
    })

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.IdDtllOdontologo = this.options.initialState;
  }

  ngOnInit(): void {
    this.Cargar_TipoDocumentos();
    this.Cargar_Consecutivo_Paciente();
  }

  async GuardarPedido() {
    try {
      this.Cargando = 'Block';

      this.FormIngPacientes.value.IdEmpresa = this.IdEmpresa
      this.FormIngPacientes.value.IdSucursal = this.IdSucursal
      this.FormIngPacientes.value.IdDtllOdontologo = this.IdDtllOdontologo

      await this.terceroService.Crer_Paciente(this.FormIngPacientes.value).then(resp => {
        if(resp){
          Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
         
          this.bsModalRef.content
          this.bsModalRef.hide();

        }else{
          Swal.fire({
            title: "NO se realizo el proceso...!!!",
            html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + resp + '</p></div>', icon: 'error',
          });     
        }
      })

      this.Cargando = 'none';
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + error.error.message + '</p></div>', icon: 'error',
      });
    }


  }

  async Cargar_TipoDocumentos() {
    try {
      this.Cargando = 'Block';

      const resp: ListarTipoDocumentoRoot = await this.terceroService.Listar_Tipo_Documentos({})
      this.arrTipoDoc = resp.ListarTipoDocumentos;

      this.FormIngPacientes.get("IdTipoDoc").setValue(99)

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + error.error.message + '</p></div>', icon: 'error',
      });
    }

  }

  async Cargar_Consecutivo_Paciente() {
    try {
      this.Cargando = 'Block';

      const resp: any = await this.terceroService.Cargar_Consecutivo_Paciente({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal
      })

      this.consecutivo = resp.ListarConsecutivoPaciente[0].Codigo;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + error.error.message + '</p></div>', icon: 'error',
      });
    }

  }
}
