import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-fechas-facturas',
  templateUrl: './fechas-facturas.component.html',
  styleUrls: ['./fechas-facturas.component.scss']
})
export class FechasFacturasComponent implements OnInit {

  validationForm: FormGroup;
  modelFooter: NgbDateStruct;
  IdEmpresa: string;
  IdSucursal: string;
  boton: any
  factura: any;


  constructor(private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,) {

    this.factura = this.options.initialState;

    this.validationForm = this.fb.group({
      fechaexp: ['', Validators.required],
      fechavence: ['', Validators.required],
      idFactura: [this.factura.IdFactura]
    })
  }

  ngOnInit(): void {
    this.boton = false
  }

  async actualizarFechas(data: any) {
    this.boton = true
    this.validationForm.value.fechaexp = formatDate(this.validationForm.value.fechaexp, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
    this.validationForm.value.fechavence = formatDate(this.validationForm.value.fechavence, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")

    await this.invoiceService.UpdateDateInovice(this.validationForm.value).then((res) => {
      this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
      this.bsModalRef.hide();
    }, error => {
      this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
    })

  }


}
