<!-- Container-fluid starts -->
<form [formGroup]="validationForm">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 ">
                <div class="card">
                    <div class="card-header">
                        <h5>Proceso de desinfeccion
                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </h5>
                    </div>

                    <div class="card-body">

                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Entrada/Salida</th>
                                    <th scope="col">Tipo de Desinfeccion</th>
                                    <th scope="col">Solucion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>
                                    <div class="col">
                                        <div class="form-group m-t-15 custom-radio-ml">
                                            <div class="radio radio-secondary">
                                                <input type="radio" name="rdES" id="rdEntrada" value="Entrada" (change)="chkEntradaSalida($event)">
                                                <label for="rdEntrada">Entrada</label>
                                            </div>
                                            <div class="radio radio-success">
                                                <input type="radio" name="rdES" id="rdSalida" value="Salida" (change)="chkEntradaSalida($event)">
                                                <label for="rdSalida">Salida</label>
                                            </div>

                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col">
                                        <div class="form-group m-t-15 custom-radio-ml">
                                            <div class="radio radio-secondary">
                                                <input type="radio" name="rdAI" id="radio22" value="Aspersion" (change)="chkTipo($event)">
                                                <label for="radio22">Aspersion</label>
                                            </div>
                                            <div class="radio radio-success">
                                                <input type="radio" name="rdAI" id="radio55" value="Inmersion" (change)="chkTipo($event)">
                                                <label for="radio55">Inmersion</label>
                                            </div>

                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col">
                                        <div class="form-group row">
                                            <label class="col-sm-2.5 col-form-label"
                                                style="font-weight: bold;">Desinfectante:</label>
                                            <div class="col-sm-3">
                                                <input type="text" class="form-control" (change)="txtdesen($event)">
                                            </div>

                                            <label class="col-sm-2.5 col-form-label"
                                                style="font-weight: bold;">Concentracion:</label>
                                            <div class="col-sm-2">
                                                <input type="text" class="form-control" (change)="txtconcen($event)">
                                            </div>

                                            <label class="col-sm-2.5 col-form-label"
                                                style="font-weight: bold;">Tiempo Expo:</label>
                                            <div class="col-sm-2">
                                                <input type="text" class="form-control" [formControl]="validationForm.controls['TiempoExpo']">
                                            </div>

                                        </div>

                                    </div>
                                </td>

                            </tbody>
                        </table>

                        <div class="row">
                            <div [formArrayName]="'dtll'">
                                <div class="card-body"
                                    *ngFor="let item of validationForm.get('dtll')['controls'];  let i = index;"
                                    [formGroupName]="i">
                                    <hr width=auto>
                                    <div class="row">
                                        <label class="col-form-label pt-0"> <b>Caja:</b>
                                            {{validationForm.get('dtll')['controls'][i].value.Caja}}</label>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <label class="col-form-label pt-0"> <b>Odontologo:</b>
                                            {{validationForm.get('dtll')['controls'][i].value.Odontologo}}</label>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <label class="col-form-label pt-0"> <b>Paciente:</b>
                                            {{validationForm.get('dtll')['controls'][i].value.Paciente}}</label>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <label class="col-form-label pt-0"> <b>Trabajo:</b>
                                            {{validationForm.get('dtll')['controls'][i].value.Trabajo}}</label>
                                    </div>

                                    <!-- <div class="row">
                                        <tbody>
                                            <td>
                                                <div class="col">
                                                    <div
                                                        class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                        <div class="radio radio-primary">
                                                            <input type="radio"
                                                                name="{{validationForm.get('dtll')['controls'][i].value.Alguinato}}"
                                                                id="{{validationForm.get('dtll')['controls'][i].value.Alguinato}}"
                                                                value="Alguinato" (change)="chkalguinato(i, $event)">
                                                            <label
                                                                for="{{validationForm.get('dtll')['controls'][i].value.Alguinato}}"
                                                                class="mb-0">Alguinato</label>
                                                        </div>
                                                        <div class="radio radio-primary">
                                                            <input type="radio"
                                                                name="{{validationForm.get('dtll')['controls'][i].value.Silicona}}"
                                                                id="{{validationForm.get('dtll')['controls'][i].value.Silicona}}"
                                                                value="Silicona" (change)="chkSilicona(i, $event)">
                                                            <label
                                                                for="{{validationForm.get('dtll')['controls'][i].value.Silicona}}"
                                                                class="mb-0">Silicona</label>
                                                        </div>
                                                        <div class="radio radio-primary">
                                                            <input type="radio"
                                                                name="{{validationForm.get('dtll')['controls'][i].value.PastaZinquenolica}}"
                                                                id="{{validationForm.get('dtll')['controls'][i].value.PastaZinquenolica}}"
                                                                value="Pasta Zinquenolica" (change)="chkPasta(i, $event)">
                                                            <label
                                                                for="{{validationForm.get('dtll')['controls'][i].value.PastaZinquenolica}}"
                                                                class="mb-0">Pasta
                                                                Zinquenolica</label>
                                                        </div>
                                                        <div class="radio radio-primary">
                                                            <input type="radio"
                                                                name="{{validationForm.get('dtll')['controls'][i].value.ModeloYeso}}"
                                                                id="{{validationForm.get('dtll')['controls'][i].value.ModeloYeso}}"
                                                                value="Modelo Yeso" (change)="chkModelo(i, $event)">
                                                            <label
                                                                for="{{validationForm.get('dtll')['controls'][i].value.ModeloYeso}}"
                                                                class="mb-0">Modelo Yeso</label>
                                                        </div>
                                                        <div class="radio radio-primary">
                                                            <input type="radio"
                                                                name="{{validationForm.get('dtll')['controls'][i].value.Trabajo}}"
                                                                id="{{validationForm.get('dtll')['controls'][i].value.Trabajo}}"
                                                                value="{{validationForm.get('dtll')['controls'][i].value.Trabajo}}" (change)="chkTrabajo(i, $event)">
                                                            <label
                                                                for="{{validationForm.get('dtll')['controls'][i].value.Trabajo}}"
                                                                class="mb-0">{{validationForm.get('dtll')['controls'][i].value.Trabajo}}</label>
                                                        </div>


                                                    </div>
                                                </div>
                                            </td>
                                            <td style="padding-top: 30px;">
                                                <div class="form-group row">
                                                    <label class="col-sm-2.5 col-form-label"
                                                        style="font-weight: bold;">Otros: </label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control" (change)="txtotros(i, $event)">
                                                    </div>
                                                </div>
                                            </td>
                                        </tbody>

                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="btn-group">
                                <button *ngIf="!boton" class="btn btn-primary" [disabled]="!validationForm.valid"
                                    type="submit" (click)="guardarHistorial(validationForm.value)">Guardar</button>
                                <button *ngIf="boton" class="btn btn-primary" disabled
                                    type="submit">Guardando...!!!</button>
                                &nbsp;

                                <button class="btn btn-warning" type="button"
                                    (click)="Cancelaringreso()">Cancelar</button>
                            </div>

                        </div>
                    </div>





                    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                        <div class="spinnerContent">
                            <div class="spinnerItem">
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                            </div>
                            <div class="MensajeDeEspera">Cargando...!!!</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</form>