import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UserService } from './user.service';
import { Licencia } from '../models/licencia';
import { ListarLoginHttp } from '../models/listarLogin'
import { User } from '../models/user';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatorService {

  token: string;
  basicHeaders: any;
  user!: User

  licencia: boolean

  constructor(private http: HttpClient, private userService: UserService) {
    this.token = sessionStorage.getItem('token');
    this.ngsetHeaders(this.token);

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`})
  }

  ngLogin(body) {
    return this.http.post(Enviroment.url_backend + 'ListarLogin', body, { headers: this.basicHeaders }).pipe((map((response: any) => {
            
      if (this.decodeTokenToLicence(response.tokenAdentLab)){
        this.ngSaveData(response.tokenAdentLab);
        return response;
      } else {
        return ('False')
      }
  
    })), catchError((error => {
      return throwError(error)
    })))
  }

  ngSaveData(token) {
    this.token = token
    sessionStorage.setItem('token', token);
    this.user =  this.userService.deserializeUser(this.userService.decodeTokenToUser(this.token))
    sessionStorage.setItem('idUsuario', this.user.idTblUsuarios)
    sessionStorage.setItem('idtblTercero', this.user.idTblTerceros)
    sessionStorage.setItem('ChrNombreCompleto', this.user.ChrNombreCompleto)
    sessionStorage.setItem('urlimagen', this.user.urlImagen)
    sessionStorage.setItem('ChrUsuarios', this.user.ChrUsuarios)
  }

  decodeTokenToLicence(token): boolean {
    let decoded: Licencia = jwt_decode(token);

    if(decoded.user.licence != undefined){
      var today = new Date();
      let todaylicencia = formatDate(today, 'yyyy-MM-dd', 'en-US');
      var licencia = formatDate(decoded.user.licence.DtmVenceLicencia, 'yyyy-MM-dd', 'en-US');
  
      if (licencia >= todaylicencia){
        return true
      }else{
        return false
      }
    }
    
    return false
    
  }

  ngsetHeaders(token) {
    return this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    })
  }

  ngDeleteSession(){
    localStorage.clear()
    this.userService.user = null
  }

  renewToken({user}): Promise<any>{
    const body = {user}
    return this.http.post<any>(Enviroment.url_backend + 'renew-token', body, { headers: this.basicHeaders }).toPromise();
  }

}
