import { Component, OnInit } from '@angular/core';
import Dynamsoft from 'dwt';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WebTwain } from 'dwt/WebTwain';
import { ListarTercero, ListarTerceroRoot, ListarEstablecimientosRoot, ListarEstablecimientos, ListarOdoPaciente, ListarOdoPacienteRoot } from '../../../shared/models/terceros';
import { ListarCajasRoot, ListarCajas, Listar_ProcesosRoot, Listar_Procesos } from 'src/app/shared/models/pedidos';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { Listar_Trabajos, Listar_TrabajosRoot } from '../../../shared/models/trabajos';
import { TrabajosService } from 'src/app/shared/services/trabajos.service';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IngPacientesComponent } from '../../Terceros/ing-pacientes/ing-pacientes.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ing-pedidos',
  templateUrl: './ing-pedidos.component.html',
  styleUrls: ['./ing-pedidos.component.scss']
})
export class IngPedidosComponent implements OnInit {

  validationForm: FormGroup;
  dtllpedidofrom: FormGroup;
  dtllHistorialfrom: FormGroup;
  dtllImagenesfrom: FormGroup;

  private bsModalRef: BsModalRef;

  DWObject: any;
  containerId = "dwtcontrolContainer";
  boton: any;
  isloading: boolean;
  Cargando: string;
  btnpaciente: boolean
  validardatos: boolean

  arrOdontologo: ListarTercero[];
  arrClinicas: ListarEstablecimientos[];
  arrTrabajos: Listar_Trabajos[];
  arrCajas: ListarCajas[];
  arrPaciente: ListarOdoPaciente[];
  arrProcesos: Listar_Procesos[];
  arrAsignado: ListarTercero[];

  keyword: string;
  keywordTrabajo: string;
  keywordCajas: string;
  keywordPaciente: string;
  keywordClinicas: string;
  keywordProceso: string;
  keywordAsginado: string;

  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  IdOdontologo: number;
  IdClinica: number;
  IdSucursalClinica: number;
  IdDtllOdontologo: number;
  IdPaciente: number 
  IdCaja: number;
  IdTrabajo: number;
  IdProceso: number;
  IdAsginado: number;


  constructor(private fb: FormBuilder,
    private terceroService: TercerosService,
    private trabajosService: TrabajosService,
    private toastaService: ToastrService,
    private pedidosService: PedidosService,
    private modalService: BsModalService,
    private router: Router) {

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.validationForm = this.fb.group({
      IdEmpresa: [''],
      IdSucursal: [''],
      IdTercero: ['', Validators.required],
      IdDetalleTercero: ['', Validators.required],
      DesPedido: [''],
      NotaPedido: [''],
      IdRealizado: [''],
      IdEstados: [2],
      Preorden: [0],
      Sucursal: [''],

      DtllPedido: [[
        this.dtllpedidofrom = this.fb.group({
          idTipoTrabajo: ['', Validators.required],
          idCajas: ['', Validators.required],
          idEstados: [2],
          FechaIngreso: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"), Validators.required],
          FechaEgreso: [''],
          Descripcion: ['', Validators.required],
          Cantidad: ['', Validators.required],
          IdPacientes: ['', Validators.required],
          chrMaterial: [''],
          chrSolped: [''],
          IdDtDental: [0],

          DtllHistoryOrder: [
            [this.dtllHistorialfrom = this.fb.group({
              idProceso: ['', Validators.required],
              varDescrio: ['', Validators.required],
              idUsuario: [''],
              fk_UsuarioRealiza: ['', Validators.required],
              fechaEntrada: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
              horaEntrada: ['', Validators.required],

              Imagenes: [[
                this.dtllImagenesfrom = this.fb.group({
                  nomtreImag: [''],
                  bitIma: ['']
                })
              ]]
            })
            ], Validators.required]

        })
      ], Validators.required]



    })

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.isloading = false;

    this.keyword = 'ChrNombreCompleto';
    this.keywordTrabajo = 'ChrNombreTrabajo';
    this.keywordCajas = 'ChrNumeCaja';
    this.keywordPaciente = 'Nom_Paciente';
    this.keywordClinicas = 'ChrNombreCompleto';
    this.keywordProceso = 'ChrNombreProceso';
    this.keywordAsginado = 'ChrNombreCompleto';

    this.Cargando = 'none';

    this.btnpaciente = true
    this.validardatos = false
  }

  async ngOnInit(): Promise<any> {
    try {
      /*  Dynamsoft.DWT.Containers = [{
         WebTwainId: 'dwtObject',
         ContainerId: this.containerId,
         Width: '300px',
         Height: '400px'
       }];
 
       Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
         this.Dynamsoft_OnReady();
       });
       Dynamsoft.DWT.ProductKey = 't0153KQMAAIwjorNtwgRyC1ph6UT241+4JeJzJgN7Hn7bwOiAm/hP28fNeriNMNB9QyiOvDhUmiExszo/eYH/ulAOBZfzPv8Nk28EHYNsIu2VyvRqjBzmbUarwU2dv7G37swHx8WgTmNkwqhvrD9j08189lD6mVsjE0Z9083cmH6fUyj/+FVwq5k5MjJh1Dc189Wsa4qk8geZWJ8J';
       Dynamsoft.DWT.ResourcesPath = 'assets/dwt-resources';
       Dynamsoft.DWT.Load(); */

      this.boton = false

      this.Cargar_Odontologo();
      this.Cargar_Trabajos();
      this.Cargar_Cajas();
      this.Cargar_Asginado();


    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async selectEvent(item) {
    try {
      this.Cargas_Establecimientos_Odo(item.idTblTerceros);
      this.Cargas_Pacientes_Odo(item.idTblTerceros);

      this.IdOdontologo = item.idTblTerceros
      this.IdDtllOdontologo = item.idtblDtllTipoTercero

    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async selectEventClinica(item) {
    this.IdClinica = item.idTblTerceros
    this.IdSucursalClinica = item.idtblsucursalclin;
  }

  async selectEventTrabajo(item) {
    try {
      this.Cargar_Procesos(item.idTblTrabajos);
      this.IdTrabajo = item.idTblTrabajos
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async selectEventCaja(item) {
    this.IdCaja = item.idtblcajas
  }

  async selectEventPaciente(item) {
    this.IdPaciente = item.idTblTerceros
  }

  async selectEventProceso(item) {
    this.IdProceso = item.idTblProcesos
  }

  async selectEventAsginado(item) {
    this.IdAsginado = item.idTblTerceros
  }

  onChangeSearch(val: string) {
  }

  onFocused(e) {
  }

  onFocusedClinica(e) {
  }

  onFocusedtrabjo(e) {
    try {
      if (this.IdEmpresa === '22') {
        this.dtllpedidofrom.get("idTipoTrabajo").setValue(this.arrTrabajos[0])
        this.selectEventTrabajo(this.arrTrabajos[0])
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onFocusedCaja(e) {
    try {
      this.dtllpedidofrom.get("idCajas").setValue(this.arrCajas[0])
      this.selectEventCaja(this.arrCajas[0])
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onFocusedPaciente(e) {
    this.btnpaciente = false
  }

  onFocusedProcesos(e) {

  }

  onFocusedAsginado(e) {

  }

  Dynamsoft_OnReady() {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
  }

  acquireImage(): void {
    if (this.DWObject) {
      this.DWObject.SelectSource(() => {
        this.DWObject.OpenSource();
        this.DWObject.AcquireImage();
      }, () => { });
    }
  }

  async Cargar_Odontologo() {
    try {

      this.Cargando = 'Block';

      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "5",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })

      this.arrOdontologo = resp.ListarTerceros;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Trabajos() {
    try {
      this.Cargando = 'Block';

      const resp: Listar_TrabajosRoot = await this.trabajosService.Cargar_Trabajos(
        {
          IntEmpresa: this.IdEmpresa,
          IntSucursal: this.IdSucursal,
          ChrNombreTra: "5",
          IdTipoBus: 1
        })
      this.arrTrabajos = resp.Listar_Trabajos;

      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async Cargar_Cajas() {
    try {
      this.Cargando = 'Block';

      const resp: ListarCajasRoot = await this.pedidosService.ListarCajas(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          ChrNumCaja: "5",
          TipoBusq: "1"
        })
      this.arrCajas = resp.ListarCajas;

      if (this.IdEmpresa == '47') {
        this.dtllpedidofrom.get("idCajas").setValue('0001')
        this.dtllHistorialfrom.controls.idProceso.errors.required = false
        this.IdCaja = 20994
      }


      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async Cargas_Establecimientos_Odo(id) {
    try {

      this.Cargando = 'Block';

      const resp: ListarEstablecimientosRoot = await this.terceroService.Cargar_Establecimientos(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdOdontologo: id,
        })

      this.arrClinicas = resp.ListarEstablecimientos;

      this.Cargando = 'none';

      if (this.arrClinicas.length == 1) {
        this.validationForm.get("IdDetalleTercero").setValue(this.arrClinicas[0])
        this.IdClinica = this.arrClinicas[0].idTblTerceros
        this.IdSucursalClinica = this.arrClinicas[0].idtblsucursalclin;
      }



    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async Cargas_Pacientes_Odo(id) {
    try {

      this.Cargando = 'Block';

      const resp: ListarOdoPacienteRoot = await this.terceroService.Listar_Odo_Paciente(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          TipoBus: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          IdOdonPe: id,
        })

      this.arrPaciente = resp.ListarOdoPaciente;

      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async Cargar_Procesos(id) {
    try {

      this.Cargando = 'Block';

      const resp: Listar_ProcesosRoot = await this.pedidosService.ListarProcesos(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdTrabajos: id,
          IdBusqueda: "1",
          NombreTrabajo: ""
        })
      this.arrProcesos = resp.Listar_Procesos;

      this.Cargando = 'none';

      if (this.arrProcesos.length == 0) {
        this.dtllHistorialfrom.get("idProceso").setValue('ENTRADA')
        this.dtllHistorialfrom.controls.idProceso.errors.required = false
        this.IdProceso = 0
      } else {
        this.dtllHistorialfrom.get("idProceso").setValue(this.arrProcesos[0])
        this.selectEventProceso(this.arrProcesos[0])
      }

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async Cargar_Asginado() {
    try {
      this.Cargando = 'Block';
      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrAsignado = resp.ListarTerceros;
      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async GuardarPedido(data: any) {
    try {

      await this.validador().then(async (validado) => {
        if (validado) {

          this.Cargando = 'Block'

          this.boton = true

          this.validationForm.value.IdEmpresa = this.IdEmpresa
          this.validationForm.value.IdSucursal = this.IdSucursal
          this.validationForm.value.IdTercero = this.IdOdontologo
          this.validationForm.value.IdDetalleTercero = this.IdClinica
          this.validationForm.value.Sucursal = this.IdSucursalClinica
          this.validationForm.value.IdRealizado = this.IdUsuario

          this.dtllpedidofrom.value.FechaEgreso = formatDate(this.dtllHistorialfrom.value.horaEntrada, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
          this.dtllpedidofrom.value.idTipoTrabajo = this.IdTrabajo
          this.dtllpedidofrom.value.idCajas = this.IdCaja
          this.dtllpedidofrom.value.IdPacientes = this.IdPaciente


          if (this.arrProcesos.length == 0) {
            this.dtllHistorialfrom.value.idProceso = 0
          } else {
            this.dtllHistorialfrom.value.idProceso = this.IdProceso
          }

          this.dtllHistorialfrom.value.horaEntrada = formatDate(this.dtllHistorialfrom.value.horaEntrada, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
          this.dtllHistorialfrom.value.idUsuario = this.IdUsuario
          this.dtllHistorialfrom.value.fk_UsuarioRealiza = this.IdAsginado

          this.dtllHistorialfrom.value.Imagenes = [this.dtllImagenesfrom.value]
          this.dtllpedidofrom.value.DtllHistoryOrder = [this.dtllHistorialfrom.value]
          this.validationForm.value.DtllPedido = [this.dtllpedidofrom.value]


          if (this.IdEmpresa == '22') {
            this.dtllpedidofrom.value.idTipoTrabajo = 2230;
            this.dtllpedidofrom.value.idCajas = 20994;

            this.dtllHistorialfrom.value.idProceso = 5644;
          }

          await this.pedidosService.Insertar_Pedido(this.validationForm.value).then((res) => {
            if (res) {

              this.Cargando = 'none'

              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });


              this.boton = false

              if (this.IdEmpresa == '47') {
                this.pedidosService.Insertar_DtllHisto_Precio({
                  IdHistorial: res.Result.Historial.insertId
                }).then((resp) => { })

                this.pedidosService.Update_Dtll_Ali_Paci({
                  IdPaciente: this.IdPaciente,
                  idDtll: res.Result.DtllPedido.insertId,

                  IdEmpresa: this.IdEmpresa,
                  IdSucursal: this.IdSucursal
                }).then((resp) => { })

              }

              this.router.navigate(['/home/dashboard'])

            }
          }, error => {

            this.Cargando = 'none'

            this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
          })
        }
      })
    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> ' + error + ' </p></div>', icon: 'error'
      });
    }
  }

  showModalIngPaciente() {

    if (this.IdDtllOdontologo != undefined) {

      this.bsModalRef = this.modalService.show(IngPacientesComponent, { backdrop: 'static', class: 'modal-lg', initialState: this.IdDtllOdontologo });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.Cargas_Pacientes_Odo(this.IdOdontologo)
      })

    } else {
      Swal.fire({
        title: "NO se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p> No se puede crear paciente sin primero elegir el odontologo </p></div>', icon: 'error',
      });
    }

  }

  async validador() {

    if (this.IdOdontologo === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin odontologo, por favor seleccione el odontologo </p></div>', icon: 'error'
      });

      return false
    }


    if (this.IdDtllOdontologo === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin Clinica, por favor seleccione una clinica </p></div>', icon: 'error'
      });

      return false
    }


    if (this.IdPaciente === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin Paciente, por favor seleccione el Paciente </p></div>', icon: 'error'
      });

      return false
    }


    if (this.IdCaja === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin caja, por favor seleccione el caja </p></div>', icon: 'error'
      });

      return false
    }


    if (this.IdTrabajo === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin trabajo, por favor seleccione el trabajo </p></div>', icon: 'error'
      });

      return false
    }


    if (this.IdProceso === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin proceso, por favor seleccione el proceso </p></div>', icon: 'error'
      });

      return false
    }

    if (this.IdAsginado === undefined) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> No se puede guardar sin asignado, por favor seleccione el asignado </p></div>', icon: 'error'
      });

      return false
    }


    return true;
  }

  Cancelaringreso() {
    this.router.navigate(['/home/dashboard'])
  }

}
