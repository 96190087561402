import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService {
  
  basicHeaders: any;
  token: string;
  IdEmpresa: string;
  body: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
    
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    }
    )
  }

  StTrabajos_Ingresados(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'StTrabajosIngresados', body, { headers: this.basicHeaders }).toPromise();
  }

  StCantIngre_Actual(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'StCantIngreActual', body, { headers: this.basicHeaders }).toPromise();
  }

  StCantIngre_Anterior(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'StCantIngreAnterior', body, { headers: this.basicHeaders }).toPromise();
  }

  StCantTrabajos_x_Estados(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'StCantTrabajosxEstados', body, { headers: this.basicHeaders }).toPromise();
  }


}
