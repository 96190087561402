import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtdentalServices } from '../../../shared/services/dtdental.service'
import { User, LoginDtDental, PetitionRoot, Petition } from '../../../shared/models/dtDental'
import { SocketWebService } from 'src/app/shared/services/socket-web.service';

@Component({
  selector: 'app-invitaciondtdental',
  templateUrl: './invitaciondtdental.component.html',
  styleUrls: ['./invitaciondtdental.component.scss']
})
export class InvitaciondtdentalComponent implements OnInit {

  user: User;
  userdt: LoginDtDental;
  arrPeticiones: Petition[];
  arrNew: any[];
  IdEmpresa: number;
  Cargando: string;


  constructor(
    private _DtDentalService: DtdentalServices,
    private toastaService: ToastrService,
    private _socketService: SocketWebService) {

    this.Cargando = 'none';
    this.IdEmpresa = parseInt(sessionStorage.getItem('IdEmpresa'));

    /*   this._socketService.listen('resinvitacionesdt').subscribe(async data => {
        this._DtDentalService.getAllPetitions().then((response) => {
          this.arrPeticiones = response.petitions;
          this.arrNew = this.arrPeticiones.filter(callback => callback.laboratory_id == (this.IdEmpresa))
        });
      }) */
  }

  async ngOnInit() {
    try {

      this.Cargando = 'Block';

      await this._DtDentalService.login().then((resp) => {
        sessionStorage.setItem('tokendt', resp.headers.get('access-token'));
        sessionStorage.setItem('clientdt', resp.headers.get('client'));
      })

      await this._DtDentalService.getAllPetitions().then((response) => {
        this.arrPeticiones = response.petitions;
        this.arrNew = this.arrPeticiones.filter(callback => callback.laboratory_id == (this.IdEmpresa))
      })

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';

      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async onClicksucces(idPetitions) {

    this.Cargando = 'Block';

    await this._DtDentalService.UpdatePetitionsStatus(idPetitions, 1).then((res) => {
      this.toastaService.success('Informacion actualizada correctamente', 'Mensaje', { timeOut: 3000 });

      this.Cargando = 'none';

      this.ngOnInit()

    }).catch((error) => {
      this.Cargando = 'none';
      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  async onClickdanger(idPetitions) {
    this.Cargando = 'Block';

    await this._DtDentalService.UpdatePetitionsStatus(idPetitions, 2).then((res) => {
      this.toastaService.success('Informacion actualizado correctamente', 'Mensaje', { timeOut: 3000 });

      this.Cargando = 'none';

      this.ngOnInit()

    }).catch((error) => {
      this.Cargando = 'none';
      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  getCellClass({ value }): any {
    return {
      'verde': value === 'Vinculado',
      'rojo': value === 'Rechazado',
      'naranja': value === 'En proceso'
    };
  }

}
