import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { get_All_Cajas, get_All_CajasRoot } from 'src/app/shared/models/pedidos';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

@Component({
  selector: 'app-lista-cajas',
  templateUrl: './lista-cajas.component.html',
  styleUrls: ['./lista-cajas.component.scss']
})
export class ListaCajasComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;

  arrCajas: get_All_Cajas[];
  temp: get_All_Cajas[];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor( private modalService: BsModalService,
    private pedidoServices: PedidosService
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.Cargando = 'none';
  }

  async ngOnInit() {
    this.cargar_Cajas()
  }

  updateFilternumero(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.ChrNumeCaja.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrCajas = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }
 

  async cargar_Cajas() {
    try {
      this.Cargando = 'Block';

      const res: get_All_CajasRoot = await this.pedidoServices.get_all_cajas(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
        })

      this.temp = [...res.get_All_Cajas]
      this.arrCajas = res.get_All_Cajas;

        console.log(this.arrCajas);
        
      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }
  }

  async updatecaja(row: get_All_Cajas){
    try {

      let ocupado;

        if(row.BlnCajaOcua == true){
          ocupado = false;
        } else{
          ocupado = true;
        }

          this.Cargando = 'Block';

          await this.pedidoServices.update_Caja(
            {
              IDCaja: row.idtblcajas,
              FkEmpresa: this.IdEmpresa,
              FkSucursal: this.IdSucursal,
              Ocupada: ocupado
            }).then((resp)=>{
              if(resp){
                Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                this.Cargando = 'none';
                this.cargar_Cajas();
              }else{
                Swal.fire({
                  title: "NO se realizo el proceso...!!",
                  html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + resp + '</p></div>', icon: 'error'
                });
              }
            }) 

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }
    
  }

}

