import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-updatetratamientos',
  templateUrl: './updatetratamientos.component.html',
  styleUrls: ['./updatetratamientos.component.scss']
})
export class UpdatetratamientosComponent implements OnInit {

  validationForm: FormGroup;
  formPrecios: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  IdEmpresa: string;
  IdSucursal: string;
  boton: any;
  idTrabajo: any;
  idtblTercero: string;
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  precio: string;

  constructor(private fb: FormBuilder,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idtblTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.idTrabajo = this.options.initialState;

    this.arrIngreso = [];
    this.precio = "";

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.validationForm = this.fb.group({
      typePlaca: ['', [Validators.required]],
      marcaPlaca: ['', [Validators.required]],
      numPlacas: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)]],
      descripcion: ['', Validators.required],

      IdUsuario: [this.idtblTercero],
      observacion: ['', Validators.required],
      Fk_UsuarioRealiza: ['', Validators.required],
      fechaentrega: ['', Validators.required],
      IdDtalle: [this.idTrabajo.idtblDtllPedidos],
      idtblDtllHistorico: [this.idTrabajo.idtblDtllHistorico],
      //idtblhistoricoprecio: [this.idTrabajo.idtblhistoricoprecio],
      //Preciouni: [[]]

    })

    this.formPrecios = this.fb.group({
      IdPrecio: ['', Validators.required],
      Cantidad: ['', Validators.required],
      Preciouni: ['', Validators.required],
      idtblhistoricoprecio: [''],
      Nota: ['', Validators.required]
    })

  }

  async ngOnInit() {
    try {
      this.boton = false;
      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrUsuarios = resp.ListarTerceros;

      const respu: ListaPrecioAlineadoreRoot = await this.pedidosServices.getPrecioAlinieadores({
        id: this.idTrabajo.IdClasAlineadores
      })
      this.arrPrecios = respu.ListaPrecioAlineadores;

      this.validationForm.get("typePlaca").setValue(this.idTrabajo.Tipo)
      this.validationForm.get("marcaPlaca").setValue(this.idTrabajo.Marca)
      this.validationForm.get("numPlacas").setValue(this.idTrabajo.Cantidad_T)
      this.validationForm.get("descripcion").setValue(this.idTrabajo.Arcada)

      this.validationForm.get("observacion").setValue(this.idTrabajo.Observaciones)
      this.validationForm.get("Fk_UsuarioRealiza").setValue(this.idTrabajo.Fk_UsuarioRealiza)

      let fecha = formatDate(this.idTrabajo.Fecha_Entrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
      this.validationForm.get("fechaentrega").setValue(fecha)

      this.formPrecios.get("IdPrecio").setValue(this.idTrabajo.FkPreciosHisto)
      this.formPrecios.get("Cantidad").setValue(this.idTrabajo.Cantidad_E)
      this.formPrecios.get("Preciouni").setValue(this.idTrabajo.precioUnitario)
      this.formPrecios.get("Nota").setValue(this.idTrabajo.Descripcion)


    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async guardarTratamiento(datos: any) {
    
    this.validationForm.value.fechaentrega = formatDate(this.validationForm.value.fechaentrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
    this.formPrecios.value.idtblhistoricoprecio = this.idTrabajo.idtblhistoricoprecio   

    this.boton = true
   
    await this.pedidosServices.Actualizar_Tratamientos(this.validationForm.value).then( async (res) => {      
      await this.pedidosServices.UpdatePrecioHistorico(this.formPrecios.value).then((resp) => {
        if (resp) {
          this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
          this.bsModalRef.hide();
        }
      })

    }, error => {
      console.log(error);

      this.toastaService.warning(error.error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
      this.bsModalRef.hide();
    })

  }

  onChange($event) {
    this.precio = String(this.arrPrecios[$event.target.value - 1].Valor);
    this.formPrecios.get("Preciouni").setValue(this.precio)
  }




}
