<!-- Container-fluid starts -->
<div class="container-fluid">

    <div id="htmlData" #pdfTable>
        <div class="row">
            <div class="col-sm-12 ">
                <div class="row justify-content-center">
                    <div class="row mb-2" style="padding: 20px; align-items: center;">
                        <div class="col-auto"><img width="280" height="120" alt="" src={{logo}}>
                        </div>
                        <div class="col">
                            <h2 class="mb-1">{{nombreEmpresa}}</h2>
                            <p class="mb-2"><b> Dirrecion: </b> {{direccion}} &nbsp;&nbsp; <b> Telefono: </b>
                                {{telefono}}
                            </p>
                            <p class="mb-2"><b> Ciudad: </b> {{ciudad}} &nbsp;&nbsp; <b> Pais: </b> {{pais}}</p>
                            <p class="mb-2"><b> PRESCRIPCION DE DISPOSITIVO MEDICO SOBRE MEDIDA BUCAL </b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h5>DATOS DEL DOCTOR Y CLINICA </h5>
                    </div>
                    <div class="card-body">
                        <form class="needs-validation" validate>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustomUsername">Identificacion</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroupPrepend">ID</span>
                                        </div>
                                        <input type="text" class="form-control" (change)="DatosDoc($event)" id="validationCustomUsername"
                                            placeholder="Identificacion" aria-describedby="inputGroupPrepend"
                                            [formControl]="validationForm.controls['idDoctor']" required >
                                    </div>
                                    <div *ngIf="validationForm.controls.idDoctor.touched && validationForm.controls.idDoctor.errors?.required"
                                        class="text text-danger">
                                        La identificacion es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                    <div *ngIf="validationForm.controls.idDoctor.touched && validationForm.controls.idDoctor.errors?.pattern"
                                        class="text text-danger">
                                        La identificacion debe ser alfanumero.
                                    </div>
                                </div>
                                <div class="col-md-8 mb-3">
                                    <label for="validationCustom01">Nombres y Apellidos</label>
                                    <input type="text" class="form-control" id="validationCustom01"
                                        placeholder="Nombres" [formControl]="validationForm.controls['NomDoctor']"
                                        required>
                                    <div *ngIf="validationForm.controls.NomDoctor.touched && validationForm.controls.NomDoctor.errors?.required"
                                        class="text text-danger">
                                        El nombre es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                    <div *ngIf="validationForm.controls.NomDoctor.touched && validationForm.controls.NomDoctor.errors?.pattern"
                                        class="text text-danger">
                                        El nombre debe ser alfanumero.
                                    </div>
                                </div>
                                <!-- <div class="col-md-4 mb-3">
                                    <label for="validationCustom02">Apellidos</label>
                                    <input type="text" class="form-control" id="validationCustom02"
                                        placeholder="Apellidos" [formControl]="validationForm.controls['ApeDoctor']"
                                        required>
                                    <div *ngIf="validationForm.controls.ApeDoctor.touched && validationForm.controls.ApeDoctor.errors?.required"
                                        class="text text-danger">
                                        El apellido es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                    <div *ngIf="validationForm.controls.ApeDoctor.touched && validationForm.controls.ApeDoctor.errors?.pattern"
                                        class="text text-danger">
                                        El apellido debe ser alfanumero.
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom03">Dirrecion</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="Dirrecion" [formControl]="validationForm.controls['DirDoctor']"
                                        required>

                                    <div *ngIf="validationForm.controls.DirDoctor.touched && validationForm.controls.DirDoctor.errors?.required"
                                        class="text text-danger">
                                        La direccion es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationCustom04">Telefono</label>
                                    <input type="text" class="form-control" id="validationCustom04"
                                        placeholder="Telefono" [formControl]="validationForm.controls['TelDoctor']"
                                        required>
                                    <div *ngIf="validationForm.controls.TelDoctor.touched && validationForm.controls.TelDoctor.errors?.required"
                                        class="text text-danger">
                                        El telefono es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                    <div *ngIf="validationForm.controls.TelDoctor.touched && validationForm.controls.TelDoctor.errors?.pattern"
                                        class="text text-danger">
                                        El telefono debe ser valido, el telefono debe contener 10 carateres
                                    </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationCustom05">Correo</label>
                                    <input type="text" class="form-control" id="validationCustom05" placeholder="Correo"
                                        [formControl]="validationForm.controls['CorDoctor']" required>
                                    <div *ngIf="validationForm.controls.CorDoctor.touched && validationForm.controls.CorDoctor.errors?.email"
                                        class="text text-danger">
                                        El correo debe ser un correo valido.
                                    </div>
                                    <div *ngIf="validationForm.controls.CorDoctor.touched && validationForm.controls.CorDoctor.errors?.required"
                                        class="text text-danger">
                                        El correo es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustom03">#Registro Profesional</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="#Registro Profesional"
                                        [formControl]="validationForm.controls['RegDoctor']" required>

                                    <div *ngIf="validationForm.controls.RegDoctor.touched && validationForm.controls.RegDoctor.errors?.required"
                                        class="text text-danger">
                                        El numero de registro es requerida segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustom03">Clinica</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="Clinica" [formControl]="validationForm.controls['Clinica']">
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustom03">Sucursal</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="Sucursal" [formControl]="validationForm.controls['Sucursal']">
                                </div>
                            </div>

                            <div class="form-row">
                                <form class="was-validated">
                                    <label for="validationCustom05">Adjunta Rut (Si es primera vez que tienes relacion
                                        con
                                        el laboratorio)</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="validatedCustomFile"
                                            (change)="captureRut($event)">
                                        <label class="custom-file-label"
                                            for="validatedCustomFile">{{NomArchivoRut}}</label>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h5>PRESCRIPCION Y DATOS DEL PACIENTE </h5>
                    </div>
                    <div class="card-body">
                        <form class="needs-validation" novalidate>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustomUsername">Fecha Prescripción</label>
                                    <div class="input-group">
                                        <input class="form-control digits" type="date"
                                            [formControl]="validationForm.controls['FechaOrden']" readonly="true">
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustomUsername">Fecha Entrega:</label>
                                    <div class="input-group">
                                        <input class="form-control digits" type="date" value="2018-01-01"
                                            [formControl]="validationForm.controls['FechaEntrega']">
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustom02">#Prescripción</label>
                                    <input type="text" class="form-control" id="validationCustom02"
                                        placeholder="# Orden" readonly="true"
                                        [formControl]="validationForm.controls['NumOrden']">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustomUsername">Identificacion</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroupPrepend">ID</span>
                                        </div>
                                        <input type="text" class="form-control"  (change)="DatosPaci($event)" id="validationCustomUsername"
                                            placeholder="Identificacion" aria-describedby="inputGroupPrepend"
                                            [formControl]="validationForm.controls['IdPaciente']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.IdPaciente.touched && validationForm.controls.IdPaciente.errors?.required"
                                        class="text text-danger">
                                        El numero de identificacion del paciente es requerida segun Res. 214 de
                                        15-02-2022
                                        del MinSalud.
                                    </div>
                                </div>
                                <div class="col-md-8 mb-3">
                                    <label for="validationCustom01">Nombres y Apellidos</label>
                                    <input type="text" class="form-control" id="validationCustom01"
                                        placeholder="Nombres" [formControl]="validationForm.controls['NomPaciente']"
                                        required>
                                    <div *ngIf="validationForm.controls.NomPaciente.touched && validationForm.controls.NomPaciente.errors?.required"
                                        class="text text-danger">
                                        El nombre del paciente es requerido segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                </div>
                                <!-- <div class="col-md-4 mb-3">
                                    <label for="validationCustom02">Apellidos</label>
                                    <input type="text" class="form-control" id="validationCustom02"
                                        placeholder="Apellidos" [formControl]="validationForm.controls['ApelPaciente']"
                                        required>
                                    <div *ngIf="validationForm.controls.ApelPaciente.touched && validationForm.controls.ApelPaciente.errors?.required"
                                        class="text text-danger">
                                        El apellido del paciente es requerido segun Res. 214 de 15-02-2022 del MinSalud.
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom03">Dirrecion</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="Dirrecion" [formControl]="validationForm.controls['DirPaciente']">
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationCustom04">Telefono</label>
                                    <input type="text" class="form-control" id="validationCustom04"
                                        placeholder="Telefono" [formControl]="validationForm.controls['TelPaciente']">
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationCustom05">Correo</label>
                                    <input type="text" class="form-control" id="validationCustom05" placeholder="Correo"
                                        [formControl]="validationForm.controls['CorPaciente']">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom05">#Historia Clinica</label>
                                    <input type="text" class="form-control" id="validationCustom05"
                                        placeholder="#Historia Clinica"
                                        [formControl]="validationForm.controls['HisClinicaPaciente']" required>
                                    <div *ngIf="validationForm.controls.HisClinicaPaciente.touched && validationForm.controls.HisClinicaPaciente.errors?.required"
                                        class="text text-danger">
                                        El numero de historia clinica del paciente es requerido segun Res. 214 de
                                        15-02-2022
                                        del MinSalud.
                                    </div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <form class="was-validated">
                                        <label for="validationCustom05">Adjunta Fotos del paciente (Comprima todas las
                                            fotos
                                            y enviar un solo archivo)</label>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile"
                                                (change)="captureFotosPaciente($event)">
                                            <label class="custom-file-label"
                                                for="validatedCustomFile">{{NomArchivoFoto}}</label>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h5>TIPO DE TRABAJO Y MATERIALES
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="form-row"> 
                            <!-- <div class="col">
                            <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                

                                <div class="radio radio-primary" *ngFor="let trabajo of arrTrabajos">
                                    <input type="radio" name="radio1" id="{{trabajo.id}}" value="{{trabajo.id}}"
                                        [formControl]="validationForm.controls['TipoTrabajo']" required>
                                    <label for="{{trabajo.id}}" class="mb-0">{{trabajo.name}}<span
                                            class="digits"></span></label>
                                </div>
                                <div *ngIf="validationForm.controls.TipoTrabajo.touched && validationForm.controls.TipoTrabajo.errors?.required"
                                    class="text text-danger">
                                    El tipo de trabajo es requerido segun Res. 214 de 15-02-2022 del MinSalud.
                                </div>

                            </div>
                        </div> -->

                            <div class="col-md-12 col-mb-3">
                                <label class="col-form-label pt-0">Trabajo:</label>
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="arrTrabajos" [searchKeyword]="keywordTrabajo"
                                        placeholder="Seleciona Trabajo" (selected)='selectEventTrabajo($event)'
                                        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedtrabjo($event)'
                                        [itemTemplate]="itemTrabajo" [notFoundTemplate]="notFoundTemplate"
                                        [formControl]="validationForm.controls['TipoTrabajo']" required>
                                    </ng-autocomplete>

                                    <ng-template #itemTrabajo let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div *ngIf="validationForm.controls.TipoTrabajo.touched && validationForm.controls.TipoTrabajo.errors?.required"
                                    class="text text-danger">
                                    El tipo de trabajo es requerido segun Res. 214 de 15-02-2022 del MinSalud.
                                </div>
                            </div>


                        </div>

                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <label for="validationCustom03">Indicaciones</label>
                                <textarea row="4" class="form-control" id="validationCustom03"
                                    placeholder="Ejemplo: Trabajo especifico, Tipo Material, Prueba o Terminado, Metales, Marca, Guias, Implamente, Etc...!!! "
                                    [formControl]="validationForm.controls['Indicaciones']" required></textarea>

                                <div *ngIf="validationForm.controls.Indicaciones.touched && validationForm.controls.Indicaciones.errors?.required"
                                    class="text text-danger">
                                    Las indicaciones del trabajo es requerido segun Res. 214 de 15-02-2022 del MinSalud.
                                </div>


                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="validationCustom02">Color</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Color"
                                    [formControl]="validationForm.controls['Color']">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="validationCustom02">Guia</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Guia"
                                    [formControl]="validationForm.controls['Guia']">
                            </div>
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 ml-1">
                                    <div class="checkbox checkbox-dark">
                                        <input id="inline-1" type="checkbox"
                                            [formControl]="validationForm.controls['Antagonista']">
                                        <label for="inline-1">Antagonista</label>
                                    </div>
                                    <div class="checkbox checkbox-dark">
                                        <input id="inline-2" type="checkbox"
                                            [formControl]="validationForm.controls['ResgMordida']">
                                        <label for="inline-2">Registro de Modirda</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <form class="was-validated">
                                <label for="validationCustom05">Adjunta Archivos STL (Comprima todos los archivos y
                                    adjunta
                                    un solo archivo)</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="validatedCustomFile"
                                        (change)="captureSTL($event)">
                                    <label class="custom-file-label" for="validatedCustomFile">{{NomArchivoSTL}}</label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h5>GUIA DE DIENTES</h5>
                    </div>

                    <div class="card-body">
                        <div class="form-row">
                            <div class="col-md-6 mb-3" style="height: 300px">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 560 1055" width="500" height="600">


                                    <g transform="translate(800.000000,429.000000)" fill="#C0C0C0" stroke="none">

                                         <path
                                            d="M266.17 8.55C259.99 -4.36 193.1 6.32 197.86 21.38C206.17 47.66 225.74 73.28 248.05 67.4C267.9 62.17 262.54 20.32 266.17 8.55Z"
                                            id="iso-11" style="cursor: pointer;"></path>
                                        <path d="M250.64 19.39C239.08 14.5 227 16.21 214.06 26.43" id="iso-11a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M145.49 62.43C137.94 40.31 191.03 12.91 200.03 32.34C205.95 45.15 204.62 79.12 191.95 86.18C177.61 94.17 150.76 77.86 145.49 62.43Z"
                                            id="iso-12" style="cursor: pointer;"></path>
                                        <path d="M186.48 40.87C177.65 41.5 170.4 40.72 159.99 53.98" id="iso-12a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M150.11 75.16C142.5 69.28 129.83 70.79 121.26 75.16C111.64 80.07 105.24 91.01 102.25 101.39C99.52 110.86 104.3 132.99 114.05 131.55C134.71 128.49 156.11 139.07 161.91 120.4C167.02 103.98 162.44 84.69 150.11 75.16Z"
                                            id="iso-13" style="cursor: pointer;"></path>
                                        <path d="M137.24 82.38C117.15 85.94 113.51 101.9 112.46 113.44" id="iso-13a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M18.36 529.31C18.36 529.31 7.02 525.35 5.37 504.43C4.86 497.88 13.89 490.15 12.81 482.51C10.67 467.32 21.2 448.9 31.68 446.13C58.89 438.94 72.33 459.38 79.53 476.93C86.12 492.99 79.12 515.06 68.15 527.74C56.25 534.5 32.29 530.07 18.36 529.31Z"
                                            id="iso-18" style="cursor: pointer;"></path>
                                        <path
                                            d="M98.89 414.82C91.72 434.68 71.23 446.39 48.57 441.68C28.93 437.59 16.41 426.82 11.8 400.23C7.47 375.3 4.28 345.65 48.9 345.64C91.28 345.62 114.44 371.79 98.89 414.82Z"
                                            id="iso-17" style="cursor: pointer;"></path>
                                        <path
                                            d="M78.88 362.56C72.53 364.71 61.58 363.31 60.14 370.37C59.95 371.31 58.41 378.85 58.22 379.79C62.2 382.7 69.4 379.52 75.18 379.02C75.16 379.44 55.97 386.13 55.81 386.2C53.89 387.07 49.14 400.59 53.55 406.92C61.81 412.09 70.99 407.73 79.55 407.88C71.38 409.57 59.68 411.06 58.93 413.2C57.6 420.28 56.36 425.9 55.13 431.44C55.14 425.13 56.46 418.45 52.15 413.39C41.3 410.68 40.92 412.54 35.26 412.97C40.74 411.11 44.03 406.54 46.15 400.52C48.16 392.44 46.33 392.05 45.45 389.75C39.48 387.56 36.28 390.97 31.82 391.32C37.61 388.74 44.41 385.5 49.31 381.64C55.02 377.15 47.89 371.47 42.78 366.36C49.45 364.88 56.47 365.06 61.1 354.34C62.36 362.44 71.16 361.06 78.88 362.56Z"
                                            id="iso-17a" style="cursor: pointer;"></path>
                                        <path
                                            d="M44.65 266.85C44.65 266.85 32.12 267.49 26.4 284.67C24.61 290.05 32.51 298.7 29.93 304.86C24.79 317.11 28.02 335.09 38.38 339.9C45.63 343.28 58.05 342.06 64.03 342.11C80.21 342.25 90.22 334.9 98.14 325.38C108.05 313.45 105.01 293.25 96.05 279.99C84.99 271.49 59.26 269.52 44.65 266.85Z"
                                            id="iso-16" style="cursor: pointer;"></path>
                                        <path
                                            d="M56.94 494.91C60.48 492.65 67.62 496.05 72.83 497.1C71.34 496.21 59.47 489.15 57.98 488.26C52.76 480.91 55.48 474.74 55.2 467.8C51.42 468.58 48.21 465.25 45.07 461.37C45.68 465.45 50.5 471.58 45.68 473.01C44.93 472.73 41.17 471.36 34.4 468.9C41.23 473.96 45.02 476.77 45.78 477.33C45.78 477.33 48.56 483.47 46 482.51C43.43 481.56 31.11 484.1 31.11 484.1C31.11 484.1 43.66 483.26 48.24 488.28C52.82 493.3 52.75 496.35 53.21 499.76C53.68 503.17 52.89 506.57 54.97 509.07C56.8 511.28 63.26 512.72 63.26 512.72C59.78 506.61 57.11 500.6 56.94 494.91Z"
                                            id="b2DYah0Bi" style="cursor: pointer;"></path>
                                        <path
                                            d="M124.86 144.48C120.94 136.65 109.98 133 101.25 133.32C91.45 133.67 81.91 140.28 75.4 147.74C69.45 154.55 63.37 185.34 71.92 187.91C90.03 193.36 104.78 206.01 116.86 192.75C127.48 181.1 131.22 157.16 124.86 144.48Z"
                                            id="iso-14" style="cursor: pointer;"></path>
                                        <path
                                            d="M104.66 146.08C96.02 147.61 88.62 150.83 84.96 155.61C80.11 161.94 80.47 170.56 79.88 177.14"
                                            id="b6fpiYGql" style="cursor: pointer;"></path>
                                        <path
                                            d="M69.15 299.11C72.26 301.81 79.33 298.09 84.34 297.03C82.86 298.01 70.96 305.83 69.47 306.81C63.82 315.15 65.77 322.32 64.82 330.31C61.33 329.32 57.97 333.06 54.63 337.45C55.61 332.77 60.76 325.83 56.36 324.06C55.62 324.35 51.94 325.83 45.32 328.49C52.25 322.84 56.11 319.7 56.88 319.07C56.88 319.07 60.11 312.08 57.6 313.11C55.08 314.14 43.72 310.87 43.72 310.87C43.72 310.87 55.47 312.18 60.29 306.52C65.1 300.86 65.34 297.34 66.11 293.42C66.89 289.51 66.48 285.57 68.69 282.74C70.63 280.25 76.87 278.76 76.87 278.76C72.98 285.71 69.87 292.56 69.15 299.11Z"
                                            id="b6rcDQpXhc" style="cursor: pointer;"></path>
                                        <path
                                            d="M91.09 200.85C85.15 193.29 72.49 191.66 63.11 193.81C52.59 196.23 43.71 205.28 38.28 214.61C33.32 223.13 37.92 262.17 47.73 263.15C68.51 265.22 86.64 275.94 96.82 259.25C105.78 244.58 100.73 213.1 91.09 200.85Z"
                                            id="iso-15" style="cursor: pointer;"></path>
                                        <path
                                            d="M47.29 239.85C48.2 237.5 47.09 235.21 47.29 233.03C47.82 226.98 48.23 221.74 51.73 217.98C53.09 216.51 54.68 215.22 56.42 214.05C61.11 210.89 66.87 208.65 72.13 206.52"
                                            id="b22znv61x" style="cursor: pointer;"></path>
                                        <path
                                            d="M89.08 186.79C96.72 187.31 104.32 187.57 108.8 184.62C116.26 179.71 118.22 169.44 120.59 162.59"
                                            id="cyecxkyP8" style="cursor: pointer;"></path>
                                        <path d="M65.93 256.06C86.29 257.43 88.65 244.61 92.44 233.66" id="a3AsQKojJ1"
                                            style="cursor: pointer;"></path><text id="b16evo6f5C" x="214.21" y="10.52"
                                            font-size="45"
                                            transform="matrix(1 0 0 1 10.526439084936285 162.96435359690972)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text><text id="a4WSJkkK4" x="214.21" y="10.52" font-size="45"
                                            transform="matrix(1 0 0 1 27.682689084936285 178.58935359690972)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text><text id="dhJLWP0Rx" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -8.000000000000028 -30.303030303030482)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">11
                                            </tspan>
                                        </text><text id="h484gZh3x" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -45.04545454545476 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">12
                                            </tspan>
                                        </text><text id="a5HTD7Ziun" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -76.03030303030349 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">13
                                            </tspan>
                                        </text><text id="dPldy7HEx" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -110.04545454545521 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">14
                                            </tspan>
                                        </text><text id="aagZSuMJr" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">15
                                            </tspan>
                                        </text><text id="c3f2gAlj3" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">16
                                            </tspan>
                                        </text><text id="fWwh13pJ" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -134.90482954545524 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">17
                                            </tspan>
                                        </text><text id="iALQXea9Y" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -155.76420454545527 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">18
                                            </tspan>
                                        </text>
                                        <path
                                            d="M293.44 8.55C299.61 -4.36 366.51 6.32 361.74 21.38C353.43 47.66 333.86 73.28 311.56 67.4C291.71 62.17 297.06 20.32 293.44 8.55Z"
                                            id="iso-21" style="cursor: pointer;"></path>
                                        <path d="M308.96 19.39C320.52 14.5 332.61 16.21 345.55 26.43" id="h3ymJ8JsAJ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M414.11 62.43C421.67 40.31 368.58 12.91 359.58 32.34C353.65 45.15 354.99 79.12 367.65 86.18C382 94.17 408.85 77.86 414.11 62.43Z"
                                            id="iso-22" style="cursor: pointer;"></path>
                                        <path d="M373.13 40.87C381.95 41.5 389.21 40.72 399.62 53.98" id="e7zZFZz9XZ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M409.49 75.16C417.1 69.28 429.78 70.79 438.34 75.16C447.96 80.07 454.37 91.01 457.36 101.39C460.09 110.86 455.31 132.99 445.56 131.55C424.89 128.49 403.5 139.07 397.69 120.4C392.59 103.98 397.16 84.69 409.49 75.16Z"
                                            id="iso-23" style="cursor: pointer;"></path>
                                        <path d="M422.37 82.38C442.46 85.94 446.1 101.9 447.15 113.44" id="bX8HWOfji"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M541.25 529.31C541.25 529.31 552.59 525.35 554.23 504.43C554.75 497.88 545.72 490.15 546.8 482.51C548.94 467.32 538.41 448.9 527.93 446.13C500.72 438.94 487.28 459.38 480.08 476.93C473.49 492.99 480.49 515.06 491.46 527.74C503.35 534.5 527.32 530.07 541.25 529.31Z"
                                            id="iso-28" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.71 414.82C467.89 434.68 488.38 446.39 511.04 441.68C530.67 437.59 543.19 426.82 547.81 400.23C552.14 375.3 555.32 345.65 510.71 345.64C468.33 345.62 445.17 371.79 460.71 414.82Z"
                                            id="iso-27" style="cursor: pointer;"></path>
                                        <path
                                            d="M480.73 362.56C487.08 364.71 498.03 363.31 499.47 370.37C499.66 371.31 501.19 378.85 501.38 379.79C497.4 382.7 490.21 379.52 484.42 379.02C484.45 379.44 503.64 386.13 503.8 386.2C505.71 387.07 510.47 400.59 506.05 406.92C497.79 412.09 488.62 407.73 480.06 407.88C488.23 409.57 499.93 411.06 500.68 413.2C502 420.28 503.24 425.9 504.47 431.44C504.47 425.13 503.15 418.45 507.45 413.39C518.31 410.68 518.69 412.54 524.35 412.97C518.87 411.11 515.58 406.54 513.45 400.52C511.45 392.44 513.28 392.05 514.16 389.75C520.13 387.56 523.32 390.97 527.79 391.32C522 388.74 515.2 385.5 510.29 381.64C504.59 377.15 511.72 371.47 516.82 366.36C510.16 364.88 503.13 365.06 498.51 354.34C497.25 362.44 488.45 361.06 480.73 362.56Z"
                                            id="lmVcyt8R" style="cursor: pointer;"></path><text id="anXNzSK2j"
                                            x="214.21" y="10.52" font-size="45"
                                            transform="matrix(-1 0 0 1 519.8434429734264 -14.916991393939833)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text>
                                        <path
                                            d="M514.96 266.85C514.96 266.85 527.49 267.49 533.2 284.67C534.99 290.05 527.1 298.7 529.68 304.86C534.82 317.11 531.59 335.09 521.23 339.9C513.97 343.28 501.56 342.06 495.58 342.11C479.4 342.25 469.38 334.9 461.47 325.38C451.56 313.45 454.6 293.25 463.55 279.99C474.61 271.49 500.35 269.52 514.96 266.85Z"
                                            id="iso-26" style="cursor: pointer;"></path>
                                        <path
                                            d="M502.67 494.91C499.12 492.65 491.98 496.05 486.78 497.1C488.26 496.21 500.14 489.15 501.62 488.26C506.85 480.91 504.13 474.74 504.4 467.8C508.19 468.58 511.4 465.25 514.53 461.37C513.93 465.45 509.1 471.58 513.93 473.01C514.68 472.73 518.44 471.36 525.21 468.9C518.38 473.96 514.59 476.77 513.83 477.33C513.83 477.33 511.04 483.47 513.61 482.51C516.18 481.56 528.5 484.1 528.5 484.1C528.5 484.1 515.94 483.26 511.36 488.28C506.78 493.3 506.86 496.35 506.39 499.76C505.93 503.17 506.72 506.57 504.64 509.07C502.81 511.28 496.35 512.72 496.35 512.72C499.82 506.61 502.5 500.6 502.67 494.91Z"
                                            id="bsHecJRYc" style="cursor: pointer;"></path>
                                        <path
                                            d="M434.74 144.48C438.66 136.65 449.63 133 458.35 133.32C468.15 133.67 477.69 140.28 484.21 147.74C490.16 154.55 496.24 185.34 487.69 187.91C469.58 193.36 454.82 206.01 442.74 192.75C432.12 181.1 428.39 157.16 434.74 144.48Z"
                                            id="iso-24" style="cursor: pointer;"></path>
                                        <path
                                            d="M454.95 146.08C463.59 147.61 470.99 150.83 474.64 155.61C479.49 161.94 479.13 170.56 479.73 177.14"
                                            id="d6p4ZsEBwV" style="cursor: pointer;"></path>
                                        <path
                                            d="M490.46 299.11C487.34 301.81 480.27 298.09 475.26 297.03C476.75 298.01 488.65 305.83 490.13 306.81C495.79 315.15 493.84 322.32 494.78 330.31C498.27 329.32 501.63 333.06 504.97 337.45C504 332.77 498.84 325.83 503.25 324.06C503.98 324.35 507.67 325.83 514.29 328.49C507.35 322.84 503.5 319.7 502.72 319.07C502.72 319.07 499.49 312.08 502.01 313.11C504.52 314.14 515.89 310.87 515.89 310.87C515.89 310.87 504.13 312.18 499.32 306.52C494.5 300.86 494.27 297.34 493.49 293.42C492.72 289.51 493.12 285.57 490.92 282.74C488.97 280.25 482.74 278.76 482.74 278.76C486.62 285.71 489.74 292.56 490.46 299.11Z"
                                            id="aLBv0ngyf" style="cursor: pointer;"></path>
                                        <path
                                            d="M468.51 200.85C474.46 193.29 487.12 191.66 496.49 193.81C507.02 196.23 515.9 205.28 521.33 214.61C526.28 223.13 521.68 262.17 511.88 263.15C491.09 265.22 472.97 275.94 462.78 259.25C453.83 244.58 458.88 213.1 468.51 200.85Z"
                                            id="iso-25" style="cursor: pointer;"></path>
                                        <path
                                            d="M512.32 239.85C511.4 237.5 512.51 235.21 512.32 233.03C511.78 226.98 511.38 221.74 507.88 217.98C506.51 216.51 504.92 215.22 503.19 214.05C498.49 210.89 492.74 208.65 487.48 206.52"
                                            id="a1IjZsIm8S" style="cursor: pointer;"></path>
                                        <path
                                            d="M470.52 186.79C462.89 187.31 455.28 187.57 450.81 184.62C443.35 179.71 441.38 169.44 439.01 162.59"
                                            id="b2b6VhQYX9" style="cursor: pointer;"></path>
                                        <path d="M493.67 256.06C473.32 257.43 470.95 244.61 467.16 233.66"
                                            id="b2JgD7E2Kq" style="cursor: pointer;"></path><text id="b1gODCfJB5"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 62.5303030303032 -33.3333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">21
                                            </tspan>
                                        </text><text id="a2KwBh9N1C" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 98.21212121212167 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">22
                                            </tspan>
                                        </text><text id="avSr25PQ8" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 130.03030303030368 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">23
                                            </tspan>
                                        </text><text id="b1IAgvlN7L" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 162.49083356665588 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">24
                                            </tspan>
                                        </text><text id="a5jdHsmSg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 189.14062500000085 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">25
                                            </tspan>
                                        </text><text id="eEJTk9OPg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.3502085666559 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">26
                                            </tspan>
                                        </text><text id="a1kI1wu28" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.35020856665585 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">27
                                            </tspan>
                                        </text><text id="f10grZcqP0" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 208.20958356665594 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">28
                                            </tspan>
                                        </text>
                                    </g>
                                    <g class="main">
                                        <g class="q1">
                                            <g class="1">
                                                <g>
                                                    <use xlink:href="#iso-11" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-11" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#iso-11a" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-11a" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="2">
                                                <g>
                                                    <use xlink:href="#iso-12" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-12" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#iso-12a" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-12a" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="3">
                                                <g>
                                                    <use xlink:href="#iso-13" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-13" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#iso-13a" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-13a" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="4">
                                                <g>
                                                    <use xlink:href="#iso-14" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-14" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b6fpiYGql" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#b6fpiYGql" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#cyecxkyP8" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#cyecxkyP8" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="5">
                                                <g>
                                                    <use xlink:href="#iso-15" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-15" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b22znv61x" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#b22znv61x" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#a3AsQKojJ1" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#a3AsQKojJ1" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="6">
                                                <g>
                                                    <use xlink:href="#iso-16" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-16" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b6rcDQpXhc" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#b6rcDQpXhc" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="7">
                                                <g>
                                                    <use xlink:href="#iso-17" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-17" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#iso-17a" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#iso-17a" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="8">
                                                <g>
                                                    <use xlink:href="#iso-18" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-18" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b2DYah0Bi" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#b2DYah0Bi" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="text">
                                                <g id="e1BKNeURVa">
                                                    <use xlink:href="#dhJLWP0Rx" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="c11nH75JKG">
                                                    <use xlink:href="#h484gZh3x" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="apGn9cSxF">
                                                    <use xlink:href="#a5HTD7Ziun" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="bjZ1YSxTN">
                                                    <use xlink:href="#dPldy7HEx" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="fg2EsfBeL">
                                                    <use xlink:href="#aagZSuMJr" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="a5Xmbxqft">
                                                    <use xlink:href="#c3f2gAlj3" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="a3IMKLYnOx">
                                                    <use xlink:href="#fWwh13pJ" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="c31CWrtXNW">
                                                    <use xlink:href="#iALQXea9Y" opacity="1" fill="#000"></use>
                                                </g>
                                            </g>
                                        </g>
                                        <g class="q2">
                                            <g class="1">
                                                <g>
                                                    <use xlink:href="#iso-21" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-21" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#h3ymJ8JsAJ" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#h3ymJ8JsAJ" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="2">
                                                <g>
                                                    <use xlink:href="#iso-22" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-22" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#e7zZFZz9XZ" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#e7zZFZz9XZ" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="3">
                                                <g>
                                                    <use xlink:href="#iso-23" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-23" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#bX8HWOfji" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#bX8HWOfji" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="4">
                                                <g>
                                                    <use xlink:href="#iso-24" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-24" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#d6p4ZsEBwV" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#d6p4ZsEBwV" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b2b6VhQYX9" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#b2b6VhQYX9" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="5">
                                                <g>
                                                    <use xlink:href="#iso-25" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-25" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#a1IjZsIm8S" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#a1IjZsIm8S" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b2JgD7E2Kq" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#b2JgD7E2Kq" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="6">
                                                <g>
                                                    <use xlink:href="#iso-26" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-26" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#aLBv0ngyf" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#aLBv0ngyf" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="7">
                                                <g>
                                                    <use xlink:href="#iso-27" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-27" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#lmVcyt8R" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#lmVcyt8R" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="8">
                                                <g>
                                                    <use xlink:href="#iso-28" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-28" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#bsHecJRYc" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#bsHecJRYc" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="text">
                                                <g id="c2vG1vlZh4">
                                                    <use xlink:href="#b1gODCfJB5" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="a1nYN9cpt">
                                                    <use xlink:href="#a2KwBh9N1C" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="bAKpE8e15">
                                                    <use xlink:href="#avSr25PQ8" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="f13C0Z9htE">
                                                    <use xlink:href="#b1IAgvlN7L" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="h2OfTRUQ6B">
                                                    <use xlink:href="#a5jdHsmSg" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="b4tUNqNk6T">
                                                    <use xlink:href="#eEJTk9OPg" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="clBT5njbz">
                                                    <use xlink:href="#a1kI1wu28" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="aG1IO93Cx">
                                                    <use xlink:href="#f10grZcqP0" opacity="1" fill="#000"></use>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div class="col-md-6 mb-3" style="height: 300px">
                                <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
                                    version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 500 560 1055"
                                    style="max-height: 600px;" width="500" height="600" class="svgdientesinf">


                                    <g transform="translate(800.000000,429.000000)" fill="#C0C0C0" stroke="none">

                                        <path
                                            d="M266.17 8.55C259.99 -4.36 193.1 6.32 197.86 21.38C206.17 47.66 225.74 73.28 248.05 67.4C267.9 62.17 262.54 20.32 266.17 8.55Z"
                                            id="iso-11" style="cursor: pointer;"></path>
                                        <path d="M250.64 19.39C239.08 14.5 227 16.21 214.06 26.43" id="iso-11a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M145.49 62.43C137.94 40.31 191.03 12.91 200.03 32.34C205.95 45.15 204.62 79.12 191.95 86.18C177.61 94.17 150.76 77.86 145.49 62.43Z"
                                            id="iso-12" style="cursor: pointer;"></path>
                                        <path d="M186.48 40.87C177.65 41.5 170.4 40.72 159.99 53.98" id="iso-12a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M150.11 75.16C142.5 69.28 129.83 70.79 121.26 75.16C111.64 80.07 105.24 91.01 102.25 101.39C99.52 110.86 104.3 132.99 114.05 131.55C134.71 128.49 156.11 139.07 161.91 120.4C167.02 103.98 162.44 84.69 150.11 75.16Z"
                                            id="iso-13" style="cursor: pointer;"></path>
                                        <path d="M137.24 82.38C117.15 85.94 113.51 101.9 112.46 113.44" id="iso-13a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M18.36 529.31C18.36 529.31 7.02 525.35 5.37 504.43C4.86 497.88 13.89 490.15 12.81 482.51C10.67 467.32 21.2 448.9 31.68 446.13C58.89 438.94 72.33 459.38 79.53 476.93C86.12 492.99 79.12 515.06 68.15 527.74C56.25 534.5 32.29 530.07 18.36 529.31Z"
                                            id="iso-18" style="cursor: pointer;"></path>
                                        <path
                                            d="M98.89 414.82C91.72 434.68 71.23 446.39 48.57 441.68C28.93 437.59 16.41 426.82 11.8 400.23C7.47 375.3 4.28 345.65 48.9 345.64C91.28 345.62 114.44 371.79 98.89 414.82Z"
                                            id="iso-17" style="cursor: pointer;"></path>
                                        <path
                                            d="M78.88 362.56C72.53 364.71 61.58 363.31 60.14 370.37C59.95 371.31 58.41 378.85 58.22 379.79C62.2 382.7 69.4 379.52 75.18 379.02C75.16 379.44 55.97 386.13 55.81 386.2C53.89 387.07 49.14 400.59 53.55 406.92C61.81 412.09 70.99 407.73 79.55 407.88C71.38 409.57 59.68 411.06 58.93 413.2C57.6 420.28 56.36 425.9 55.13 431.44C55.14 425.13 56.46 418.45 52.15 413.39C41.3 410.68 40.92 412.54 35.26 412.97C40.74 411.11 44.03 406.54 46.15 400.52C48.16 392.44 46.33 392.05 45.45 389.75C39.48 387.56 36.28 390.97 31.82 391.32C37.61 388.74 44.41 385.5 49.31 381.64C55.02 377.15 47.89 371.47 42.78 366.36C49.45 364.88 56.47 365.06 61.1 354.34C62.36 362.44 71.16 361.06 78.88 362.56Z"
                                            id="iso-17a" style="cursor: pointer;"></path>
                                        <path
                                            d="M44.65 266.85C44.65 266.85 32.12 267.49 26.4 284.67C24.61 290.05 32.51 298.7 29.93 304.86C24.79 317.11 28.02 335.09 38.38 339.9C45.63 343.28 58.05 342.06 64.03 342.11C80.21 342.25 90.22 334.9 98.14 325.38C108.05 313.45 105.01 293.25 96.05 279.99C84.99 271.49 59.26 269.52 44.65 266.85Z"
                                            id="iso-16" style="cursor: pointer;"></path>
                                        <path
                                            d="M56.94 494.91C60.48 492.65 67.62 496.05 72.83 497.1C71.34 496.21 59.47 489.15 57.98 488.26C52.76 480.91 55.48 474.74 55.2 467.8C51.42 468.58 48.21 465.25 45.07 461.37C45.68 465.45 50.5 471.58 45.68 473.01C44.93 472.73 41.17 471.36 34.4 468.9C41.23 473.96 45.02 476.77 45.78 477.33C45.78 477.33 48.56 483.47 46 482.51C43.43 481.56 31.11 484.1 31.11 484.1C31.11 484.1 43.66 483.26 48.24 488.28C52.82 493.3 52.75 496.35 53.21 499.76C53.68 503.17 52.89 506.57 54.97 509.07C56.8 511.28 63.26 512.72 63.26 512.72C59.78 506.61 57.11 500.6 56.94 494.91Z"
                                            id="b2DYah0Bi" style="cursor: pointer;"></path>
                                        <path
                                            d="M124.86 144.48C120.94 136.65 109.98 133 101.25 133.32C91.45 133.67 81.91 140.28 75.4 147.74C69.45 154.55 63.37 185.34 71.92 187.91C90.03 193.36 104.78 206.01 116.86 192.75C127.48 181.1 131.22 157.16 124.86 144.48Z"
                                            id="iso-14" style="cursor: pointer;"></path>
                                        <path
                                            d="M104.66 146.08C96.02 147.61 88.62 150.83 84.96 155.61C80.11 161.94 80.47 170.56 79.88 177.14"
                                            id="b6fpiYGql" style="cursor: pointer;"></path>
                                        <path
                                            d="M69.15 299.11C72.26 301.81 79.33 298.09 84.34 297.03C82.86 298.01 70.96 305.83 69.47 306.81C63.82 315.15 65.77 322.32 64.82 330.31C61.33 329.32 57.97 333.06 54.63 337.45C55.61 332.77 60.76 325.83 56.36 324.06C55.62 324.35 51.94 325.83 45.32 328.49C52.25 322.84 56.11 319.7 56.88 319.07C56.88 319.07 60.11 312.08 57.6 313.11C55.08 314.14 43.72 310.87 43.72 310.87C43.72 310.87 55.47 312.18 60.29 306.52C65.1 300.86 65.34 297.34 66.11 293.42C66.89 289.51 66.48 285.57 68.69 282.74C70.63 280.25 76.87 278.76 76.87 278.76C72.98 285.71 69.87 292.56 69.15 299.11Z"
                                            id="b6rcDQpXhc" style="cursor: pointer;"></path>
                                        <path
                                            d="M91.09 200.85C85.15 193.29 72.49 191.66 63.11 193.81C52.59 196.23 43.71 205.28 38.28 214.61C33.32 223.13 37.92 262.17 47.73 263.15C68.51 265.22 86.64 275.94 96.82 259.25C105.78 244.58 100.73 213.1 91.09 200.85Z"
                                            id="iso-15" style="cursor: pointer;"></path>
                                        <path
                                            d="M47.29 239.85C48.2 237.5 47.09 235.21 47.29 233.03C47.82 226.98 48.23 221.74 51.73 217.98C53.09 216.51 54.68 215.22 56.42 214.05C61.11 210.89 66.87 208.65 72.13 206.52"
                                            id="b22znv61x" style="cursor: pointer;"></path>
                                        <path
                                            d="M89.08 186.79C96.72 187.31 104.32 187.57 108.8 184.62C116.26 179.71 118.22 169.44 120.59 162.59"
                                            id="cyecxkyP8" style="cursor: pointer;"></path>
                                        <path d="M65.93 256.06C86.29 257.43 88.65 244.61 92.44 233.66" id="a3AsQKojJ1"
                                            style="cursor: pointer;"></path><text id="b16evo6f5C" x="214.21" y="10.52"
                                            font-size="45"
                                            transform="matrix(1 0 0 1 10.526439084936285 162.96435359690972)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text><text id="a4WSJkkK4" x="214.21" y="10.52" font-size="45"
                                            transform="matrix(1 0 0 1 27.682689084936285 178.58935359690972)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text><text id="dhJLWP0Rx" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -8.000000000000028 -30.303030303030482)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">11
                                            </tspan>
                                        </text><text id="h484gZh3x" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -45.04545454545476 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">12
                                            </tspan>
                                        </text><text id="a5HTD7Ziun" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -76.03030303030349 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">13
                                            </tspan>
                                        </text><text id="dPldy7HEx" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -110.04545454545521 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">14
                                            </tspan>
                                        </text><text id="aagZSuMJr" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">15
                                            </tspan>
                                        </text><text id="c3f2gAlj3" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">16
                                            </tspan>
                                        </text><text id="fWwh13pJ" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -134.90482954545524 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">17
                                            </tspan>
                                        </text><text id="iALQXea9Y" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -155.76420454545527 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">18
                                            </tspan>
                                        </text>
                                        <path
                                            d="M293.44 8.55C299.61 -4.36 366.51 6.32 361.74 21.38C353.43 47.66 333.86 73.28 311.56 67.4C291.71 62.17 297.06 20.32 293.44 8.55Z"
                                            id="iso-21" style="cursor: pointer;"></path>
                                        <path d="M308.96 19.39C320.52 14.5 332.61 16.21 345.55 26.43" id="h3ymJ8JsAJ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M414.11 62.43C421.67 40.31 368.58 12.91 359.58 32.34C353.65 45.15 354.99 79.12 367.65 86.18C382 94.17 408.85 77.86 414.11 62.43Z"
                                            id="iso-22" style="cursor: pointer;"></path>
                                        <path d="M373.13 40.87C381.95 41.5 389.21 40.72 399.62 53.98" id="e7zZFZz9XZ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M409.49 75.16C417.1 69.28 429.78 70.79 438.34 75.16C447.96 80.07 454.37 91.01 457.36 101.39C460.09 110.86 455.31 132.99 445.56 131.55C424.89 128.49 403.5 139.07 397.69 120.4C392.59 103.98 397.16 84.69 409.49 75.16Z"
                                            id="iso-23" style="cursor: pointer;"></path>
                                        <path d="M422.37 82.38C442.46 85.94 446.1 101.9 447.15 113.44" id="bX8HWOfji"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M541.25 529.31C541.25 529.31 552.59 525.35 554.23 504.43C554.75 497.88 545.72 490.15 546.8 482.51C548.94 467.32 538.41 448.9 527.93 446.13C500.72 438.94 487.28 459.38 480.08 476.93C473.49 492.99 480.49 515.06 491.46 527.74C503.35 534.5 527.32 530.07 541.25 529.31Z"
                                            id="iso-28" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.71 414.82C467.89 434.68 488.38 446.39 511.04 441.68C530.67 437.59 543.19 426.82 547.81 400.23C552.14 375.3 555.32 345.65 510.71 345.64C468.33 345.62 445.17 371.79 460.71 414.82Z"
                                            id="iso-27" style="cursor: pointer;"></path>
                                        <path
                                            d="M480.73 362.56C487.08 364.71 498.03 363.31 499.47 370.37C499.66 371.31 501.19 378.85 501.38 379.79C497.4 382.7 490.21 379.52 484.42 379.02C484.45 379.44 503.64 386.13 503.8 386.2C505.71 387.07 510.47 400.59 506.05 406.92C497.79 412.09 488.62 407.73 480.06 407.88C488.23 409.57 499.93 411.06 500.68 413.2C502 420.28 503.24 425.9 504.47 431.44C504.47 425.13 503.15 418.45 507.45 413.39C518.31 410.68 518.69 412.54 524.35 412.97C518.87 411.11 515.58 406.54 513.45 400.52C511.45 392.44 513.28 392.05 514.16 389.75C520.13 387.56 523.32 390.97 527.79 391.32C522 388.74 515.2 385.5 510.29 381.64C504.59 377.15 511.72 371.47 516.82 366.36C510.16 364.88 503.13 365.06 498.51 354.34C497.25 362.44 488.45 361.06 480.73 362.56Z"
                                            id="lmVcyt8R" style="cursor: pointer;"></path><text id="anXNzSK2j"
                                            x="214.21" y="10.52" font-size="45"
                                            transform="matrix(-1 0 0 1 519.8434429734264 -14.916991393939833)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text>
                                        <path
                                            d="M514.96 266.85C514.96 266.85 527.49 267.49 533.2 284.67C534.99 290.05 527.1 298.7 529.68 304.86C534.82 317.11 531.59 335.09 521.23 339.9C513.97 343.28 501.56 342.06 495.58 342.11C479.4 342.25 469.38 334.9 461.47 325.38C451.56 313.45 454.6 293.25 463.55 279.99C474.61 271.49 500.35 269.52 514.96 266.85Z"
                                            id="iso-26" style="cursor: pointer;"></path>
                                        <path
                                            d="M502.67 494.91C499.12 492.65 491.98 496.05 486.78 497.1C488.26 496.21 500.14 489.15 501.62 488.26C506.85 480.91 504.13 474.74 504.4 467.8C508.19 468.58 511.4 465.25 514.53 461.37C513.93 465.45 509.1 471.58 513.93 473.01C514.68 472.73 518.44 471.36 525.21 468.9C518.38 473.96 514.59 476.77 513.83 477.33C513.83 477.33 511.04 483.47 513.61 482.51C516.18 481.56 528.5 484.1 528.5 484.1C528.5 484.1 515.94 483.26 511.36 488.28C506.78 493.3 506.86 496.35 506.39 499.76C505.93 503.17 506.72 506.57 504.64 509.07C502.81 511.28 496.35 512.72 496.35 512.72C499.82 506.61 502.5 500.6 502.67 494.91Z"
                                            id="bsHecJRYc" style="cursor: pointer;"></path>
                                        <path
                                            d="M434.74 144.48C438.66 136.65 449.63 133 458.35 133.32C468.15 133.67 477.69 140.28 484.21 147.74C490.16 154.55 496.24 185.34 487.69 187.91C469.58 193.36 454.82 206.01 442.74 192.75C432.12 181.1 428.39 157.16 434.74 144.48Z"
                                            id="iso-24" style="cursor: pointer;"></path>
                                        <path
                                            d="M454.95 146.08C463.59 147.61 470.99 150.83 474.64 155.61C479.49 161.94 479.13 170.56 479.73 177.14"
                                            id="d6p4ZsEBwV" style="cursor: pointer;"></path>
                                        <path
                                            d="M490.46 299.11C487.34 301.81 480.27 298.09 475.26 297.03C476.75 298.01 488.65 305.83 490.13 306.81C495.79 315.15 493.84 322.32 494.78 330.31C498.27 329.32 501.63 333.06 504.97 337.45C504 332.77 498.84 325.83 503.25 324.06C503.98 324.35 507.67 325.83 514.29 328.49C507.35 322.84 503.5 319.7 502.72 319.07C502.72 319.07 499.49 312.08 502.01 313.11C504.52 314.14 515.89 310.87 515.89 310.87C515.89 310.87 504.13 312.18 499.32 306.52C494.5 300.86 494.27 297.34 493.49 293.42C492.72 289.51 493.12 285.57 490.92 282.74C488.97 280.25 482.74 278.76 482.74 278.76C486.62 285.71 489.74 292.56 490.46 299.11Z"
                                            id="aLBv0ngyf" style="cursor: pointer;"></path>
                                        <path
                                            d="M468.51 200.85C474.46 193.29 487.12 191.66 496.49 193.81C507.02 196.23 515.9 205.28 521.33 214.61C526.28 223.13 521.68 262.17 511.88 263.15C491.09 265.22 472.97 275.94 462.78 259.25C453.83 244.58 458.88 213.1 468.51 200.85Z"
                                            id="iso-25" style="cursor: pointer;"></path>
                                        <path
                                            d="M512.32 239.85C511.4 237.5 512.51 235.21 512.32 233.03C511.78 226.98 511.38 221.74 507.88 217.98C506.51 216.51 504.92 215.22 503.19 214.05C498.49 210.89 492.74 208.65 487.48 206.52"
                                            id="a1IjZsIm8S" style="cursor: pointer;"></path>
                                        <path
                                            d="M470.52 186.79C462.89 187.31 455.28 187.57 450.81 184.62C443.35 179.71 441.38 169.44 439.01 162.59"
                                            id="b2b6VhQYX9" style="cursor: pointer;"></path>
                                        <path d="M493.67 256.06C473.32 257.43 470.95 244.61 467.16 233.66"
                                            id="b2JgD7E2Kq" style="cursor: pointer;"></path><text id="b1gODCfJB5"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 62.5303030303032 -33.3333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">21
                                            </tspan>
                                        </text><text id="a2KwBh9N1C" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 98.21212121212167 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">22
                                            </tspan>
                                        </text><text id="avSr25PQ8" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 130.03030303030368 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">23
                                            </tspan>
                                        </text><text id="b1IAgvlN7L" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 162.49083356665588 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">24
                                            </tspan>
                                        </text><text id="a5jdHsmSg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 189.14062500000085 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">25
                                            </tspan>
                                        </text><text id="eEJTk9OPg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.3502085666559 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">26
                                            </tspan>
                                        </text><text id="a1kI1wu28" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.35020856665585 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">27
                                            </tspan>
                                        </text><text id="f10grZcqP0" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 208.20958356665594 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">28
                                            </tspan>
                                        </text>
                                        <path
                                            d="M264.9 1047.24C261.44 1058.52 216.61 1052.18 219.04 1039.13C223.27 1016.34 235 993.66 250.05 997.75C263.46 1001.39 261.93 1037.34 264.9 1047.24Z"
                                            id="iso-41" style="cursor: pointer;"></path>
                                        <path d="M254.1 1038.64C246.68 1043.28 238.59 1042.33 229.52 1034.14"
                                            id="a2Ot1hj5Ti" style="cursor: pointer;"></path>
                                        <path
                                            d="M161.38 1007.25C155.47 1029.79 207.41 1053.97 214.85 1034.04C219.75 1020.91 216.56 987.08 204.13 980.78C190.07 973.66 165.51 991.54 161.38 1007.25Z"
                                            id="iso-42" style="cursor: pointer;"></path>
                                        <path d="M201.5 1026.33C193.09 1026.23 186.25 1027.45 175.62 1014.83"
                                            id="c3OkjuGmoe" style="cursor: pointer;"></path>
                                        <path
                                            d="M167.04 994.77C160.15 1001.09 148.04 1000.34 139.66 996.49C130.25 992.16 123.55 981.62 120.13 971.44C117 962.15 120.28 939.77 129.62 940.63C149.4 942.46 169.12 930.62 175.68 948.91C181.46 964.99 178.21 984.52 167.04 994.77Z"
                                            id="iso-43" style="cursor: pointer;"></path>
                                        <path d="M154.41 988.33C135.14 985.97 130.78 970.26 129.13 958.8"
                                            id="c1DvhakQel" style="cursor: pointer;"></path>
                                        <path
                                            d="M16.27 549.27C16.27 549.27 5.73 553.9 5.35 574.88C5.23 581.45 14.25 588.63 13.65 596.32C12.48 611.61 23.52 629.37 33.62 631.51C59.86 637.07 71.46 615.86 77.3 597.91C82.64 581.49 74.74 559.88 63.62 547.87C51.94 541.83 29.45 547.69 16.27 549.27Z"
                                            id="iso-48" style="cursor: pointer;"></path>
                                        <path
                                            d="M99.19 658.77C91.26 639.36 71.15 628.89 49.9 634.95C31.5 640.2 20.23 651.7 17.35 678.51C14.66 703.65 13.31 733.44 55.66 730.8C95.88 728.29 116.38 700.79 99.19 658.77Z"
                                            id="iso-47" style="cursor: pointer;"></path>
                                        <path
                                            d="M83.15 712.12C77.01 710.36 66.69 712.41 64.93 705.44C64.69 704.51 62.81 697.08 62.57 696.15C66.19 693.01 73.2 695.75 78.72 695.91C78.67 695.49 60.07 689.96 59.92 689.9C58.05 689.15 52.78 675.93 56.61 669.35C64.15 663.69 73.11 667.5 81.22 666.85C73.37 665.64 62.18 664.85 61.35 662.76C59.69 655.77 58.2 650.23 56.71 644.78C57.08 651.07 58.71 657.66 54.91 662.98C44.76 666.32 44.29 664.49 38.9 664.4C44.2 665.92 47.58 670.3 49.95 676.18C52.3 684.13 50.59 684.63 49.88 686.97C44.34 689.51 41.12 686.3 36.86 686.22C42.5 688.44 49.14 691.27 54.01 694.84C59.68 698.98 53.24 705.07 48.68 710.48C55.09 711.56 61.75 710.96 66.74 721.38C67.48 713.23 75.91 714.07 83.15 712.12Z"
                                            id="c18sfWQXw1" style="cursor: pointer;"></path><text id="b2iKkN8Sl1"
                                            x="214.21" y="10.52" font-size="44"
                                            transform="matrix(0.9980376052223248 -0.0626173982379266 -0.05663394169426829 -0.9983950103281617 56.93330868565021 1091.9163454821464)">
                                            <tspan x="214.21" dy="0em" dominant-baseline="text-before-edge">
                                            </tspan>
                                        </text>
                                        <path
                                            d="M56.08 809.7C56.08 809.7 44.16 809.8 37.76 792.99C35.75 787.73 42.76 778.63 39.96 772.64C34.39 760.71 36.44 742.58 45.99 737.15C52.69 733.35 64.54 733.83 70.22 733.42C85.56 732.31 95.49 739.06 103.54 748.09C113.62 759.4 111.88 779.75 104.13 793.52C94.12 802.67 69.8 806.16 56.08 809.7Z"
                                            id="iso-46" style="cursor: pointer;"></path>
                                        <path
                                            d="M54.84 581.31C58.33 583.35 64.91 579.54 69.79 578.18C68.44 579.16 57.56 586.92 56.21 587.89C51.66 595.54 54.59 601.53 54.72 608.47C51.09 607.93 48.23 611.44 45.47 615.5C45.82 611.4 50.05 604.99 45.39 603.85C44.69 604.17 41.2 605.76 34.92 608.62C41.11 603.16 44.55 600.13 45.24 599.52C45.24 599.52 47.53 593.24 45.15 594.34C42.77 595.44 30.93 593.64 30.93 593.64C30.93 593.64 42.89 593.73 46.96 588.44C51.02 583.16 50.78 580.12 51.03 576.69C51.27 573.26 50.33 569.91 52.16 567.29C53.78 564.98 59.83 563.16 59.83 563.16C56.87 569.47 54.67 575.62 54.84 581.31Z"
                                            id="b7ARVWKg9" style="cursor: pointer;"></path>
                                        <path
                                            d="M35.25 624.78C31.16 619.74 27.5 608.86 24.26 592.15L18.31 578.44C19.48 568.55 20.23 562.9 20.55 561.49"
                                            id="kjsO4lDF" style="cursor: pointer;"></path>
                                        <path
                                            d="M139.15 927.08C135.87 935.12 125.67 939.42 117.37 939.62C108.05 939.85 98.62 933.83 92.01 926.77C85.98 920.33 78.47 889.95 86.44 886.88C103.32 880.35 116.61 866.85 128.82 879.36C139.56 890.37 144.46 914.04 139.15 927.08Z"
                                            id="iso-44" style="cursor: pointer;"></path>
                                        <path
                                            d="M119.88 926.69C111.59 925.67 104.39 922.9 100.65 918.34C95.69 912.31 95.54 903.69 94.6 897.16"
                                            id="c2SUh81nX8" style="cursor: pointer;"></path>
                                        <path
                                            d="M77.51 776.04C80.31 773.15 87.24 776.45 92.05 777.21C90.59 776.32 78.85 769.22 77.38 768.33C71.54 760.35 72.99 753.07 71.64 745.15C68.38 746.35 64.98 742.81 61.56 738.63C62.75 743.24 68.04 749.86 63.96 751.9C63.24 751.65 59.67 750.39 53.23 748.13C60.13 753.36 63.97 756.26 64.74 756.84C64.74 756.84 68.2 763.63 65.76 762.75C63.31 761.87 52.71 765.81 52.71 765.81C52.71 765.81 63.79 763.81 68.68 769.17C73.58 774.54 74 778.03 74.95 781.89C75.91 785.76 75.75 789.71 78 792.41C79.99 794.78 85.99 795.89 85.99 795.89C81.91 789.18 78.56 782.53 77.51 776.04Z"
                                            id="b3NaX9ixLx" style="cursor: pointer;"></path>
                                        <path
                                            d="M103.9 872.82C98.69 880.72 86.77 883.09 77.75 881.51C67.62 879.73 58.68 871.22 53 862.23C47.81 854.02 49.96 814.77 59.22 813.21C78.83 809.91 95.43 798.13 106.04 814.18C115.37 828.29 112.36 860.02 103.9 872.82Z"
                                            id="iso-45" style="cursor: pointer;"></path>
                                        <path
                                            d="M60.12 836.49C61.12 838.79 60.2 841.14 60.5 843.3C61.36 849.31 62.04 854.52 65.57 858.06C66.95 859.44 68.53 860.64 70.25 861.71C74.88 864.58 80.47 866.47 85.58 868.29"
                                            id="c9QkCsAMn" style="cursor: pointer;"></path>
                                        <path
                                            d="M102.79 886.97C110.01 886 117.22 885.28 121.63 887.96C128.99 892.42 131.44 902.56 134.07 909.25"
                                            id="d38q1jr7XL" style="cursor: pointer;"></path>
                                        <path d="M76.9 819.2C96.14 816.63 99.11 829.28 103.33 839.99" id="amh0k6iSv"
                                            style="cursor: pointer;"></path><text id="b85Z9biXKR" x="241.89" y="100.29"
                                            font-size="20"
                                            transform="matrix(1 0 0 1 -5.500000000000028 869.8745265151553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">41
                                            </tspan>
                                        </text><text id="c1DY6ILtkz" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -39.76562500000003 855.2745265151555)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">42
                                            </tspan>
                                        </text><text id="b7Um5lGNA6" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -67.65482954545479 820.8849431818218)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">43
                                            </tspan>
                                        </text><text id="bFYSV3ojY" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -101.11576704545519 770.7026515151548)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">44
                                            </tspan>
                                        </text><text id="a58joFtV32" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -125.48784120066395 713.8795190562305)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">45
                                            </tspan>
                                        </text><text id="d1FL8DxoUa" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -127.01010687857391 647.545712238048)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">46
                                            </tspan>
                                        </text><text id="c1WvdIbIzw" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 552.0747842077441)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">47
                                            </tspan>
                                        </text><text id="g1ie3fhzhA" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -159.776903700664 465.24931072289553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">48
                                            </tspan>
                                        </text>
                                        <path
                                            d="M294.7 1047.24C298.16 1058.52 342.99 1052.18 340.57 1039.13C336.33 1016.34 324.61 993.66 309.55 997.75C296.15 1001.39 297.67 1037.34 294.7 1047.24Z"
                                            id="iso-31" style="cursor: pointer;"></path>
                                        <path d="M305.51 1038.64C312.93 1043.28 321.02 1042.33 330.08 1034.14"
                                            id="cjuFfUWr" style="cursor: pointer;"></path>
                                        <path
                                            d="M398.22 1007.25C404.14 1029.79 352.2 1053.97 344.76 1034.04C339.86 1020.91 343.05 987.08 355.47 980.78C369.54 973.66 394.1 991.54 398.22 1007.25Z"
                                            id="iso-32" style="cursor: pointer;"></path>
                                        <path d="M358.1 1026.33C366.51 1026.23 373.35 1027.45 383.99 1014.83"
                                            id="ahVnr1hTO" style="cursor: pointer;"></path>
                                        <path
                                            d="M392.57 994.77C399.46 1001.09 411.57 1000.34 419.95 996.49C429.36 992.16 436.06 981.62 439.48 971.44C442.61 962.15 439.32 939.77 429.99 940.63C410.2 942.46 390.49 930.62 383.92 948.91C378.15 964.99 381.4 984.52 392.57 994.77Z"
                                            id="iso-33" style="cursor: pointer;"></path>
                                        <path d="M405.19 988.33C424.46 985.97 428.82 970.26 430.47 958.8"
                                            id="d2sFISoAcr" style="cursor: pointer;"></path>
                                        <path
                                            d="M543.34 549.27C543.34 549.27 553.87 553.9 554.25 574.88C554.37 581.45 545.36 588.63 545.95 596.32C547.13 611.61 536.09 629.37 525.98 631.51C499.75 637.07 488.15 615.86 482.31 597.91C476.97 581.49 484.86 559.88 495.99 547.87C507.66 541.83 530.16 547.69 543.34 549.27Z"
                                            id="iso-38" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.41 658.77C468.35 639.36 488.46 628.89 509.7 634.95C528.1 640.2 539.38 651.7 542.26 678.51C544.95 703.65 546.3 733.44 503.95 730.8C463.72 728.29 443.22 700.79 460.41 658.77Z"
                                            id="iso-37" style="cursor: pointer;"></path>
                                        <path
                                            d="M476.45 712.12C482.6 710.36 492.92 712.41 494.68 705.44C494.92 704.51 496.8 697.08 497.03 696.15C493.42 693.01 486.41 695.75 480.89 695.91C480.94 695.49 499.53 689.96 499.69 689.9C501.56 689.15 506.83 675.93 503 669.35C495.45 663.69 486.5 667.5 478.38 666.85C486.23 665.64 497.42 664.85 498.25 662.76C499.91 655.77 501.41 650.23 502.89 644.78C502.53 651.07 500.89 657.66 504.7 662.98C514.85 666.32 515.31 664.49 520.71 664.4C515.4 665.92 512.02 670.3 509.66 676.18C507.3 684.13 509.02 684.63 509.72 686.97C515.27 689.51 518.49 686.3 522.75 686.22C517.1 688.44 510.47 691.27 505.59 694.84C499.92 698.98 506.37 705.07 510.92 710.48C504.52 711.56 497.86 710.96 492.86 721.38C492.13 713.23 483.7 714.07 476.45 712.12Z"
                                            id="b5JyusjzlT" style="cursor: pointer;"></path>
                                        <path
                                            d="M503.52 809.7C503.52 809.7 515.45 809.8 521.85 792.99C523.85 787.73 516.85 778.63 519.65 772.64C525.22 760.71 523.17 742.58 513.61 737.15C506.92 733.35 495.06 733.83 489.39 733.42C474.04 732.31 464.12 739.06 456.07 748.09C445.98 759.4 447.72 779.75 455.48 793.52C465.49 802.67 489.81 806.16 503.52 809.7Z"
                                            id="iso-36" style="cursor: pointer;"></path>
                                        <path
                                            d="M504.77 581.31C501.28 583.35 494.69 579.54 489.81 578.18C491.17 579.16 502.04 586.92 503.4 587.89C507.94 595.54 505.02 601.53 504.88 608.47C508.52 607.93 511.38 611.44 514.13 615.5C513.79 611.4 509.56 604.99 514.22 603.85C514.92 604.17 518.41 605.76 524.69 608.62C518.5 603.16 515.05 600.13 514.37 599.52C514.37 599.52 512.07 593.24 514.45 594.34C516.84 595.44 528.68 593.64 528.68 593.64C528.68 593.64 516.71 593.73 512.65 588.44C508.59 583.16 508.83 580.12 508.58 576.69C508.33 573.26 509.27 569.91 507.44 567.29C505.83 564.98 499.78 563.16 499.78 563.16C502.73 569.47 504.93 575.62 504.77 581.31Z"
                                            id="a9wXxhEQy" style="cursor: pointer;"></path>
                                        <path
                                            d="M420.46 927.08C423.73 935.12 433.93 939.42 442.23 939.62C451.55 939.85 460.98 933.83 467.59 926.77C473.63 920.33 481.14 889.95 473.17 886.88C456.29 880.35 443 866.85 430.78 879.36C420.04 890.37 415.14 914.04 420.46 927.08Z"
                                            id="iso-34" style="cursor: pointer;"></path>
                                        <path
                                            d="M439.73 926.69C448.01 925.67 455.22 922.9 458.96 918.34C463.92 912.31 464.06 903.69 465 897.16"
                                            id="f1nJy2rCGI" style="cursor: pointer;"></path>
                                        <path
                                            d="M482.1 776.04C479.29 773.15 472.37 776.45 467.55 777.21C469.02 776.32 480.76 769.22 482.22 768.33C488.06 760.35 486.62 753.07 487.97 745.15C491.22 746.35 494.62 742.81 498.04 738.63C496.85 743.24 491.57 749.86 495.65 751.9C496.36 751.65 499.94 750.39 506.38 748.13C499.47 753.36 495.64 756.26 494.87 756.84C494.87 756.84 491.4 763.63 493.85 762.75C496.29 761.87 506.9 765.81 506.9 765.81C506.9 765.81 495.81 763.81 490.92 769.17C486.03 774.54 485.61 778.03 484.65 781.89C483.7 785.76 483.86 789.71 481.6 792.41C479.62 794.78 473.61 795.89 473.61 795.89C477.69 789.18 481.04 782.53 482.1 776.04Z"
                                            id="m211qMeGpL" style="cursor: pointer;"></path>
                                        <path
                                            d="M455.7 872.82C460.92 880.72 472.84 883.09 481.86 881.51C491.99 879.73 500.93 871.22 506.61 862.23C511.8 854.02 509.64 814.77 500.39 813.21C480.78 809.91 464.18 798.13 453.57 814.18C444.24 828.29 447.25 860.02 455.7 872.82Z"
                                            id="iso-35" style="cursor: pointer;"></path>
                                        <path
                                            d="M499.49 836.49C498.48 838.79 499.41 841.14 499.1 843.3C498.25 849.31 497.57 854.52 494.03 858.06C492.65 859.44 491.07 860.64 489.36 861.71C484.73 864.58 479.13 866.47 474.02 868.29"
                                            id="bHt0uYEvE" style="cursor: pointer;"></path>
                                        <path
                                            d="M456.81 886.97C449.6 886 442.39 885.28 437.98 887.96C430.62 892.42 428.17 902.56 425.53 909.25"
                                            id="aaB5NMNJEr" style="cursor: pointer;"></path>
                                        <path d="M482.71 819.2C463.46 816.63 460.49 829.28 456.28 839.99" id="awAtPqnox"
                                            style="cursor: pointer;"></path><text id="b1BVUVOWBq" x="241.89" y="100.29"
                                            font-size="20"
                                            transform="matrix(1 0 0 1 57.80000000000004 869.8745265151554)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">31
                                            </tspan>
                                        </text><text id="a3t972Cvsg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 95.73437500000031 855.2745265151555)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">32
                                            </tspan>
                                        </text><text id="i4mHUxK2re" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 152.8896780303037 770.7026515151549)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">34
                                            </tspan>
                                        </text><text id="d3EsTPegQ" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 121.07149621212173 820.8849431818218)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">33
                                            </tspan>
                                        </text><text id="b2ApfidLEf" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 175.7490530303037 713.8795190562305)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">35
                                            </tspan>
                                        </text><text id="b2aRoIXnu9" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 179.9811271272621 644.030087238048)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">36
                                            </tspan>
                                        </text><text id="a1E40eZIMw" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 187.17874053030374 552.0747842077441)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">37
                                            </tspan>
                                        </text><text id="b18Xayl0Ve" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 212.00000000000088 465.24931072289553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">38
                                            </tspan>
                                        </text>
                                         <path
                                            d="M266.17 8.55C259.99 -4.36 193.1 6.32 197.86 21.38C206.17 47.66 225.74 73.28 248.05 67.4C267.9 62.17 262.54 20.32 266.17 8.55Z"
                                            id="iso-11" style="cursor: pointer;"></path> 
                                         <path
                                            d="M266.17 8.55C259.99 -4.36 193.1 6.32 197.86 21.38C206.17 47.66 225.74 73.28 248.05 67.4C267.9 62.17 262.54 20.32 266.17 8.55Z"
                                            id="iso-11" style="cursor: pointer;"></path>
                                        <path d="M250.64 19.39C239.08 14.5 227 16.21 214.06 26.43" id="iso-11a"
                                            style="cursor: pointer;"></path>
                                        <path d="M250.64 19.39C239.08 14.5 227 16.21 214.06 26.43" id="iso-11a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M145.49 62.43C137.94 40.31 191.03 12.91 200.03 32.34C205.95 45.15 204.62 79.12 191.95 86.18C177.61 94.17 150.76 77.86 145.49 62.43Z"
                                            id="iso-12" style="cursor: pointer;"></path>
                                        <path
                                            d="M145.49 62.43C137.94 40.31 191.03 12.91 200.03 32.34C205.95 45.15 204.62 79.12 191.95 86.18C177.61 94.17 150.76 77.86 145.49 62.43Z"
                                            id="iso-12" style="cursor: pointer;"></path>
                                        <path d="M186.48 40.87C177.65 41.5 170.4 40.72 159.99 53.98" id="iso-12a"
                                            style="cursor: pointer;"></path>
                                        <path d="M186.48 40.87C177.65 41.5 170.4 40.72 159.99 53.98" id="iso-12a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M150.11 75.16C142.5 69.28 129.83 70.79 121.26 75.16C111.64 80.07 105.24 91.01 102.25 101.39C99.52 110.86 104.3 132.99 114.05 131.55C134.71 128.49 156.11 139.07 161.91 120.4C167.02 103.98 162.44 84.69 150.11 75.16Z"
                                            id="iso-13" style="cursor: pointer;"></path>
                                        <path
                                            d="M150.11 75.16C142.5 69.28 129.83 70.79 121.26 75.16C111.64 80.07 105.24 91.01 102.25 101.39C99.52 110.86 104.3 132.99 114.05 131.55C134.71 128.49 156.11 139.07 161.91 120.4C167.02 103.98 162.44 84.69 150.11 75.16Z"
                                            id="iso-13" style="cursor: pointer;"></path>
                                        <path d="M137.24 82.38C117.15 85.94 113.51 101.9 112.46 113.44" id="iso-13a"
                                            style="cursor: pointer;"></path>
                                        <path d="M137.24 82.38C117.15 85.94 113.51 101.9 112.46 113.44" id="iso-13a"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M124.86 144.48C120.94 136.65 109.98 133 101.25 133.32C91.45 133.67 81.91 140.28 75.4 147.74C69.45 154.55 63.37 185.34 71.92 187.91C90.03 193.36 104.78 206.01 116.86 192.75C127.48 181.1 131.22 157.16 124.86 144.48Z"
                                            id="iso-14" style="cursor: pointer;"></path>
                                        <path
                                            d="M124.86 144.48C120.94 136.65 109.98 133 101.25 133.32C91.45 133.67 81.91 140.28 75.4 147.74C69.45 154.55 63.37 185.34 71.92 187.91C90.03 193.36 104.78 206.01 116.86 192.75C127.48 181.1 131.22 157.16 124.86 144.48Z"
                                            id="iso-14" style="cursor: pointer;"></path>
                                        <path
                                            d="M104.66 146.08C96.02 147.61 88.62 150.83 84.96 155.61C80.11 161.94 80.47 170.56 79.88 177.14"
                                            id="b6fpiYGql" style="cursor: pointer;"></path>
                                        <path
                                            d="M104.66 146.08C96.02 147.61 88.62 150.83 84.96 155.61C80.11 161.94 80.47 170.56 79.88 177.14"
                                            id="b6fpiYGql" style="cursor: pointer;"></path>
                                        <path
                                            d="M89.08 186.79C96.72 187.31 104.32 187.57 108.8 184.62C116.26 179.71 118.22 169.44 120.59 162.59"
                                            id="cyecxkyP8" style="cursor: pointer;"></path>
                                        <path
                                            d="M89.08 186.79C96.72 187.31 104.32 187.57 108.8 184.62C116.26 179.71 118.22 169.44 120.59 162.59"
                                            id="cyecxkyP8" style="cursor: pointer;"></path>
                                        <path
                                            d="M91.09 200.85C85.15 193.29 72.49 191.66 63.11 193.81C52.59 196.23 43.71 205.28 38.28 214.61C33.32 223.13 37.92 262.17 47.73 263.15C68.51 265.22 86.64 275.94 96.82 259.25C105.78 244.58 100.73 213.1 91.09 200.85Z"
                                            id="iso-15" style="cursor: pointer;"></path>
                                        <path
                                            d="M91.09 200.85C85.15 193.29 72.49 191.66 63.11 193.81C52.59 196.23 43.71 205.28 38.28 214.61C33.32 223.13 37.92 262.17 47.73 263.15C68.51 265.22 86.64 275.94 96.82 259.25C105.78 244.58 100.73 213.1 91.09 200.85Z"
                                            id="iso-15" style="cursor: pointer;"></path>
                                        <path
                                            d="M47.29 239.85C48.2 237.5 47.09 235.21 47.29 233.03C47.82 226.98 48.23 221.74 51.73 217.98C53.09 216.51 54.68 215.22 56.42 214.05C61.11 210.89 66.87 208.65 72.13 206.52"
                                            id="b22znv61x" style="cursor: pointer;"></path>
                                        <path
                                            d="M47.29 239.85C48.2 237.5 47.09 235.21 47.29 233.03C47.82 226.98 48.23 221.74 51.73 217.98C53.09 216.51 54.68 215.22 56.42 214.05C61.11 210.89 66.87 208.65 72.13 206.52"
                                            id="b22znv61x" style="cursor: pointer;"></path>
                                        <path d="M65.93 256.06C86.29 257.43 88.65 244.61 92.44 233.66" id="a3AsQKojJ1"
                                            style="cursor: pointer;"></path>
                                        <path d="M65.93 256.06C86.29 257.43 88.65 244.61 92.44 233.66" id="a3AsQKojJ1"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M44.65 266.85C44.65 266.85 32.12 267.49 26.4 284.67C24.61 290.05 32.51 298.7 29.93 304.86C24.79 317.11 28.02 335.09 38.38 339.9C45.63 343.28 58.05 342.06 64.03 342.11C80.21 342.25 90.22 334.9 98.14 325.38C108.05 313.45 105.01 293.25 96.05 279.99C84.99 271.49 59.26 269.52 44.65 266.85Z"
                                            id="iso-16" style="cursor: pointer;"></path>
                                        <path
                                            d="M44.65 266.85C44.65 266.85 32.12 267.49 26.4 284.67C24.61 290.05 32.51 298.7 29.93 304.86C24.79 317.11 28.02 335.09 38.38 339.9C45.63 343.28 58.05 342.06 64.03 342.11C80.21 342.25 90.22 334.9 98.14 325.38C108.05 313.45 105.01 293.25 96.05 279.99C84.99 271.49 59.26 269.52 44.65 266.85Z"
                                            id="iso-16" style="cursor: pointer;"></path>
                                        <path
                                            d="M69.15 299.11C72.26 301.81 79.33 298.09 84.34 297.03C82.86 298.01 70.96 305.83 69.47 306.81C63.82 315.15 65.77 322.32 64.82 330.31C61.33 329.32 57.97 333.06 54.63 337.45C55.61 332.77 60.76 325.83 56.36 324.06C55.62 324.35 51.94 325.83 45.32 328.49C52.25 322.84 56.11 319.7 56.88 319.07C56.88 319.07 60.11 312.08 57.6 313.11C55.08 314.14 43.72 310.87 43.72 310.87C43.72 310.87 55.47 312.18 60.29 306.52C65.1 300.86 65.34 297.34 66.11 293.42C66.89 289.51 66.48 285.57 68.69 282.74C70.63 280.25 76.87 278.76 76.87 278.76C72.98 285.71 69.87 292.56 69.15 299.11Z"
                                            id="b6rcDQpXhc" style="cursor: pointer;"></path>
                                        <path
                                            d="M69.15 299.11C72.26 301.81 79.33 298.09 84.34 297.03C82.86 298.01 70.96 305.83 69.47 306.81C63.82 315.15 65.77 322.32 64.82 330.31C61.33 329.32 57.97 333.06 54.63 337.45C55.61 332.77 60.76 325.83 56.36 324.06C55.62 324.35 51.94 325.83 45.32 328.49C52.25 322.84 56.11 319.7 56.88 319.07C56.88 319.07 60.11 312.08 57.6 313.11C55.08 314.14 43.72 310.87 43.72 310.87C43.72 310.87 55.47 312.18 60.29 306.52C65.1 300.86 65.34 297.34 66.11 293.42C66.89 289.51 66.48 285.57 68.69 282.74C70.63 280.25 76.87 278.76 76.87 278.76C72.98 285.71 69.87 292.56 69.15 299.11Z"
                                            id="b6rcDQpXhc" style="cursor: pointer;"></path>
                                        <path
                                            d="M98.89 414.82C91.72 434.68 71.23 446.39 48.57 441.68C28.93 437.59 16.41 426.82 11.8 400.23C7.47 375.3 4.28 345.65 48.9 345.64C91.28 345.62 114.44 371.79 98.89 414.82Z"
                                            id="iso-17" style="cursor: pointer;"></path>
                                        <path
                                            d="M98.89 414.82C91.72 434.68 71.23 446.39 48.57 441.68C28.93 437.59 16.41 426.82 11.8 400.23C7.47 375.3 4.28 345.65 48.9 345.64C91.28 345.62 114.44 371.79 98.89 414.82Z"
                                            id="iso-17" style="cursor: pointer;"></path>
                                        <path
                                            d="M78.88 362.56C72.53 364.71 61.58 363.31 60.14 370.37C59.95 371.31 58.41 378.85 58.22 379.79C62.2 382.7 69.4 379.52 75.18 379.02C75.16 379.44 55.97 386.13 55.81 386.2C53.89 387.07 49.14 400.59 53.55 406.92C61.81 412.09 70.99 407.73 79.55 407.88C71.38 409.57 59.68 411.06 58.93 413.2C57.6 420.28 56.36 425.9 55.13 431.44C55.14 425.13 56.46 418.45 52.15 413.39C41.3 410.68 40.92 412.54 35.26 412.97C40.74 411.11 44.03 406.54 46.15 400.52C48.16 392.44 46.33 392.05 45.45 389.75C39.48 387.56 36.28 390.97 31.82 391.32C37.61 388.74 44.41 385.5 49.31 381.64C55.02 377.15 47.89 371.47 42.78 366.36C49.45 364.88 56.47 365.06 61.1 354.34C62.36 362.44 71.16 361.06 78.88 362.56Z"
                                            id="iso-17a" style="cursor: pointer;"></path>
                                        <path
                                            d="M78.88 362.56C72.53 364.71 61.58 363.31 60.14 370.37C59.95 371.31 58.41 378.85 58.22 379.79C62.2 382.7 69.4 379.52 75.18 379.02C75.16 379.44 55.97 386.13 55.81 386.2C53.89 387.07 49.14 400.59 53.55 406.92C61.81 412.09 70.99 407.73 79.55 407.88C71.38 409.57 59.68 411.06 58.93 413.2C57.6 420.28 56.36 425.9 55.13 431.44C55.14 425.13 56.46 418.45 52.15 413.39C41.3 410.68 40.92 412.54 35.26 412.97C40.74 411.11 44.03 406.54 46.15 400.52C48.16 392.44 46.33 392.05 45.45 389.75C39.48 387.56 36.28 390.97 31.82 391.32C37.61 388.74 44.41 385.5 49.31 381.64C55.02 377.15 47.89 371.47 42.78 366.36C49.45 364.88 56.47 365.06 61.1 354.34C62.36 362.44 71.16 361.06 78.88 362.56Z"
                                            id="iso-17a" style="cursor: pointer;"></path>
                                        <path
                                            d="M18.36 529.31C18.36 529.31 7.02 525.35 5.37 504.43C4.86 497.88 13.89 490.15 12.81 482.51C10.67 467.32 21.2 448.9 31.68 446.13C58.89 438.94 72.33 459.38 79.53 476.93C86.12 492.99 79.12 515.06 68.15 527.74C56.25 534.5 32.29 530.07 18.36 529.31Z"
                                            id="iso-18" style="cursor: pointer;"></path>
                                        <path
                                            d="M18.36 529.31C18.36 529.31 7.02 525.35 5.37 504.43C4.86 497.88 13.89 490.15 12.81 482.51C10.67 467.32 21.2 448.9 31.68 446.13C58.89 438.94 72.33 459.38 79.53 476.93C86.12 492.99 79.12 515.06 68.15 527.74C56.25 534.5 32.29 530.07 18.36 529.31Z"
                                            id="iso-18" style="cursor: pointer;"></path>
                                        <path
                                            d="M56.94 494.91C60.48 492.65 67.62 496.05 72.83 497.1C71.34 496.21 59.47 489.15 57.98 488.26C52.76 480.91 55.48 474.74 55.2 467.8C51.42 468.58 48.21 465.25 45.07 461.37C45.68 465.45 50.5 471.58 45.68 473.01C44.93 472.73 41.17 471.36 34.4 468.9C41.23 473.96 45.02 476.77 45.78 477.33C45.78 477.33 48.56 483.47 46 482.51C43.43 481.56 31.11 484.1 31.11 484.1C31.11 484.1 43.66 483.26 48.24 488.28C52.82 493.3 52.75 496.35 53.21 499.76C53.68 503.17 52.89 506.57 54.97 509.07C56.8 511.28 63.26 512.72 63.26 512.72C59.78 506.61 57.11 500.6 56.94 494.91Z"
                                            id="b2DYah0Bi" style="cursor: pointer;"></path>
                                        <path
                                            d="M56.94 494.91C60.48 492.65 67.62 496.05 72.83 497.1C71.34 496.21 59.47 489.15 57.98 488.26C52.76 480.91 55.48 474.74 55.2 467.8C51.42 468.58 48.21 465.25 45.07 461.37C45.68 465.45 50.5 471.58 45.68 473.01C44.93 472.73 41.17 471.36 34.4 468.9C41.23 473.96 45.02 476.77 45.78 477.33C45.78 477.33 48.56 483.47 46 482.51C43.43 481.56 31.11 484.1 31.11 484.1C31.11 484.1 43.66 483.26 48.24 488.28C52.82 493.3 52.75 496.35 53.21 499.76C53.68 503.17 52.89 506.57 54.97 509.07C56.8 511.28 63.26 512.72 63.26 512.72C59.78 506.61 57.11 500.6 56.94 494.91Z"
                                            id="b2DYah0Bi" style="cursor: pointer;"></path><text id="dhJLWP0Rx"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -8.000000000000028 -30.303030303030482)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">11
                                            </tspan>
                                        </text><text id="h484gZh3x" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -45.04545454545476 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">12
                                            </tspan>
                                        </text><text id="a5HTD7Ziun" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -76.03030303030349 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">13
                                            </tspan>
                                        </text><text id="dPldy7HEx" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -110.04545454545521 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">14
                                            </tspan>
                                        </text><text id="aagZSuMJr" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">15
                                            </tspan>
                                        </text><text id="c3f2gAlj3" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">16
                                            </tspan>
                                        </text><text id="fWwh13pJ" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -134.90482954545524 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">17
                                            </tspan>
                                        </text><text id="iALQXea9Y" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -155.76420454545527 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">18
                                            </tspan>
                                        </text>
                                        <path
                                            d="M293.44 8.55C299.61 -4.36 366.51 6.32 361.74 21.38C353.43 47.66 333.86 73.28 311.56 67.4C291.71 62.17 297.06 20.32 293.44 8.55Z"
                                            id="iso-21" style="cursor: pointer;"></path>
                                        <path
                                            d="M293.44 8.55C299.61 -4.36 366.51 6.32 361.74 21.38C353.43 47.66 333.86 73.28 311.56 67.4C291.71 62.17 297.06 20.32 293.44 8.55Z"
                                            id="iso-21" style="cursor: pointer;"></path>
                                        <path d="M308.96 19.39C320.52 14.5 332.61 16.21 345.55 26.43" id="h3ymJ8JsAJ"
                                            style="cursor: pointer;"></path>
                                        <path d="M308.96 19.39C320.52 14.5 332.61 16.21 345.55 26.43" id="h3ymJ8JsAJ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M414.11 62.43C421.67 40.31 368.58 12.91 359.58 32.34C353.65 45.15 354.99 79.12 367.65 86.18C382 94.17 408.85 77.86 414.11 62.43Z"
                                            id="iso-22" style="cursor: pointer;"></path>
                                        <path
                                            d="M414.11 62.43C421.67 40.31 368.58 12.91 359.58 32.34C353.65 45.15 354.99 79.12 367.65 86.18C382 94.17 408.85 77.86 414.11 62.43Z"
                                            id="iso-22" style="cursor: pointer;"></path>
                                        <path d="M373.13 40.87C381.95 41.5 389.21 40.72 399.62 53.98" id="e7zZFZz9XZ"
                                            style="cursor: pointer;"></path>
                                        <path d="M373.13 40.87C381.95 41.5 389.21 40.72 399.62 53.98" id="e7zZFZz9XZ"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M409.49 75.16C417.1 69.28 429.78 70.79 438.34 75.16C447.96 80.07 454.37 91.01 457.36 101.39C460.09 110.86 455.31 132.99 445.56 131.55C424.89 128.49 403.5 139.07 397.69 120.4C392.59 103.98 397.16 84.69 409.49 75.16Z"
                                            id="iso-23" style="cursor: pointer;"></path>
                                        <path
                                            d="M409.49 75.16C417.1 69.28 429.78 70.79 438.34 75.16C447.96 80.07 454.37 91.01 457.36 101.39C460.09 110.86 455.31 132.99 445.56 131.55C424.89 128.49 403.5 139.07 397.69 120.4C392.59 103.98 397.16 84.69 409.49 75.16Z"
                                            id="iso-23" style="cursor: pointer;"></path>
                                        <path d="M422.37 82.38C442.46 85.94 446.1 101.9 447.15 113.44" id="bX8HWOfji"
                                            style="cursor: pointer;"></path>
                                        <path d="M422.37 82.38C442.46 85.94 446.1 101.9 447.15 113.44" id="bX8HWOfji"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M434.74 144.48C438.66 136.65 449.63 133 458.35 133.32C468.15 133.67 477.69 140.28 484.21 147.74C490.16 154.55 496.24 185.34 487.69 187.91C469.58 193.36 454.82 206.01 442.74 192.75C432.12 181.1 428.39 157.16 434.74 144.48Z"
                                            id="iso-24" style="cursor: pointer;"></path>
                                        <path
                                            d="M434.74 144.48C438.66 136.65 449.63 133 458.35 133.32C468.15 133.67 477.69 140.28 484.21 147.74C490.16 154.55 496.24 185.34 487.69 187.91C469.58 193.36 454.82 206.01 442.74 192.75C432.12 181.1 428.39 157.16 434.74 144.48Z"
                                            id="iso-24" style="cursor: pointer;"></path>
                                        <path
                                            d="M454.95 146.08C463.59 147.61 470.99 150.83 474.64 155.61C479.49 161.94 479.13 170.56 479.73 177.14"
                                            id="d6p4ZsEBwV" style="cursor: pointer;"></path>
                                        <path
                                            d="M454.95 146.08C463.59 147.61 470.99 150.83 474.64 155.61C479.49 161.94 479.13 170.56 479.73 177.14"
                                            id="d6p4ZsEBwV" style="cursor: pointer;"></path>
                                        <path
                                            d="M470.52 186.79C462.89 187.31 455.28 187.57 450.81 184.62C443.35 179.71 441.38 169.44 439.01 162.59"
                                            id="b2b6VhQYX9" style="cursor: pointer;"></path>
                                        <path
                                            d="M470.52 186.79C462.89 187.31 455.28 187.57 450.81 184.62C443.35 179.71 441.38 169.44 439.01 162.59"
                                            id="b2b6VhQYX9" style="cursor: pointer;"></path>
                                        <path
                                            d="M468.51 200.85C474.46 193.29 487.12 191.66 496.49 193.81C507.02 196.23 515.9 205.28 521.33 214.61C526.28 223.13 521.68 262.17 511.88 263.15C491.09 265.22 472.97 275.94 462.78 259.25C453.83 244.58 458.88 213.1 468.51 200.85Z"
                                            id="iso-25" style="cursor: pointer;"></path>
                                        <path
                                            d="M468.51 200.85C474.46 193.29 487.12 191.66 496.49 193.81C507.02 196.23 515.9 205.28 521.33 214.61C526.28 223.13 521.68 262.17 511.88 263.15C491.09 265.22 472.97 275.94 462.78 259.25C453.83 244.58 458.88 213.1 468.51 200.85Z"
                                            id="iso-25" style="cursor: pointer;"></path>
                                        <path
                                            d="M512.32 239.85C511.4 237.5 512.51 235.21 512.32 233.03C511.78 226.98 511.38 221.74 507.88 217.98C506.51 216.51 504.92 215.22 503.19 214.05C498.49 210.89 492.74 208.65 487.48 206.52"
                                            id="a1IjZsIm8S" style="cursor: pointer;"></path>
                                        <path
                                            d="M512.32 239.85C511.4 237.5 512.51 235.21 512.32 233.03C511.78 226.98 511.38 221.74 507.88 217.98C506.51 216.51 504.92 215.22 503.19 214.05C498.49 210.89 492.74 208.65 487.48 206.52"
                                            id="a1IjZsIm8S" style="cursor: pointer;"></path>
                                        <path d="M493.67 256.06C473.32 257.43 470.95 244.61 467.16 233.66"
                                            id="b2JgD7E2Kq" style="cursor: pointer;"></path>
                                        <path d="M493.67 256.06C473.32 257.43 470.95 244.61 467.16 233.66"
                                            id="b2JgD7E2Kq" style="cursor: pointer;"></path>
                                        <path
                                            d="M514.96 266.85C514.96 266.85 527.49 267.49 533.2 284.67C534.99 290.05 527.1 298.7 529.68 304.86C534.82 317.11 531.59 335.09 521.23 339.9C513.97 343.28 501.56 342.06 495.58 342.11C479.4 342.25 469.38 334.9 461.47 325.38C451.56 313.45 454.6 293.25 463.55 279.99C474.61 271.49 500.35 269.52 514.96 266.85Z"
                                            id="iso-26" style="cursor: pointer;"></path>
                                        <path
                                            d="M514.96 266.85C514.96 266.85 527.49 267.49 533.2 284.67C534.99 290.05 527.1 298.7 529.68 304.86C534.82 317.11 531.59 335.09 521.23 339.9C513.97 343.28 501.56 342.06 495.58 342.11C479.4 342.25 469.38 334.9 461.47 325.38C451.56 313.45 454.6 293.25 463.55 279.99C474.61 271.49 500.35 269.52 514.96 266.85Z"
                                            id="iso-26" style="cursor: pointer;"></path>
                                        <path
                                            d="M490.46 299.11C487.34 301.81 480.27 298.09 475.26 297.03C476.75 298.01 488.65 305.83 490.13 306.81C495.79 315.15 493.84 322.32 494.78 330.31C498.27 329.32 501.63 333.06 504.97 337.45C504 332.77 498.84 325.83 503.25 324.06C503.98 324.35 507.67 325.83 514.29 328.49C507.35 322.84 503.5 319.7 502.72 319.07C502.72 319.07 499.49 312.08 502.01 313.11C504.52 314.14 515.89 310.87 515.89 310.87C515.89 310.87 504.13 312.18 499.32 306.52C494.5 300.86 494.27 297.34 493.49 293.42C492.72 289.51 493.12 285.57 490.92 282.74C488.97 280.25 482.74 278.76 482.74 278.76C486.62 285.71 489.74 292.56 490.46 299.11Z"
                                            id="aLBv0ngyf" style="cursor: pointer;"></path>
                                        <path
                                            d="M490.46 299.11C487.34 301.81 480.27 298.09 475.26 297.03C476.75 298.01 488.65 305.83 490.13 306.81C495.79 315.15 493.84 322.32 494.78 330.31C498.27 329.32 501.63 333.06 504.97 337.45C504 332.77 498.84 325.83 503.25 324.06C503.98 324.35 507.67 325.83 514.29 328.49C507.35 322.84 503.5 319.7 502.72 319.07C502.72 319.07 499.49 312.08 502.01 313.11C504.52 314.14 515.89 310.87 515.89 310.87C515.89 310.87 504.13 312.18 499.32 306.52C494.5 300.86 494.27 297.34 493.49 293.42C492.72 289.51 493.12 285.57 490.92 282.74C488.97 280.25 482.74 278.76 482.74 278.76C486.62 285.71 489.74 292.56 490.46 299.11Z"
                                            id="aLBv0ngyf" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.71 414.82C467.89 434.68 488.38 446.39 511.04 441.68C530.67 437.59 543.19 426.82 547.81 400.23C552.14 375.3 555.32 345.65 510.71 345.64C468.33 345.62 445.17 371.79 460.71 414.82Z"
                                            id="iso-27" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.71 414.82C467.89 434.68 488.38 446.39 511.04 441.68C530.67 437.59 543.19 426.82 547.81 400.23C552.14 375.3 555.32 345.65 510.71 345.64C468.33 345.62 445.17 371.79 460.71 414.82Z"
                                            id="iso-27" style="cursor: pointer;"></path>
                                        <path
                                            d="M480.73 362.56C487.08 364.71 498.03 363.31 499.47 370.37C499.66 371.31 501.19 378.85 501.38 379.79C497.4 382.7 490.21 379.52 484.42 379.02C484.45 379.44 503.64 386.13 503.8 386.2C505.71 387.07 510.47 400.59 506.05 406.92C497.79 412.09 488.62 407.73 480.06 407.88C488.23 409.57 499.93 411.06 500.68 413.2C502 420.28 503.24 425.9 504.47 431.44C504.47 425.13 503.15 418.45 507.45 413.39C518.31 410.68 518.69 412.54 524.35 412.97C518.87 411.11 515.58 406.54 513.45 400.52C511.45 392.44 513.28 392.05 514.16 389.75C520.13 387.56 523.32 390.97 527.79 391.32C522 388.74 515.2 385.5 510.29 381.64C504.59 377.15 511.72 371.47 516.82 366.36C510.16 364.88 503.13 365.06 498.51 354.34C497.25 362.44 488.45 361.06 480.73 362.56Z"
                                            id="lmVcyt8R" style="cursor: pointer;"></path>
                                        <path
                                            d="M480.73 362.56C487.08 364.71 498.03 363.31 499.47 370.37C499.66 371.31 501.19 378.85 501.38 379.79C497.4 382.7 490.21 379.52 484.42 379.02C484.45 379.44 503.64 386.13 503.8 386.2C505.71 387.07 510.47 400.59 506.05 406.92C497.79 412.09 488.62 407.73 480.06 407.88C488.23 409.57 499.93 411.06 500.68 413.2C502 420.28 503.24 425.9 504.47 431.44C504.47 425.13 503.15 418.45 507.45 413.39C518.31 410.68 518.69 412.54 524.35 412.97C518.87 411.11 515.58 406.54 513.45 400.52C511.45 392.44 513.28 392.05 514.16 389.75C520.13 387.56 523.32 390.97 527.79 391.32C522 388.74 515.2 385.5 510.29 381.64C504.59 377.15 511.72 371.47 516.82 366.36C510.16 364.88 503.13 365.06 498.51 354.34C497.25 362.44 488.45 361.06 480.73 362.56Z"
                                            id="lmVcyt8R" style="cursor: pointer;"></path>
                                        <path
                                            d="M541.25 529.31C541.25 529.31 552.59 525.35 554.23 504.43C554.75 497.88 545.72 490.15 546.8 482.51C548.94 467.32 538.41 448.9 527.93 446.13C500.72 438.94 487.28 459.38 480.08 476.93C473.49 492.99 480.49 515.06 491.46 527.74C503.35 534.5 527.32 530.07 541.25 529.31Z"
                                            id="iso-28" style="cursor: pointer;"></path>
                                        <path
                                            d="M541.25 529.31C541.25 529.31 552.59 525.35 554.23 504.43C554.75 497.88 545.72 490.15 546.8 482.51C548.94 467.32 538.41 448.9 527.93 446.13C500.72 438.94 487.28 459.38 480.08 476.93C473.49 492.99 480.49 515.06 491.46 527.74C503.35 534.5 527.32 530.07 541.25 529.31Z"
                                            id="iso-28" style="cursor: pointer;"></path>
                                        <path
                                            d="M502.67 494.91C499.12 492.65 491.98 496.05 486.78 497.1C488.26 496.21 500.14 489.15 501.62 488.26C506.85 480.91 504.13 474.74 504.4 467.8C508.19 468.58 511.4 465.25 514.53 461.37C513.93 465.45 509.1 471.58 513.93 473.01C514.68 472.73 518.44 471.36 525.21 468.9C518.38 473.96 514.59 476.77 513.83 477.33C513.83 477.33 511.04 483.47 513.61 482.51C516.18 481.56 528.5 484.1 528.5 484.1C528.5 484.1 515.94 483.26 511.36 488.28C506.78 493.3 506.86 496.35 506.39 499.76C505.93 503.17 506.72 506.57 504.64 509.07C502.81 511.28 496.35 512.72 496.35 512.72C499.82 506.61 502.5 500.6 502.67 494.91Z"
                                            id="bsHecJRYc" style="cursor: pointer;"></path>
                                        <path
                                            d="M502.67 494.91C499.12 492.65 491.98 496.05 486.78 497.1C488.26 496.21 500.14 489.15 501.62 488.26C506.85 480.91 504.13 474.74 504.4 467.8C508.19 468.58 511.4 465.25 514.53 461.37C513.93 465.45 509.1 471.58 513.93 473.01C514.68 472.73 518.44 471.36 525.21 468.9C518.38 473.96 514.59 476.77 513.83 477.33C513.83 477.33 511.04 483.47 513.61 482.51C516.18 481.56 528.5 484.1 528.5 484.1C528.5 484.1 515.94 483.26 511.36 488.28C506.78 493.3 506.86 496.35 506.39 499.76C505.93 503.17 506.72 506.57 504.64 509.07C502.81 511.28 496.35 512.72 496.35 512.72C499.82 506.61 502.5 500.6 502.67 494.91Z"
                                            id="bsHecJRYc" style="cursor: pointer;"></path><text id="b1gODCfJB5"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 62.5303030303032 -33.3333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">21
                                            </tspan>
                                        </text><text id="a2KwBh9N1C" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 98.21212121212167 -19.8333333333336)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">22
                                            </tspan>
                                        </text><text id="avSr25PQ8" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 130.03030303030368 16.257575757575637)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">23
                                            </tspan>
                                        </text><text id="b1IAgvlN7L" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 162.49083356665588 61.819418583930684)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">24
                                            </tspan>
                                        </text><text id="a5jdHsmSg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 189.14062500000085 122.24318752332468)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">25
                                            </tspan>
                                        </text><text id="eEJTk9OPg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.3502085666559 187.2124110081733)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">26
                                            </tspan>
                                        </text><text id="a1kI1wu28" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 185.35020856665585 279.45436176574924)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">27
                                            </tspan>
                                        </text><text id="f10grZcqP0" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 208.20958356665594 379.27207009908284)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">28
                                            </tspan>
                                        </text>
                                        <path
                                            d="M294.7 1047.24C298.16 1058.52 342.99 1052.18 340.57 1039.13C336.33 1016.34 324.61 993.66 309.55 997.75C296.15 1001.39 297.67 1037.34 294.7 1047.24Z"
                                            id="iso-31" style="cursor: pointer;"></path>
                                        <path
                                            d="M294.7 1047.24C298.16 1058.52 342.99 1052.18 340.57 1039.13C336.33 1016.34 324.61 993.66 309.55 997.75C296.15 1001.39 297.67 1037.34 294.7 1047.24Z"
                                            id="iso-31" style="cursor: pointer;"></path>
                                        <path d="M305.51 1038.64C312.93 1043.28 321.02 1042.33 330.08 1034.14"
                                            id="cjuFfUWr" style="cursor: pointer;"></path>
                                        <path d="M305.51 1038.64C312.93 1043.28 321.02 1042.33 330.08 1034.14"
                                            id="cjuFfUWr" style="cursor: pointer;"></path>
                                        <path
                                            d="M398.22 1007.25C404.14 1029.79 352.2 1053.97 344.76 1034.04C339.86 1020.91 343.05 987.08 355.47 980.78C369.54 973.66 394.1 991.54 398.22 1007.25Z"
                                            id="iso-32" style="cursor: pointer;"></path>
                                        <path
                                            d="M398.22 1007.25C404.14 1029.79 352.2 1053.97 344.76 1034.04C339.86 1020.91 343.05 987.08 355.47 980.78C369.54 973.66 394.1 991.54 398.22 1007.25Z"
                                            id="iso-32" style="cursor: pointer;"></path>
                                        <path d="M358.1 1026.33C366.51 1026.23 373.35 1027.45 383.99 1014.83"
                                            id="ahVnr1hTO" style="cursor: pointer;"></path>
                                        <path d="M358.1 1026.33C366.51 1026.23 373.35 1027.45 383.99 1014.83"
                                            id="ahVnr1hTO" style="cursor: pointer;"></path>
                                        <path
                                            d="M392.57 994.77C399.46 1001.09 411.57 1000.34 419.95 996.49C429.36 992.16 436.06 981.62 439.48 971.44C442.61 962.15 439.32 939.77 429.99 940.63C410.2 942.46 390.49 930.62 383.92 948.91C378.15 964.99 381.4 984.52 392.57 994.77Z"
                                            id="iso-33" style="cursor: pointer;"></path>
                                        <path
                                            d="M392.57 994.77C399.46 1001.09 411.57 1000.34 419.95 996.49C429.36 992.16 436.06 981.62 439.48 971.44C442.61 962.15 439.32 939.77 429.99 940.63C410.2 942.46 390.49 930.62 383.92 948.91C378.15 964.99 381.4 984.52 392.57 994.77Z"
                                            id="iso-33" style="cursor: pointer;"></path>
                                        <path d="M405.19 988.33C424.46 985.97 428.82 970.26 430.47 958.8"
                                            id="d2sFISoAcr" style="cursor: pointer;"></path>
                                        <path d="M405.19 988.33C424.46 985.97 428.82 970.26 430.47 958.8"
                                            id="d2sFISoAcr" style="cursor: pointer;"></path>
                                        <path
                                            d="M420.46 927.08C423.73 935.12 433.93 939.42 442.23 939.62C451.55 939.85 460.98 933.83 467.59 926.77C473.63 920.33 481.14 889.95 473.17 886.88C456.29 880.35 443 866.85 430.78 879.36C420.04 890.37 415.14 914.04 420.46 927.08Z"
                                            id="iso-34" style="cursor: pointer;"></path>
                                        <path
                                            d="M420.46 927.08C423.73 935.12 433.93 939.42 442.23 939.62C451.55 939.85 460.98 933.83 467.59 926.77C473.63 920.33 481.14 889.95 473.17 886.88C456.29 880.35 443 866.85 430.78 879.36C420.04 890.37 415.14 914.04 420.46 927.08Z"
                                            id="iso-34" style="cursor: pointer;"></path>
                                        <path
                                            d="M439.73 926.69C448.01 925.67 455.22 922.9 458.96 918.34C463.92 912.31 464.06 903.69 465 897.16"
                                            id="f1nJy2rCGI" style="cursor: pointer;"></path>
                                        <path
                                            d="M439.73 926.69C448.01 925.67 455.22 922.9 458.96 918.34C463.92 912.31 464.06 903.69 465 897.16"
                                            id="f1nJy2rCGI" style="cursor: pointer;"></path>
                                        <path
                                            d="M456.81 886.97C449.6 886 442.39 885.28 437.98 887.96C430.62 892.42 428.17 902.56 425.53 909.25"
                                            id="aaB5NMNJEr" style="cursor: pointer;"></path>
                                        <path
                                            d="M456.81 886.97C449.6 886 442.39 885.28 437.98 887.96C430.62 892.42 428.17 902.56 425.53 909.25"
                                            id="aaB5NMNJEr" style="cursor: pointer;"></path>
                                        <path
                                            d="M455.7 872.82C460.92 880.72 472.84 883.09 481.86 881.51C491.99 879.73 500.93 871.22 506.61 862.23C511.8 854.02 509.64 814.77 500.39 813.21C480.78 809.91 464.18 798.13 453.57 814.18C444.24 828.29 447.25 860.02 455.7 872.82Z"
                                            id="iso-35" style="cursor: pointer;"></path>
                                        <path
                                            d="M455.7 872.82C460.92 880.72 472.84 883.09 481.86 881.51C491.99 879.73 500.93 871.22 506.61 862.23C511.8 854.02 509.64 814.77 500.39 813.21C480.78 809.91 464.18 798.13 453.57 814.18C444.24 828.29 447.25 860.02 455.7 872.82Z"
                                            id="iso-35" style="cursor: pointer;"></path>
                                        <path
                                            d="M499.49 836.49C498.48 838.79 499.41 841.14 499.1 843.3C498.25 849.31 497.57 854.52 494.03 858.06C492.65 859.44 491.07 860.64 489.36 861.71C484.73 864.58 479.13 866.47 474.02 868.29"
                                            id="bHt0uYEvE" style="cursor: pointer;"></path>
                                        <path
                                            d="M499.49 836.49C498.48 838.79 499.41 841.14 499.1 843.3C498.25 849.31 497.57 854.52 494.03 858.06C492.65 859.44 491.07 860.64 489.36 861.71C484.73 864.58 479.13 866.47 474.02 868.29"
                                            id="bHt0uYEvE" style="cursor: pointer;"></path>
                                        <path d="M482.71 819.2C463.46 816.63 460.49 829.28 456.28 839.99" id="awAtPqnox"
                                            style="cursor: pointer;"></path>
                                        <path d="M482.71 819.2C463.46 816.63 460.49 829.28 456.28 839.99" id="awAtPqnox"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M503.52 809.7C503.52 809.7 515.45 809.8 521.85 792.99C523.85 787.73 516.85 778.63 519.65 772.64C525.22 760.71 523.17 742.58 513.61 737.15C506.92 733.35 495.06 733.83 489.39 733.42C474.04 732.31 464.12 739.06 456.07 748.09C445.98 759.4 447.72 779.75 455.48 793.52C465.49 802.67 489.81 806.16 503.52 809.7Z"
                                            id="iso-36" style="cursor: pointer;"></path>
                                        <path
                                            d="M503.52 809.7C503.52 809.7 515.45 809.8 521.85 792.99C523.85 787.73 516.85 778.63 519.65 772.64C525.22 760.71 523.17 742.58 513.61 737.15C506.92 733.35 495.06 733.83 489.39 733.42C474.04 732.31 464.12 739.06 456.07 748.09C445.98 759.4 447.72 779.75 455.48 793.52C465.49 802.67 489.81 806.16 503.52 809.7Z"
                                            id="iso-36" style="cursor: pointer;"></path>
                                        <path
                                            d="M482.1 776.04C479.29 773.15 472.37 776.45 467.55 777.21C469.02 776.32 480.76 769.22 482.22 768.33C488.06 760.35 486.62 753.07 487.97 745.15C491.22 746.35 494.62 742.81 498.04 738.63C496.85 743.24 491.57 749.86 495.65 751.9C496.36 751.65 499.94 750.39 506.38 748.13C499.47 753.36 495.64 756.26 494.87 756.84C494.87 756.84 491.4 763.63 493.85 762.75C496.29 761.87 506.9 765.81 506.9 765.81C506.9 765.81 495.81 763.81 490.92 769.17C486.03 774.54 485.61 778.03 484.65 781.89C483.7 785.76 483.86 789.71 481.6 792.41C479.62 794.78 473.61 795.89 473.61 795.89C477.69 789.18 481.04 782.53 482.1 776.04Z"
                                            id="m211qMeGpL" style="cursor: pointer;"></path>
                                        <path
                                            d="M482.1 776.04C479.29 773.15 472.37 776.45 467.55 777.21C469.02 776.32 480.76 769.22 482.22 768.33C488.06 760.35 486.62 753.07 487.97 745.15C491.22 746.35 494.62 742.81 498.04 738.63C496.85 743.24 491.57 749.86 495.65 751.9C496.36 751.65 499.94 750.39 506.38 748.13C499.47 753.36 495.64 756.26 494.87 756.84C494.87 756.84 491.4 763.63 493.85 762.75C496.29 761.87 506.9 765.81 506.9 765.81C506.9 765.81 495.81 763.81 490.92 769.17C486.03 774.54 485.61 778.03 484.65 781.89C483.7 785.76 483.86 789.71 481.6 792.41C479.62 794.78 473.61 795.89 473.61 795.89C477.69 789.18 481.04 782.53 482.1 776.04Z"
                                            id="m211qMeGpL" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.41 658.77C468.35 639.36 488.46 628.89 509.7 634.95C528.1 640.2 539.38 651.7 542.26 678.51C544.95 703.65 546.3 733.44 503.95 730.8C463.72 728.29 443.22 700.79 460.41 658.77Z"
                                            id="iso-37" style="cursor: pointer;"></path>
                                        <path
                                            d="M460.41 658.77C468.35 639.36 488.46 628.89 509.7 634.95C528.1 640.2 539.38 651.7 542.26 678.51C544.95 703.65 546.3 733.44 503.95 730.8C463.72 728.29 443.22 700.79 460.41 658.77Z"
                                            id="iso-37" style="cursor: pointer;"></path>
                                        <path
                                            d="M476.45 712.12C482.6 710.36 492.92 712.41 494.68 705.44C494.92 704.51 496.8 697.08 497.03 696.15C493.42 693.01 486.41 695.75 480.89 695.91C480.94 695.49 499.53 689.96 499.69 689.9C501.56 689.15 506.83 675.93 503 669.35C495.45 663.69 486.5 667.5 478.38 666.85C486.23 665.64 497.42 664.85 498.25 662.76C499.91 655.77 501.41 650.23 502.89 644.78C502.53 651.07 500.89 657.66 504.7 662.98C514.85 666.32 515.31 664.49 520.71 664.4C515.4 665.92 512.02 670.3 509.66 676.18C507.3 684.13 509.02 684.63 509.72 686.97C515.27 689.51 518.49 686.3 522.75 686.22C517.1 688.44 510.47 691.27 505.59 694.84C499.92 698.98 506.37 705.07 510.92 710.48C504.52 711.56 497.86 710.96 492.86 721.38C492.13 713.23 483.7 714.07 476.45 712.12Z"
                                            id="b5JyusjzlT" style="cursor: pointer;"></path>
                                        <path
                                            d="M476.45 712.12C482.6 710.36 492.92 712.41 494.68 705.44C494.92 704.51 496.8 697.08 497.03 696.15C493.42 693.01 486.41 695.75 480.89 695.91C480.94 695.49 499.53 689.96 499.69 689.9C501.56 689.15 506.83 675.93 503 669.35C495.45 663.69 486.5 667.5 478.38 666.85C486.23 665.64 497.42 664.85 498.25 662.76C499.91 655.77 501.41 650.23 502.89 644.78C502.53 651.07 500.89 657.66 504.7 662.98C514.85 666.32 515.31 664.49 520.71 664.4C515.4 665.92 512.02 670.3 509.66 676.18C507.3 684.13 509.02 684.63 509.72 686.97C515.27 689.51 518.49 686.3 522.75 686.22C517.1 688.44 510.47 691.27 505.59 694.84C499.92 698.98 506.37 705.07 510.92 710.48C504.52 711.56 497.86 710.96 492.86 721.38C492.13 713.23 483.7 714.07 476.45 712.12Z"
                                            id="b5JyusjzlT" style="cursor: pointer;"></path>
                                        <path
                                            d="M543.34 549.27C543.34 549.27 553.87 553.9 554.25 574.88C554.37 581.45 545.36 588.63 545.95 596.32C547.13 611.61 536.09 629.37 525.98 631.51C499.75 637.07 488.15 615.86 482.31 597.91C476.97 581.49 484.86 559.88 495.99 547.87C507.66 541.83 530.16 547.69 543.34 549.27Z"
                                            id="iso-38" style="cursor: pointer;"></path>
                                        <path
                                            d="M543.34 549.27C543.34 549.27 553.87 553.9 554.25 574.88C554.37 581.45 545.36 588.63 545.95 596.32C547.13 611.61 536.09 629.37 525.98 631.51C499.75 637.07 488.15 615.86 482.31 597.91C476.97 581.49 484.86 559.88 495.99 547.87C507.66 541.83 530.16 547.69 543.34 549.27Z"
                                            id="iso-38" style="cursor: pointer;"></path>
                                        <path
                                            d="M504.77 581.31C501.28 583.35 494.69 579.54 489.81 578.18C491.17 579.16 502.04 586.92 503.4 587.89C507.94 595.54 505.02 601.53 504.88 608.47C508.52 607.93 511.38 611.44 514.13 615.5C513.79 611.4 509.56 604.99 514.22 603.85C514.92 604.17 518.41 605.76 524.69 608.62C518.5 603.16 515.05 600.13 514.37 599.52C514.37 599.52 512.07 593.24 514.45 594.34C516.84 595.44 528.68 593.64 528.68 593.64C528.68 593.64 516.71 593.73 512.65 588.44C508.59 583.16 508.83 580.12 508.58 576.69C508.33 573.26 509.27 569.91 507.44 567.29C505.83 564.98 499.78 563.16 499.78 563.16C502.73 569.47 504.93 575.62 504.77 581.31Z"
                                            id="a9wXxhEQy" style="cursor: pointer;"></path>
                                        <path
                                            d="M504.77 581.31C501.28 583.35 494.69 579.54 489.81 578.18C491.17 579.16 502.04 586.92 503.4 587.89C507.94 595.54 505.02 601.53 504.88 608.47C508.52 607.93 511.38 611.44 514.13 615.5C513.79 611.4 509.56 604.99 514.22 603.85C514.92 604.17 518.41 605.76 524.69 608.62C518.5 603.16 515.05 600.13 514.37 599.52C514.37 599.52 512.07 593.24 514.45 594.34C516.84 595.44 528.68 593.64 528.68 593.64C528.68 593.64 516.71 593.73 512.65 588.44C508.59 583.16 508.83 580.12 508.58 576.69C508.33 573.26 509.27 569.91 507.44 567.29C505.83 564.98 499.78 563.16 499.78 563.16C502.73 569.47 504.93 575.62 504.77 581.31Z"
                                            id="a9wXxhEQy" style="cursor: pointer;"></path><text id="b1BVUVOWBq"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 57.80000000000004 869.8745265151554)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">31
                                            </tspan>
                                        </text><text id="a3t972Cvsg" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 95.73437500000031 855.2745265151555)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">32
                                            </tspan>
                                        </text><text id="i4mHUxK2re" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 152.8896780303037 770.7026515151549)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">34
                                            </tspan>
                                        </text><text id="d3EsTPegQ" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 121.07149621212173 820.8849431818218)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">33
                                            </tspan>
                                        </text><text id="b2ApfidLEf" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 175.7490530303037 713.8795190562305)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">35
                                            </tspan>
                                        </text><text id="b2aRoIXnu9" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 179.9811271272621 644.030087238048)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">36
                                            </tspan>
                                        </text><text id="a1E40eZIMw" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 187.17874053030374 552.0747842077441)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">37
                                            </tspan>
                                        </text><text id="b18Xayl0Ve" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 212.00000000000088 465.24931072289553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">38
                                            </tspan>
                                        </text>
                                        <path
                                            d="M264.9 1047.24C261.44 1058.52 216.61 1052.18 219.04 1039.13C223.27 1016.34 235 993.66 250.05 997.75C263.46 1001.39 261.93 1037.34 264.9 1047.24Z"
                                            id="iso-41" style="cursor: pointer;"></path>
                                        <path
                                            d="M264.9 1047.24C261.44 1058.52 216.61 1052.18 219.04 1039.13C223.27 1016.34 235 993.66 250.05 997.75C263.46 1001.39 261.93 1037.34 264.9 1047.24Z"
                                            id="iso-41" style="cursor: pointer;"></path>
                                        <path d="M254.1 1038.64C246.68 1043.28 238.59 1042.33 229.52 1034.14"
                                            id="a2Ot1hj5Ti" style="cursor: pointer;"></path>
                                        <path d="M254.1 1038.64C246.68 1043.28 238.59 1042.33 229.52 1034.14"
                                            id="a2Ot1hj5Ti" style="cursor: pointer;"></path>
                                        <path
                                            d="M161.38 1007.25C155.47 1029.79 207.41 1053.97 214.85 1034.04C219.75 1020.91 216.56 987.08 204.13 980.78C190.07 973.66 165.51 991.54 161.38 1007.25Z"
                                            id="iso-42" style="cursor: pointer;"></path>
                                        <path
                                            d="M161.38 1007.25C155.47 1029.79 207.41 1053.97 214.85 1034.04C219.75 1020.91 216.56 987.08 204.13 980.78C190.07 973.66 165.51 991.54 161.38 1007.25Z"
                                            id="iso-42" style="cursor: pointer;"></path>
                                        <path d="M201.5 1026.33C193.09 1026.23 186.25 1027.45 175.62 1014.83"
                                            id="c3OkjuGmoe" style="cursor: pointer;"></path>
                                        <path d="M201.5 1026.33C193.09 1026.23 186.25 1027.45 175.62 1014.83"
                                            id="c3OkjuGmoe" style="cursor: pointer;"></path>
                                        <path
                                            d="M167.04 994.77C160.15 1001.09 148.04 1000.34 139.66 996.49C130.25 992.16 123.55 981.62 120.13 971.44C117 962.15 120.28 939.77 129.62 940.63C149.4 942.46 169.12 930.62 175.68 948.91C181.46 964.99 178.21 984.52 167.04 994.77Z"
                                            id="iso-43" style="cursor: pointer;"></path>
                                        <path
                                            d="M167.04 994.77C160.15 1001.09 148.04 1000.34 139.66 996.49C130.25 992.16 123.55 981.62 120.13 971.44C117 962.15 120.28 939.77 129.62 940.63C149.4 942.46 169.12 930.62 175.68 948.91C181.46 964.99 178.21 984.52 167.04 994.77Z"
                                            id="iso-43" style="cursor: pointer;"></path>
                                        <path d="M154.41 988.33C135.14 985.97 130.78 970.26 129.13 958.8"
                                            id="c1DvhakQel" style="cursor: pointer;"></path>
                                        <path d="M154.41 988.33C135.14 985.97 130.78 970.26 129.13 958.8"
                                            id="c1DvhakQel" style="cursor: pointer;"></path>
                                        <path
                                            d="M139.15 927.08C135.87 935.12 125.67 939.42 117.37 939.62C108.05 939.85 98.62 933.83 92.01 926.77C85.98 920.33 78.47 889.95 86.44 886.88C103.32 880.35 116.61 866.85 128.82 879.36C139.56 890.37 144.46 914.04 139.15 927.08Z"
                                            id="iso-44" style="cursor: pointer;"></path>
                                        <path
                                            d="M139.15 927.08C135.87 935.12 125.67 939.42 117.37 939.62C108.05 939.85 98.62 933.83 92.01 926.77C85.98 920.33 78.47 889.95 86.44 886.88C103.32 880.35 116.61 866.85 128.82 879.36C139.56 890.37 144.46 914.04 139.15 927.08Z"
                                            id="iso-44" style="cursor: pointer;"></path>
                                        <path
                                            d="M119.88 926.69C111.59 925.67 104.39 922.9 100.65 918.34C95.69 912.31 95.54 903.69 94.6 897.16"
                                            id="c2SUh81nX8" style="cursor: pointer;"></path>
                                        <path
                                            d="M119.88 926.69C111.59 925.67 104.39 922.9 100.65 918.34C95.69 912.31 95.54 903.69 94.6 897.16"
                                            id="c2SUh81nX8" style="cursor: pointer;"></path>
                                        <path
                                            d="M102.79 886.97C110.01 886 117.22 885.28 121.63 887.96C128.99 892.42 131.44 902.56 134.07 909.25"
                                            id="d38q1jr7XL" style="cursor: pointer;"></path>
                                        <path
                                            d="M102.79 886.97C110.01 886 117.22 885.28 121.63 887.96C128.99 892.42 131.44 902.56 134.07 909.25"
                                            id="d38q1jr7XL" style="cursor: pointer;"></path>
                                        <path
                                            d="M103.9 872.82C98.69 880.72 86.77 883.09 77.75 881.51C67.62 879.73 58.68 871.22 53 862.23C47.81 854.02 49.96 814.77 59.22 813.21C78.83 809.91 95.43 798.13 106.04 814.18C115.37 828.29 112.36 860.02 103.9 872.82Z"
                                            id="iso-45" style="cursor: pointer;"></path>
                                        <path
                                            d="M103.9 872.82C98.69 880.72 86.77 883.09 77.75 881.51C67.62 879.73 58.68 871.22 53 862.23C47.81 854.02 49.96 814.77 59.22 813.21C78.83 809.91 95.43 798.13 106.04 814.18C115.37 828.29 112.36 860.02 103.9 872.82Z"
                                            id="iso-45" style="cursor: pointer;"></path>
                                        <path
                                            d="M60.12 836.49C61.12 838.79 60.2 841.14 60.5 843.3C61.36 849.31 62.04 854.52 65.57 858.06C66.95 859.44 68.53 860.64 70.25 861.71C74.88 864.58 80.47 866.47 85.58 868.29"
                                            id="c9QkCsAMn" style="cursor: pointer;"></path>
                                        <path
                                            d="M60.12 836.49C61.12 838.79 60.2 841.14 60.5 843.3C61.36 849.31 62.04 854.52 65.57 858.06C66.95 859.44 68.53 860.64 70.25 861.71C74.88 864.58 80.47 866.47 85.58 868.29"
                                            id="c9QkCsAMn" style="cursor: pointer;"></path>
                                        <path d="M76.9 819.2C96.14 816.63 99.11 829.28 103.33 839.99" id="amh0k6iSv"
                                            style="cursor: pointer;"></path>
                                        <path d="M76.9 819.2C96.14 816.63 99.11 829.28 103.33 839.99" id="amh0k6iSv"
                                            style="cursor: pointer;"></path>
                                        <path
                                            d="M56.08 809.7C56.08 809.7 44.16 809.8 37.76 792.99C35.75 787.73 42.76 778.63 39.96 772.64C34.39 760.71 36.44 742.58 45.99 737.15C52.69 733.35 64.54 733.83 70.22 733.42C85.56 732.31 95.49 739.06 103.54 748.09C113.62 759.4 111.88 779.75 104.13 793.52C94.12 802.67 69.8 806.16 56.08 809.7Z"
                                            id="iso-46" style="cursor: pointer;"></path>
                                        <path
                                            d="M56.08 809.7C56.08 809.7 44.16 809.8 37.76 792.99C35.75 787.73 42.76 778.63 39.96 772.64C34.39 760.71 36.44 742.58 45.99 737.15C52.69 733.35 64.54 733.83 70.22 733.42C85.56 732.31 95.49 739.06 103.54 748.09C113.62 759.4 111.88 779.75 104.13 793.52C94.12 802.67 69.8 806.16 56.08 809.7Z"
                                            id="iso-46" style="cursor: pointer;"></path>
                                        <path
                                            d="M77.51 776.04C80.31 773.15 87.24 776.45 92.05 777.21C90.59 776.32 78.85 769.22 77.38 768.33C71.54 760.35 72.99 753.07 71.64 745.15C68.38 746.35 64.98 742.81 61.56 738.63C62.75 743.24 68.04 749.86 63.96 751.9C63.24 751.65 59.67 750.39 53.23 748.13C60.13 753.36 63.97 756.26 64.74 756.84C64.74 756.84 68.2 763.63 65.76 762.75C63.31 761.87 52.71 765.81 52.71 765.81C52.71 765.81 63.79 763.81 68.68 769.17C73.58 774.54 74 778.03 74.95 781.89C75.91 785.76 75.75 789.71 78 792.41C79.99 794.78 85.99 795.89 85.99 795.89C81.91 789.18 78.56 782.53 77.51 776.04Z"
                                            id="b3NaX9ixLx" style="cursor: pointer;"></path>
                                        <path
                                            d="M77.51 776.04C80.31 773.15 87.24 776.45 92.05 777.21C90.59 776.32 78.85 769.22 77.38 768.33C71.54 760.35 72.99 753.07 71.64 745.15C68.38 746.35 64.98 742.81 61.56 738.63C62.75 743.24 68.04 749.86 63.96 751.9C63.24 751.65 59.67 750.39 53.23 748.13C60.13 753.36 63.97 756.26 64.74 756.84C64.74 756.84 68.2 763.63 65.76 762.75C63.31 761.87 52.71 765.81 52.71 765.81C52.71 765.81 63.79 763.81 68.68 769.17C73.58 774.54 74 778.03 74.95 781.89C75.91 785.76 75.75 789.71 78 792.41C79.99 794.78 85.99 795.89 85.99 795.89C81.91 789.18 78.56 782.53 77.51 776.04Z"
                                            id="b3NaX9ixLx" style="cursor: pointer;"></path>
                                        <path
                                            d="M99.19 658.77C91.26 639.36 71.15 628.89 49.9 634.95C31.5 640.2 20.23 651.7 17.35 678.51C14.66 703.65 13.31 733.44 55.66 730.8C95.88 728.29 116.38 700.79 99.19 658.77Z"
                                            id="iso-47" style="cursor: pointer;"></path>
                                        <path
                                            d="M99.19 658.77C91.26 639.36 71.15 628.89 49.9 634.95C31.5 640.2 20.23 651.7 17.35 678.51C14.66 703.65 13.31 733.44 55.66 730.8C95.88 728.29 116.38 700.79 99.19 658.77Z"
                                            id="iso-47" style="cursor: pointer;"></path>
                                        <path
                                            d="M83.15 712.12C77.01 710.36 66.69 712.41 64.93 705.44C64.69 704.51 62.81 697.08 62.57 696.15C66.19 693.01 73.2 695.75 78.72 695.91C78.67 695.49 60.07 689.96 59.92 689.9C58.05 689.15 52.78 675.93 56.61 669.35C64.15 663.69 73.11 667.5 81.22 666.85C73.37 665.64 62.18 664.85 61.35 662.76C59.69 655.77 58.2 650.23 56.71 644.78C57.08 651.07 58.71 657.66 54.91 662.98C44.76 666.32 44.29 664.49 38.9 664.4C44.2 665.92 47.58 670.3 49.95 676.18C52.3 684.13 50.59 684.63 49.88 686.97C44.34 689.51 41.12 686.3 36.86 686.22C42.5 688.44 49.14 691.27 54.01 694.84C59.68 698.98 53.24 705.07 48.68 710.48C55.09 711.56 61.75 710.96 66.74 721.38C67.48 713.23 75.91 714.07 83.15 712.12Z"
                                            id="c18sfWQXw1" style="cursor: pointer;"></path>
                                        <path
                                            d="M83.15 712.12C77.01 710.36 66.69 712.41 64.93 705.44C64.69 704.51 62.81 697.08 62.57 696.15C66.19 693.01 73.2 695.75 78.72 695.91C78.67 695.49 60.07 689.96 59.92 689.9C58.05 689.15 52.78 675.93 56.61 669.35C64.15 663.69 73.11 667.5 81.22 666.85C73.37 665.64 62.18 664.85 61.35 662.76C59.69 655.77 58.2 650.23 56.71 644.78C57.08 651.07 58.71 657.66 54.91 662.98C44.76 666.32 44.29 664.49 38.9 664.4C44.2 665.92 47.58 670.3 49.95 676.18C52.3 684.13 50.59 684.63 49.88 686.97C44.34 689.51 41.12 686.3 36.86 686.22C42.5 688.44 49.14 691.27 54.01 694.84C59.68 698.98 53.24 705.07 48.68 710.48C55.09 711.56 61.75 710.96 66.74 721.38C67.48 713.23 75.91 714.07 83.15 712.12Z"
                                            id="c18sfWQXw1" style="cursor: pointer;"></path>
                                        <path
                                            d="M16.27 549.27C16.27 549.27 5.73 553.9 5.35 574.88C5.23 581.45 14.25 588.63 13.65 596.32C12.48 611.61 23.52 629.37 33.62 631.51C59.86 637.07 71.46 615.86 77.3 597.91C82.64 581.49 74.74 559.88 63.62 547.87C51.94 541.83 29.45 547.69 16.27 549.27Z"
                                            id="iso-48" style="cursor: pointer;"></path>
                                        <path
                                            d="M16.27 549.27C16.27 549.27 5.73 553.9 5.35 574.88C5.23 581.45 14.25 588.63 13.65 596.32C12.48 611.61 23.52 629.37 33.62 631.51C59.86 637.07 71.46 615.86 77.3 597.91C82.64 581.49 74.74 559.88 63.62 547.87C51.94 541.83 29.45 547.69 16.27 549.27Z"
                                            id="iso-48" style="cursor: pointer;"></path>
                                        <path
                                            d="M54.84 581.31C58.33 583.35 64.91 579.54 69.79 578.18C68.44 579.16 57.56 586.92 56.21 587.89C51.66 595.54 54.59 601.53 54.72 608.47C51.09 607.93 48.23 611.44 45.47 615.5C45.82 611.4 50.05 604.99 45.39 603.85C44.69 604.17 41.2 605.76 34.92 608.62C41.11 603.16 44.55 600.13 45.24 599.52C45.24 599.52 47.53 593.24 45.15 594.34C42.77 595.44 30.93 593.64 30.93 593.64C30.93 593.64 42.89 593.73 46.96 588.44C51.02 583.16 50.78 580.12 51.03 576.69C51.27 573.26 50.33 569.91 52.16 567.29C53.78 564.98 59.83 563.16 59.83 563.16C56.87 569.47 54.67 575.62 54.84 581.31Z"
                                            id="b7ARVWKg9" style="cursor: pointer;"></path>
                                        <path
                                            d="M54.84 581.31C58.33 583.35 64.91 579.54 69.79 578.18C68.44 579.16 57.56 586.92 56.21 587.89C51.66 595.54 54.59 601.53 54.72 608.47C51.09 607.93 48.23 611.44 45.47 615.5C45.82 611.4 50.05 604.99 45.39 603.85C44.69 604.17 41.2 605.76 34.92 608.62C41.11 603.16 44.55 600.13 45.24 599.52C45.24 599.52 47.53 593.24 45.15 594.34C42.77 595.44 30.93 593.64 30.93 593.64C30.93 593.64 42.89 593.73 46.96 588.44C51.02 583.16 50.78 580.12 51.03 576.69C51.27 573.26 50.33 569.91 52.16 567.29C53.78 564.98 59.83 563.16 59.83 563.16C56.87 569.47 54.67 575.62 54.84 581.31Z"
                                            id="b7ARVWKg9" style="cursor: pointer;"></path><text id="b85Z9biXKR"
                                            x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -5.500000000000028 869.8745265151553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">41
                                            </tspan>
                                        </text><text id="c1DY6ILtkz" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -39.76562500000003 855.2745265151555)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">42
                                            </tspan>
                                        </text><text id="b7Um5lGNA6" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -67.65482954545479 820.8849431818218)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">43
                                            </tspan>
                                        </text><text id="bFYSV3ojY" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -101.11576704545519 770.7026515151548)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">44
                                            </tspan>
                                        </text><text id="a58joFtV32" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -125.48784120066395 713.8795190562305)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">45
                                            </tspan>
                                        </text><text id="d1FL8DxoUa" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -127.01010687857391 647.545712238048)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">46
                                            </tspan>
                                        </text><text id="c1WvdIbIzw" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -132.90482954545524 552.0747842077441)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">47
                                            </tspan>
                                        </text><text id="g1ie3fhzhA" x="241.89" y="100.29" font-size="20"
                                            transform="matrix(1 0 0 1 -159.776903700664 465.24931072289553)">
                                            <tspan x="241.89" dy="0em" dominant-baseline="text-before-edge">48
                                            </tspan>
                                        </text>
                                    </g>
                                    <g class="main">

                                        <g class="q3">
                                            <g class="1">
                                                <g>
                                                    <use xlink:href="#iso-31" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-31" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#cjuFfUWr" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#cjuFfUWr" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="2">
                                                <g>
                                                    <use xlink:href="#iso-32" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-32" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#ahVnr1hTO" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#ahVnr1hTO" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="3">
                                                <g>
                                                    <use xlink:href="#iso-33" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-33" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#d2sFISoAcr" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#d2sFISoAcr" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="4">
                                                <g>
                                                    <use xlink:href="#iso-34" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-34" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#f1nJy2rCGI" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#f1nJy2rCGI" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#aaB5NMNJEr" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#aaB5NMNJEr" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="5">
                                                <g>
                                                    <use xlink:href="#iso-35" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-35" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#bHt0uYEvE" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#bHt0uYEvE" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#awAtPqnox" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#awAtPqnox" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="6">
                                                <g>
                                                    <use xlink:href="#iso-36" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-36" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#m211qMeGpL" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#m211qMeGpL" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="7">
                                                <g>
                                                    <use xlink:href="#iso-37" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-37" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b5JyusjzlT" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#b5JyusjzlT" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="8">
                                                <g>
                                                    <use xlink:href="#iso-38" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-38" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#a9wXxhEQy" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#a9wXxhEQy" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="text">
                                                <g id="a8me8h3BSZ">
                                                    <use xlink:href="#b1BVUVOWBq" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="aXE5ZGRZF">
                                                    <use xlink:href="#a3t972Cvsg" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="c3IdvmfULY">
                                                    <use xlink:href="#i4mHUxK2re" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="d4igiagCc0">
                                                    <use xlink:href="#d3EsTPegQ" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="b4CCaq9LOV">
                                                    <use xlink:href="#b2ApfidLEf" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="ako4Nkrak">
                                                    <use xlink:href="#b2aRoIXnu9" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="eBV6uRJmu">
                                                    <use xlink:href="#a1E40eZIMw" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="aQUtEqY2g">
                                                    <use xlink:href="#b18Xayl0Ve" opacity="1" fill="#000"></use>
                                                </g>
                                            </g>
                                        </g>
                                        <g class="q4">
                                            <g class="1">
                                                <g>
                                                    <use xlink:href="#iso-41" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-41" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#a2Ot1hj5Ti" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#a2Ot1hj5Ti" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="2">
                                                <g>
                                                    <use xlink:href="#iso-42" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-42" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#c3OkjuGmoe" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#c3OkjuGmoe" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="3">
                                                <g>
                                                    <use xlink:href="#iso-43" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-43" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#c1DvhakQel" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#c1DvhakQel" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="4">
                                                <g>
                                                    <use xlink:href="#iso-44" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-44" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#c2SUh81nX8" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#c2SUh81nX8" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#d38q1jr7XL" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#d38q1jr7XL" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="5">
                                                <g>
                                                    <use xlink:href="#iso-45" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-45" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#c9QkCsAMn" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#c9QkCsAMn" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#amh0k6iSv" opacity="1" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#amh0k6iSv" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="6">
                                                <g>
                                                    <use xlink:href="#iso-46" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-46" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b3NaX9ixLx" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#b3NaX9ixLx" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="7">
                                                <g>
                                                    <use xlink:href="#iso-47" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-47" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#c18sfWQXw1" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#c18sfWQXw1" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="8">
                                                <g>
                                                    <use xlink:href="#iso-48" fill="#000000" fill-opacity="0">
                                                    </use>
                                                    <g>
                                                        <use xlink:href="#iso-48" fill-opacity="0" stroke="#000000"
                                                            stroke-width="2px" (click)="OnClick($event)"></use>
                                                    </g>
                                                </g>
                                                <g>
                                                    <use xlink:href="#b7ARVWKg9" opacity="1" fill="#000"></use>
                                                    <g>
                                                        <use xlink:href="#b7ARVWKg9" fill-opacity="0" stroke="#000000"
                                                            stroke-width="0px"></use>
                                                    </g>
                                                </g>
                                            </g>
                                            <g class="text">
                                                <g id="esxiVjmkn">
                                                    <use xlink:href="#b85Z9biXKR" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="a5VexlPOx">
                                                    <use xlink:href="#c1DY6ILtkz" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="aaP8d9N3o">
                                                    <use xlink:href="#b7Um5lGNA6" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="adSaYX4L8">
                                                    <use xlink:href="#bFYSV3ojY" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="f5VTtmANks">
                                                    <use xlink:href="#a58joFtV32" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="akb2LDStY">
                                                    <use xlink:href="#d1FL8DxoUa" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="iGZzsgY86">
                                                    <use xlink:href="#c1WvdIbIzw" opacity="1" fill="#000"></use>
                                                </g>
                                                <g id="b43283rfI4">
                                                    <use xlink:href="#g1ie3fhzhA" opacity="1" fill="#000"></use>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <label for="validationCustom03">Dientes Seleccionados</label>
                                <textarea row="4" class="form-control" id="validationCustom03" readonly="false"
                                    >{{this.dientesSelecionados}}</textarea>
                            </div>

                    </div>

                </div>
            </div>


        </div>

        <div class="page-break">
            <div class="row">
                <div class="col-sm-12 col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>VIGENCIA, GARANTIAS Y FIRMA </h5>
                        </div>
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="row mb-2" style="padding: 20px; align-items: center;">
                                    <div class="col">
                                        <p class="mb-2"> <b>Vigencia :</b> Esta prescripción medica vence en
                                            seis(6) meses,
                                            según resolución 214
                                            de 15 de febrero de 2022 del MINITERIO DE SALUD Y PROTECCION SOCIAL
                                        </p>

                                        <p class="mb-2"> <b> Claúsula de Protección de Datos Personales: </b> De
                                            conformidad
                                            con
                                            la ley 1 581 del 2012, de Protección de Datos personales y
                                            Habeas Data, autorizo libre y voluntariamente al Laboratorio Dental
                                            {{nombreEmpresa}} a que los datos suministrados por usted en esta
                                            entrevista o conversación, quedarán incorporados en la base de datos
                                            de nuestra
                                            compañía, y Los cuales serán utilizados exclusivamente
                                            para la finalidad de contactarlo para ofrecer nuevos productos o
                                            procedimientos
                                            realizados por el Laboratorio, informarle acerca de los
                                            trabajos solicitados al Laboratorio Dental {{nombreEmpresa}}, para
                                            almacenarlos
                                            y
                                            utilizarlos con el objetivo de mantenerlo enterado
                                            acerca de los productos que ofrece el Laboratorio Dental
                                            {{nombreEmpresa}} a
                                            través
                                            de su equipo de ventas y mercadeo, igualmente para
                                            fines contables, administrativos y todos aquellos indicados en la
                                            política de
                                            tratamiento de datos personales del Laboratorio.
                                            Los datos personales serán tratados con el grado de protección
                                            adecuado,
                                            tomándose
                                            las medidas de seguridad necesarias para evitar su alteración,
                                            pérdida, indebido tratamiento o acceso no autorizado por parte de
                                            terceros.
                                            Usted
                                            podrá ejercer los derechos de acceso, rectificación, actualización,
                                            inclusión o supresión escribiéndonos a nuestro correo de atención de
                                            peticiones
                                            quejas y reclamos {{email}}.
                                        </p>

                                        <!-- <p class="mb-2"> <b> Garantia: </b> 
                                </p> -->
                                    </div>
                                </div>

                            </div>

                            <div class="row justify-content-center">
                                <div style="margin: auto;" class="firma-digital">
                                    <div class="firma-digital--body">
                                        &nbsp;FIRMA DIGITAL:
                                        <canvas #firmaDigital width="450" height="160"
                                            style="touch-action: none;"></canvas>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="col-sm-12 col-xl-12">
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row justify-content-md-center">
                                                    <div class="col-md-2 col-sm-2">
                                                        <button class="btn btn-success" (click)="GuardarOrden()"
                                                            [disabled]="!validationForm.valid"  [hidden]="butonguardar" type="button">Guardar
                                                            Orden
                                                        </button>
                                                    </div>
                                                    <div class="col-md-2 col-sm-2">
                                                        <button class="btn btn-info" (click)="limpiarfirma()"
                                                            type="button">Limpiar Firma </button>

                                                        <!-- <button kendo-button (click)="exportToPDF()">
                                                            Export As PDF
                                                        </button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>


</div>