<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Actualizar tratamiento
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation" novalidate>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom01">Tipo de Placa</label>
                                <input type="text" class="form-control" id="validationCustom01" placeholder="Tipo Placa"
                                    [formControl]="validationForm.controls['typePlaca']" required>
                                <div *ngIf="validationForm.controls.typePlaca.touched && validationForm.controls.typePlaca.errors?.required"
                                    class="text text-danger">
                                    El tipo de placa es requerido
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustom02">Marca de Placa</label>
                                <input type="text" class="form-control" id="validationCustom02"
                                    placeholder="Marca de Placa" [formControl]="validationForm.controls['marcaPlaca']"
                                    required>
                                <div *ngIf="validationForm.controls.marcaPlaca.touched && validationForm.controls.marcaPlaca.errors?.required"
                                    class="text text-danger">
                                    La marca de placa es requida
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustomUsername">Cantidad-T</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="validationCustomUsername"
                                        placeholder="Cantidad-T" aria-describedby="inputGroupPrepend"
                                        [formControl]="validationForm.controls['numPlacas']" required>
                                </div>
                                <div *ngIf="validationForm.controls.numPlacas.touched && validationForm.controls.numPlacas.errors?.required"
                                    class="text text-danger">
                                    El numero de placas es obligatorios
                                </div>
                                <div *ngIf="validationForm.controls.numPlacas.touched && validationForm.controls.numPlacas.errors?.pattern"
                                    class="text text-danger">
                                    El valor introducido debe ser numerico y no superior a 3 digitos
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="validationCustomUsername">Arcada</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="validationCustomUsername"
                                        placeholder="Arcada" aria-describedby="inputGroupPrepend"
                                        [formControl]="validationForm.controls['descripcion']" required>
                                </div>
                                <div *ngIf="validationForm.controls.descripcion.touched && validationForm.controls.descripcion.errors?.required"
                                    class="text text-danger">
                                    la arcada es obligatorios
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Observaciones</label>
                                <textarea class="form-control" id="validationCustom03" rows="3"
                                    placeholder="Observaciones" [formControl]="validationForm.controls['observacion']"
                                    required></textarea>
                                <div *ngIf="validationForm.controls.observacion.touched && validationForm.controls.observacion.errors?.required"
                                    class="text text-danger">
                                    La Observacion es requerida
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Fecha Entrega</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                        <input class="form-control digits" type="datetime-local"
                                            id="example-datetime-local-input"
                                            [formControl]="validationForm.controls['fechaentrega']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.fechaentrega.touched && validationForm.controls.fechaentrega.errors?.required"
                                        class="text text-danger">
                                        La fecha de entrega es requerida
                                    </div>
                                </form>
                            </div>

                            <ng-template #footerTemplate>
                                <hr class="my-0">
                                <button class="btn btn-primary btn-sm m-2 float-left"
                                    (click)="model = today; f.close()">Today</button>
                                <button class="btn btn-secondary btn-sm m-2 float-right"
                                    (click)="f.close()">Close</button>
                            </ng-template>

                            <div class="col-md-3 mb-3">
                                <label for="validationCustom03">Asignado</label>
                                <form class="was-validated">
                                    <select class="custom-select" required
                                        [formControl]="validationForm.controls['Fk_UsuarioRealiza']">
                                        <option *ngFor="let usuarios of arrUsuarios" value={{usuarios.idTblTerceros}}>
                                            {{usuarios.ChrNombreCompleto}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Debes seleccionar el asginado</div>
                                </form>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body">
                    <form class="needs-validation" novalidate>
                        <div class="form-row">

                            <div class="col-md-3 mb-3">
                                <label for="validationCustom03">Producto</label>
                                <form class="was-validated">
                                    <select class="custom-select" (change)="onChange($event)" required
                                        [formControl]="formPrecios.controls['IdPrecio']">
                                        <option *ngFor="let precio of arrPrecios"
                                            value={{precio.idtblprecioalinieadores}}>
                                            {{precio.ChrNombre}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">El producto es obligatorio</div>
                                </form>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="validationCustom01">Descripcion</label>
                                <input type="text" class="form-control" id="validationCustom01"
                                    placeholder="Descripcion" [formControl]="formPrecios.controls['Nota']" required>
                                <div *ngIf="formPrecios.controls.Nota.touched && formPrecios.controls.Nota.errors?.required"
                                    class="text text-danger">
                                    La descripcion es obligatorio
                                </div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="validationCustom02">Cantidad</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Cantidad"
                                    [formControl]="formPrecios.controls['Cantidad']" required>
                                <div *ngIf="formPrecios.controls.Cantidad.touched && formPrecios.controls.Cantidad.errors?.required"
                                    class="text text-danger">
                                    La cantidad es obligatoria
                                </div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label style="display: none;" for="validationCustomUsername">Precio Unitario</label>
                                <div class="input-group">
                                    <input style="display: none;" type="text" class="form-control" id="validationCustomUsername"
                                        placeholder="Precio Unitario" aria-describedby="inputGroupPrepend"
                                        [formControl]="formPrecios.controls['Preciouni']" >
                                </div>
                                <div *ngIf="formPrecios.controls.Preciouni.touched && formPrecios.controls.Preciouni.errors?.required"
                                    class="text text-danger">
                                    El precio Unitario es obligatorio
                                </div>
                            </div>
                            
                        </div>

                    </form>
                </div>

                <button *ngIf="!boton" class="btn btn-primary btn-lg btn-block" [disabled]="!validationForm.valid" type="submit"
                (click)="guardarTratamiento(validationForm.value)">Actualizar</button>
            <button *ngIf="boton" class="btn btn-primary btn-lg btn-block" disabled
                type="submit">Actualizando...!!!</button>

            </div>
        </div>
    </div>
</div>