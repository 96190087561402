<h2 class="text-center">FIRMA DIGITAL</h2>

<div class="col-sm-12">
    <div class="card">
        <div class="card-body">

            <div class="row justify-content-md-center">

                <p class="mb-1"><b>Trabajo:</b> {{ruta.ChrNombreTrabajo}}</p>
                &nbsp;
                &nbsp;
                &nbsp;
                <p class="mb-1"><b>Doctor:</b> {{ruta.Doctor}}</p>

            </div>

            <div class="row justify-content-md-center">
                <p class="mb-1"><b>Clinica:</b> {{ruta.Clinica}}</p>
                &nbsp;
                &nbsp;
                &nbsp;
                <p class="mb-1"><b>Sucursal:</b> {{ruta.Sucursal}}</p>
            </div>

            <div class="row justify-content-md-center">
                <p class="mb-1"><b>Paciente:</b> {{ruta.Paciente}}</p>
                &nbsp;
                &nbsp;
                &nbsp;
                <p class="mb-1"><b>Cantidad:</b> {{ruta.ChrCantidadPedido}}</p>
            </div>

        </div>
    </div>
</div>

<div style="margin: auto;" class="firma-digital">
    <div class="firma-digital--body">
        &nbsp;FIRMA DIGITAL:
        <canvas #firmaDigital width="450" height="160" style="touch-action: none;"></canvas>
    </div>
</div>
<br>
<br>
<div class="col-sm-12 col-xl-12">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="col-md-3 col-sm-3">
                        <button class="btn btn-primary" (click)="guardarfirma()" type="submit">Guardar</button>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <button class="btn btn-success" (click)="limpiarfirma()" type="button">Limpiar </button>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <button class="btn btn-warning" (click)="cancelarfirma()" type="button">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>

</div>