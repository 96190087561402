<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div >
            <div class="card">
                <div class="card-header">
                    <h4>Datos de ingreso a tratamiento
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h4>
                </div>
                <div class="card-body">
                    <form class="needs-validation" novalidate>
                        <div class="form-row">
                            <div class="col-md-7 mb-3">
                                <label for="validationCustom03">Buscar Pacientes</label>
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="arrPedidos" [searchKeyword]="keyword"
                                        placeholder="Buscar Pacientes" (selected)='selectEvent($event)'
                                        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.Pacientes"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-5 mb-3">
                                <label for="validationCustom02">Informacion del Tratamiento</label>
                                <textarea type="text" class="form-control" rows="2" [(ngModel)]="marca"
                                    [ngModelOptions]="{standalone: true}" readonly="true"></textarea>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Observaciones</label>
                                <textarea class="form-control" id="validationCustom03" rows="2"
                                    placeholder="Observaciones" [formControl]="validationForm.controls['VarDescrio']"
                                    required></textarea>
                                <div *ngIf="validationForm.controls.VarDescrio.touched && validationForm.controls.VarDescrio.errors?.required"
                                    class="text text-danger">
                                    La Observacion es requerida
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Fecha Entrega</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                        <input class="form-control digits" type="datetime-local"
                                            id="example-datetime-local-input"
                                            [formControl]="validationForm.controls['HoraEntrada']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.HoraEntrada.touched && validationForm.controls.HoraEntrada.errors?.required"
                                        class="text text-danger">
                                        La fecha de entrega es requerida
                                    </div>
                                </form>
                            </div>

                            <ng-template #footerTemplate>
                                <hr class="my-0">
                                <button class="btn btn-primary btn-sm m-2 float-left"
                                    (click)="model = today; f.close()">Today</button>
                                <button class="btn btn-secondary btn-sm m-2 float-right"
                                    (click)="f.close()">Close</button>
                            </ng-template>

                            <div class="col-md-3 mb-3">
                                <label for="validationCustom03">Asignado</label>
                                <form class="was-validated">
                                    <select class="custom-select" required
                                        [formControl]="validationForm.controls['Fk_UsuarioRealiza']">
                                        <option *ngFor="let usuarios of arrUsuarios" value={{usuarios.idTblTerceros}}>
                                            {{usuarios.ChrNombreCompleto}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Debes seleccionar el asginado</div>
                                </form>
                            </div>

                         <!--    <div class="col-md-3 mb-5">
                                <label for="validationCustom03">Planeacion</label>
                                <div class="custom-control custom-checkbox mb-5">
                                    <input type="checkbox" class="custom-control-input" id="customControlValidation1"  (change)="planeacion($event)" required>
                                    <label class="custom-control-label" for="customControlValidation1">¿Planeacion?</label>
                                </div>    
                            </div> -->
                                                    
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Producto</th>
                                            <th scope="col">Descripcion</th>
                                            <th scope="col">Cantidad - Entrega</th>
                                            <th scope="col">Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <select class="custom-select" (change)="onChange($event)"
                                                [formControl]="formPrecios.controls['IdPrecio']" required>
                                                <option *ngFor="let precio of arrPrecios"
                                                    value={{precio.idtblprecioalinieadores}}>
                                                    {{precio.ChrNombre}}
                                                </option>
                                            </select>
                                            <div *ngIf="formPrecios.controls.IdPrecio.touched && formPrecios.controls.IdPrecio.errors?.required"
                                                class="text text-danger">
                                                Debes seleccionar el tipo producto
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" id="validationCustom03"
                                                placeholder="Descripcion" [formControl]="formPrecios.controls['Nota']"
                                                required />
                                            <div *ngIf="formPrecios.controls.Nota.touched && formPrecios.controls.Nota.errors?.required"
                                                class="text text-danger">
                                                La descripcion es Requerida
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" id="validationCustom03"
                                                placeholder="Cantidad - Entrega" [formControl]="formPrecios.controls['Cantidad']"
                                                required />
                                            <div *ngIf="formPrecios.controls.Cantidad.touched && formPrecios.controls.Cantidad.errors?.required"
                                                class="text text-danger">
                                                La Cantidad de Entrega es Requerida
                                            </div>
                                        </td>

                                        <td>
                                            <input type="text" class="form-control" id="validationCustom03"
                                                placeholder="Precio Unitario"
                                                [formControl]="formPrecios.controls['Preciouni']" required
                                                [(ngModel)]="precio" />
                                            <div *ngIf="formPrecios.controls.Preciouni.touched && formPrecios.controls.Preciouni.errors?.required"
                                                class="text text-danger">
                                                El precio unitario es requerido
                                            </div>
                                        </td>
                                        <td>
                                            <button (click)="aggPrecio(formPrecios)" class="btn btn-primary tn-sm"><i
                                                    class="fa fa-plus-circle"></i></button>
                                        </td>

                                    </tbody>
                                    <tbody>
                                        <tr *ngFor="let precios of arrIngreso">
                                            <td>
                                                {{precios.Nombre}}
                                            </td>
                                            <td>
                                                {{precios.Nota}}
                                            </td>
                                            <td>
                                                {{precios.Cantidad}}
                                            </td>
                                            <td>
                                                {{precios.Preciouni | currency: 'COP'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <button *ngIf="!boton" class="btn btn-primary" [disabled]="(!validationForm.valid && !formPrecios.valid)" type="submit"
                            (click)="guardarHistorial(validationForm.value)">Guardar</button>
                        <button *ngIf="boton" class="btn btn-primary btn-lg btn-block" disabled
                            type="submit">Guardando...!!!</button>

                    </form>
                </div>


                <div class="spinnerBackdrop"   [ngStyle]="{'display': Cargando }">
                    <div class="spinnerContent">
                        <div class="spinnerItem">
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                        </div>
                        <div class="MensajeDeEspera">Cargando...!!!</div>
                    </div>
                </div>
                

            </div>
        </div>
    </div>
</div>