<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Invitacion de odontologos suscritos a DT.Dental</h5>
                </div>
                <div class="card-body custom-datatable">
                    <ngx-datatable class="bootstrap" [rows]="arrNew" [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="'auto'" [limit]="15" [scrollbarH]="true" >
                        <ngx-datatable-column name="Identificacion" prop="nit"></ngx-datatable-column>
                        <ngx-datatable-column name="Consultorio" prop="company"></ngx-datatable-column>
                        <ngx-datatable-column name="Telefono" prop="mobile"></ngx-datatable-column>
                        <ngx-datatable-column name="Direccion" prop="address"></ngx-datatable-column>
                        <ngx-datatable-column name="Pais" prop="country"></ngx-datatable-column>
                        <ngx-datatable-column name="Departamento" prop="deparment"></ngx-datatable-column>
                        <ngx-datatable-column name="Ciudad" prop="city"></ngx-datatable-column>
                        <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
                        <ngx-datatable-column name="Estado" prop="status">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div [ngClass]="{naranja: (row.state === 0), verde: (row.state === 1), rojo:(row.state === 2)}">{{row.status}}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name ="Acciones">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <button class="btn btn-success btn-xs" type="button" placement="top" ngbTooltip="Acetptar Peticion" (click)="onClicksucces(row.petition_id)"> Aceptar</button>
                                <button class="btn btn-danger btn-xs"  type="button" placement="top" ngbTooltip="Rechazar Peticion" (click)="onClickdanger(row.petition_id)"> Rechazado </button>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>
    
</div>
<!-- Container-fluid Ends -->