<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Fechas de cambio de factura
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation" novalidate>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom1">Fecha Expedicion</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                        <input class="form-control digits" type="datetime-local"
                                        id="example-datetime-local-input" [formControl]="validationForm.controls['fechaexp']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.fechaexp.touched && validationForm.controls.fechaexp.errors?.required"
                                        class="text text-danger">
                                        La fecha de Expedicion es requerida
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom2">Fecha Vencimiento</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                            <input class="form-control digits" type="datetime-local"
                                                id="example-datetime-local-input" [formControl]="validationForm.controls['fechavence']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.fechavence.touched && validationForm.controls.fechavence.errors?.required"
                                        class="text text-danger">
                                        La fecha de Vencimiento es requerida
                                    </div>
                                </form>
                            </div>
                        </div>
                        <button *ngIf="!boton" class="btn btn-primary btn-lg btn-block" [disabled]="!validationForm.valid" type="submit" (click)="actualizarFechas(validationForm.value)">Guardar</button>
                        <button *ngIf="boton" class="btn btn-primary btn-lg btn-block" disabled type="submit">Guardando...!!!</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>