export class ListarMensajeriaAllxUserRoot {
    ok: boolean;
    message: string;
    ListarMensajeriaAllxUser: ListarMensajeriaAllxUser[];
}

export class ListarMensajeriaAllxUser {
    Mensajero: string;
    DtmFecha: Date;
    ChrObservaciones: string;
    ChrNota: string | null;
    BlnCumplida: number;
    ChrNombreTrabajo: string | null;
    ChrNumeCaja: null | string;
    Doctor: null | string;
    Clinica: null | string;
    Sucursal: null | string;
    DtmIngreso: Date;
    DtmFechaRetiro: Date;
    ChrDescripcion: null | string;
    ChrCantidadPedido: number | null;
    idtblmensajeria: number;
    idtblDtllPedidos: number;
    IdHistorial: number;
    Paciente: string;
}

export class Delivery {
    idEmpresa: string;
    NombreEmpresa: string;
    lng: number;
    lat: number;
    //coords: Coords[];
    nombreUsuario: string;
}

export class Coords {
    lng: number;
    lat: number;
}

export interface ListarMensajeriaAllRoot {
    ok: boolean;
    message: string;
    ListarMensajeriaAll: ListarMensajeriaAll[];
}


export interface ListarMensajeriaAll {
    Mensajero: string;
    DtmFecha: Date;
    ChrObservaciones: string;
    ChrNota: string;
    BlnCumplida: number;
    ChrNombreTrabajo: string;
    ChrNumeCaja: string;
    Doctor: string;
    Clinica: string;
    Sucursal: string;
    DtmIngreso: any;
    DtmFechaRetiro: any;
    ChrDescripcion: string;
    ChrCantidadPedido?: number;
    idtblmensajeria: number;
    Paciente: string;
}




