<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">

      <div class="card">
        <div class="card-header">
          <h5>Facturas Generadas</h5>
        </div>
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body custom-datatable">

            <div class="rowfilter">
              <b><label >Busqueda: &nbsp; </label></b>
            
               <input type='text' class="filter-ngx form-control" placeholder='Buscar Cliente...!!!'
                (keyup)='updateFiltercliente($event)' />
              
                &nbsp;
                &nbsp;
              <input type='text' class="filter-ngx form-control" placeholder='Buscar Numero Factura...!!!'
                (keyup)='updateFilternumero($event)' />
            
          </div>

            <ngx-datatable class="bootstrap" [rows]="arrFacGeneradas" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="45" [limit]="10" [scrollbarH]="true">

              <ngx-datatable-column [width]="130" name="Tipo" prop="ChrNombreResol"></ngx-datatable-column>
              <ngx-datatable-column [width]="80" name="Prefijo" prop="chrprefijoFac"></ngx-datatable-column>
              <ngx-datatable-column [width]="80" name="Numero" prop="IntIdFactura"></ngx-datatable-column>
              <ngx-datatable-column [width]="180" name="Cliente" prop="ChrNombreCompleto"></ngx-datatable-column>

              <ngx-datatable-column [width]="80" name="Fecha" prop="DtmEpxFac">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.DtmEpxFac | date: 'mediumDate'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="130" name="Total" prop="IntTotalFactura">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.IntTotalFactura | currency: 'COP'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="50" name="Aceptada" prop="BlnAceptadaDIAN">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.BlnAceptadaDIAN && 'Si' || 'No' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" name="Respuesta" prop="ChrMotivoRespues"></ngx-datatable-column>
              <ngx-datatable-column [width]="200" name="CUFE" prop="chrCufeDIAN"></ngx-datatable-column>

              <ngx-datatable-column [width]="90" name="Ver Dian" prop="acu_recibo">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div *ngIf="row.BlnAceptadaDIAN" class="btn-group" role="group"
                    aria-label="Basic mixed styles example" placement="top" ngbTooltip="Ver en DIAN">
                    <button (click)="ver_Dian(row)" class="btnverdian">
                      <i class="fa fa-file-text-o"></i></button>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="200" name="Acciones">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                  <div class="btn-group" role="group" aria-label="Basic mixed styles example" placement="top"
                    ngbTooltip="Cambio de Fecha">
                    <button *ngIf="!row.BlnAceptadaDIAN" (click)="showModal(row)"
                      class="btncambiarfecha"><i class="fa fa-calendar"></i></button>
                  </div>

                  <div class="btn-group" role="group" aria-label="Basic mixed styles example" placement="top"
                    ngbTooltip="Cambio de Terceros">
                    <button *ngIf="!row.BlnAceptadaDIAN" (click)="cambioTerceros(row)" class="btncambiartercero"><i
                        class="fa fa-retweet"></i></button>
                  </div>

                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </div>

      <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
          <div class="spinnerItem">
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
          </div>
          <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
      </div>



    </div>
  </div>
</div>
<!-- Container-fluid Ends -->