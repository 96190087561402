<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <!-- <div class="row"> -->
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <b class="titulo">Ingreso de Pedidos</b>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Odontologo:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrOdontologo" [searchKeyword]="keyword"
                                            placeholder="Seleciona Odontologo" (selected)='selectEvent($event)'
                                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="validationForm.controls['IdTercero']" required
                                            [isLoading]='isloading'>
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Clinica:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrClinicas" [searchKeyword]="keywordClinicas"
                                            placeholder="Selacciona Clinica" (selected)='selectEventClinica($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedClinica($event)' [itemTemplate]="itemClinica"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="validationForm.controls['IdDetalleTercero']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemClinica let-item>
                                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">Autorizacion:</label>

                                    <input type="text" class="form-control" placeholder="# Autorizacion"
                                        [formControl]="validationForm.controls['NotaPedido']">

                                </div>

                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">Pedido:</label>
                                    <div class="ng-autocomplete">
                                        <input type="text" class="form-control" id="validationCustom04"
                                            placeholder="# Pedido" [formControl]="validationForm.controls['DesPedido']">
                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-12 col-sm-3">
                                    <label class="col-form-label pt-0">Pedidos Dt-Dental:</label>
                                    <div class="ng-autocomplete">
                                        <input type="text" class="form-control" id="validationCustom05"
                                            placeholder="Pedidos Dt-Dental"
                                            [formControl]="dtllpedidofrom.controls['IdDtDental']" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>

            <div class="col-sm-12 col-xl-6">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <b class="titulo">Ingreso de Detalle del Pedidos</b>
                        </div>
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">*Trabajo:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrTrabajos" [searchKeyword]="keywordTrabajo"
                                            placeholder="Seleciona Trabajo" (selected)='selectEventTrabajo($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedtrabjo($event)' [itemTemplate]="itemTrabajo"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="dtllpedidofrom.controls['idTipoTrabajo']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemTrabajo let-item>
                                            <a [innerHTML]="item.ChrNombreTrabajo"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">*Caja:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrCajas" [searchKeyword]="keywordCajas"
                                            placeholder="Seleciona Caja" (selected)='selectEventCaja($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedCaja($event)' [itemTemplate]="itemCaja"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="dtllpedidofrom.controls['idCajas']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemCaja let-item>
                                            <a [innerHTML]="item.ChrNumeCaja"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">*Fecha Ingreso</label>
                                    <input class="form-control digits" type="datetime-local"
                                        id="example-datetime-local-input"
                                        [formControl]="dtllpedidofrom.controls['FechaIngreso']" required>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">Material</label>
                                    <input type="text" class="form-control" id="validationCustom03"
                                        placeholder="Material" [formControl]="dtllpedidofrom.controls['chrMaterial']"
                                        required>
                                </div>
                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">Solpe</label>
                                    <input type="text" class="form-control" id="validationCustom04" placeholder="Solpe"
                                        [formControl]="dtllpedidofrom.controls['chrSolped']" required>
                                </div>
                                <div class="col-md-4 col-sm-3">
                                    <label class="col-form-label pt-0">*Cantidad</label>
                                    <input type="text" class="form-control" id="validationCustom05"
                                        placeholder="Cantidad" [formControl]="dtllpedidofrom.controls['Cantidad']"
                                        required>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Paciente:</label>

                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrPaciente" [searchKeyword]="keywordPaciente"
                                            placeholder="Buscar Paciente" (selected)='selectEventPaciente($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedPaciente($event)' [itemTemplate]="itemPaciente"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="dtllpedidofrom.controls['IdPacientes']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemPaciente let-item>
                                            <a [innerHTML]="item.Nom_Paciente"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <button class="btn btn-primary" (click)="showModalIngPaciente()"
                                        *ngIf="!btnpaciente"><i class="fa fa-plus-circle"></i></button>
                                </div>


                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Observacion</label>
                                    <textarea class="form-control" id="validationCustom03" rows="1"
                                        placeholder="Observaciones del trabajo en general"
                                        [formControl]="dtllpedidofrom.controls['Descripcion']" required></textarea>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <b class="titulo">Ingreso de Procesos</b>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <label class="col-form-label pt-0">*Proceso:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrProcesos" [searchKeyword]="keywordProceso"
                                            placeholder="Buscar Proceso" (selected)='selectEventProceso($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedProcesos($event)' [itemTemplate]="itemProcesos"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="dtllHistorialfrom.controls['idProceso']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemProcesos let-item>
                                            <a [innerHTML]="item.ChrNombreProceso"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <label class="col-form-label pt-0">*Asignado:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete [data]="arrAsignado" [searchKeyword]="keywordAsginado"
                                            placeholder="Buscar Asginado" (selected)='selectEventAsginado($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedAsginado($event)' [itemTemplate]="itemAsginado"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="dtllHistorialfrom.controls['fk_UsuarioRealiza']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemAsginado let-item>
                                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <label class="col-form-label pt-0">*Fecha:</label>
                                    <input class="form-control digits" type="datetime-local"
                                        id="example-datetime-local-input"
                                        [formControl]="dtllHistorialfrom.controls['horaEntrada']" required>

                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <label class="col-form-label pt-0">*Observaciones del Doctor</label>
                                    <textarea class="form-control" id="validationCustom03" rows="3"
                                        placeholder="Todas las anotaciones que ha enviado el doctor para el proceso"
                                        [formControl]="dtllHistorialfrom.controls['varDescrio']" required></textarea>
                                </div>
                            </div>

                        </div>

                        <div class="card-footer">

                            <button *ngIf="!boton" class="btn btn-primary"
                                [disabled]="(!dtllpedidofrom.valid && !dtllHistorialfrom.valid)" type="submit"
                                (click)="GuardarPedido(validationForm.value)">Guardar</button>
                            <button *ngIf="boton" class="btn btn-primary" disabled
                                type="submit">Guardando...!!!</button>
                            &nbsp;
                            <button class="btn btn-warning" type="button" (click)="Cancelaringreso()">Cancelar</button>
                            &nbsp;
                            <!--   <button *ngIf="!boton" class="btn btn-secondary"
                            [disabled]="(!dtllpedidofrom.valid && !dtllHistorialfrom.valid)" type="submit"
                            (click)="GuardarPedido(validationForm.value)">Agregar</button> -->
                        </div>

                    </div>
                </div>

            </div>

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">

                        <div class="card-body">
                            <div class="row">


                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>

    </div>
</div>





<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Ingreso de Pedidos</h5>
                </div>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-row">
                        <div class="col-md-3 mb-3">
                            <label for="validationCustom01">Odontologo</label>
                            <div class="faq-form">
                                <ng-autocomplete [data]="arrOdontologo" [searchKeyword]="keyword"
                                    placeholder="Buscar Odontologo" (selected)='selectEvent($event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                    [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                    [formControl]="validationForm.controls['IdTercero']" required
                                    [isLoading]='isloading'>
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.ChrNombreCompleto"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                            <div *ngIf="validationForm.controls.IdTercero.errors?.required" class="text text-danger">
                                El Odontologo es requerido.
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label for="validationCustom02">Clinica</label>
                            <ng-autocomplete [data]="arrClinicas" [searchKeyword]="keywordClinicas"
                                placeholder="Selacciona Clinica" (selected)='selectEventClinica($event)'
                                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedClinica($event)'
                                [itemTemplate]="itemClinica" [notFoundTemplate]="notFoundTemplate"
                                [formControl]="validationForm.controls['IdDetalleTercero']" required>
                            </ng-autocomplete>

                            <ng-template #itemClinica let-item>
                                <a [innerHTML]="item.ChrNombreCompleto"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <div *ngIf="validationForm.controls.IdDetalleTercero.errors?.required"
                                class="text text-danger">
                                La Clinica es requerida.
                            </div>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationCustom03">Autorizacion</label>
                            <input type="text" class="form-control" id="validationCustom03" placeholder="# Autorizacion"
                                [formControl]="validationForm.controls['NotaPedido']">
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationCustom04">Pedido</label>
                            <input type="text" class="form-control" id="validationCustom04" placeholder="# Pedido"
                                [formControl]="validationForm.controls['DesPedido']">
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationCustom05">Pedidos Dt-Dental</label>
                            <input type="text" class="form-control" id="validationCustom05"
                                placeholder="Pedidos Dt-Dental" [formControl]="dtllpedidofrom.controls['IdDtDental']"
                                required>
                        </div>
                    </div>
                </form>
            </div>

            <div class="card-body">
                <div class="form-row">
                    <form>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="validationCustom01">Trabajo</label>
                                <ng-autocomplete [data]="arrTrabajos" [searchKeyword]="keywordTrabajo"
                                    placeholder="Buscar Trabajo" (selected)='selectEventTrabajo($event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedtrabjo($event)'
                                    [itemTemplate]="itemTrabajo" [notFoundTemplate]="notFoundTemplate"
                                    [formControl]="dtllpedidofrom.controls['idTipoTrabajo']" required>
                                </ng-autocomplete>

                                <ng-template #itemTrabajo let-item>
                                    <a [innerHTML]="item.ChrNombreTrabajo"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <div *ngIf="dtllpedidofrom.controls.idTipoTrabajo.errors?.required"
                                    class="text text-danger">
                                    El Trabajo es requerido.
                                </div>
                            </div>

                            <div class="col-md-1 mb-3">
                                <label for="validationCustom01">Caja</label>
                                <ng-autocomplete [data]="arrCajas" [searchKeyword]="keywordCajas"
                                    placeholder="Buscar Caja" (selected)='selectEventCaja($event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedCaja($event)'
                                    [itemTemplate]="itemCaja" [notFoundTemplate]="notFoundTemplate"
                                    [formControl]="dtllpedidofrom.controls['idCajas']" required>
                                </ng-autocomplete>

                                <ng-template #itemCaja let-item>
                                    <a [innerHTML]="item.ChrNumeCaja"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <div *ngIf="dtllpedidofrom.controls.idCajas.errors?.required" class="text text-danger">
                                    La caja es requerido.
                                </div>
                            </div>

                            <div class="col-md-3.5 mb-5">
                                <label for="validationCustom03">Fecha Ingreso</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                        <input class="form-control digits" type="datetime-local"
                                            id="example-datetime-local-input"
                                            [formControl]="dtllpedidofrom.controls['FechaIngreso']" required>
                                    </div>
                                </form>
                            </div>

                            <div class="col-md-2 mb-3">
                                <label for="validationCustom03">Material</label>
                                <input type="text" class="form-control" id="validationCustom03" placeholder="Material"
                                    [formControl]="dtllpedidofrom.controls['chrMaterial']" required>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label for="validationCustom04">Solpe</label>
                                <input type="text" class="form-control" id="validationCustom04" placeholder="Solpe"
                                    [formControl]="dtllpedidofrom.controls['chrSolped']" required>
                            </div>
                            <div class="col-md-1 mb-3">
                                <label for="validationCustom05">Cantidad</label>
                                <input type="text" class="form-control" id="validationCustom05" placeholder="Cantidad"
                                    [formControl]="dtllpedidofrom.controls['Cantidad']" required>
                                <div *ngIf="dtllpedidofrom.controls.Cantidad.errors?.required" class="text text-danger">
                                    Cantidad es requeida.
                                </div>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label for="validationCustom05">Paciente </label>
                                <ng-autocomplete [data]="arrPaciente" [searchKeyword]="keywordPaciente"
                                    placeholder="Buscar Paciente" (selected)='selectEventPaciente($event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedPaciente($event)'
                                    [itemTemplate]="itemPaciente" [notFoundTemplate]="notFoundTemplate"
                                    [formControl]="dtllpedidofrom.controls['IdPacientes']" required>
                                </ng-autocomplete>

                                <ng-template #itemPaciente let-item>
                                    <a [innerHTML]="item.Nom_Paciente"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>

                                <div *ngIf="dtllpedidofrom.controls.IdPacientes.touched && dtllpedidofrom.controls.IdPacientes.errors?.required"
                                    class="text text-danger">
                                    El paciente es requerido
                                </div>
                            </div>

                            <div>
                                <br>
                                <button class="btn btn-primary" (click)="showModalIngPaciente()" *ngIf="!btnpaciente"><i
                                        class="fa fa-plus-circle"></i></button>
                            </div>


                            <div class="col-md-4 mb-3">
                                <label for="validationCustom03">Observaciones</label>
                                <textarea class="form-control" id="validationCustom03" rows="2"
                                    placeholder="Observaciones del trabajo en general"
                                    [formControl]="dtllpedidofrom.controls['Descripcion']" required></textarea>

                                <div *ngIf="dtllpedidofrom.controls.Descripcion.touched && dtllpedidofrom.controls.Descripcion.errors?.required"
                                    class="text text-danger">
                                    La Observacion es requerida
                                </div>
                            </div>
                        </div>
                        <br>
                    </form>
                </div>
            </div>

            <div class="card-body">
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label for="validationCustom01">Proceso</label>
                        <ng-autocomplete [data]="arrProcesos" [searchKeyword]="keywordProceso"
                            placeholder="Buscar Proceso" (selected)='selectEventProceso($event)'
                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedProcesos($event)'
                            [itemTemplate]="itemProcesos" [notFoundTemplate]="notFoundTemplate"
                            [formControl]="dtllHistorialfrom.controls['idProceso']" required>
                        </ng-autocomplete>

                        <ng-template #itemProcesos let-item>
                            <a [innerHTML]="item.ChrNombreProceso"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                        <div *ngIf="dtllHistorialfrom.controls.idProceso.errors?.required" class="text text-danger">
                            El proceso es requeido
                        </div>

                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationCustom01">Asginado</label>

                        <ng-autocomplete [data]="arrAsignado" [searchKeyword]="keywordAsginado"
                            placeholder="Buscar Asginado" (selected)='selectEventAsginado($event)'
                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocusedAsginado($event)'
                            [itemTemplate]="itemAsginado" [notFoundTemplate]="notFoundTemplate"
                            [formControl]="dtllHistorialfrom.controls['fk_UsuarioRealiza']" required>
                        </ng-autocomplete>

                        <ng-template #itemAsginado let-item>
                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                        <div *ngIf="dtllHistorialfrom.controls.fk_UsuarioRealiza.errors?.required"
                            class="text text-danger">
                            El asignado es requeido
                        </div>

                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="validationCustom03">Fecha Entrega-Proceso</label>
                        <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                            <div class="form-group mb-0">
                                <input class="form-control digits" type="datetime-local"
                                    id="example-datetime-local-input"
                                    [formControl]="dtllHistorialfrom.controls['horaEntrada']" required>
                            </div>
                            <div *ngIf="dtllHistorialfrom.controls.horaEntrada.errors?.required"
                                class="text text-danger">
                                La fecha de entrega es requerida
                            </div>
                        </form>
                    </div>
                    <div class="col-md-10 mb-3">
                        <label for="validationCustom03">Observaciones del Trabajo a entregar</label>
                        <textarea class="form-control" id="validationCustom03" rows="4"
                            placeholder="Todas las anotaciones que ha enviado el doctor para el proceso"
                            [formControl]="dtllHistorialfrom.controls['varDescrio']" required></textarea>
                        <div *ngIf="dtllHistorialfrom.controls.varDescrio.errors?.required" class="text text-danger">
                            Las Observaciones son requeridas
                        </div>
                    </div>


                </div>

                <div>
                    <button *ngIf="!boton" class="btn btn-primary"
                        [disabled]="(!dtllpedidofrom.valid && !dtllHistorialfrom.valid)" type="submit"
                        (click)="GuardarPedido(validationForm.value)">Guardar</button>
                    <button *ngIf="boton" class="btn btn-primary" disabled type="submit">Guardando...!!!</button>
                </div>


            </div>

            <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                <div class="spinnerContent">
                    <div class="spinnerItem">
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                    </div>
                    <div class="MensajeDeEspera">Cargando...!!!</div>
                </div>
            </div>
        </div>
    </div>
</div> -->