import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ListarSeleEmpresa, ListarSeleEmpresaRoot } from '../models/listarSelecEmpresas'

@Injectable({
  providedIn: 'root'
})
export class MensajeriaService {
  basicHeaders: any;
  formdata: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');


    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })

    this.formdata = new HttpHeaders({
      'Authorization': `${this.token}`,
    })
  }

  getAllxUser(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarMensajeriaAllxUser', body, { headers: this.basicHeaders }).toPromise();
  }

  getAll(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarMensajeriaAll', body, { headers: this.basicHeaders }).toPromise();
  }

  Insertar_Mensajeria(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'InsertarMensajeria', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateMensajeria(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarMensajeria', body, { headers: this.basicHeaders }).toPromise();
  }

  Cargar_Firma_S3(body: FormData): Observable<any> {
    return this.http.post(Enviroment.url_backend + 'CargarImagenesS3', body, { headers: this.formdata })
  }

  Update_Diligencia(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'BorrarDiligencia', body, { headers: this.basicHeaders }).toPromise();
  }


  
  /*  upload(body): Promise<any> {
     return this.http.post<any>(Enviroment.url_backend + 'ListarMensajeriaAll', body, {
       headers: new HttpHeaders({
         'Authorization': `${this.token}`
       })
     }).toPromise();
   }
  */
}
