import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firma-remisiones',
  templateUrl: './firma-remisiones.component.html',
  styleUrls: ['./firma-remisiones.component.scss']
})
export class FirmaRemisionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
