import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-updateprocesos-alineadores',
  templateUrl: './updateprocesos-alineadores.component.html',
  styleUrls: ['./updateprocesos-alineadores.component.scss']
})
export class UpdateprocesosAlineadoresComponent implements OnInit {

  formPrecios: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  IdEmpresa: string;
  IdSucursal: string;
  boton: any;
  idTrabajo: any;
  idtblTercero: string;
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  precio: string;

  constructor(private fb: FormBuilder,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService) {

    this.idTrabajo = this.options.initialState;
    this.arrIngreso = [];
    this.precio = "";

    this.formPrecios = this.fb.group({
      IdPrecio: ['', Validators.required],
      Cantidad: ['', Validators.required],
      Preciouni: ['', Validators.required],
      idtblhistoricoprecio: [''],
      Nota: ['', Validators.required]
    })

  }

  async ngOnInit() {
    try {
      const respu: ListaPrecioAlineadoreRoot = await this.pedidosServices.getPrecioAlinieadores({
        id: this.idTrabajo.IdClasAlineadores
      })
      this.arrPrecios = respu.ListaPrecioAlineadores;

      this.formPrecios.get("IdPrecio").setValue(this.idTrabajo.FkPreciosHisto)
      this.formPrecios.get("Cantidad").setValue(this.idTrabajo.Cantidad)
      this.formPrecios.get("Preciouni").setValue(this.idTrabajo.PrecioUni)
      this.formPrecios.get("Nota").setValue(this.idTrabajo.Descripcion)     

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  onChange($event) {
    this.precio = String(this.arrPrecios[$event.target.value - 1].Valor);
    this.formPrecios.get("Preciouni").setValue(this.precio)
  }


  async guardarTratamiento(datos: any) {
    this.boton = true
    this.formPrecios.value.idtblhistoricoprecio = this.idTrabajo.idtblhistoricoprecio   
    await this.pedidosServices.UpdatePrecioHistorico(this.formPrecios.value).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        this.bsModalRef.hide();
      }
    }, error => {
      console.log(error);

      this.toastaService.warning(error.error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
      this.bsModalRef.hide();
    })

  }

}
