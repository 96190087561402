<div class="page-wrapper">
    <div class="auth-bg">
        <div class="authentication-box">
            <div class="text-center"><img width="196" height="82" src="assets/images/Imagen1.png" alt=""></div>
            <div class="card mt-4">
                <div class="card-body">
                    <div class="text-center">
                        <h4>LOGIN</h4>
                        <h6>Entra tu usuario y contraseña </h6>
                    </div>
                    <form class="theme-form" [formGroup]="selectCompanyForm">
                        <div class="form-group">
                            <strong>
                                 <label class="col-form-label pt-0">Seleccione Empresa</label>
                            </strong>
                            <select class="from-select" formControlName="empresa" (change)="onChange($event)">
                                <option value={{0}}>
                                    {{'-'}}
                                </option>
                                <option *ngFor="let empresa of arrEmpresas" value={{empresa.idTlbEmpresas}}>
                                    {{empresa.ChrNonEmp}}
                                </option>
                            </select>
                            <div *ngIf="selectCompanyForm.controls.empresa.touched && selectCompanyForm.controls.empresa.errors?.required"
                                class="text text-danger mt-1">
                                La empresa es requerida
                            </div>
                        </div>
                        <div class="form-group">
                            <div>
                            <strong>
                                <label class="col-form-label">Seleccionar Sucursal</label>
                            </strong>
                            </div>
                            <select class="from-select form-select-sm"  formControlName="sucursal" >
                                <option value={{0}}>
                                    {{'-'}}
                                </option>
                                <option *ngFor="let sucursal of arrNew" value={{sucursal.idTblSucursal}}>
                                    {{sucursal.ChrSucursal}}
                                </option>
                            </select>
                            <!-- <div *ngIf="selectCompanyForm.controls.sucursal.touched"
                                class="text text-danger mt-1">
                                La sucursal es requerida
                            </div> -->
                        </div>
                        <div class="form-group form-row mt-3 mb-0">
                            <button class="btn btn-primary btn-block btn-lg" [disabled]="!selectCompanyForm.valid" type="submit" (click)="onClick()">Entrar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>