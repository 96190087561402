import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgPlenacion, NgPlenacionRoot } from 'src/app/shared/models/planeacion';
import { AgendaService } from 'src/app/shared/services/agenda.service';
import { IngTratamientoComponent } from '../ing-tratamiento/ing-tratamiento.component';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment'
import { UpdateplaneacionComponent } from '../../Alineadores/updateplaneacion/updateplaneacion.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-planeacion',
  templateUrl: './planeacion.component.html',
  styleUrls: ['./planeacion.component.scss']
})
export class PlaneacionComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;
  arrAgenda: NgPlenacion[];
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  temp: NgPlenacion[];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  SelectionType = SelectionType;
  selected = [];
  pressboton: boolean;
  Cargando: string;

  constructor(
    private toastaService: ToastrService,
    private _agendaService: AgendaService,
    private modalService: BsModalService,
    public pedidosServices: PedidosService
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.pressboton = false;
    this.Cargando = 'none';
  }

  async ngOnInit(): Promise<void> {
    try {
      this.cargar_Planeacion(0)
    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }

  }

  async cargar_Planeacion(realizado: any): Promise<void> {
    try {
      this.Cargando = 'Block';

      const res: NgPlenacionRoot = await this._agendaService.getAllPlenacion(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          realizado: realizado
        })

      this.temp = [...res.Ng_Plenacion]
      this.arrAgenda = res.Ng_Plenacion;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }
  }

  showModal(dtllPedido: any) {
    this.bsModalRef = this.modalService.show(IngTratamientoComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.cargar_Planeacion(0)
    })
  }

  async UpdatePrecioCance(dtllPedido: any) {
    try {

      this.Cargando = 'Block';

      const respu: ListaPrecioAlineadoreRoot = await this.pedidosServices.getPrecioAlinieadores({
        id: dtllPedido.IdClasAlineadores
      })
      this.arrPrecios = respu.ListaPrecioAlineadores;

      await this.pedidosServices.UpdatePrecioPlaneAnulado({
        idEstados: 4,
        precioUnitario: this.arrPrecios[2].Valor,
        idtblhistoricoprecio: dtllPedido.idtblhistoricoprecio
      }).then((res) => {
        if (res) {

          this.Cargando = 'none';

          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success',
            timer: 5000
          });

          const index = this.arrAgenda.findIndex(callback => callback.idtblhistoricoprecio == dtllPedido.idtblhistoricoprecio)
          this.arrAgenda[index].Estado = "Cancelado"
          this.arrAgenda = [...this.arrAgenda];

        }
      }, error => {
        Swal.fire({
          title: "NO se realizo el proceso comunicate con el administrador",
          icon: 'error',
          timer: 3000
        });
      });

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }

  }

  async UpdatePrecioAnu(dtllPedido: any) {
    try {
      this.Cargando = 'Block';

      await this.pedidosServices.UpdatePrecioPlaneAnulado({
        idEstados: 11,
        precioUnitario: 0,
        idtblhistoricoprecio: dtllPedido.idtblhistoricoprecio
      }).then((res) => {
        if (res) {
          this.Cargando = 'none';

          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success',
            timer: 5000
          });

          const index = this.arrAgenda.findIndex(callback => callback.idtblhistoricoprecio == dtllPedido.idtblhistoricoprecio)
          this.arrAgenda[index].Estado = "Anulado"
          this.arrAgenda = [...this.arrAgenda];

        }
      }, error => {
        Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
      });

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }

  }

  async UpdatePrecioEntregado(dtllPedido: any) {
    try {
      await this.pedidosServices.UpdatePrecioPlaneAnulado({
        idEstados: 2,
        precioUnitario: 0,
        idtblhistoricoprecio: dtllPedido.idtblhistoricoprecio
      }).then((res) => {
        if (res) {
          this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });

          const index = this.arrAgenda.findIndex(callback => callback.idtblhistoricoprecio == dtllPedido.idtblhistoricoprecio)
          this.arrAgenda[index].Estado = "Entregado"
          this.arrAgenda = [...this.arrAgenda];

        }
      }, error => {
        this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
      });

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async valueChange($event) {
    let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
    let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))
    this.arrAgenda = this.arrAgenda.filter(callback => callback.Fecha_Entrega >= datstar && callback.Fecha_Entrega <= dateend)
  }

  async valueChangeIngreso($event) {
    let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
    let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))
    this.arrAgenda = this.arrAgenda.filter(callback => callback.Fecha_Ingreso >= datstar && callback.Fecha_Ingreso <= dateend)
  }

  updateFilterpaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  updateFilterdoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Doctor.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  cargartodos($event: any) {
    if ($event.target.checked) {
      this.cargar_Planeacion(1)
    } else {
      this.cargar_Planeacion(0)
    }

  }

  getCellClass({ row, column, value }): any {
    return {
      'produccion': row.Estado === 'Pendiente',
      'entregado': row.Estado === 'Entregado',
      'finalizado': row.Estado === 'Cancelado',
      'despachado': row.Estado === 'Aprobado',
      'facturado': row.Estado === 'Anulado',
    };
  }

  updateFilterBotones(event: any) {
    const val = event
    const temp = this.temp.filter(callback => callback.Estado == val);
    this.arrAgenda = temp;
    this.table.offset = 0;
  }

  onSelect({ selected }) {
    //this.onActivate;
    //console.log(this.pressboton);    
    /* if (this.pressboton == false) {
      this.bsModalRef = this.modalService.show(UpdateplaneacionComponent, { backdrop: 'static', class: 'modal-xl', initialState: selected[0] });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.cargar_Planeacion(0)
      })
    } */
    //console.log('Select Event', selected[0], this.selected);
  }

  onActivate(event) {
    if (event.cellIndex !== 9 && event.type == 'click') {

      this.bsModalRef = this.modalService.show(UpdateplaneacionComponent, { backdrop: 'static', class: 'modal-xl', initialState: event.row });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.cargar_Planeacion(0)
      })

    }

    //console.log('Activate Event', event);
  }

  getCellClassObs({ row, column, value }): any {
    return {
      'observacion': row.Requerimiento !== ' ',
    };
  }

}
