-<!-- Container-fluid starts -->
<div class="container-fluid">

  <div class="row">
    <div class="col-sm-12 ">
      <div class="row justify-content-center">
        <div class="row mb-2" style="padding: 20px; align-items: center;">
          <div class="col-auto"><img width="280" height="120" alt="" src={{logo}}>
          </div>
          <div class="col">
            <h2 class="mb-1">{{nombreEmpresa}}</h2>
            <p class="mb-2"><b> Dirrecion: </b> {{direccion}} &nbsp;&nbsp; <b> Telefono: </b>
              {{telefono}}
            </p>
            <p class="mb-2"><b> Ciudad: </b> {{ciudad}} &nbsp;&nbsp; <b> Pais: </b> {{pais}}</p>
            <p class="mb-2"><b> ORDEN PARA FRESADO Y DISEÑO TERCERIZADO </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="card-body step-indicator">
      <aw-wizard navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf085;', fontFamily: 'FontAwesome ' }" stepTitle="Servicio">
          <app-trabajo></app-trabajo>
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf0f6;', fontFamily: 'FontAwesome' }" stepTitle="Pedidos">
          <app-fr-pedido></app-fr-pedido>
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf0ee;', fontFamily: 'FontAwesome' }" stepTitle="Archivos">
          <app-fr-archivos></app-fr-archivos>
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf022;', fontFamily: 'FontAwesome' }" stepTitle="Facturacion">
          <app-fr-facturacion></app-fr-facturacion>
        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf155;', fontFamily: 'FontAwesome' }" stepTitle="Pago">
          <app-fr-pago></app-fr-pago>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>



</div>


<div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
  <div class="spinnerContent">
    <div class="spinnerItem">
      <div class="line bg-primary"></div>
      <div class="line bg-primary"></div>
      <div class="line bg-primary"></div>
      <div class="line bg-primary"></div>
    </div>
    <div class="MensajeDeEspera">Cargando...!!!</div>
  </div>
</div>