import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ListarTratamientosNg, ListarTratamientosNgRoot } from 'src/app/shared/models/planeacion';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment'
import { IngHistorialComponent } from '../ing-historial/ing-historial.component';
import { ColumnMode, DatatableComponent, SelectionType  } from '@swimlane/ngx-datatable';
import { UpdatetratamientosComponent } from '../../Alineadores/updatetratamientos/updatetratamientos.component';

@Component({
  selector: 'app-tratamientos',
  templateUrl: './tratamientos.component.html',
  styleUrls: ['./tratamientos.component.scss']
})
export class TratamientosComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;
  arrTratamientos: ListarTratamientosNg[];
  Fecha: Date
  temp: ListarTratamientosNg[];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  selected = [];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private toastaService: ToastrService,
    private pedidosService: PedidosService,
    private modalService: BsModalService

  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    //this.arrTratamientos = [];    
  }

  async ngOnInit(): Promise<any> {
    try {
      this.cargar_Tratamiento(0)

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async valueChange($event) {
    await this.ngOnInit();
    let datstar = (moment($event[0]).format(moment.defaultFormatUtc))
    let dateend = (moment($event[1]).format(moment.defaultFormatUtc))
    this.arrTratamientos = this.arrTratamientos.filter(callback => callback.Fecha_Entrega >= datstar && callback.Fecha_Entrega <= dateend)
  }

  showModalIngresarHistorial() {
    this.bsModalRef = this.modalService.show(IngHistorialComponent, { backdrop: 'static', class: 'modal-xl' });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.cargar_Tratamiento(0)
    })
  }

 /* async onclick(row: any) {
    this.bsModalRef = this.modalService.show(UpdatetratamientosComponent, { backdrop: 'static', class: 'modal-xl', initialState: row });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.cargar_Tratamiento(0)
    })
  }  */

  buscarpaciente($event: any) {
    try {
      const filterItems = query => {
        return this.arrTratamientos.filter((el) =>
          el.Paciente.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      }
      this.arrTratamientos = filterItems($event.target.value);

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  cargartodos($event: any) {
    if ($event.target.checked) {
      this.cargar_Tratamiento(1)
    } else {
      this.cargar_Tratamiento(0)
    }

  }

  async cargar_Tratamiento(realizado: any) {
    try {
      const res: ListarTratamientosNgRoot = await this.pedidosService.getAllTratamientos(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          realizado: realizado
        })
        this.temp = [...res.Listar_TratamientosNg]
        this.arrTratamientos = res.Listar_TratamientosNg;      
                
    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  updateFilterpaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrTratamientos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  updateFilterdoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Doctor.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrTratamientos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  onSelect({ selected }) {

   this.bsModalRef = this.modalService.show(UpdatetratamientosComponent, { backdrop: 'static', class: 'modal-xl', initialState: selected[0] });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.cargar_Tratamiento(0)
    })
 
   // console.log('Select Event', selected[0], this.selected);
  }

   onActivate(event) {
    //console.log('Activate Event', event);
  } 

  getCellClass({ row, column, value }): any {
    return {
      'observacion': row.Observaciones !== ' ',
    };
  }

}

