<!--   <div class="col-xs-12 col-12 col-md-4 form-group mb-3">
                        <input type="text" placeholder="Rango de fechas" formControlName="fecha" class="form-control" bsDaterangepicker 
                            #dpr="bsDaterangepicker" [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue'}"
                            (onHidden)="onclik($event)">
                    </div> -->
<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-4 col-sm-3"> 
                                <b><label class="col-form-label pt-0">AGENDA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                    &nbsp;&nbsp;
                                </label></b>
                            <b><label class="col-form-label pt-0">Cargar Todos: &nbsp; </label></b>
                            <button class="btn btn-primary btn-sm btn-pill btn-air-primary" type="button"
                                placement="top" ngbTooltip="Cargar todos los trabajos pendientes"
                                (click)="onClick()">Confirmar</button>
                            </div>

                            <div class="col-md-4 col-sm-3"> 

                                <b><label class="col-form-label pt-0">Seleccione Usuario o Area: &nbsp;</label></b>
                                <select class="from-select" (change)="onChange($event)">
                                    <option *ngFor="let usuarios of arrUsuarios" value={{usuarios.idTblTerceros}}>
                                        {{usuarios.ChrNombreCompleto}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-4 col-sm-3"> 
                                <button class="semaforo"
                                    [ngClass]="{ semaforo: true, redondo: true, rojo: (true)}"></button>
                                <b><label>Menor o igual a 1 dia</label></b>
                                &nbsp;
                                <button class="semaforo" [ngClass]="{ semaforo: true, redondo: true, amarillo: (true)}">
                                </button>
                                <b><label>Entro 2 y 3 dias</label></b>
                                &nbsp;
                                <button class="semaforo" [ngClass]="{ semaforo: true, redondo: true, verde: (true)}">
                                </button>
                                <b><label> Mayor a 3 dias</label></b>
                            </div>

                            

                            

                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-3">
                                <input type='text' class="form-control" placeholder='Buscar paciente...!!!'
                                    (keyup)='updateFilterPaciente($event)' />
                            </div>

                            <div class="col-md-3 col-sm-3">
                                <input type='text' class="form-control" placeholder='Buscar caja...!!!'
                                    (keyup)='updateFiltercaja($event)' />
                            </div>

                            <div class="col-md-3 col-sm-3">
                                <input type='text' class="form-control" placeholder='Buscar Doctor...!!!'
                                    (keyup)='updateFilterOdontologo($event)' />
                            </div>

                            <div class="col-md-3 col-sm-3">
                                <input type="text" placeholder="Rango de fechas" class="form-control" bsDaterangepicker
                                    #dpr="bsDaterangepicker"
                                    [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                                    (bsValueChange)="valueChange($event)">
                            </div>

                        </div>

                    </div>

                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Caja</th>
                                        <th scope="col">Odontologo</th>
                                        <th scope="col">Paciente</th>
                                        <th scope="col">Trabajo</th>
                                        <th scope="col">Proceso</th>
                                        <th scope="col">Cant</th>
                                        <th scope="col">Fecha Entrega</th>
                                        <th scope="col">Hora Entrega</th>
                                        <th scope="col">Responsable</th>
                                        <th scope="col">Alerta</th>
                                        <th scope="col">Accion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let agenda of arrAgenda">
                                        <th scope="row" [ngClass]="{naranja: (agenda.BlnGarantia === 1)}">
                                            {{agenda.Caja}}
                                        </th>
                                        <td>{{agenda.Odontologo}}</td>
                                        <td>{{agenda.Paciente}}</td>
                                        <td>{{agenda.Trabajo}}</td>
                                        <td>{{agenda.Proceso}}</td>
                                        <td align="center">{{agenda.Cantidad}}</td>
                                        <td>{{agenda.Fecha}}</td>
                                        <td>{{agenda.Hora}}</td>
                                        <td>{{agenda.Responsable}}</td>
                                        <td>
                                            <div class="semaforo"
                                                [ngClass]="{ semaforo: true, redondo: true, rojo: (agenda.Dias <= 1), amarillo: (agenda.Dias > 1 && agenda.Dias <=3), verde: (agenda.Dias >3 ) }">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <button (click)="showModalHistorial(agenda.idtblDtllPedidos)"
                                                    class="btn btn-secondary btn-sm" type="button" ngbTooltip="Mostar Historial" placement="top"><i
                                                        class="fa fa-history"></i></button>
                                                &nbsp;
                                                <button (click)="cambioFecha(agenda)" class="btn btn-primary btn-sm"
                                                    type="button" placement="top"  ngbTooltip="Cambiar Fecha"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                            &nbsp;
                                            <button (click)="showModalCambio(agenda)" class="btn btn-success btn-sm"
                                                type="button" placement="top" ngbTooltip="Cambiar Proceso"><i
                                                    class="fa fa-paper-plane-o"></i></button>

                                            <button (click)="UdptaRealizado(agenda)" class="btn btn-warning btn-sm"
                                                type="button" placement="top" ngbTooltip="Marcar Realizado"><i class="fa fa-check"></i></button>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                        <div class="spinnerContent">
                            <div class="spinnerItem">
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                            </div>
                            <div class="MensajeDeEspera">Cargando...!!!</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>