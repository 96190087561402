import { Component, OnInit } from '@angular/core';
import { SocketWebService } from 'src/app/shared/services/socket-web.service';
import mapboxgl from 'mapbox-gl';
import { Enviroment } from '../../../shared/services/env';
import { MapsService } from '../../../shared/services/maps.service';
import { interval } from 'rxjs';
import {  Coords, Delivery } from '../../../shared/models/mensajeria'


@Component({
  selector: 'app-mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.scss']
})
export class MensajeriaComponent implements OnInit {

  public idEmpresa: string;
  idSucursal: string;
  idNomUsuario: string;
  NomEmpresa: string;
  lat: number;
  lng: number;
  public arrDelivery: Delivery[];
  arrCoords: any[];
  public delivery: Delivery
  
  mapa: mapboxgl.Map;

  constructor(private mapsService: MapsService,
    private socketWebService: SocketWebService) {
    this.idEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.NomEmpresa = sessionStorage.getItem('ChrNonEmp');
    this.idNomUsuario = sessionStorage.getItem('ChrNombreCompleto');

    this.arrDelivery = [];
    this.delivery = new Delivery;
  }

  ngOnInit() {
    this.mapsService.bulidMap().then((data) => {
    }).catch((error) => {
      console.log(error)
    })

    navigator.geolocation.watchPosition(successLocation, errorLocation, { enableHighAccuracy: true })
    function successLocation(position) {
      sessionStorage.setItem('lng', position.coords.longitude);
      sessionStorage.setItem('lat', position.coords.latitude);      
    }
    function errorLocation() {
    }
    
    

  
/* 
    this.delivery1.idEmpresa = 'Otra'
    this.delivery1.NombreEmpresa ='Prueba'
    this.delivery1.lng = -75.58410664015027
    this.delivery1.lat = 6.240978373501231
    this.delivery1.nombreUsuario = 'Prueba'
    
    this.arrDelivery.push(this.delivery1)
 */
    //this.arrCoords.push([sessionStorage.getItem('lng'), sessionStorage.getItem('lat')])
    //this.arrCoords.push(['-75.58410664015027', '6.240978373501231'])
    
    //console.log(this.arrDelivery);
    //console.log([sessionStorage.getItem('lng'), sessionStorage.getItem('lat')])
    
  }

  AddMarker(){
    this.delivery.idEmpresa = this.idEmpresa
    this.delivery.NombreEmpresa = this.NomEmpresa
    this.delivery.nombreUsuario = this.idNomUsuario
    this.delivery.lng = parseFloat(sessionStorage.getItem('lng'))
    this.delivery.lat = parseFloat(sessionStorage.getItem('lat'))
    this.arrDelivery.push(this.delivery)
    
    this.mapsService.addMarker(this.arrDelivery)
  }

}
