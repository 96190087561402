<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Actualizar Procesos
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation" novalidate>
                        <div class="form-row">

                            <div class="col-md-3 mb-3">
                                <label for="validationCustom03">Producto</label>
                                <form class="was-validated">
                                    <select class="custom-select" (change)="onChange($event)" required
                                        [formControl]="formPrecios.controls['IdPrecio']">
                                        <option *ngFor="let precio of arrPrecios"
                                            value={{precio.idtblprecioalinieadores}}>
                                            {{precio.ChrNombre}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">El producto es obligatorio</div>
                                </form>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="validationCustom01">Descripcion</label>
                                <input type="text" class="form-control" id="validationCustom01"
                                    placeholder="Descripcion" [formControl]="formPrecios.controls['Nota']" required>
                                <div *ngIf="formPrecios.controls.Nota.touched && formPrecios.controls.Nota.errors?.required"
                                    class="text text-danger">
                                    La descripcion es obligatorio
                                </div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="validationCustom02">Cantidad</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Cantidad"
                                    [formControl]="formPrecios.controls['Cantidad']" required>
                                <div *ngIf="formPrecios.controls.Cantidad.touched && formPrecios.controls.Cantidad.errors?.required"
                                    class="text text-danger">
                                    La cantidad es obligatoria
                                </div>
                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="validationCustomUsername">Precio Unitario</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="validationCustomUsername"
                                        placeholder="Precio Unitario" aria-describedby="inputGroupPrepend"
                                        [formControl]="formPrecios.controls['Preciouni']" required>
                                </div>
                                <div *ngIf="formPrecios.controls.Preciouni.touched && formPrecios.controls.Preciouni.errors?.required"
                                    class="text text-danger">
                                    El precio Unitario es obligatorio
                                </div>
                            </div>
                        </div>

                        <button *ngIf="!boton" class="btn btn-primary btn-lg btn-block"
                            [disabled]="!formPrecios.valid" type="submit"
                            (click)="guardarTratamiento(formPrecios.value)">Actualizar</button>
                        <button *ngIf="boton" class="btn btn-primary btn-lg btn-block" disabled
                            type="submit">Actualizando...!!!</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>