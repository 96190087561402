<form [formGroup]="FromTrabajos">
    <div class="container-fluid">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Proceo de Firma para conformidad de calidad
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>

                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Resultado</th>
                            <th scope="col">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td style="width:50%;">
                            <!-- <div class="col"> -->
                                <div class="col-md-12">
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-success">
                                            <input type="radio" name="rdES" id="rdAprobado" value="Aprobado" (change)="chkResultado($event)">
                                            <label for="rdAprobado">Aprobado</label>
                                        </div>

                                        <div class="radio radio-danger">
                                            <input type="radio" name="rdES" id="rdRechazado" value="Rechazado" (change)="chkResultado($event)">
                                            <label for="rdRechazado">Rechazado</label>
                                        </div>

                                        <div class="radio radio-warning">
                                            <input type="radio" name="rdES" id="rdDevuelto" value="Devuelto" (change)="chkResultado($event)">
                                            <label for="rdDevuelto">Devuelto</label>
                                        </div>

                                        <div class="radio radio-info">
                                            <input type="radio" name="rdES" id="rdCuarentena" value="Cuarentena" (change)="chkResultado($event)">
                                            <label for="rdCuarentena">Cuarentena</label>
                                        </div>

                                    </div>
                                </div>
                            <!-- </div> -->
                        </td>
                        <td style="width:50%;">
                            <div class="col-md-12 mb-3">
                                <!-- <label class="col-form-label pt-0">*Observaciones</label> -->
                                <textarea class="form-control" rows="2" placeholder="Observaciones" required (change)="txtObserva($event)"></textarea>
                            </div>


                        </td>

                    </tbody>
                </table>



                <div [formArrayName]="'procesos'">
                    <div class="card-body" style="padding: 0px;"
                        *ngFor="let item of FromTrabajos.get('procesos')['controls'];  let i = index;"
                        [formGroupName]="i">
                        <hr>
                        <div class="row" style="justify-content: center;">
                            <label class="col-form-label pt-0"> <b>Caja:</b>
                                {{FromTrabajos.get('procesos')['controls'][i].value.Caja}}</label>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <label class="col-form-label pt-0"> <b>Odontologo:</b>
                                {{FromTrabajos.get('procesos')['controls'][i].value.Odontologo}}</label>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <label class="col-form-label pt-0"> <b>Paciente:</b>
                                {{FromTrabajos.get('procesos')['controls'][i].value.Paciente}}</label>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <label class="col-form-label pt-0"> <b>Trabajo:</b>
                                {{FromTrabajos.get('procesos')['controls'][i].value.Trabajo}}</label>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <label class="col-form-label pt-0"> <b>Proceso:</b>
                                {{FromTrabajos.get('procesos')['controls'][i].value.Proceso}}</label>
                        </div>
                    </div>
                </div>

                <div class="page-break">
                    <div class="row">
                        <div class="col-sm-12 col-xl-12">
                            <div class="card-body">
                                <div class="row justify-content-center">
                                    <div style="margin: auto;" class="firma-digital">
                                        <div class="firma-digital--body">
                                            &nbsp;FIRMA DIGITAL:
                                            <canvas #firmaDigital width="450" height="160"
                                                style="touch-action: none;"></canvas>
                                        </div>
                                    </div>
                                    <br>
                                    <br>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="btn-group">
                        <button class="btn btn-primary" type="submit" (click)="guardarProceso()">Guardar</button>
                        &nbsp;

                        <button class="btn btn-warning" type="button" (click)="Cancelaringreso()">Cancelar</button>
                    </div>


                </div>
            </div>


        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>


    </div>
</form>