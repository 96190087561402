import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import * as feather from 'feather-icons';
import { CustomizerService } from '../../../services/customizer.service';
import { StatisticsService } from '../../../services/statistics.service';
import { StTrabajosIngresadoRoot, StTrabajosIngresado, StCantIngreActualRoot, StCantIngreActual, StCantIngreAnterior, StCantIngreAnteriorRoot, StCantTrabajosxEstados, StCantTrabajosxEstadosRoot } from '../../../models/static';
import Swal from 'sweetalert2';
import { DatatableComponent } from '@swimlane/ngx-datatable';

declare var require: any
var Knob = require('knob')// browserify require

var primary = localStorage.getItem('primary_color') || '#4466f2';
var secondary = localStorage.getItem('secondary_color') || '#1ea6ec';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {


  constructor(public navServices: NavService, public customizer: CustomizerService) {    
  }

  async ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  
}
