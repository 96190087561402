import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ListarFacturasGenerada, ListarFacturasGeneradaRoot } from 'src/app/shared/models/invoice';
import { InvoiceService } from '../../../shared/services/invoice.service'
import { FechasFacturasComponent } from '../fechas-facturas/fechas-facturas.component';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';


@Component({
  selector: 'app-cambio-fechas-fac',
  templateUrl: './cambio-fechas-fac.component.html',
  styleUrls: ['./cambio-fechas-fac.component.scss']
})
export class CambioFechasFacComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;

  arrFacGeneradas: ListarFacturasGenerada[];
  temp: ListarFacturasGenerada[];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private toastaService: ToastrService,
    private inoviceService: InvoiceService,
    private modalService: BsModalService
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.Cargando = 'none';
  }

  async ngOnInit() {
    this.cargar_facturas()
  }

  showModal(factura: any) {
    this.bsModalRef = this.modalService.show(FechasFacturasComponent, { backdrop: 'static', class: 'modal-lg', initialState: factura });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content

      this.ngOnInit();
    })
  }

  updateFiltercliente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.ChrNombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrFacGeneradas = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  updateFilternumero(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.IntIdFactura.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrFacGeneradas = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  async cambioTerceros(row: ListarFacturasGenerada) {
    try {
      Swal.fire({
        title: 'Estas seguro?',
        html: '<p>Estas seguro que deseas cambiar el cliente de la factura a nombre de: <b style="font-weight: bold">' + row.OtroTercero + '</b> </p>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado'
      }).then(async (result) => {
        if (result.isConfirmed) {

          this.Cargando = 'Block';

          await this.inoviceService.update_cambiar_terceros(
            {
              IdClt: row.FkOdonto,
              IdOdo: row.idTblTerceros,
              IdFac: row.IdFactura
            }).then((resp)=>{
              if(resp){
                Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                this.Cargando = 'none';
                this.cargar_facturas();
              }else{
                Swal.fire({
                  title: "NO se realizo el proceso...!!",
                  html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + resp + '</p></div>', icon: 'error'
                });
              }
            }) 
        }
      })

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }


  }

  async cargar_facturas() {
    try {
      this.Cargando = 'Block';

      const res: ListarFacturasGeneradaRoot = await this.inoviceService.getAllFactGeneradas(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          Mostrar: '1000'
        })

      this.temp = [...res.Listar_Facturas_Generadas]
      this.arrFacGeneradas = res.Listar_Facturas_Generadas;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }
  }

  ver_Dian(data: ListarFacturasGenerada) {
    window.open("https://catalogo-vpfe.dian.gov.co/Document/ShowDocumentToPublic/" + data.chrCufeDIAN + "?Token=")
  }

}
