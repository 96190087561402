import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListaTrabajosPreOrden, ListaTrabajosPreOrdenRoot } from 'src/app/shared/models/listarEmpresas';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fr-pedido',
  templateUrl: './fr-pedido.component.html',
  styleUrls: ['./fr-pedido.component.scss'],  
  encapsulation: ViewEncapsulation.None
})
export class FrPedidoComponent implements OnInit {

  public pedidoForm: FormGroup;
 
  constructor(private route: Router, private fb: FormBuilder,  private activateRoute: ActivatedRoute, private listarempresasservice: ListarEmpresasService,) { 


  }

  ngOnInit(): void {
  }


}
