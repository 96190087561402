import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticatorService } from './shared/services/authenticator.service';
// import { SocketWebService } from './shared/services/socket-web.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'endless-starterkit';

  user: any;

  constructor(private http: HttpClient,
    private toastaService: ToastrService,
    private authservices: AuthenticatorService) {
    this.user = sessionStorage.getItem('ChrUsuarios');
  }


  /*  async ngOnInit() {
     if (this.user != null) {
       const res: any = await this.authservices.renewToken({ user: this.user });
       if (res.ok = true) {
         this.toastaService.success('Su token ha sido renovado puede seguir utilizando los servicios de AdentLab', 'Mensaje', { timeOut: 3000 });;
         localStorage.setItem('token', res.token);
       }
     }
   } catch(error) {
     this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
   } */


}




