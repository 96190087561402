<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Hoja de Tratamientos</h5>
                    <form class="form-inline theme-form mt-3">
                        <div class="form-group">
                            <label> Ingresar Tratamiento: </label>
                            <button (click)="showModalIngresarHistorial()" class="btn btn-primary"><i
                                    class="fa fa-plus-circle"></i></button>
                        </div>
                        <div class="form-group">
                            <div class="checkbox checkbox-success">
                                <input id="checkbox-primary" type="checkbox" (change)="cargartodos($event)">
                                <label for="checkbox-primary">Cargar Todos</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control"
                                placeholder='Paciente...!!!' (keyup)='updateFilterpaciente($event)' />
                        </div>

                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control"
                                placeholder='Doctor...!!!' (keyup)='updateFilterdoctor($event)' />
                        </div>
                    </form>
                </div>
                <!--    <div class="col-xs-12 col-12 col-md-4 form-group mb-3">
                        <input type="text" placeholder="Rango de fechas" class="form-control" bsDaterangepicker
                            #dpr="bsDaterangepicker"
                            [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                            (bsValueChange)="valueChange($event)">
                    </div> -->
                <div class="card-body custom-datatable">
                    <ngx-datatable #table class="bootstrap" [rows]="arrTratamientos" [columnMode]="ColumnMode.force"
                        [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="10"
                        [scrollbarH]="true"    [selected]="selected"
                        [selectionType]="SelectionType.single"
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)">
                        <ngx-datatable-column name="Clinica" prop="Clinica"></ngx-datatable-column>
                        <ngx-datatable-column name="Odontologo" prop="Doctor"></ngx-datatable-column>
                        <ngx-datatable-column name="Paciente" [width]="200" prop="Paciente"></ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Ingreso" [width]="84" prop="Fecha_Ingreso"></ngx-datatable-column> -->
                        <ngx-datatable-column name="Tipo" [width]="130" prop="Tipo"></ngx-datatable-column>
                        <ngx-datatable-column name="Marca" [width]="80" prop="Marca"></ngx-datatable-column>
                        <ngx-datatable-column name="Can_T" [width]="80" prop="Cantidad_T"></ngx-datatable-column>
                        <ngx-datatable-column name="Arcada" [width]="100" prop="Arcada"></ngx-datatable-column>
                        <ngx-datatable-column name="Observaciones" [width]="300" prop="Observaciones" [cellClass]="getCellClass">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Descripcion" [width]="110" prop="Descripcion">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Can_E" [width]="80" prop="Cantidad_E"></ngx-datatable-column>
                        <ngx-datatable-column name="Producto" [width]="90" prop="Producto"></ngx-datatable-column>
                        <ngx-datatable-column name="Entrega" [width]="100" prop="Fecha_Entrega">
                           
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Proceso" prop="Proceso"></ngx-datatable-column>
                        <ngx-datatable-column name="Estados" [width]="110" prop="Estado"></ngx-datatable-column>
                    <!--     <ngx-datatable-column [width]="150" name="Acciones">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group" role="group">
                                    <button (click)="onclick(row)" class="btn btn-success btn-sm btn-pill"><i
                                            class="icofont icofont-ui-edit"></i></button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column> -->
                    </ngx-datatable>
                    
                    <div class="selected-column">
                        <h4>Selecionado</h4>
                        <ul>
                          <li *ngFor="let sel of selected">
                            {{ sel.Paciente }}
                          </li>
                          <li *ngIf="!selected.length">No Selecionado</li>
                        </ul>
                    </div>
                    
             

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->