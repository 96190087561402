import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Listar_EjecucionNg, Listar_EjecucionNgRoot } from 'src/app/shared/models/planeacion';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment'
import { IngHistorialComponent } from '../../Alineadores/ing-historial/ing-historial.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
@Component({
  selector: 'app-ejecucion',
  templateUrl: './ejecucion.component.html',
  styleUrls: ['./ejecucion.component.scss']
})
export class EjecucionComponent implements OnInit {

  IdEmpresa: string;
  totalcantidade: number;
  IdSucursal: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;
  arrEjecucion: Listar_EjecucionNg[];
  temp: Listar_EjecucionNg[];
  Fecha: Date
  Cargando: string;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private toastaService: ToastrService,
    private pedidosService: PedidosService,
    private modalService: BsModalService

  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.totalcantidade = 0
    this.arrEjecucion = [];
    this.Cargando = 'none'

  }

  async ngOnInit(): Promise<any> {
    try {
      this.cargar_Ejecucion(1)
    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async valueChange($event) {
    let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
    let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))
    this.arrEjecucion = this.arrEjecucion.filter(callback => callback.Fecha_Entrega >= datstar && callback.Fecha_Entrega <= dateend)
  }

  async onclickPlancha(row: any) {
    await this.pedidosService.UpdateEstadoTratamiento({ idtblhistoricoprecio: row.idtblhistoricoprecio, idEstados: 6 }).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        const index = this.arrEjecucion.findIndex(callback => callback.idtblhistoricoprecio == row.idtblhistoricoprecio)
        this.arrEjecucion[index].Estado = "En Plancha"
        this.arrEjecucion = [...this.arrEjecucion];
      }
    }, error => {
      this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  async onclickImpreso(row: any) {
    await this.pedidosService.UpdateEstadoTratamiento({ idtblhistoricoprecio: row.idtblhistoricoprecio, idEstados: 7 }).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        const index = this.arrEjecucion.findIndex(callback => callback.idtblhistoricoprecio == row.idtblhistoricoprecio)
        this.arrEjecucion[index].Estado = "Impreso"
        this.arrEjecucion = [...this.arrEjecucion];
      }
    }, error => {
      this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  async onclickFinalizado(row: any) {
    await this.pedidosService.UpdateEstadoTratamiento({ idtblhistoricoprecio: row.idtblhistoricoprecio, idEstados: 8 }).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        const index = this.arrEjecucion.findIndex(callback => callback.idtblhistoricoprecio == row.idtblhistoricoprecio)
        this.arrEjecucion[index].Estado = "Finalizado"
        this.arrEjecucion = [...this.arrEjecucion];
      }
    }, error => {
      this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  async onclickDespachado(row: any) {
    await this.pedidosService.UpdateEstadoTratamiento({ idtblhistoricoprecio: row.idtblhistoricoprecio, idEstados: 9 }).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        const index = this.arrEjecucion.findIndex(callback => callback.idtblhistoricoprecio == row.idtblhistoricoprecio)
        this.arrEjecucion[index].Estado = "Despachado"
        this.arrEjecucion = [...this.arrEjecucion];
      }
    }, error => {
      this.toastaService.warning(error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
    })
  }

  getCellClass({ row, column, value }): any {
    return {
      'produccion': row.Estado === 'En Produccion',
      'plancha': row.Estado === 'En Plancha',
      'impreso': row.Estado === 'Impreso',
      'finalizado': row.Estado === 'Finalizado',
      'despachado': row.Estado === 'Despachado',
      'facturado': row.Estado === 'Facturado',
    };
  }

  getCellClassOb({ row, column, value }): any {
    return {
      'observacion': row.Observaciones !== ' ',
    };
  }

  updateFilterpaciente(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.arrEjecucion = temp;
    this.table.offset = 0;
  }

  updateFilterdoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Doctor.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrEjecucion = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  cargartodos($event: any) {
    if ($event.target.checked) {
      this.cargar_Ejecucion(4)
    } else {
      this.cargar_Ejecucion(1)
    }

  }

  async cargar_Ejecucion(sql: any) {
    try {

      this.Cargando = 'Block'
      const res: Listar_EjecucionNgRoot = await this.pedidosService.getAllEjecucion(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          sql: sql
        })
      this.temp = [...res.Listar_EjecucionNg]
      this.arrEjecucion = res.Listar_EjecucionNg;   

      this.totalcantidade = this.arrEjecucion.map(item => item.Cantidad).reduce((prev, curr) => prev + curr, 0);

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none'
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  updateFilterBotones(event: any) {   
    const val = event
    const temp = this.temp.filter(callback => callback.Estado == val);
    this.arrEjecucion = temp;
    this.table.offset = 0;

    this.totalcantidade = temp.map(item => item.Cantidad).reduce((prev, curr) => prev + curr, 0);

  }


}



