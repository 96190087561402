import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CsvModule } from '@ctrl/ngx-csv';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'; 
import { FileUploadModule } from 'ng2-file-upload';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { LoginComponent } from './components/General/login/login.component';
import { ListarempresasComponent } from './components/General/listar-empresas/listar-empresas.component';
import { MensajeriaComponent } from './components/Mensajerias/mensajeria/mensajeria.component';
import { AgendaComponent } from './components/AgendaAll/agenda/agenda.component';
import { CambioFechasFacComponent } from './components/Facturas/cambio-fechas-fac/cambio-fechas-fac.component';
import { TokenInterceptorService } from '../app/shared/services/tokeninterceptor.service';
import { InvitaciondtdentalComponent } from './components/DtDental/invitaciondtdental/invitaciondtdental.component';
import { OrdenesdtComponent } from './components/DtDental/ordenesdt/ordenesdt.component';
import { PlaneacionComponent } from './components/Alineadores/planeacion/planeacion.component'
import { ModalModule } from 'ngx-bootstrap/modal';
import { IngTratamientoComponent } from './components/Alineadores/ing-tratamiento/ing-tratamiento.component';

import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { HistorialComponent } from './components/AgendaAll/historial/historial.component';
import { CambiodeprocesoComponent } from './components/AgendaAll/cambiodeproceso/cambiodeproceso.component';
import { FechasFacturasComponent } from './components/Facturas/fechas-facturas/fechas-facturas.component';
import { TratamientosComponent } from './components/Alineadores/tratamientos/tratamientos.component';
import { RemisionesComponent } from './components/Pedidos/remisiones/remisiones.component';
import { FirmaRemisionesComponent } from './components/Pedidos/firma-remisiones/firma-remisiones.component';
import { IngHistorialComponent } from './components/Alineadores/ing-historial/ing-historial.component';
import { EjecucionComponent } from './components/Alineadores/ejecucion/ejecucion.component';
import { RuteroComponent } from './components/Mensajerias/rutero/rutero.component';
import { ProcesosAlineadoresComponent } from './components/Alineadores/procesos-alineadores/procesos-alineadores.component';
import { UpdatetratamientosComponent } from './components/Alineadores/updatetratamientos/updatetratamientos.component';
import { IngPedidosComponent } from './components/Pedidos/ing-pedidos/ing-pedidos.component';
import { UpdateprocesosAlineadoresComponent } from './components/Alineadores/updateprocesos-alineadores/updateprocesos-alineadores.component';
import { UpdateplaneacionComponent } from './components/Alineadores/updateplaneacion/updateplaneacion.component';
import { TercerosComponent } from './components/terceros/terceros/terceros.component';
import { RecibodocumentosComponent } from './components/recibodocumentos/recibodocumentos.component';
import { IngPacientesComponent } from './components/Terceros/ing-pacientes/ing-pacientes.component';
import { ListaCajasComponent } from './components/Pedidos/lista-cajas/lista-cajas.component';
import { ListadoComponent } from './components/Procesos/listado/listado.component';
import { UsuariosComponent } from './components/General/usuarios/usuarios.component';
import { ConfiguracionComponent } from './components/General/configuracion/configuracion.component';
import { ProgramadorComponent } from './components/Mensajerias/programador/programador.component';
import { RecogidasComponent } from './components/Mensajerias/recogidas/recogidas.component';
import { EntregasComponent } from './components/Mensajerias/entregas/entregas.component';
import { SignaturePabComponent } from './components/General/signature-pab/signature-pab.component';
import { AgendaxuserComponent } from './components/AgendaAll/agendaxuser/agendaxuser.component';
import { DashboardComponent } from './components/General/dashboard/dashboard.component';
import { OrdenTrabajoComponent } from './components/Pedidos/orden-trabajo/orden-trabajo.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ListaPreordenComponent } from './components/Pedidos/lista-preorden/lista-preorden.component';
import { IngInventarioComponent } from './components/Pedidos/ing-inventario/ing-inventario.component';
import { DesinfeccionComponent } from './components/AgendaAll/desinfeccion/desinfeccion.component';
import { FirmacalidadComponent } from './components/AgendaAll/firmacalidad/firmacalidad.component';
import { IngcalidadComponent } from './components/AgendaAll/ingcalidad/ingcalidad.component';

registerLocaleData(localeES, 'es')


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListarempresasComponent,
    MensajeriaComponent,
    AgendaComponent,
    CambioFechasFacComponent,
    InvitaciondtdentalComponent,
    OrdenesdtComponent,
    PlaneacionComponent,
    IngTratamientoComponent,
    HistorialComponent,
    CambiodeprocesoComponent,
    FechasFacturasComponent,
    TratamientosComponent,
    RemisionesComponent,
    FirmaRemisionesComponent,
    IngHistorialComponent,
    EjecucionComponent,
    RuteroComponent,
    ProcesosAlineadoresComponent,
    UpdatetratamientosComponent,
    IngPedidosComponent,
    UpdateprocesosAlineadoresComponent,
    UpdateplaneacionComponent,
    TercerosComponent,
    RecibodocumentosComponent,
    IngPacientesComponent,
    ListaCajasComponent,
    ListadoComponent,
    UsuariosComponent,
    ConfiguracionComponent,
    ProgramadorComponent,
    RecogidasComponent,
    EntregasComponent,
    SignaturePabComponent,
    AgendaxuserComponent,
    DashboardComponent,
    OrdenTrabajoComponent,
    ListaPreordenComponent,
    IngInventarioComponent,
    DesinfeccionComponent,
    FirmacalidadComponent,
    IngcalidadComponent,
  ],
  imports: [
    BrowserModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'No hay resultado para mostrar..!!', // Message to show when array is presented, but contains no values
        totalMessage: 'total', // Footer total message
        selectedMessage: 'selected' // Footer selected message
      }
      }),
    AppRoutingModule,
    AutocompleteLibModule,
    SharedModule,
    FormsModule,
    NgxQRCodeModule,
    NgbModule,
    FileUploadModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    CsvModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    PDFExportModule,
  ],
  providers: [
     {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    CookieService,
    { 
      provide: LOCALE_ID, useValue: 'es'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
