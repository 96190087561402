import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Listar_EjecucionNg, Listar_EjecucionNgRoot } from 'src/app/shared/models/planeacion';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment'
import { IngHistorialComponent } from '../ing-historial/ing-historial.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { UpdateprocesosAlineadoresComponent } from '../updateprocesos-alineadores/updateprocesos-alineadores.component';

@Component({
  selector: 'app-procesos-alineadores',
  templateUrl: './procesos-alineadores.component.html',
  styleUrls: ['./procesos-alineadores.component.scss']
})
export class ProcesosAlineadoresComponent implements OnInit {

  IdEmpresa: string;
  IdSucursal: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;
  arrEjecucion: Listar_EjecucionNg[];
  Fecha: Date
  temp: Listar_EjecucionNg[];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  SelectionType = SelectionType;
  selected = [];
  totalcantidade: number;

  constructor(private toastaService: ToastrService,
    private pedidosService: PedidosService,
    private modalService: BsModalService
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.arrEjecucion = [];
    this.totalcantidade = 0
  }

  async ngOnInit(): Promise<any> {
    try {
      this.cargar_Ejecucion(2)
    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async valueChange($event) {
    let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
    let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))
    this.arrEjecucion = this.arrEjecucion.filter(callback => callback.Fecha_Entrega >= datstar && callback.Fecha_Entrega <= dateend)
  }

  cargartodos($event: any) {
    if ($event.target.checked) {
      this.cargar_Ejecucion(3)
    } else {
      this.cargar_Ejecucion(2)
    }

  }

  async cargar_Ejecucion(sql: any) {
    try {
      const res: Listar_EjecucionNgRoot = await this.pedidosService.getAllEjecucion(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          sql: sql
        })
      this.temp = [...res.Listar_EjecucionNg]
      this.arrEjecucion = res.Listar_EjecucionNg;
      
      this.totalcantidade = this.arrEjecucion.map(item => item.Cantidad).reduce((prev, curr) => prev + curr, 0);


    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  updateFilterpaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrEjecucion = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  updateFilterdoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Doctor.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrEjecucion = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  onSelect({ selected }) {

    this.bsModalRef = this.modalService.show(UpdateprocesosAlineadoresComponent, { backdrop: 'static', class: 'modal-xl', initialState: selected[0] });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.cargar_Ejecucion(2)
    })
  }

  onActivate(event) {
  }




}
