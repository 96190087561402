import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ListarMensajeriaAllxUserRoot, ListarMensajeriaAllxUser } from 'src/app/shared/models/mensajeria';
import { MensajeriaService } from '../../../shared/services/mensajeria.service'
import Swal from 'sweetalert2';
import { SignaturePabComponent } from '../../General/signature-pab/signature-pab.component';
import * as moment from 'moment'
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-rutero',
  templateUrl: './rutero.component.html',
  styleUrls: ['./rutero.component.scss']
})
export class RuteroComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  IdEmpresa: string;
  IdSucursal: string;
  idtblTercero: string;
  /**Instancia del modal */
  private bsModalRef: BsModalRef;
  arrRuteroxUsuario: ListarMensajeriaAllxUser[];
  arrTemp:  ListarMensajeriaAllxUser[];
  Cargando: string;
  

  constructor(private toastaService: ToastrService,
    private mensajeriaService: MensajeriaService,
    private modalService: BsModalService
  ) {
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idtblTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));

      this.Cargando = 'none';
  }

  async ngOnInit() {
    try {
      this.Cargar_Mensajeria();
    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }
  }

  async UpdateCumplida(id: ListarMensajeriaAllxUser) {
    try {

      this.Cargando = 'Block';

      const res = await this.mensajeriaService.UpdateMensajeria(
        {
          idAc: 4,
          Oberva: "",
          Cumplida: 1,
          idmensajeria: id.idtblmensajeria
        })

      if (res) {

        this.Cargando = 'none';

        Swal.fire({
          title: "Proceso completado correctamente",
          icon: 'success',
          timer: 5000
        });
        this.ngOnInit()
      }

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }

  }

  async abrirfirma(ruta: ListarMensajeriaAllxUser){
    try {
      this.bsModalRef = this.modalService.show(SignaturePabComponent, { backdrop: 'static', class: 'modal-xl', initialState: ruta });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content;
        this.Cargar_Mensajeria();
      })
    } catch (error) {
      
    }
  }

  async Cargar_Mensajeria(){
    try {

      this.Cargando = 'Block';

      const res: ListarMensajeriaAllxUserRoot = await this.mensajeriaService.getAllxUser(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          Cumplida: 0,
          IdUsuario: this.idtblTercero,
          IdSql: 1
        })

      this.Cargando = 'none';      
      this.arrRuteroxUsuario = res.ListarMensajeriaAllxUser;   
      this.arrTemp = [...res.ListarMensajeriaAllxUser];   

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error',
        timer: 3000
      });
    }
  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Doctor || '').toLowerCase().indexOf(val) !== -1 || !val;
    });
     // update the rows    
    this.arrRuteroxUsuario = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterClinica(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Clinica || '').toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrRuteroxUsuario = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Paciente || '').toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrRuteroxUsuario = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async valueChange($event) {
    try {
      this.Cargando = 'Block';

      if ($event != null) {

        let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
        let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))

        this.Cargando = 'Block';

        const res: ListarMensajeriaAllxUserRoot = await this.mensajeriaService.getAllxUser(
          {
            IdEmpresa: this.IdEmpresa,
            IdSucursal: this.IdSucursal,
            Cumplida: 0,
            IdUsuario: this.idtblTercero,
            IdSql: 2,
            dtmDesde: datstar,
            dtmHasta: dateend
          })
  
        this.Cargando = 'none';      
        this.arrRuteroxUsuario = res.ListarMensajeriaAllxUser;   
        this.arrTemp = [...res.ListarMensajeriaAllxUser];   
  

      } else {

        await this.Cargar_Mensajeria();
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }



  }

  
}
