<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Hoja de Procesos</h5>
                    <form class="form-inline theme-form mt-3">
                        <div class="form-group">
                            <input type="text" placeholder="Rango de fechas" class="form-control" bsDaterangepicker
                                #dpr="bsDaterangepicker"
                                [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                                (bsValueChange)="valueChange($event)">

                        </div>
                        <div class="form-group">
                            <div class="checkbox checkbox-success">
                                <input id="checkbox-primary" type="checkbox" (change)="cargartodos($event)">
                                <label for="checkbox-primary">Cargar Todos</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control" placeholder='Paciente...!!!'
                                (keyup)='updateFilterpaciente($event)' />
                        </div>

                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control" placeholder='Doctor...!!!'
                                (keyup)='updateFilterdoctor($event)' />
                        </div>

                        <div class="form-group">
                            <a csvLink [data]="arrEjecucion">Export a Excel</a>
                        </div>
                    </form>
                </div>
                <div class="card-body custom-datatable">
                    <ngx-datatable class="bootstrap" [rows]="arrEjecucion" [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="32" [limit]="18" [scrollbarH]="true" [selected]="selected"
                        [selectionType]="SelectionType.single"
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)">
                        <ngx-datatable-column name="Entrega" prop="Fecha_Entrega">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Clinica" prop="Clinica"></ngx-datatable-column>
                        <ngx-datatable-column name="Odontologo" prop="Doctor"></ngx-datatable-column>
                        <ngx-datatable-column name="Paciente" prop="Paciente">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Tipo" prop="Tipo"></ngx-datatable-column>
                        <ngx-datatable-column name="Descripcion" prop="Descripcion"></ngx-datatable-column>
                        <ngx-datatable-column name="Cantidad_E" prop="Cantidad"></ngx-datatable-column>
                        <ngx-datatable-column name="Precio" prop="PrecioUni">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{row.PrecioUni | currency: 'COP'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Total" prop="Total">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{row.Total | currency: 'COP'}}
                            </ng-template>
                        </ngx-datatable-column>
                        
                    </ngx-datatable>

                    <div class="selected-column">
                        <h4>Selecionado</h4>
                        <ul>
                          <li *ngFor="let sel of selected">
                            {{ sel.Paciente }}
                          </li>
                          <li *ngIf="!selected.length">No Selecionado</li>
                        </ul>

                        <label >Total a entregar:  {{totalcantidade}} </label>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->