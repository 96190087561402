import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ListarDetallePedidoNg, ListarDetallePedidoNgRoot, ListarEstado, ListarEstadoRoot } from 'src/app/shared/models/pedidos';
import { DtdentalServices } from 'src/app/shared/services/dtdental.service';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import Swal from 'sweetalert2';
import { HistorialComponent } from '../../AgendaAll/historial/historial.component';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})

export class ListadoComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
 
  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;
  arrPedidos: ListarDetallePedidoNg[];
  arrPedidosAll: ListarDetallePedidoNg[];
  arrTemp: ListarDetallePedidoNg[];
  IdTokenNG: string;

  arrEstados: ListarEstado[];
  public arrSelected = [];

  private bsModalRef: BsModalRef;
  ngmDoctor: string;
  ngmPaciente: string;
  ngmcaja: string;
  ngmClinica: string;
  
  arrEstadosSelec: string[]
  operacionSeleccionada: number;
  idTercero: number;

  constructor(private _PedidoService: PedidosService,
    private modalService: BsModalService,
    private _DtDentalService: DtdentalServices,
    private router: Router) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.IdTokenNG = sessionStorage.getItem('IdToken');
    this.Cargando = 'none'

    this.arrEstadosSelec = ['Finalizar', 'Anular', 'Pendiente']
  }


  async ngOnInit() {
    try {

      await this.Cargar_Pedidos("1", 0, '');
      await this.Cargar_Estados();

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Pedidos(idBusqueda: string, IdEstados: number, NombrePaciente: string) {
    try {

      this.Cargando = 'Block'

      await this._PedidoService.searchListarDetallePedidoNg({
        IdBusqueda: idBusqueda,
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        IdEstados: IdEstados,
        NombrePaciente: NombrePaciente
      }
      ).then((res: ListarDetallePedidoNgRoot) => {
        this.arrPedidos = res.Listar_Detalle_Pedido_Ng;
        this.arrTemp = [...res.Listar_Detalle_Pedido_Ng]
        this.arrPedidosAll = res.Listar_Detalle_Pedido_Ng;
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  showModalHistorial(dtllPedido: any) {
    this.bsModalRef = this.modalService.show(HistorialComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
    })
  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Odonotologo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterClinica(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Establecimiento.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Pacientes.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterCaja(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.ChrNumeCaja.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async Cargar_Estados() {
    try {

      this.Cargando = 'Block'

      await this._PedidoService.getEstados().then((res: ListarEstadoRoot) => {
        this.arrEstados = res.Listar_Estados;

      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async onChangeEstados($event: any) {
    try {
      if (($event.target.value == -1 || $event.target.value == 0)) {
        await this.Cargar_Pedidos("1", 0, '')
      } else {     
          this.Cargar_Pedidos("2", $event.target.value, "")
        }
        
      //limpiar filtros
      this.ngmDoctor = ''
      this.ngmcaja = ''
      this.ngmClinica= ''

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  onSelectTable({ selected }) {
    this.arrSelected.splice(0, this.arrSelected.length);
    this.arrSelected.push(...selected);
  }

  async updatePedidos() {
    try {

      var idECajaNg;
      var idProcesoNg;
      var IdConceptoNg;
      var idEstadogNg

      if (this.operacionSeleccionada !== undefined) {

        //Operacion para finalizar
        if (this.operacionSeleccionada == 4) {
          idECajaNg = 0
          idProcesoNg = 2
          idEstadogNg = 4
          IdConceptoNg = "Trabajo Finalizado"
        }

        //Operacion para Anular
        if (this.operacionSeleccionada == 6) {
          idECajaNg = 0
          idProcesoNg = -2
          idEstadogNg = 6
          IdConceptoNg = "Trabajo Anulado"
        }

        //Operacion para Pendiente
        if (this.operacionSeleccionada == 7) {
          idECajaNg = 1
          idProcesoNg = -1
          idEstadogNg = 7
          IdConceptoNg = "Trabajo en estado Pendiente"
        }


        await this.validarpediosselec(this.operacionSeleccionada).then(valido => {
          if (valido) {

            Swal.fire({
              title: 'Estas seguro?',
              html: '<p>Por favor introduzca el numero del token: <b style="font-weight: bold"> <input class="form-control digits" type="input" id="inputtoken"></b> </p>',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmado',
              preConfirm: () => {
                return [
                  (document.getElementById('inputtoken') as HTMLInputElement).value
                ]
              }
            }).then(async (result) => {
              if (result.isConfirmed) {

                if (result.value[0] != '') {

                  this.Cargando = 'Block';

                  await this._PedidoService.ValidarToken({
                    idsecret: this.IdTokenNG,
                    token: result.value[0]
                  }).then((res) => {

                    this._PedidoService.UpdateInserDtllPediNG({
                      idECaja: idECajaNg,
                      idProcesog: idProcesoNg,
                      idEstadog: idEstadogNg,
                      IdConcepto: IdConceptoNg,
                      idUsuariog: this.idTercero,
                      dtllInser: this.arrSelected
                    }).then(async (resp) => {
                        if(resp){
                          this._PedidoService.UpdateDtllPediRealizaAll({
                            dtllInser: this.arrSelected
                          }).then(async (respuesta)=>{
                            this.Cargando = 'none';

                            Swal.fire({
                              title: "Proceso completado correctamente",
                              icon: 'success'
                            });
                              
                            this.operacionSeleccionada = undefined
                            this.arrSelected = [];
                            await this.Cargar_Pedidos("1", 0, '');
                          })
                        }
                     
                    }, error => {
                      this.Cargando = 'none';
                      Swal.fire({
                        title: "Error",
                        html: '<div><p>' + error.error + '</p></div>',
                        icon: 'error'
                      });
                    })

                  }, error => {
                    this.Cargando = 'none';

                    Swal.fire({
                      title: "Error",
                      html: '<div><p>' + error.error.error + '</p></div>',
                      icon: 'error'
                    });
                  })

                } else {
                  this.Cargando = 'none';
                  Swal.fire({
                    title: "Error",
                    html: '<div><p> No has introducido el token</p></div>',
                    icon: 'error'
                  });
                }

              }
            })
          } else {
            this.Cargando = 'none';
            Swal.fire({
              title: "Error",
              html: '<div><p> 1 - No se puede realizar la accion porque has elegido un pedido que esta en diferente estado a En Laboratorio, Periodo de Prueba, Pendiente; </p></div> <div><p> 2 - Alguno de los pedidos selecionado tiene mas de un proceso por favor verificar; </p></div>',
              icon: 'error'
            });
          }
        })
      } else {
        this.Cargando = 'none';
        Swal.fire({
          title: "Error",
          html: '<div><p>No se puede realizar la accion sin elegir una opcion de estados por favor verificar</p></div>',
          icon: 'error'
        });
      }


    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div><p>' + error.error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async validarpediosselec(idEstados: number) {
    try {

      let contador = 0;

      for (let item of this.arrSelected) {
        if (!(item.idtblEstados === 2 || item.idtblEstados === 3 || item.idtblEstados === 7)) {
          return false;
        }
      }

      for (let item of this.arrSelected) {
        for (let j of this.arrPedidosAll) {
          if (item.idTblPedidos === j.idTblPedidos) {
            contador = contador + 1

            if (contador >= 2) {
              return false;
            }
          }

        }
        contador = 0
      }


      return true;

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  borrarselect() {
    this.arrSelected = [];
  }
  /*  async cargartodos(event){
     try {
            
       if (event.target.checked) {
         this.Cargar_Pedidos("2")
       } else {
         this.Cargar_Pedidos("1")
       }
 
     } catch (error) {
       this.Cargando = 'none';
       Swal.fire({
         title: "No se realizo el proceso...!!!",
         html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
         icon: 'error'
       });
     }
 
   } */

  async LoginDtDental() {
    try {

      await this._DtDentalService.login().then((resp) => {
        sessionStorage.setItem('tokendt', resp.headers.get('access-token'));
        sessionStorage.setItem('clientdt', resp.headers.get('client'));
      })

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: '<p>' + error + '</p>',
        icon: 'error'
      });
    }

  }

  async ActualizarPedidosDT(IdEstadoDT: any) {
    try {

      await this.LoginDtDental();

      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);

      for (let item of this.arrSelected) {
        if (item.IdDtDental != null && item.IdDtDental != 0) {

          if(IdEstadoDT == 4){
            await this._DtDentalService.UpdateOrder(IdEstadoDT, {
              state: IdEstadoDT,
              order_details_attributes: [{
                created_by: 1,
                description: "Trabajo Finalizado " + item.ChrNombreTrabajo,
                date: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
              }]
            }).then((res) => {
  
            })
          }

          if(IdEstadoDT == 6){
            await this._DtDentalService.UpdateOrder(IdEstadoDT, {
              state: IdEstadoDT,
              order_details_attributes: [{
                created_by: 1,
                description: "Trabajo Anulado " + item.ChrNombreTrabajo,
                date: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
              }]
            }).then((res) => {
  
            })
          }
        
        }
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: '<p>' + error + '</p>',
        icon: 'error'
      });
    }

  }



}
