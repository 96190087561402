import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgAgenda, NgAgendaRoot } from 'src/app/shared/models/agenda';
import { ListarEstado } from 'src/app/shared/models/pedidos';
import { AgendaService } from 'src/app/shared/services/agenda.service';
import Swal from 'sweetalert2';
import { HistorialComponent } from '../historial/historial.component';
import { CambiodeprocesoComponent } from '../cambiodeproceso/cambiodeproceso.component';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import { IngInventarioComponent } from '../../Pedidos/ing-inventario/ing-inventario.component';
import { DesinfeccionComponent } from '../desinfeccion/desinfeccion.component';
import { FirmacalidadComponent } from '../firmacalidad/firmacalidad.component';
import { IngcalidadComponent } from '../ingcalidad/ingcalidad.component';

@Component({
  selector: 'app-agendaxuser',
  templateUrl: './agendaxuser.component.html',
  styleUrls: ['./agendaxuser.component.scss']
})
export class AgendaxuserComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  NomUsuario: string;

  arrAgenda: NgAgenda[];
  arrTemp: NgAgenda[];
  arrEstados: ListarEstado[];

  ngmDoctor: string;
  ngmPaciente: string;
  ngmcaja: string;
  ngmClinica: string;
  ngmTrabajo: string;

  bsModalRef: BsModalRef;

  tipoaccor: string;

  public arrSelected = [];

  constructor(private _agendaService: AgendaService,
    private modalService: BsModalService,
    public pedidosServices: PedidosService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.NomUsuario = (sessionStorage.getItem('ChrNombreCompleto'));

    this.tipoaccor = "light"

  }

  async ngOnInit() {
    await this.CargarAgendaXUsuario()
  }

  async CargarAgendaXUsuario() {
    try {
      this.Cargando = 'Block';

      const res: NgAgendaRoot = await this._agendaService.getAll(
        {
          idConsulta: '2',
          IdEmpresa: this.IdEmpresa,
          Sucursal: this.IdSucursal,
          IdUsuario: this.IdUsuario,
        })
        
      this.arrAgenda = res.NgAgenda;
      this.arrTemp = [...res.NgAgenda]

      this.Cargando = 'none';

      this.ngmDoctor = "";
      this.ngmPaciente = "";
      this.ngmcaja = "";
      this.ngmTrabajo = "";


    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  onSelectTable({ selected }) {
    this.arrSelected.splice(0, this.arrSelected.length);
    this.arrSelected.push(...selected);
  }

  updateFilterCaja(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Caja.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Odontologo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterTrabajo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Trabajo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  showModalHistorial(dtllPedido: any) {
    this.bsModalRef = this.modalService.show(HistorialComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
    })
  }

  showModalCambio(dtllPedido: any) {

    if (dtllPedido.length != 0) {
      this.bsModalRef = this.modalService.show(CambiodeprocesoComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.arrSelected = [];
        this.CargarAgendaXUsuario();
      })
    } else {
      Swal.fire({
        title: "Informacion",
        text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
        icon: 'info'
      });
    }
  }

  showModalDesinfeccion(dtllPedido: any) {

    if (dtllPedido.length != 0) {
      this.bsModalRef = this.modalService.show(DesinfeccionComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.arrSelected = [];
        this.CargarAgendaXUsuario();
      })
    } else {
      Swal.fire({
        title: "Informacion",
        text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
        icon: 'info'
      });
    }
  }

  showModalInventario(dtllPedido: any) {

    if (dtllPedido.length != 0) {
      this.bsModalRef = this.modalService.show(IngInventarioComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.arrSelected = [];
        this.CargarAgendaXUsuario();
      })
    } else {
      Swal.fire({
        title: "Informacion",
        text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
        icon: 'info'
      });
    }
  }

  async UdptaRealizado(dtllPedido: any) {
    try {

      if (dtllPedido.length != 0) {
        let resp: any

        Swal.fire({
          title: '¿Estas Seguro?',
          text: "¡Vas a marcar todo estos pedidos como realizado, esta accion borrar los pedidos de tu agenda!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Si, Seguro!'
        }).then(async (result) => {
          if (result.isConfirmed) {

            for (let i in dtllPedido) {
              await this.pedidosServices.UpdateDllRealiza({
                iDDtllhistorico: dtllPedido[i].idtblDtllHistorico
              }).then(async (res) => {
                if (res) {
                  resp = 1
                } else {
                  resp = 0
                }
              })
            }

            if (resp == 1) {
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });

              await this.CargarAgendaXUsuario()

            } else {
              Swal.fire({
                title: "No ha realizado ninguna accion..!!",
                text: "¡Por favor comunicarce con el administrador del sistema!",
                icon: 'warning'
              });

              await this.CargarAgendaXUsuario()

            }


          } else {
            Swal.fire({
              title: "No ha realizado ninguna accion..!!",
              icon: 'warning'
            });
          }

        })
      } else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
          icon: 'info'
        });
      }




    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async cambioFecha(row: NgAgenda[]) {
    try {
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);

      if (row.length == 1) {
        Swal.fire({
          title: 'Estas seguro?',
          html: '<p>Estas seguro que deseas reprogramar la fecha entrega de este proceso: ' + row[0].Proceso + ' Paciente:' + row[0].Paciente + ' <b style="font-weight: bold"> <input class="form-control digits" type="datetime-local" id="inputfecha"> Este cambio genera un historial al pedido </b> </p>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmado',
          preConfirm: () => {
            return [
              (document.getElementById('inputfecha') as HTMLInputElement).value
            ]
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

            if (result.value[0] != '') {

              this.Cargando = 'Block';

              await this.pedidosServices.saveHistorialNg({
                procesos: [{
                  VarDescrio: 'Fecha de entrega reprogramada por ' + this.NomUsuario + ', fecha anterior de entrega ' + formatDate(row[0].FechaEntrega, "yyyy-MM-dd HH:mm:ss", "en-CO", "-0500"),
                  IdProcesoForm: -6,
                  IdUsuario: this.IdUsuario,
                  Fk_UsuarioRealiza: row[0].idTblTerceros,
                  FechaEntrada: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                  HoraEntrada: formatDate(result.value[0], "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                  IdDtalle: row[0].idtblDtllPedidos
                }]
              }).then((res) => {
                if (res) {
                  this.pedidosServices.UpdateDllPedido({
                    IdEstado: 2,
                    FechaIngreso: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                    FechaRetiro: formatDate(result.value[0], "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                    IdDtllPedido: row[0].idtblDtllPedidos
                  }).then((repuestapedido) => {
                    if (repuestapedido) {
                      this.Cargando = 'none';
                      Swal.fire({
                        title: "Proceso completado correctamente",
                        icon: 'success',
                        timer: 5000
                      });
                      this.CargarAgendaXUsuario();
                    }
                  })
                }
              }, error => {
                Swal.fire({
                  title: "NO se realizo el proceso comunicate con el administrador",
                  icon: 'error',
                  timer: 3000
                });
              })

              this.Cargando = 'none';

            } else {
              Swal.fire({
                title: "Cuidado...!!",
                html: ' <div class="well well-sm ContenedorGris"><label></label><br /><p> No se puede actualizar sin la fecha de entrega, por favor verificar...!! </p></div>', icon: 'warning'
              });
            }

          }
        })

      } else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion debes seleccionar un solo pedido, por favor verificar!",
          icon: 'info'
        });
      }

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }


  }

  showModalFirmaConfomidad(dtllPedido: any) {

    if (dtllPedido.length != 0) {
      this.bsModalRef = this.modalService.show(FirmacalidadComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.arrSelected = [];
        this.CargarAgendaXUsuario();
      })
    } else {
      Swal.fire({
        title: "Informacion",
        text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
        icon: 'info'
      });
    }
  }

  showModalIngresoCalidad(dtllPedido: any) {

    if (dtllPedido.length != 0) {
      this.bsModalRef = this.modalService.show(IngcalidadComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content
        this.arrSelected = [];
        this.CargarAgendaXUsuario();
      })
    } else {
      Swal.fire({
        title: "Informacion",
        text: "¡Para realizar esta accion al menos debes seleccionar un pedido, por favor verificar!",
        icon: 'info'
      });
    }
  }

  getCellClass({ row, column, value }): any {
    //console.log(row)
    // return {
    //   'checkbox': row.ChrEstados === 'INICIAL',
    // };
  }

}
