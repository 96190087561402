import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListarEmpresasService } from '../../shared/services/listar-empresas.service';
import Swal from 'sweetalert2';
import { ListaEmpresaPreOrdenRoot, ListarEmpresas, ListaTrabajosPreOrdenRoot, ListaTrabajosPreOrden, BusTerceroIDRoot, BusTerceroID } from '../../shared/models/listarEmpresas';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit {

  logo: string;
  nombreEmpresa: string;
  direccion: string
  telefono: string;
  ciudad: string;
  pais: string;
  email: string;
  emailNoti: string;
  Cargando: string;
  IdEmpresa: number;

  constructor(private activateRoute: ActivatedRoute,
    private listarempresasservice: ListarEmpresasService,) {

    this.Cargando = 'none'

  }

  async ngOnInit() {
    try {
      this.activateRoute.params.subscribe(async params => {
        this.IdEmpresa = params.IdEmpresa
        await this.Cargar_Empresa(params.IdEmpresa)
      });

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Empresa(idEmpresa) {
    try {
      this.Cargando = 'Block'
      await this.listarempresasservice.getEmpresaNoToken({
        IdEmpresa: idEmpresa
      }).then((res: ListaEmpresaPreOrdenRoot) => {

        this.logo = res.ListaEmpresaPreOrden[0].ChrImagen
        this.nombreEmpresa = res.ListaEmpresaPreOrden[0].ChrNonEmp
        this.direccion = res.ListaEmpresaPreOrden[0].ChrDirEmp
        this.telefono = res.ListaEmpresaPreOrden[0].ChrNuTele
        this.ciudad = res.ListaEmpresaPreOrden[0].Ciudad
        this.pais = res.ListaEmpresaPreOrden[0].Pais
        this.email = res.ListaEmpresaPreOrden[0].ChrEmailOficial
        this.emailNoti = res.ListaEmpresaPreOrden[0].ChrEmailNoti
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }
}
