<div class="col-sm-12 pt-3">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-sm-3">
                            <input type='text' class="form-control" placeholder='Buscar paciente...!!!'
                                (keyup)='updateFilterPaciente($event)' />
                        </div>
                        <div class="col-md-3 col-sm-3">
                            <input type='text' class="form-control" placeholder='Buscar Doctor...!!!'
                                (keyup)='updateFilterOdontologo($event)' />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body custom-datatable">

                        <ngx-datatable class="material" [rows]="arrPreOrden" [columnMode]="'force'"
                            [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="15"
                            [scrollbarH]="true">

                            <ngx-datatable-column [width]="60" name="No-Orden" prop="numOrden">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="200" name="Doctor" prop="NomDoctor">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Paciente"
                                prop="NomPaciente"></ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Trabajo" prop="ChrNombreTrabajo">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="50" name="Estado" prop="ChrEstados"
                                [cellClass]="getCellClass">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="nomEstado">
                                        <strong>{{row.ChrEstados}}</strong>
                                    </div>

                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="Acciones">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button (click)="UpdateEstado(row)" *ngIf="row.ChrEstados == 'INICIAL'" class="btn btn-danger btn-sm btn-pill"><i
                                                class="fa fa-trash"></i></button>
                                    </div>

                                    <div class="btn-group" role="group">
                                        <button (click)="Download_Files(row)" class="btn btn-success btn-sm btn-pill"><i
                                                class="fa fa-file-text-o"></i></button>
                                    </div>

                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>



    </div>
</div>
<!-- Container-fluid Ends -->