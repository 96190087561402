<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Actualizar Planeacion
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation" novalidate>
                     
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Requerimientos</label>
                                <textarea class="form-control" id="validationCustom03" rows="3"
                                    placeholder="Requerimientos" [formControl]="validationForm.controls['observacion']"
                                    required></textarea>
                                <div *ngIf="validationForm.controls.observacion.touched && validationForm.controls.observacion.errors?.required"
                                    class="text text-danger">
                                    La Requerimientos es requerida
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="validationCustom03">Fecha Entrega</label>
                                <form class="form-inline custom-datepicker custom-datepicker-dropdown">
                                    <div class="form-group mb-0">
                                        <input class="form-control digits" type="datetime-local"
                                            id="example-datetime-local-input"
                                            [formControl]="validationForm.controls['fechaentrega']" required>
                                    </div>
                                    <div *ngIf="validationForm.controls.fechaentrega.touched && validationForm.controls.fechaentrega.errors?.required"
                                        class="text text-danger">
                                        La fecha de entrega es requerida
                                    </div>
                                </form>
                            </div>

                            <ng-template #footerTemplate>
                                <hr class="my-0">
                                <button class="btn btn-primary btn-sm m-2 float-left"
                                    (click)="model = today; f.close()">Today</button>
                                <button class="btn btn-secondary btn-sm m-2 float-right"
                                    (click)="f.close()">Close</button>
                            </ng-template>

                            <div class="col-md-3 mb-3">
                                <label for="validationCustom03">Asignado</label>
                                <form class="was-validated">
                                    <select class="custom-select" required
                                        [formControl]="validationForm.controls['Fk_UsuarioRealiza']">
                                        <option *ngFor="let usuarios of arrUsuarios" value={{usuarios.idTblTerceros}}>
                                            {{usuarios.ChrNombreCompleto}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Debes seleccionar el asginado</div>
                                </form>
                            </div>
                        </div>
                        <button *ngIf="!boton" class="btn btn-primary" [disabled]="!validationForm.valid" type="submit"
                            (click)="guardarTratamiento(validationForm.value)">Actualizar</button>
                        <button *ngIf="boton" class="btn btn-primary btn-lg btn-block" disabled
                            type="submit">Actualizando...!!!</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>