<div class="col-sm-12 pt-3">
  <div class="card">
    <div class="card-header">
      <h6> <b> Lista de las ordenes generadas desde DT.Dental </b></h6>
    </div>
    <div class="card-body default-accordion">
      <ngb-accordion [closeOthers]="true" type={{tipoaccor}} *ngFor="let order of arrNew">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span><b>Orden #: </b> {{order.order_id}} </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span><b>Estado: </b> {{order.status}} </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <b> Doctor: </b> {{order.doctor.doctor_name}}
            &nbsp; &nbsp; &nbsp;
            <b> Tipo Id: </b> {{order.doctor.identification_type}}
            &nbsp; &nbsp; &nbsp;
            <b> Identificacion: </b> {{order.doctor.identification}}
            <br>
            <b> Consultorio: </b> {{order.office.name}}
            &nbsp; &nbsp; &nbsp;
            <b> Identificacion: </b> {{order.office.nit}}
            &nbsp; &nbsp; &nbsp;
            <b> Direccion: </b> {{order.office.address}}
            &nbsp; &nbsp; &nbsp;
            <b> Ciudad: </b> {{order.office.city}}
            <br>
            <b> Descripcion: </b> {{order.description}}
            <br>
            <br>
            <div class="table-responsive">
              <div>
                <b>PACIENTE</b>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Identi</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Direccion</th>
                    <th scope="col">Telefono</th>
                    <th scope="col">Mail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{order.patient.identification_type}}</td>
                    <td>{{order.patient.identification}}</td>
                    <td>{{order.patient.name}}</td>
                    <td>{{order.patient.address}}</td>
                    <td>{{order.patient.mobile}}</td>
                    <td>{{order.patient.email}}</td>
                  </tr>
                </tbody>
              </table>
            <br>
            <br>
            </div>
       <!--      <div class="table-responsive">
              
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Creado</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orderdtll of order.details">
                    <td>
                      <ng-template *ngIf="orderdtll.created_by; then Lab else NotLab"></ng-template>
                      <ng-template #Lab>
                        Laboratorio
                      </ng-template>
                      <ng-template #NotLab>
                        Consultorio
                      </ng-template>
                    </td>
                    <td>{{orderdtll.date}}</td>
                    <td>{{orderdtll.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
            <div>
              <b>OBSERVACIONES</b>
            </div>
            <div class="card-body">
              <div class="timeline-small"  *ngFor="let orderdtll of order.details">
                <br>
                <br>
                <div class="media">
                  <div class="timeline-round m-r-30 timeline-line-1 bg-secondary">
                    <app-feather-icons [icon]="'message-circle'"></app-feather-icons>
                  </div>
                  <div class="media-body">
                    <ng-template *ngIf="orderdtll.created_by; then Lab else NotLab"></ng-template>
                      <ng-template #Lab>
                        <h6>Laboratorio <span class="pull-right f-14"> {{orderdtll.date | date:'full'}} </span></h6>
                      </ng-template>
                      <ng-template #NotLab>
                        <h6>Consultorio <span class="pull-right f-14"> {{orderdtll.date | date:'full'}} </span></h6>
                      </ng-template>
                      <p>{{orderdtll.description}}</p>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  
  <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
    <div class="spinnerContent">
        <div class="spinnerItem">
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
            <div class="line bg-primary"></div>
        </div>
        <div class="MensajeDeEspera">Cargando...!!!</div>
    </div>
</div>


</div>