import { Component, OnInit, ViewChild } from '@angular/core';
import { TercerosService } from '../../../shared/services/terceros.service';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import Swal from 'sweetalert2';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MensajeriaService } from '../../../shared/services/mensajeria.service';
import { ListarDetallePedidoNg, ListarDetallePedidoNgRoot, ListarEstado, ListarEstadoRoot } from 'src/app/shared/models/pedidos';
import { PedidosService } from 'src/app/shared/services/pedidos.service';


@Component({
  selector: 'app-entregas',
  templateUrl: './entregas.component.html',
  styleUrls: ['./entregas.component.scss']
})
export class EntregasComponent implements OnInit {
  @ViewChild('auto') auto;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  openPanel(e): void {
    e.stopPropagation();
    this.auto.open();
  }


  Cargando: string;
  IdEmpresa: any;
  IdSucursal: any;

  arrAsignado: ListarTercero[];
  arrPedidos: ListarDetallePedidoNg[];
  arrTemp: ListarDetallePedidoNg[];

  keywordMensajero: string;
  IdAsginado: number;
  SelectionType = SelectionType;
  operacionSeleccionada: number;

  FormDtll: FormGroup;
  validationForm: FormGroup;

  arrEstados: ListarEstado[];
  public arrSelected = [];
  tipoaccor: string;

  arrDtll = new FormArray([]);

  constructor(private terceroService: TercerosService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _mensajeriaService: MensajeriaService,
    private _PedidoService: PedidosService,) {

    this.Cargando = 'none'
    this.keywordMensajero = 'ChrNombreCompleto';
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.tipoaccor = "light"

    this.arrPedidos = [];
    this.arrTemp = [];

    this.validationForm = this.fb.group({
      IdEmpresa: ([this.IdEmpresa]),
      IdSucursal: ([this.IdSucursal]),
      IdDtllPedido: (['']),
      Mensajero: (['', Validators.required]),
      ActivoMensajeria: (['1']),
      FechaDil: ([formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"), Validators.required]),
      Observaciones: (['']),
      Cumplida: (['0']),
      Tipo: (['Entregas']),
    })

    this.FormDtll = this.fb.group({
      Dtll: this.fb.array([])
    })

  }

  async ngOnInit() {
    try {
      await this.Cargar_Asginado();
      // await this.Cargar_Estados();
      await this.Cargar_Pedidos("3", 0)

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onChangeSearch(val: string) {
  }

  onFocusedAsginado(e) {
  }

  async selectEventAsginado(item) {
    this.IdAsginado = item.idTblTerceros
  }

  async Cargar_Asginado() {
    try {
      this.Cargando = 'Block';
      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrAsignado = resp.ListarTerceros;
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Pedidos(idBusqueda: string, IdEstados: number) {
    try {

      this.Cargando = 'Block'

      await this._PedidoService.searchListarDetallePedidoNg({
        IdBusqueda: idBusqueda,
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        IdEstados: IdEstados
      }
      ).then((res: ListarDetallePedidoNgRoot) => {

        this.arrPedidos = res.Listar_Detalle_Pedido_Ng;
        this.arrTemp = [...res.Listar_Detalle_Pedido_Ng];
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  onSelectTable({ selected }) {
    this.arrSelected.splice(0, this.arrSelected.length);
    this.arrSelected.push(...selected);
  }

  async cancelar() {
    this.bsModalRef.hide()
  }

  async Guardar() {
    try {
      this.Cargando = 'Block';

      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      const fechahoy = formatDate(this.validationForm.value.FechaDil, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")

      for (let item of this.arrSelected) {

        this.validationForm.value.IdEmpresa = this.IdEmpresa,
          this.validationForm.value.IdSucursal = this.IdSucursal,
          this.validationForm.value.IdDtllPedido = item.idtblDtllPedidos,
          this.validationForm.value.Mensajero = this.IdAsginado,
          this.validationForm.value.ActivoMensajeria = '1',
          this.validationForm.value.FechaDil = fechahoy,
          this.validationForm.value.Observaciones = ('Entrega con relacion a pedidos'),
          this.validationForm.value.Cumplida = '0',
          this.validationForm.value.Tipo = 'Entregas'

        this.FormDtll.value.Dtll.push(this.validationForm.value)
        this.validationForm.reset();

      }

      //this.FormDtll.value.Dtll = this.arrDtll;

      await this._mensajeriaService.Insertar_Mensajeria(this.FormDtll.value.Dtll).then((res) => {
        this.Cargando = 'none'
        Swal.fire({
          title: "Proceso completado correctamente",
          icon: 'success'
        });
        this.bsModalRef.hide()
      })

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onSelect({ selected }) {
    try {
      this.validationForm.get("Observaciones").setValue(selected[0].ChrNombreCompleto + " - " + selected[0].ChrDirreccion + " - " + selected[0].ChrTelefono);
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onActivate(event) {
    /*  try {
       this.validationForm.get("Observaciones").setValue(event.row.ChrNombreCompleto + " - " + event.row.ChrDirreccion + " - " + event.row.ChrTelefono);
     } catch (error) {
       Swal.fire({
         title: "Error",
         html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
         icon: 'error'
       });
     } */


  }

  async Cargar_Estados() {
    try {

      this.Cargando = 'Block'

      await this._PedidoService.getEstados().then((res: ListarEstadoRoot) => {
        this.arrEstados = res.Listar_Estados;

      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Odonotologo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterClinica(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Establecimiento.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Pacientes.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterCaja(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.ChrNumeCaja.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPedidos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  // async onChangeEstados($event: any) {
  //   try {

  //     if (($event.target.value == -1 || $event.target.value == 0)) {
  //       await this.Cargar_Pedidos("1", 0)
  //     } else {
  //       this.Cargar_Pedidos("2", $event.target.value)
  //     }
  //     //limpiar filtros

  //   } catch (error) {
  //     this.Cargando = 'none';
  //     Swal.fire({
  //       title: "No se realizo el proceso...!!!",
  //       html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
  //       icon: 'error'
  //     });
  //   }

  // }





}
