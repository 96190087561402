import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ListarDetallePedido, ListarDetallePedidoRoot } from '../../../shared/models/pedidos';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ing-historial',
  templateUrl: './ing-historial.component.html',
  styleUrls: ['./ing-historial.component.scss']
})
export class IngHistorialComponent implements OnInit {

  validationForm: FormGroup;
  formPrecios: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  arrPedidos: ListarDetallePedido[];
  IdEmpresa: string;
  IdSucursal: string;
  boton: any;
  idTrabajo: any;
  idtblTercero: string;
  keyword: string;
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  precio: string;
  marca: string;
  blnplaneacion: boolean;
  Cargando: string;

  constructor(private fb: FormBuilder,
    private terceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService) {

    this.arrIngreso = [];
    this.precio = "";
    this.marca = "";
    this.blnplaneacion = false;

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idtblTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.idTrabajo = 0
    this.keyword = 'Pacientes';
    this.Cargando = 'none';

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.validationForm = this.fb.group({
      IdProceso: ['5645'],
      IdUsuario: [this.idtblTercero],
      VarDescrio: [' ', Validators.required],
      Fk_UsuarioRealiza: ['', Validators.required],
      FechaEntrada: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
      HoraEntrada: ['', Validators.required],
      IdDtalle: [this.idTrabajo.idtblDtllPedidos],
      Preciouni: [[]]
    })

    this.formPrecios = this.fb.group({
      IdPrecio: ['', Validators.required],
      Cantidad: ['', Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(3)],
      Preciouni: ['', Validators.required],
      Nombre: [''],
      Nota: ['', Validators.required],
      idestadoAline: ['5', Validators.required]
    })


  }

  async ngOnInit(): Promise<any> {
    try {
      this.Cargando = 'Block';

      this.boton = false
      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrUsuarios = resp.ListarTerceros;

      const res: ListarDetallePedidoRoot = await this.pedidosServices.getPedidosPacientes(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          ChrNumCaja: "",
          IdBusqueda: 8,
          NombrePaciente: "",
          IdEstados: null,
          Fecha: "2022/04/04",
          IdDtllPedido: 0
        })
      this.arrPedidos = res.Listar_Detalle_Pedido;

      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async selectEvent(item) {
    
    let info = " Marca: " + item.ChrSolpe + "     Material: " + item.ChrMaterialServicio + "\n" + " Canitdad_T: " + item.ChrCantidadPedido + "    Arcada: " + item.ChrDescripcion

    this.idTrabajo = item
    this.marca = info

    const UltIbser: any = await this.pedidosServices.Listar_Ultimo_Historial({
      idtblDtllPedidos: item.idtblDtllPedidos
    })
        
    this.validationForm.get("VarDescrio").setValue(UltIbser.ListarUltHistorial[0].ChrDetllhisto)

    const respu: ListaPrecioAlineadoreRoot = await this.pedidosServices.getPrecioAlinieadores({
      id: item.IdClasAlineadores
    })
    this.arrPrecios = respu.ListaPrecioAlineadores;

  }

  onChangeSearch(val: string) {
  }

  onFocused(e) {
  }

  async guardarHistorial(data: any) {
    this.boton = true

    this.Cargando = 'Block';

    this.validationForm.value.Preciouni = this.arrIngreso
    this.validationForm.value.IdDtalle = this.idTrabajo.idtblDtllPedidos
    this.validationForm.value.HoraEntrada = formatDate(this.validationForm.value.HoraEntrada, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
    
    await this.pedidosServices.saveHistorial(this.validationForm.value).then((res) => {
      
      this.Cargando = 'none';

      Swal.fire({
        title: "Proceso completado correctamente",
        icon: 'success',
        timer: 5000
      });

      
      this.bsModalRef.hide();
    }, error => {
      Swal.fire({title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    })
  }

  aggPrecio(form: FormGroup) {
    if (form.value.IdPrecio != '') {
      const nombre = this.arrPrecios.find(res => res.idtblprecioalinieadores == form.value.IdPrecio)
      form.get("Nombre").setValue(nombre.ChrNombre)
      form.value.IdPrecio = parseInt(form.value.IdPrecio)
      form.value.idestadoAline = 5
      this.arrIngreso.push(form.value)
          
      this.formPrecios.reset()
    }

  }

  onChange($event) {
    this.precio = String(this.arrPrecios[$event.target.value - 1].Valor);
  }

  planeacion($event: any) {
    if ($event.target.checked) {
      this.blnplaneacion = true
    } else {
      this.blnplaneacion = false
    }
  }

}
