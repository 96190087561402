<form [formGroup]="trabajoForm" (ngSubmit)="save(trabajoForm)" class="ngx-form" novalidate>

    <label class="col-form-label pt-0">*Trabajos:</label>
    <div class="ng-autocomplete">
        <ng-autocomplete [data]="arrTrabajos" [searchKeyword]="keywordTrabajo" placeholder="Seleciona Trabajo"
            (selected)='selectEventTrabajo($event)' (inputChanged)='onChangeSearch($event)'
            (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
            formControlName="IdTrabajo" required [isLoading]='isloading'>
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>
    </div>



    <div class="form-group f1-buttons">
        <button class="btn btn-primary btn-next" awNextStep> Next
        </button>
    </div>
</form>