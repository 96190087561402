import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { PedidosService } from '../../../shared/services/pedidos.service';

@Component({
  selector: 'app-desinfeccion',
  templateUrl: './desinfeccion.component.html',
  styleUrls: ['./desinfeccion.component.scss']
})
export class DesinfeccionComponent implements OnInit {

  validationForm: FormGroup;
  datos_get: any;
  Cargando: string;
  boton: any;
  contador: number;

  constructor(private fb: FormBuilder, public options: ModalOptions, public bsModalRef: BsModalRef, public pedidosServices: PedidosService,) {

    this.datos_get = this.options.initialState;
    this.contador = 1;
    for (let i = 0; i < this.datos_get.length; i++) {
      this.datos_get[i].Alguinato = (this.contador = this.contador + 1),
        this.datos_get[i].Silicona = (this.contador = this.contador + 1),
        this.datos_get[i].PastaZinquenolica = (this.contador = this.contador + 1),
        this.datos_get[i].ModeloYeso = (this.contador = this.contador + 1)
    }
    this.validationForm = new FormGroup({
      TiempoExpo: new FormControl(''),
      dtll: this.fb.array([])
    })

    this.buildForm();

    this.Cargando = 'none';
  }

  async buildForm() {
    // const tiempoTranscurrido = Date.now();
    // const hoy = new Date(tiempoTranscurrido);
    const controlArray = this.validationForm.get("dtll") as FormArray;

    Object.keys(this.datos_get).forEach(async (i) => {
      controlArray.push(
        this.fb.group({
          ChrDescripcion: new FormControl(''),
          Iddetallehistorico: new FormControl(this.datos_get[i].idtblDtllHistorico),
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Alguinato: new FormControl(this.datos_get[i].Alguinato),
          Silicona: new FormControl(this.datos_get[i].Silicona),
          PastaZinquenolica: new FormControl(this.datos_get[i].PastaZinquenolica),
          ModeloYeso: new FormControl(this.datos_get[i].ModeloYeso)
        })
      )
    })
  }


  ngOnInit(): void {
    this.boton = false;
  }

  Cancelaringreso() {
    this.bsModalRef.hide();
  }

  async guardarHistorial(body: any) {
    try {
      this.boton = true

        Swal.fire({
          title: '¿Estas Seguro?',
          text: "¡En actualizar el historial de desinfeccion de estos pedidos!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Si, Seguro!'
        }).then(async (result) => {
          if (result.isConfirmed) {

            this.Cargando = 'block';

            await this.pedidosServices.UpdateHistorialDesc(this.validationForm.value).then(res => {
              this.Cargando = 'none';
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });

              this.bsModalRef.hide()


            }).catch(error => {
              this.Cargando = 'none';
              Swal.fire({
                title: "No se realizo el proceso...!!!",
                html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
                icon: 'error'
              });
            })

          } else {
            this.boton = false
            Swal.fire({
              title: "No ha realizado ninguna accion..!!",
              icon: 'warning'
            });
          }
        })
      
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  chkEntradaSalida(event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("ChrDescripcion").setValue("Desinfeccion de " + event.target.value)
    }
  }

  chkTipo(event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[0].get("ChrDescripcion").value

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("ChrDescripcion").setValue(desc + " " + "realizada por " + event.target.value)
    }
  }

  txtdesen(event: any){
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[0].get("ChrDescripcion").value

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("ChrDescripcion").setValue(desc + " " + "con solucion " + event.target.value)
    }
  }
  
  txtconcen(event: any){
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[0].get("ChrDescripcion").value

    for (let i = 0; i < controlArray.length; i++) {
      controlArray.controls[i].get("ChrDescripcion").setValue(desc + " " + "con una concentracion del " + event.target.value)
    }

  }

  chkalguinato(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }

  chkSilicona(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }

  chkPasta(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }

  chkModelo(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }

  chkTrabajo(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }

  txtotros(i: number, event: any) {
    const controlArray = this.validationForm.get('dtll') as FormArray;
    const desc = controlArray.controls[i].get("ChrDescripcion").value
    
    controlArray.controls[i].get("ChrDescripcion").setValue(desc +  event.target.value + " ")
  }


}
