<!-- Container-fluid starts -->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 pt-3">
      <div class="card">
        <div class="card-header">
          <h5>Rutero x Usuario</h5>
        </div>

        <div class="card-body">
          <div class="row">

            <div class="col-md-3 col-sm-3">
              <label class="col-form-label pt-0" for="inputPassword3">Paciente:</label>
              <input class="form-control" placeholder="Busqueda por paciente" (keyup)='updateFilterPaciente($event)'>
            </div>

            <div class="col-md-3 col-sm-3">
              <label class="col-form-label pt-0" for="inputPassword3">Doctor:</label>
              <input class="form-control" placeholder="Buscqueda por doctor" (keyup)='updateFilterOdontologo($event)'>
            </div>

            <div class="col-md-3 col-sm-3">
              <label class="col-form-label pt-0" for="inputPassword3">Clinica:</label>
              <input class="form-control" placeholder="Buscqueda por Clinica" (keyup)='updateFilterClinica($event)'>
            </div>

            <div class="col-md-3 col-sm-3">
              <label class="col-form-label pt-0" for="inputPassword3">Fechas:</label>
              <input type="text" placeholder="Rango de fechas" class="form-control"
                  bsDaterangepicker #dpr="bsDaterangepicker"
                  [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                  (bsValueChange)="valueChange($event)">
          </div>

          </div>
        </div>

        <div class="card-body">
          <div class="list-group">
            <a *ngFor="let ruta of arrRuteroxUsuario"
              class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Fecha: {{ruta.DtmFecha | date: 'mediumDate'}} </h5>
                <button *ngIf="ruta.IdHistorial" (click)="abrirfirma(ruta)" class="btn btn-warning btn-sm btn-pill"><i
                    class="fa fa-pencil-square-o"></i></button>
                <button (click)="UpdateCumplida(ruta)" class="btn btn-success btn-sm btn-pill"><i
                    class="fa fa-check"></i></button>
              </div>
              <p class="mb-1"><b>Observacion:</b> {{ruta.ChrObservaciones}}</p>
              <p class="mb-1"><b>Tipo:</b> {{ruta.ChrNota}}</p>
              <p class="mb-1"><b>Trabajo:</b> {{ruta.ChrNombreTrabajo}}</p>
              <p class="mb-1"><b>Doctor:</b> {{ruta.Doctor}}</p>
              <p class="mb-1"><b>Clinica:</b> {{ruta.Clinica}}</p>
              <p class="mb-1"><b>Sucursal:</b> {{ruta.Sucursal}}</p>
              <p class="mb-1"><b>Paciente:</b> {{ruta.Paciente}}</p>
              <p class="mb-1"><b>Cantidad:</b> {{ruta.ChrCantidadPedido}}</p>

            </a>

          </div>

          <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
              <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
              </div>
              <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends -->