import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import * as feather from 'feather-icons';
import { StatisticsService } from '../../../shared/services/statistics.service';
import { StTrabajosIngresadoRoot, StTrabajosIngresado, StCantIngreActualRoot, StCantIngreActual, StCantIngreAnterior, StCantIngreAnteriorRoot, StCantTrabajosxEstados, StCantTrabajosxEstadosRoot } from '../../../shared/models/static';
import Swal from 'sweetalert2';
import * as moment from 'moment'

declare var require: any
var Knob = require('knob')// browserify require

var primary = localStorage.getItem('primary_color') || '#4466f2';
var secondary = localStorage.getItem('secondary_color') || '#1ea6ec';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;
  arrTrabajos: StTrabajosIngresado[];

  IngActuales: number;
  UniActuales: number
  IngAnteriores: number;
  UniAnteriores: number;

  arrPorTrabajos: StCantTrabajosxEstados[];
  PorIni: number
  PorLab: number;
  PorPP: number;
  PorFan: number;
  PorFac: number;
  PorAn: number;

  totalItems: number;
  page: number;
  previousPage: number;
  showPagination: boolean;

  constructor(private _StatisticsService: StatisticsService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.Cargando = 'none'

    this.PorIni = 0
    this.PorLab = 0
    this.PorPP = 0
    this.PorFan = 0
    this.PorFac = 0
    this.PorAn = 0


  }

  async ngOnInit() {
    await this.Cargar_Pedidos_Ingresados(1, '', '', 10)
    await this.Cargar_Ingresos_Anteriores()
    await this.Cargar_Ingresos_Actuales(1, '', '')
    await this.Cargar_Ingresos_x_Estados(1, '', '')

    var profit = Knob({
      max: this.IngAnteriores,
      value: this.IngActuales,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 270,
      height: 270,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profit').append(profit)

    var profitini = Knob({
      max: this.IngActuales,
      value: this.PorIni,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profitini').append(profitini)

    var profitlab = Knob({
      max: this.IngActuales,
      value: this.PorLab,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profitlab').append(profitlab)

    var profittpp = Knob({
      max: this.IngActuales,
      value: this.PorPP,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profittpp').append(profittpp)

    var profitfan = Knob({
      max: this.IngActuales,
      value: this.PorFan,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profitfan').append(profitfan)

    var profitfac = Knob({
      max: this.IngActuales,
      value: this.PorFac,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profitfac').append(profitfac)

    var profitanu = Knob({
      max: this.IngActuales,
      value: this.PorAn,
      angleOffset: 0,
      className: "review",
      thickness: 0.2,
      width: 56,
      height: 56,
      fgColor: primary,
      bgColor: '#f6f7fb',
      lineCap: 'round'
    })
    document.getElementById('profitanu').append(profitanu)
  }

  async Cargar_Pedidos_Ingresados(idSql: number, dateStar: string, dateEnd: string, Cantidad: number) {
    try {

      this.Cargando = 'Block'

      await this._StatisticsService.StTrabajos_Ingresados({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        idSql: idSql,
        datstar: dateStar,
        dateend: dateEnd
      }
      ).then((res: StTrabajosIngresadoRoot) => {
        this.arrTrabajos = res.StTrabajosIngresados.slice(0, Cantidad)
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Ingresos_Actuales(idSql: number, dateStar: string, dateEnd: string) {
    try {

      this.Cargando = 'Block'

      await this._StatisticsService.StCantIngre_Actual({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        idSql: idSql,
        datstar: dateStar,
        dateend: dateEnd
      }
      ).then((res: StCantIngreActualRoot) => {
        this.IngActuales = res.StCantIngreActual[0].Cantidad_Pedidos;
        this.UniActuales = res.StCantIngreActual[0].Cantidad
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Ingresos_Anteriores() {
    try {

      this.Cargando = 'Block'

      await this._StatisticsService.StCantIngre_Anterior({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
      }
      ).then((res: StCantIngreAnteriorRoot) => {
        this.IngAnteriores = res.StCantIngreAnterior[0].Cantidad_Pedidos_Anterior;
        this.UniAnteriores = res.StCantIngreAnterior[0].Cantidad
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Ingresos_x_Estados(idSql: number, dateStar: string, dateEnd: string) {
    try {

      this.Cargando = 'Block'

      await this._StatisticsService.StCantTrabajos_x_Estados({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        idSql: idSql,
        datstar: dateStar,
        dateend: dateEnd
      }
      ).then((res: StCantTrabajosxEstadosRoot) => {
        this.arrPorTrabajos = res.StCantTrabajosxEstados

        if (this.arrPorTrabajos.find(item => item.idtblEstados === 1) != undefined) {
          this.PorIni = this.arrPorTrabajos.find(item => item.idtblEstados === 1).Cantidad_Pedidos
        }
        if (this.arrPorTrabajos.find(item => item.idtblEstados === 2) != undefined) {
          this.PorLab = this.arrPorTrabajos.find(item => item.idtblEstados === 2).Cantidad_Pedidos
        }
        if (this.arrPorTrabajos.find(item => item.idtblEstados === 3) != undefined) {
          this.PorPP = this.arrPorTrabajos.find(item => item.idtblEstados === 3).Cantidad_Pedidos
        }
        if (this.arrPorTrabajos.find(item => item.idtblEstados === 4) != undefined) {
          this.PorFan = this.arrPorTrabajos.find(item => item.idtblEstados === 4).Cantidad_Pedidos
        }
        if (this.arrPorTrabajos.find(item => item.idtblEstados === 5) != undefined) {
          this.PorFac = this.arrPorTrabajos.find(item => item.idtblEstados === 5).Cantidad_Pedidos
        }
        if (this.arrPorTrabajos.find(item => item.idtblEstados === 6) != undefined) {
          this.PorAn = this.arrPorTrabajos.find(item => item.idtblEstados === 6).Cantidad_Pedidos
        }

        // this.PorLab = Number(((this.PorLab/this.IngActuales)*100).toFixed(0))
        // this.PorPP = Number(((this.PorPP/this.IngActuales)*100).toFixed(0))
        // this.PorFac = Number(((this.PorFac/this.IngActuales)*100).toFixed(0))
        // this.PorAn = Number(((this.PorAn/this.IngActuales)*100).toFixed(0))
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async valueChange($event) {
    try {

      if ($event != null) {

        let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
        let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))

        await this.Cargar_Pedidos_Ingresados(2, datstar, dateend, 100)

      } else {
        await this.Cargar_Pedidos_Ingresados(1, '', '', 10)
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }



  }

}
