import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderRoot, Order } from 'src/app/shared/models/dtDental';
import { DtdentalServices } from '../../../shared/services/dtdental.service';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';

defineLocale('es', esLocale);

@Component({
  selector: 'app-ordenesdt',
  templateUrl: './ordenesdt.component.html',
  styleUrls: ['./ordenesdt.component.scss']
})
export class OrdenesdtComponent implements OnInit {

  tipoaccor: string
  arrOrdenes: Order[];
  arrNew: any[];
  IdEmpresa: number;
  Cargando: string;

  constructor(
    private _DtDentalService: DtdentalServices,
    private toastaService: ToastrService) {

      this.IdEmpresa = parseInt(sessionStorage.getItem('IdEmpresa'));
  }

  async ngOnInit() {
    this.tipoaccor="light"

    try {

      this.Cargando = "Block"
      await this._DtDentalService.login().then((resp) => {
        sessionStorage.setItem('tokendt', resp.headers.get('access-token'));
        sessionStorage.setItem('clientdt', resp.headers.get('client'));
      })

      await this._DtDentalService.getAllOrder().then((response: OrderRoot) => {
        this.arrOrdenes = response.orders;
        this.arrNew = this.arrOrdenes.filter(callback => callback.laboratory_id == (this.IdEmpresa)) 

        this.Cargando = "none"
      })

    } catch (error) {
      this.Cargando = "none"
      this.toastaService.warning(error, 'Lo sentimos', { timeOut: 3000 });
    }
  }

}
