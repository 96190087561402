import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { ListaPrecioAlineadoreRoot, ListaPrecioAlineadore, PrecioIngresos } from '../../../shared/models/pedidos';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-updateplaneacion',
  templateUrl: './updateplaneacion.component.html',
  styleUrls: ['./updateplaneacion.component.scss']
})
export class UpdateplaneacionComponent implements OnInit {

  validationForm: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  IdEmpresa: string;
  IdSucursal: string;
  boton: any;
  idTrabajo: any;
  idtblTercero: string;
  arrPrecios: ListaPrecioAlineadore[];
  arrIngreso: PrecioIngresos[];
  precio: string;

  constructor(private fb: FormBuilder,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idtblTercero = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.idTrabajo = this.options.initialState;

    this.arrIngreso = [];
    this.precio = "";

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    
    this.validationForm = this.fb.group({
      observacion: [' ', Validators.required],
      Fk_UsuarioRealiza: ['', Validators.required],
      fechaentrega: ['', Validators.required],
      IdDtalle: [this.idTrabajo.idtblDtllPedidos],
      idtblDtllHistorico: [this.idTrabajo.idtblDtllHistorico],
    })
    
  }

  async ngOnInit() {
    try {
      this.boton = false;
      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrUsuarios = resp.ListarTerceros;

      this.validationForm.get("observacion").setValue(this.idTrabajo.Requerimiento)
      this.validationForm.get("Fk_UsuarioRealiza").setValue(this.idTrabajo.idTblTerceros)
      
      let fecha = formatDate(this.idTrabajo.Fecha_Entrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
      this.validationForm.get("fechaentrega").setValue(fecha)
      
      this.validationForm.get("IdDtalle").setValue(this.idTrabajo.idtblDtllPedidos)
      this.validationForm.get("idtblDtllHistorico").setValue(this.idTrabajo.idtblDtllHistorico)


    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }
  }

  async guardarTratamiento(datos: any) {
    this.validationForm.value.fechaentrega = formatDate(this.validationForm.value.fechaentrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")
    this.boton = true
    await this.pedidosServices.UpdateNgPlaneacion(this.validationForm.value).then((res) => {
      if (res) {
        this.toastaService.success('Proceso completado correctamente', 'Mensaje', { timeOut: 3000 });
        this.bsModalRef.hide();
      }
    }, error => {
      console.log(error);

      this.toastaService.warning(error.error.error.sqlMessage, 'Lo sentimos', { timeOut: 3000 });
      this.bsModalRef.hide();
    })

  }

}

