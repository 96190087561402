<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Hoja de Ejecucion</h5>
                    <form class="form-inline theme-form mt-3">
                        <div class="form-group">
                            <input type="text" placeholder="Rango de fechas" class="form-control" bsDaterangepicker
                                #dpr="bsDaterangepicker"
                                [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                                (bsValueChange)="valueChange($event)">

                        </div>
                        <div class="form-group">
                            <div class="checkbox checkbox-success">
                                <input id="checkbox-primary" type="checkbox" (change)="cargartodos($event)">
                                <label for="checkbox-primary">Cargar Todos</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control" placeholder='Paciente...!!!'
                                (keyup)='updateFilterpaciente($event)' />
                        </div>

                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control" placeholder='Doctor...!!!'
                                (keyup)='updateFilterdoctor($event)' />
                        </div>

                        <div class="form-group">
                            <button class="semaforo"
                            (click)="updateFilterBotones('En Plancha')"  [ngClass]="{ semaforo: true, redondo: true, plancha: (true)}"></button>
                            <b><label> En Plancha</label></b>
                            &nbsp;
                            &nbsp;
                            <button class="semaforo" (click)="updateFilterBotones('Impreso')"  [ngClass]="{ semaforo: true, redondo: true, impreso: (true)}">
                            </button>
                            <b><label> Impreso</label></b>
                            &nbsp;
                            &nbsp;
                            <button class="semaforo" (click)="updateFilterBotones('Finalizado')"  [ngClass]="{ semaforo: true, redondo: true, finalizado: (true)}">
                            </button>
                            <b><label> Finalizado</label></b>
                            &nbsp;
                            &nbsp;
                            <button class="semaforo" (click)="updateFilterBotones('Despachado')" [ngClass]="{ semaforo: true, redondo: true, despachado: (true)}">
                            </button>
                            <b><label> Despachado</label></b>

                            &nbsp;
                            &nbsp;
                            <button class="semaforo" (click)="updateFilterBotones('En Produccion')" [ngClass]="{ semaforo: true, redondo: true, produccion: (true)}">
                            </button>
                            <b><label> En Produccion</label></b>

                        </div>

                        <div class="form-group">
                            <a csvLink [data]="arrEjecucion">Export a Excel</a>
                        </div>
                        
                    </form>

                </div>
                <div class="card-body custom-datatable">
                    <ngx-datatable class="bootstrap" [rows]="arrEjecucion" [columnMode]="'force'"
                        [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="15"
                        [scrollbarH]="true">
                        <ngx-datatable-column name="Entrega" [width]="100" prop="Fecha_Entrega">   
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Clinica" prop="Clinica"></ngx-datatable-column>
                        <ngx-datatable-column name="Odontologo" prop="Doctor"></ngx-datatable-column>
                        <ngx-datatable-column name="Paciente" [width]="200" prop="Paciente" [cellClass]="getCellClass">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Tipo" [width]="145" prop="Material"></ngx-datatable-column>
                        <ngx-datatable-column name="Observaciones" [width]="300" prop="Observaciones" [cellClass]="getCellClassOb">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Descripcion" [width]="110" prop="Descripcion">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Cant_E" [width]="60" prop="Cantidad"></ngx-datatable-column>
                        <ngx-datatable-column name="Responsable" [width]="180" prop="Responsable">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Estados" [width]="120" prop="Estado" [cellClass]="getCellClass">
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="300" name="Acciones">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group" role="group">
                                    <button (click)="onclickPlancha(row)" *ngIf="row.Estado != 'Facturado' " class="btn btn-secondary btn-sm btn-pill"
                                        type="button" placement="top"><i class="icofont icofont-imac"></i></button>
                                </div>
                                <div class="btn-group" role="group">
                                    <button (click)="onclickImpreso(row)" *ngIf="row.Estado != 'Facturado' " class="btn btn-primary btn-sm btn-pill"
                                        type="button" placement="top"><i class="fa fa-check"></i></button>
                                </div>

                                <div class="btn-group" role="group">
                                    <button (click)="onclickFinalizado(row)" *ngIf="row.Estado != 'Facturado' " class="btn btn-danger btn-sm btn-pill"
                                        type="button" placement="top"><i class="fa fa-hand-peace-o"></i></button>
                                </div>
                                <div class="btn-group" role="group">
                                    <button (click)="onclickDespachado(row, index)"  *ngIf="row.Estado != 'Facturado' "class="btn btn-pill btn-success active"
                                        type="button" placement="top"><i
                                            class="icofont icofont-space-shuttle"></i></button>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>


                    <div class="selected-column">
                        
                        <label >Total a entregar:  {{totalcantidade}} </label>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>

</div>
<!-- Container-fluid Ends -->