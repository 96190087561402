<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                   <h5>Proceso de Planeacion de Alineadores</h5>
                   <form class="form-inline theme-form mt-3">
                    <div class="form-group">
                        <input type="text" placeholder="Fechas de retiro" class="form-control" bsDaterangepicker
                            #dpr="bsDaterangepicker"
                            [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                            (bsValueChange)="valueChange($event)">
                    </div>

                    <div class="form-group">
                        <input type="text" placeholder="Fechas de ingreso" class="form-control" bsDaterangepicker
                            #dpr="bsDaterangepicker"
                            [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                            (bsValueChange)="valueChangeIngreso($event)">

                    </div>

                    <div class="form-group">
                        <div class="checkbox checkbox-success">
                            <input id="checkbox-primary" type="checkbox" (change)="cargartodos($event)">
                            <label for="checkbox-primary">Cargar Todos</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type='text' class="filter-ngx form-control"
                            placeholder='Paciente...!!!' (keyup)='updateFilterpaciente($event)' />
                    </div>
                    <div class="form-group">
                        <input type='text' class="filter-ngx form-control"
                            placeholder='Doctor...!!!' (keyup)='updateFilterdoctor($event)' />
                    </div>
                    <div class="form-group">
                        <button class="semaforo"
                        (click)="updateFilterBotones('Entregado')" [ngClass]="{ semaforo: true, redondo: true, enviado: (true)}"></button>
                        <b><label> Entregado</label></b>
                        &nbsp;
                        &nbsp;
                        <button class="semaforo" (click)="updateFilterBotones('Anulado')" [ngClass]="{ semaforo: true, redondo: true, anulado: (true)}">
                        </button>
                        <b><label> Anulado</label></b>
                        &nbsp;
                        &nbsp;
                        <button class="semaforo" (click)="updateFilterBotones('Cancelado')" [ngClass]="{ semaforo: true, redondo: true, finalizado: (true)}">
                        </button>
                        <b><label> Cancelado</label></b>
                        &nbsp;
                        &nbsp;
                        <button class="semaforo" [ngClass]="{ semaforo: true, redondo: true, despachado: (true)}">
                        </button>
                        <b><label> Aprobado</label></b>

                        &nbsp;
                        &nbsp;
                        <button class="semaforo" (click)="updateFilterBotones('Pendiente')" [ngClass]="{ semaforo: true, redondo: true, pendiente: (true)}">
                        </button>
                        <b><label> Pendiente</label></b>

                    </div>

                    <div class="form-group">
                        <a csvLink [data]="arrAgenda">Export a Excel</a>
                    </div>

                </form>
                </div>
                <div class="card-body custom-datatable">
                    <ngx-datatable class="bootstrap" [rows]="arrAgenda" [columnMode]="'force'" [headerHeight]="'auto'"
                        [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="10" [selected]="selected"
                        [selectionType]="SelectionType.single"
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)">
                        <ngx-datatable-column  name="Clinica" prop="Clinica"></ngx-datatable-column>
                        <ngx-datatable-column  name="Odontologo" prop="Doctor"></ngx-datatable-column>
                        <ngx-datatable-column  name="Paciente" prop="Paciente"></ngx-datatable-column>
                        <ngx-datatable-column [width]="90" name="Ingreso" prop="Fecha_Ingreso">
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="90" name="Entrega" prop="Fecha_Entrega">
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Requerimiento" prop="Requerimiento" [cellClass]="getCellClassObs" > 
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Dias" prop="Dias_Faltantes"></ngx-datatable-column> -->
                        <ngx-datatable-column name="Responsable" prop="Responsable"></ngx-datatable-column>
                        <ngx-datatable-column [width]="110" name="Estados" prop="Estado" [cellClass]="getCellClass"></ngx-datatable-column>
                        <ngx-datatable-column [width]="110" name="Tiempos" prop="Tiempos">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div
                                    [ngClass]="{naranja: (row.Tiempos === 'Por Vencer'), verde: (row.Tiempos === 'Con Tiempo'), rojo: (row.Tiempos === 'Vencido')}">
                                    {{row.Tiempos}}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="200" name="Acciones">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                
                                    <div class="btn-group"  role="group">
                                        <button *ngIf="row.Estado!='En Produccion'" (click)="showModal(row)" class="btn btn-success btn-sm btn-pill">
                                            <i class="fa fa-hand-peace-o"></i></button>
                                    </div>
                                    
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.Estado!='En Produccion'" (click)="UpdatePrecioEntregado(row)" class="btnentregado"><i
                                            class="icofont icofont-ui-check"></i></button>
                                    </div>

                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.Estado!='En Produccion'" (click)="UpdatePrecioCance(row)" class="btn btn-danger btn-sm btn-pill"><i
                                            class="icofont icofont-list"></i></button>
                                    </div>
                                    
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.Estado!='En Produccion'" (click)="UpdatePrecioAnu(row)" class="btnanulado"><i
                                            class="fa fa-trash"></i></button>
                                    </div>

                                
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                    <div class="selected-column">
                        <h4>Selecionado</h4>
                        <ul>
                          <li *ngFor="let sel of selected">
                            {{ sel.Paciente }}
                          </li>
                          <li *ngIf="!selected.length">No Selecionado</li>
                        </ul>
                    </div>
                    
                </div>

                <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                    <div class="spinnerContent">
                      <div class="spinnerItem">
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                      </div>
                      <div class="MensajeDeEspera">Cargando...!!!</div>
                    </div>
                  </div>
                  
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->