<!-- Container-fluid starts -->
<form [formGroup]="validationForm">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 ">
                <div class="card">
                    <div class="card-header">
                        <h5>Proceso de Firma para los pedidos de conformidad
                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </h5>
                    </div>


                    <div [formArrayName]="'procesos'">
                        <div class="card-body"
                            *ngFor="let item of validationForm.get('procesos')['controls'];  let i = index;"
                            [formGroupName]="i">
                            <hr width=auto>
                            <div class="row">
                                <label class="col-form-label pt-0"> <b>Caja:</b>  {{validationForm.get('procesos')['controls'][i].value.Caja}}</label> 
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <label class="col-form-label pt-0"> <b>Odontologo:</b>  {{validationForm.get('procesos')['controls'][i].value.Odontologo}}</label>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <label class="col-form-label pt-0"> <b>Paciente:</b>  {{validationForm.get('procesos')['controls'][i].value.Paciente}}</label>
                            </div>

                            <div class="row">
                                <div class="col-md-3 mb-3">
                                    <label class="col-form-label pt-0">*Asginado</label>
                                    <select class="form-control digits" formControlName="Fk_UsuarioRealiza">
                                        <option *ngFor="let usuarios of arrUsuarios" value={{usuarios.idTblTerceros}}>
                                            {{usuarios.ChrNombreCompleto}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Debes seleccionar el asginado</div>
                                </div>

                                <div class="col-md-3 mb-3">
                                    <label class="col-form-label pt-0">*Proceso</label>
                                    <select class="form-control digits" (change)="cambioproceso(i, $event)" required>
                                        <option value={{0}}>
                                            {{'-'}}
                                        </option>
                                        <option
                                            *ngFor="let procesos of validationForm.get('procesos')['controls'][i].value.IdProceso"
                                            value={{[procesos.idTblProcesos]}}>
                                            {{procesos.ChrNombreProceso}}
                                        </option>
                                    </select>
                                    <div *ngIf="item.controls.IdProceso.touched && item.controls.IdProceso.errors?.required"
                                        class="text text-danger">
                                        Debes seleccionar el proceso
                                    </div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label class="col-form-label pt-0">*Observaciones</label>
                                    <textarea class="form-control" rows="3" placeholder="Observaciones"
                                        formControlName="VarDescrio" required></textarea>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="card-body">
                        <div class="row">
                            <div class="btn-group">
                                <button *ngIf="!boton" class="btn btn-primary" [disabled]="!validationForm.valid"
                                    type="submit" (click)="guardarHistorial(validationForm.value)">Guardar</button>
                                <button *ngIf="boton" class="btn btn-primary" disabled
                                    type="submit">Guardando...!!!</button>
                                &nbsp;

                                <button class="btn btn-warning" type="button"
                                    (click)="Cancelaringreso()">Cancelar</button>
                            </div>

                        </div>
                    </div>


                    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                        <div class="spinnerContent">
                            <div class="spinnerItem">
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                            </div>
                            <div class="MensajeDeEspera">Cargando...!!!</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</form>