<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Ingreso de Entregas</h5>

                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Mensajero:</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete #auto [data]="arrAsignado" [searchKeyword]="keywordMensajero"
                                            placeholder="Buscar Mensajero" (selected)='selectEventAsginado($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocusedAsginado($event)' [itemTemplate]="itemMensajero"
                                            [notFoundTemplate]="notFoundTemplate"
                                            [formControl]="validationForm.controls['Mensajero']" required>
                                        </ng-autocomplete>

                                        <ng-template #itemMensajero let-item>
                                            <a [innerHTML]="item.ChrNombreCompleto"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-3">
                                    <label class="col-form-label pt-0">*Fecha:</label>
                                    <input class="form-control digits" type="datetime-local"
                                        id="example-datetime-local-input"
                                        [formControl]="validationForm.controls['FechaDil']" rdequired>
                                </div>
                            </div>

                           <!--  <div class="row">
                                <div class="col-md-12 col-sm-3">
                                    <label class="col-form-label pt-0">*Observaciones</label>
                                    <textarea class="form-control" id="validationCustom03" rows="2"
                                        placeholder="Todas las inidicaciones para la recogida"
                                        [formControl]="validationForm.controls['Observaciones']" required></textarea>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <ngb-accordion [closeOthers]="true" type={{tipoaccor}}>
                                    <ngb-panel>
                                        <ng-template ngbPanelTitle>
                                            <span><b>Busqueda Avanzada</b></span>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="card-body">
                                                <div class="row">
                                                   
                                                    <div class="col-md-4 col-sm-3">
                                                        <label class="col-form-label pt-0" for="inputPassword3">Paciente:</label>
                                                        <input class="form-control" placeholder="Busqueda por paciente"
                                                            (keyup)='updateFilterPaciente($event)'>
                                                    </div>
                        
                                                    <div class="col-md-4 col-sm-3">
                                                        <label class="col-form-label pt-0" for="inputPassword3">Doctor:</label>
                                                        <input class="form-control" placeholder="Buscqueda por doctor"
                                                            (keyup)='updateFilterOdontologo($event)'>
                                                    </div>
                        
                                                    <div class="col-md-4 col-sm-3">
                                                        <label class="col-form-label pt-0" for="inputPassword3">Clinica:</label>
                                                        <input class="form-control" placeholder="Buscqueda por Clinica"
                                                            (keyup)='updateFilterClinica($event)'>
                                                    </div>

                                                    <!-- <div class="col-md-4 col-sm-3">
                                                        <label class="col-form-label pt-0" for="inputPassword3">Estados:</label>
                        
                                                        <select class="form-control digits" (change)="onChangeEstados($event)">
                                                            <option value={{-1}}>
                                                                {{'-'}}
                                                            </option>
                                                            <option value={{0}}>
                                                                {{'ACTUALES'}}
                                                            </option>
                                                            <option *ngFor="let estados of arrEstados" value={{estados.idtblEstados}}>
                                                                {{estados.ChrEstados}}
                                                            </option>
                                                        </select>
                        
                                                    </div> -->
                        
                        
                        
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>

                            <div class="row">
                                <div class="card-body custom-datatable">
                                    <ngx-datatable class="bootstrap" [rows]="arrPedidos" [columnMode]="'force'"
                                        [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'"
                                        [limit]="5" [scrollbarH]="true" [selected]="arrSelected"
                                        [selectionType]="'checkbox'" [selectAllRowsOnPage]="false"
                                        (select)='onSelectTable($event)'>

                                        <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                            [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                                            [checkboxable]="true">
                                        </ngx-datatable-column>
                                        
                                        <ngx-datatable-column [width]="120" name="Odontologo" prop="Odonotologo">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="150" name="Pacientes" prop="Pacientes">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="160" name="Establecimiento"
                                            prop="Establecimiento">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="100" name="Sucursal" prop="chrNombre">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="150" name="Trabajo" prop="ChrNombreTrabajo">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="130" name="Estado" prop="ChrEstados">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [width]="40" name="Cantidad" prop="ChrCantidadPedido">
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="card-body btn-showcase">
                                <button class="btn btn-success" type="button" placement="top" ngbTooltip="Guardar"
                                    [disabled]="(!validationForm.valid)" (click)="Guardar()">Guardar</button>
                                <button class="btn btn-danger" type="button" placement="top" ngbTooltip="Cancelar"
                                    (click)="cancelar()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>

        </div>
    </div>