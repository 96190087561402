import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-facturacion',
  templateUrl: './fr-facturacion.component.html',
  styleUrls: ['./fr-facturacion.component.scss']
})
export class FrFacturacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    return true;
  }
  

}
