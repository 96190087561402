<div class="col-sm-12 pt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="row">
            <div class="card-body custom-datatable">
              <div class="form-group">
                <input type='text' class="filter-ngx form-control" placeholder='Buscar numero de caja...!!!'
                  (keyup)='updateFilternumero($event)' />
              </div>

              <ngx-datatable class="bootstrap" [rows]="arrCajas" [columnMode]="'flex'" [headerHeight]="50"
                [footerHeight]="50" [rowHeight]="40" [limit]="10" [scrollbarH]="true">
                <ngx-datatable-column [width]="20" name="Numero" prop="ChrNumeCaja"></ngx-datatable-column>
                <ngx-datatable-column [width]="10" name="¿Activa?">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <label class="toggle">
                      <input class="toggle-checkbox" (click)="updatecaja(row)" type="checkbox"
                        [checked]="row.BlnCajaOcua">
                      <div class="toggle-switch"></div>
                      <span class="toggle-label"></span>
                    </label>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>

            </div>
          </div>
        </div>
        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
          <div class="spinnerContent">
            <div class="spinnerItem">
              <div class="line bg-primary"></div>
              <div class="line bg-primary"></div>
              <div class="line bg-primary"></div>
              <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends -->