<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="card-body btn-showcase">
                                    <button class="btn btn-primary" type="button" placement="top"
                                        ngbTooltip="Agregar Recogidas" (click)="abrirRecogida($event)">Agregar
                                        Recogidas</button>
                                    <button class="btn btn-secondary" type="button" placement="top"
                                        ngbTooltip="Agregar Entregas" (click)="abrirEntregas($event)">Agregar
                                        Entregas</button>

                                    <button class="btn btn-success" (click)="exportExcel()">
                                        Exportar Excel</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="card-body default-accordion">
                                    <ngb-accordion [closeOthers]="true" type={{tipoaccor}}>
                                        <ngb-panel>
                                            <ng-template ngbPanelTitle>
                                                <span><b>Busqueda Avanzada</b></span>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-3 col-sm-3">
                                                            <input type="text" placeholder="Rango de fechas"
                                                                class="form-control" bsDaterangepicker
                                                                #dpr="bsDaterangepicker"
                                                                [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
                                                                (bsValueChange)="valueChange($event)">
                                                        </div>
                                                        <div class="col">
                                                            <div
                                                                class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                                <div class="radio radio-primary">
                                                                    <input type="radio" name="radio1" id="radioinline1"
                                                                        value="1" (change)="CargarCumplidas($event)">
                                                                    <label for="radioinline1" class="mb-0">Todas</label>
                                                                </div>
                                                                <div class="radio radio-primary">
                                                                    <input type="radio" name="radio1" id="radioinline2"
                                                                        value="2" (change)="CargarCumplidas($event)">
                                                                    <label for="radioinline2"
                                                                        class="mb-0">Cumplidas</label>
                                                                </div>
                                                                <div class="radio radio-primary">
                                                                    <input type="radio" name="radio1" id="radioinline3"
                                                                        value="3" (change)="CargarCumplidas($event)">
                                                                    <label for="radioinline3" class="mb-0">Por
                                                                        Cumplir</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <input type='text' class="form-control" placeholder='Filtrar Mensajero'
                                        (keyup)='updateFilterMensajero($event)' />
                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <input type='text' class="form-control" placeholder='Filtrar Doctor'
                                        (keyup)='updateFilterDoctor($event)' />
                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <input type='text' class="form-control" placeholder='Filtrar Clinica'
                                        (keyup)='updateFilterClinica($event)' />
                                </div>

                                <div class="col-md-3 col-sm-3">
                                    <input type='text' class="form-control" placeholder='Filtrar Paciente'
                                        (keyup)='updateFilterPaciente($event)' />
                                </div>
                            </div>
                            <div class="row">
                                <div class="card-body custom-datatable">
                                    <ngx-datatable id="season-tble" class="bootstrap" [rows]="arrMensajeria"
                                        [columnMode]="'force'" [headerHeight]="'auto'" [footerHeight]="'auto'"
                                        [rowHeight]="'auto'" [limit]="10" [scrollbarH]="true">

                                        <ngx-datatable-column name="Mensajero" prop="Mensajero">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Fecha" prop="DtmFecha">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.DtmFecha | date: 'mediumDate'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Descripcion" prop="ChrObservaciones">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Tipo" prop="ChrNota">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Doctor" prop="Doctor">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Clinica" prop="Clinica">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Sucursal" prop="Sucursal">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Trabajo" prop="ChrNombreTrabajo">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Paciente" prop="Paciente">
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="¿Acciones?" prop="BlnCumplida">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <div class="btn-group" role="group">
                                                    <button *ngIf="row.BlnCumplida" class="btnenviadoacuse">
                                                        <i class="fa fa-check"></i></button>
                                                    <button *ngIf="!row.BlnCumplida" class="btnnoenviadoacuse">
                                                        <i class="fa fa-ban"></i></button>
                                                    <button *ngIf="!row.BlnCumplida" (click)="borrarDili(row)"
                                                        class="btnnoenviadoacuse">
                                                        <i class="fa fa-trash"></i></button>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>

                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>

        </div>
    </div>