import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { ToastrService } from 'ngx-toastr';
import { TercerosService } from '../../../shared/services/terceros.service';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ListarProcesoRoot, ListarProceso } from '../../../shared/models/pedidos'
import { formatDate } from '@angular/common';
import { from, iif } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cambiodeproceso',
  templateUrl: './cambiodeproceso.component.html',
  styleUrls: ['./cambiodeproceso.component.scss']
})
export class CambiodeprocesoComponent implements OnInit {

  validationForm: FormGroup;
  modelFooter: NgbDateStruct;
  arrUsuarios: ListarTercero[];
  arrProcesos: ListarProceso[];
  IdEmpresa: string;
  IdSucursal: string;
  idUsuario: string;
  boton: any;
  Cargando: string;
  datos_get: any;
  NombreUsuario: string;
  allRepues: number; 

  Descrip: string

  Odontologo: string;
  Paciente: string;
  Trabajo: string;

  constructor(private fb: FormBuilder,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    public pedidosServices: PedidosService,
  ) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.idUsuario = JSON.parse(sessionStorage.getItem('idUsuario'));
    this.NombreUsuario = (sessionStorage.getItem('ChrNombreCompleto'));
    this.datos_get = this.options.initialState;

    /*   this.validationForm = this.fb.group({
        VarDescrio: ['Cambio de proceso..!!!', Validators.required],
        IdProceso: ['', Validators.required],
        IdUsuario: [this.idUsuario],
        Fk_UsuarioRealiza: ['', Validators.required],
        FechaEntrada: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
        //HoraEntrada: [formatDate(this.datos_get.FechaEntrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
        //IdDtalle: [this.datos_get.idtblDtllPedidos]
        HoraEntrada: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")],
        IdDtalle: [1]
  
      }) */

    this.validationForm = new FormGroup({
      procesos: this.fb.array([])
    })

    this.buildForm();

    this.Cargando = 'none';
  }

  async buildForm() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const controlArray = this.validationForm.get("procesos") as FormArray;

    Object.keys(this.datos_get).forEach(async (i) => {
      controlArray.push(
        this.fb.group({
          VarDescrio: new FormControl('Cambio de proceso, Completado: ' + this.datos_get[i].Proceso),
          IdProceso: new FormControl(await this.CargarProcesos(this.datos_get[i].Fk_TipoTrabajo)),
          IdProcesoForm: new FormControl('', Validators.required),
          IdUsuario: new FormControl(this.idUsuario),
          Fk_UsuarioRealiza: new FormControl('', Validators.required),
          FechaEntrada: new FormControl(formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
          HoraEntrada: new FormControl(formatDate(this.datos_get[i].FechaEntrega, "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
          IdDtalle: new FormControl(this.datos_get[i].idtblDtllPedidos),
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Proceso: new FormControl(this.datos_get[i].Proceso)
        })
      )
    })

    //await this.CargarProcesos(
    //console.log(controlArray.controls)
  }

  async ngOnInit() {
    this.boton = false;
    await this.CargarAsignados();
  }

  async guardarHistorial(datos: any) {
    this.Cargando = 'Block';
    this.boton = true

    console.log(datos)

    await this.pedidosServices.saveHistorialNg(this.validationForm.value).then(async (res) => {
      if (res) {
        const result = await this.actualizarAnteriorHistorial()
        if (result >= 0) {
          this.Cargando = 'none';
          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });
          this.bsModalRef.hide();
        }

      }
    }, error => {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    })

  }

  async actualizarAnteriorHistorial() {

    let cont = 0;

    this.datos_get.forEach(async (i) => {
      await this.pedidosServices.UpdateDllRealiza({ iDDtllhistorico: i.idtblDtllHistorico }).then((repuesta) => {
        if (repuesta) {
          cont = cont + 1
        }
      }, error => {
        this.Cargando = 'none';
        Swal.fire({
          title: "No se realizo el proceso...!!!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
          icon: 'error'
        });
      })
    })

    return cont;

  }

  cambioproceso(i: any, $event: any) {
    const idTrabajoSele = (parseInt($event.target.value));
    const controlArray = this.validationForm.get('procesos') as FormArray;
    const nombre = controlArray.value[i].IdProceso.find(res => res.idTblProcesos === idTrabajoSele)
    this.Descrip = ("Cambio de proceso, Completado: " + this.datos_get[i].Proceso + ". Por Realizar: " + nombre.ChrNombreProceso + ". Este cambio de proceso lo realizó el usuario: " + this.NombreUsuario)
    controlArray.controls[i].get("IdProcesoForm").setValue(idTrabajoSele)
    controlArray.controls[i].get("VarDescrio").setValue(this.Descrip)
  }

  Cancelaringreso() {
    this.bsModalRef.hide();
  }

  async CargarAsignados() {
    try {
      this.Cargando = 'Block';

      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrUsuarios = resp.ListarTerceros;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async CargarProcesos(i: any) {
    try {

      // const controlArray = this.validationForm.get('procesos') as FormArray;
      // const IdTrabajo = controlArray.value[i].IdProceso

      const IdTrabajo = i

      this.Cargando = 'Block';
      const respPro: ListarProcesoRoot = await this.pedidosServices.getAllProcesos(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdTrabajos: IdTrabajo,
          IdBusqueda: "1",
          NombreTrabajo: "",
        })

      this.Cargando = 'none';
      return respPro.ListarProcesos;
      //this.arrProcesos = respPro.ListarProcesos;

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }




}
