import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fresado',
  templateUrl: './fresado.component.html',
  styleUrls: ['./fresado.component.scss']
})
export class FresadoComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }

}
