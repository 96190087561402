import { Component, OnInit, ViewChild } from '@angular/core';
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { AuthenticatorService } from 'src/app/shared/services/authenticator.service';
import { ListarTercero, ListarTerceroRoot, ListarMenuNg, ListarMenuNgRoot, ListarSubMenuNgRoot, ListarSubMenuNg, ListarMenuUsuario, ListarMenuUsuarioRoot } from '../../../shared/models/terceros';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  public sidebaron: any;
  public listView: any;

  FormPermison: FormGroup;
  isloading: boolean;
  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
  arrUsuarios: ListarTercero[];
  arrMenu: ListarMenuNg[];
  arrSubMenu: ListarSubMenuNg[];
  arrMenuUsuario: ListarMenuUsuario[];
  arrTemp: ListarMenuUsuario[];

  IdUsuario: number;

  keywordUsuario: string;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private _TerceroService: TercerosService,
    private fb: FormBuilder,
  ) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.keywordUsuario = 'ChrNombreCompleto';
    this.Cargando = 'none';
    this.isloading = false

    this.FormPermison = this.fb.group({
      IdUsuario: ['', Validators.required],
      IdMenu: ['', Validators.required],
      IdSubMenu: ['', Validators.required]
    })


  }

  async ngOnInit() {
    try {
      await this.Cargar_Usuarios()
      await this.Cargar_Menu()
      await this.Cargar_Menu_Usuario()

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }


  }

  async Cargar_Usuarios() {
    try {
      this.Cargando = 'Block';

      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })

      this.arrUsuarios = resp.ListarTerceros;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Menu() {
    try {
      this.Cargando = 'Block';

      const resp: ListarMenuNgRoot = await this._TerceroService.getMenuNG();
      this.arrMenu = resp.Listar_Menu_Ng;

      this.Cargar_Sub_Menu(1)
      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Sub_Menu(item) {
    try {
      this.Cargando = 'Block';

      const resp: ListarSubMenuNgRoot = await this._TerceroService.getSubMenuNG({
        IdMenu: item
      });

      this.arrSubMenu = resp.Listar_SubMenu_Ng;

      if (this.arrSubMenu.length == 0) {
        this.FormPermison.get("IdSubMenu").setValue('Ninguno')
      } else {
        this.FormPermison.get("IdSubMenu").setValue(this.arrSubMenu[0].idsubmenu)
      }

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async selectEventUsuario(item: ListarTercero) {
    try {
      this.IdUsuario = item.idTblTerceros;

      const val = item.ChrNombreCompleto.toLowerCase();
      const temp = this.arrTemp.filter(function (d) {
        return d.ChrNombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
      });

      this.arrMenuUsuario = temp;
      //this.table.offset = 0;

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  onChangeSearch(event) {

    const val = event
    const temp = this.arrTemp.filter(function (d) {
      return d.ChrNombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.arrMenuUsuario = temp;
    this.table.offset = 0;
  }

  onFocused(e) {
    this.Cargar_Menu_Usuario()
  }

  async onChangeMenu($event) {
    await this.Cargar_Sub_Menu($event.target.value)
  }

  async Cargar_Menu_Usuario() {
    try {
      this.Cargando = 'Block';

      const resp: ListarMenuUsuarioRoot = await this._TerceroService.getMenuUsuarioNG({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal
      });

      this.arrTemp = [...resp.Listar_Menu_Usuario]
      this.arrMenuUsuario = resp.Listar_Menu_Usuario;

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async guardar(form: any) {
    try {
      this.Cargando = 'Block';

      this.FormPermison.value.IdUsuario = this.IdUsuario

      if (this.FormPermison.value.IdSubMenu === "Ninguno") {
        this.FormPermison.value.IdSubMenu = null
      }

      await this._TerceroService.InsertPermisoNG(this.FormPermison.value).then((res) => {
        if (res) {
          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });

          this.FormPermison.reset();

          this.Cargar_Menu_Usuario()
        } else {
          Swal.fire({
            title: "No se realizo el proceso, comunicate con el administrador",
            html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + res + '</p></div>',
            icon: 'error'
          });
        }
      })


      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }



  }

  async updatePermisos(row: ListarMenuUsuario) {
    try {

      if (row.blnactivo == 1) {

        await this._TerceroService.UpdatePermisoNG({
          Activo: 0,
          IdMenuNg: row.idmenung
        }).then((res => {

          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });
        }))
        
      } else {

        await this._TerceroService.UpdatePermisoNG({
          Activo: 1,
          IdMenuNg: row.idmenung
        }).then((res => {

          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });
        }))

      }

      await this.Cargar_Menu_Usuario()

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }
}