import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListaTrabajosPreOrden, ListaTrabajosPreOrdenRoot } from 'src/app/shared/models/listarEmpresas';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.component.html',
  styleUrls: ['./trabajo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrabajoComponent implements OnInit {

  public trabajoForm: FormGroup;
  arrTrabajos: ListaTrabajosPreOrden[];
  IdEmpresa: number;
  Cargando: string;
  isloading: boolean;
  keywordTrabajo: string;

  constructor(private route: Router, private fb: FormBuilder, private activateRoute: ActivatedRoute, private listarempresasservice: ListarEmpresasService,) {
    this.keywordTrabajo = 'name'

    this.trabajoForm = this.fb.group({
      IdTrabajo: ['']
    })
  }

  ngOnInit(): void {
    try {

      this.activateRoute.params.subscribe(async params => {
        this.IdEmpresa = params.IdEmpresa
        await this.Cargar_Trabajos_Empresa(params.IdEmpresa)
      });

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Trabajos_Empresa(idEmpresa) {
    try {
      this.Cargando = 'Block'
      await this.listarempresasservice.getTrabajosNoToken({
        IdEmpresa: idEmpresa
      }).then((res: ListaTrabajosPreOrdenRoot) => {
        this.arrTrabajos = res.ListaTrabajosPreOrden
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async selectEventTrabajo(item: ListaTrabajosPreOrden) {
    try {
      
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  onChangeSearch(val: string) {
  }

  onFocused(e) {
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    return true;
  }

}
