import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ListarAllPreOrdenRoot, ListarAllPreOrden } from 'src/app/shared/models/pedidos';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { ReportService } from 'src/app/shared/services/report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-preorden',
  templateUrl: './lista-preorden.component.html',
  styleUrls: ['./lista-preorden.component.css']
})
export class ListaPreordenComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  arrPreOrden: ListarAllPreOrden[];
  arrTemp: ListarAllPreOrden[];

  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;

  constructor(private _PedidosService: PedidosService, private listarempresasservice: ListarEmpresasService, private reportService: ReportService) {

    this.Cargando = "none";
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

  }

  async ngOnInit() {
    try {

      await this.Cargar_PreOrdenes(this.IdEmpresa, this.IdSucursal)

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }


  }

  async Cargar_PreOrdenes(idEmpresa, idSucursal) {
    try {
      this.Cargando = 'Block'
      await this._PedidosService.getAllPreOrdenes({
        IdEmpresa: idEmpresa,
        IdSucursal: idSucursal
      }).then((res: ListarAllPreOrdenRoot) => {
        this.arrPreOrden = res.ListarAllPreOrden;
        this.arrTemp = [...res.ListarAllPreOrden]
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  getCellClass({ row, column, value }): any {
    return {
      'inicial': row.ChrEstados === 'INICIAL',
      'laboratorio': row.ChrEstados === 'EN LABORATORIO',
      'pp': row.ChrEstados === 'EN PERIODO DE PRUEBA',
      'final': row.ChrEstados === 'FINALIZADO',
      'factu': row.ChrEstados === 'FACTURADO',
      'anulado': row.ChrEstados === 'ANULADO',
      'pendiente': row.ChrEstados === 'PENDIENTE',
    };
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.NomPaciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPreOrden = temp;
    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.NomDoctor.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrPreOrden = temp;
    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async UpdateEstado(row: ListarAllPreOrden) {
    try {
      Swal.fire({
        title: 'Estas seguro en anular la preorden? - Este proceso se le enviara un correo al doctor',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado',
      }).then(async (result) => {
        if (result.isConfirmed) {

          this.Cargando = 'Block'
          await this._PedidosService.UpdatePreOrdenes({
            IdSql: 1,
            IdEstados: 6,
            IdPreOrden: row.idtblpreorden
          }).then(async (res) => {
            await this.listarempresasservice.SendEmailAnulado({
              to: row.CorDoctor,
              doctor: row.NomDoctor,
              paciente: row.NomPaciente,
              consultorio: row.ClinicaDoctor,
              trabajo: row.ChrNombreTrabajo,
              cantidad: "0",
              nomLab: row.ChrNonEmp,
              correolab: row.ChrEmailOficial,
              numerolab: row.ChrNuTele,
            })
            Swal.fire({
              title: "Proceso completado correctamente",
              icon: 'success'
            }).then(async (res) => {
              await this.Cargar_PreOrdenes(this.IdEmpresa, this.IdSucursal)
            });
          })
          this.Cargando = 'none'
        } else if (result.isDismissed) {
          Swal.fire('No se anulo ninguna preorden', '', 'info')
        }
      })
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Download_Files(row: ListarAllPreOrden) {
    try {


      this.Cargando = 'Block'
      await this.reportService.dwRptPreOrden({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        IdPreOrden: row.idtblpreorden
      }).then((res) => {
              
        if (res.RptFE !== '') {
          const WindowPrt = window.open('', '', 'left=0,top=50,width=900,height=900,toolbar=0,scrollbars=0,status=0');
          WindowPrt?.document.write(res.RptFE);
          WindowPrt?.document.close();
          setTimeout(() => {
            WindowPrt?.focus();
            WindowPrt?.print();
            WindowPrt?.close();
          }, 500);
        }
      })
  
      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

}
