<div class="container-fluid">

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <div class="row">
        <div class="col-sm-12 col-xl-2">
            <div class="card">
                <div class="card-header">
                    <h5>TOKEN ADENTLAB WEB</h5>
                </div>
                <div class="card-body">
                        <ngx-qrcode
                        [elementType]="elementType"
                        [value]="value"
                        cssClass="aclass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <div class="card-footer">
                            <button *ngIf="!botonGuardar" class="btn btn-primary mr-1" (click)="crearId()" >Crear</button>
                            <button class="btn btn-primary mr-1" (click)="ValidarToken()" >Validar-Token</button>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-xl-3">
            <div class="card">
                <div class="card-header">
                    <h5>LINK ORDEN</h5>
                </div>
                <div class="card-body">
                    <div>
                        <input type="text" class="form-control" id="validationCustom02"
                        placeholder="# Orden" readonly="true"
                        value="{{linkodo}}">
                    </div>
                    <div class="form-group btn-showcase d-flex">
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{linkodo}}" class="fa fa-facebook"></a>   
                        <a href="https://twitter.com/intent/tweet?text=LinkPreOrden&url={{linkodo}}&hashtags=LinkPreOrden" class="fa fa-twitter"></a>
                        <a href="https://api.whatsapp.com/send?text={{linkodo}}" class="fa fa-whatsapp"></a>
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url={{linkodo}}" class="fa fa-linkedin"></a>
                        <a href="{{linkodo}}" class="fa fa-google"></a>
                      </div>                               
                </div> 
            </div>
        </div>
    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>

</div>