import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { User } from '../models/user';
import jwt_decode from 'jwt-decode';
import { Licencia } from '../models/licencia';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User
  constructor(private http: HttpClient) {
    let userDecoded = this.decodeTokenToUser(sessionStorage.getItem('token'))
    if (userDecoded) this.deserializeUser(userDecoded)
    
  }

  decodeTokenToUser(token) {
    if (token) {
      let decoded: Licencia = jwt_decode(token);
      return decoded.user.ListarLogin
    }
  }

  deserializeUser(user) {
   return this.user = new User(user.BlnActivoUsuari, user.ChrNombreCompleto, user.idEmpreGeneral, user.idTblUsuarios, user.idTblTerceros, user.urlImagen, user.ChrUsuarios)
  }
}
