import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AgendaService } from '../../../shared/services/agenda.service'
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { NgAgenda, NgAgendaRoot } from '../../../shared/models/agenda'
import { TercerosService } from 'src/app/shared/services/terceros.service';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { SocketWebService } from '../../../shared/services/socket-web.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HistorialComponent } from '../../AgendaAll/historial/historial.component';
import { CambiodeprocesoComponent } from '../cambiodeproceso/cambiodeproceso.component';
import Swal from 'sweetalert2';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PedidosService } from '../../../shared/services/pedidos.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment'

defineLocale('es', esLocale);

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss',],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class AgendaComponent implements OnInit {

  agenda: string;
  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  NomUsuario: string;
  agendaFrom: FormGroup
  FechaRetiro: Date;
  arrAgenda: NgAgenda[];
  temp: NgAgenda[];
  arrUsuarios: ListarTercero[];
  idUser: string;
  colorSelecionado: string;
  private bsModalRef: BsModalRef;
  boton: any;
  Cargando: string;

  public arrSelected = [];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private _agendaService: AgendaService,
    private _TerceroService: TercerosService,
    private toastaService: ToastrService,
    private localeService: BsLocaleService,
    private fb: FormBuilder,
    private socketService: SocketWebService,
    private modalService: BsModalService,
    public pedidosServices: PedidosService) {

    this.agendaFrom = this.fb.group({
      fecha: ['', [Validators.required]]
    })

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));
    this.NomUsuario = (sessionStorage.getItem('ChrNombreCompleto'));

    this.localeService.use('es');//fecha en español, datepicker

    this.colorSelecionado = "v"

    if (typeof (this.idUser) !== 'undefined') {
      this.socketService.listen(`agenda${this.IdEmpresa}`).subscribe(async data => {
        this.arrAgenda = [];
        await this.CargarAgendaxTercero(this.idUser);
      });
    }

    this.Cargando = 'none';

  }


  async ngOnInit() {
    try {
      this.Cargando = 'Block';

      this.boton = false

      const res: NgAgendaRoot = await this._agendaService.getAll(
        {
          idConsulta: '1',
          IdEmpresa: this.IdEmpresa,
          Sucursal: this.IdSucursal,
          IdUsuario: '',
        })
      this.arrAgenda = res.NgAgenda;
      this.temp = [...res.NgAgenda]

      const resp: ListarTerceroRoot = await this._TerceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })

      this.arrUsuarios = resp.ListarTerceros;

      this.Cargando = 'none';


    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async onChange($event: any) {
    this.idUser = $event.target.value
    this.CargarAgendaxTercero(this.idUser)
  }

  async CargarAgendaxTercero(idUser) {
    this.boton = true
    this.Cargando = 'Block';
    const res: NgAgendaRoot = await this._agendaService.getAll(
      {
        idConsulta: '2',
        IdEmpresa: this.IdEmpresa,
        Sucursal: this.IdSucursal,
        IdUsuario: idUser,
      })
    this.Cargando = 'none';
    this.arrAgenda = res.NgAgenda;
    this.temp = [...res.NgAgenda]
  }

  async onClick() {
    this.boton = false
    const res: NgAgendaRoot = await this._agendaService.getAll(
      {
        idConsulta: '1',
        IdEmpresa: this.IdEmpresa,
        Sucursal: this.IdSucursal,
        IdUsuario: '',
      })
    this.arrAgenda = res.NgAgenda;

  }

  showModalHistorial(dtllPedido: any) {
    this.bsModalRef = this.modalService.show(HistorialComponent, { backdrop: 'static', class: 'modal-xl', initialState: dtllPedido });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
    })
  }

  showModalCambio(dtllPedido: any) {

    this.arrSelected.push(dtllPedido)

    this.bsModalRef = this.modalService.show(CambiodeprocesoComponent, { backdrop: 'static', class: 'modal-xl', initialState: this.arrSelected });
    this.modalService.onHide.subscribe(() => {
      this.bsModalRef.content
      this.arrSelected = [];
      this.CargarAgendaToda();
    })
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Paciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterOdontologo(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Odontologo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFiltercaja(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Caja.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrAgenda = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async cambioFecha(row: NgAgenda) {
    try {
          const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);

      Swal.fire({
        title: 'Estas seguro?',
        html: '<p>Estas seguro que deseas reprogramar la fecha entrega de este proceso: <b style="font-weight: bold"> <input class="form-control digits" type="datetime-local" id="inputfecha"> Este cambio genera un historial al pedido </b> </p>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado',
        preConfirm: () => {
          return [
            (document.getElementById('inputfecha') as HTMLInputElement).value
          ]
        }
      }).then(async (result) => {
        if (result.isConfirmed) {

          if (result.value[0] != '') {

            this.Cargando = 'Block';

            await this.pedidosServices.saveHistorialNg( {procesos:[ {
              VarDescrio: 'Fecha de entrega reprogramada por ' + this.NomUsuario + ', fecha anterior de entrega ' + formatDate(row.FechaEntrega, "yyyy-MM-dd HH:mm:ss", "en-CO", "-0500"),
              IdProcesoForm: -6,
              IdUsuario: this.IdUsuario,
              Fk_UsuarioRealiza: row.idTblTerceros,
              FechaEntrada: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
              HoraEntrada: formatDate(result.value[0], "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
              IdDtalle: row.idtblDtllPedidos
            } ]}).then((res) => {
              if (res) {
                    this.pedidosServices.UpdateDllPedido({
                      IdEstado: 2,
                      FechaIngreso: formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                      FechaRetiro: formatDate(result.value[0], "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
                      IdDtllPedido: row.idtblDtllPedidos
                    }).then((repuestapedido) => {
                      if (repuestapedido) {
                        this.Cargando = 'none';
                        Swal.fire({
                          title: "Proceso completado correctamente",
                          icon: 'success',
                          timer: 5000
                        });
                        this.CargarAgendaToda();
                      }
                    })                
              }
            }, error => {
              Swal.fire({
                title: "NO se realizo el proceso comunicate con el administrador",
                icon: 'error',
                timer: 3000
              });
            })

            this.Cargando = 'none';

          } else {
            Swal.fire({
              title: "Cuidado...!!",
              html: ' <div class="well well-sm ContenedorGris"><label></label><br /><p> No se puede actualizar sin la fecha de entrega, por favor verificar...!! </p></div>', icon: 'warning'
            });
          }

        }
      })

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }


  }

  async CargarAgendaToda() {
    try {
      this.Cargando = 'Block';

      this.boton = false

      const res: NgAgendaRoot = await this._agendaService.getAll(
        {
          idConsulta: '1',
          IdEmpresa: this.IdEmpresa,
          Sucursal: this.IdSucursal,
          IdUsuario: '',
        })
      this.arrAgenda = res.NgAgenda;
      this.temp = [...res.NgAgenda]

      this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }

  }

  async valueChange($event) {
    try {
      this.Cargando = 'Block';
      this.boton = false

      if($event != null){

      let datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
      let dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))

      const res: NgAgendaRoot = await this._agendaService.getAll(
        {
          idConsulta: '3',
          IdEmpresa: this.IdEmpresa,
          Sucursal: this.IdSucursal,
          dtmDesde: datstar,
          dtmHasta: dateend
        })
      this.arrAgenda = res.NgAgenda;
      this.temp = [...res.NgAgenda]

      this.Cargando = 'none';

    } else {
      this.CargarAgendaToda();
    }

    } catch (error) {      
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }



  }

  async UdptaRealizado(event) {
    try {
      await this.pedidosServices.UpdateDllRealiza({
        iDDtllhistorico: event.idtblDtllHistorico
      }).then((res) => {
        if (res) {
          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });

          this.CargarAgendaToda();
        }
      })

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }



}
