<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Busquedas y acciones</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <div class="col-md-4 col-sm-3">
                                <label class="col-form-label pt-0" for="inputEmail3">Caja:</label>
                                <input class="form-control" placeholder="Busqueda por caja"
                                    (keyup)='updateFilterCaja($event)' [(ngModel)]="ngmcaja"
                                    [ngModelOptions]="{standalone: true}">
                            </div>

                            <div class="col-md-4 col-sm-3">
                                <label class="col-form-label pt-0" for="inputPassword3">Paciente:</label>
                                <input class="form-control" placeholder="Busqueda por paciente"
                                    (keyup)='updateFilterPaciente($event)' [(ngModel)]="ngmPaciente"
                                    [ngModelOptions]="{standalone: true}">
                            </div>

                            <div class="col-md-4 col-sm-3">
                                <label class="col-form-label pt-0" for="inputPassword3">Estados:</label>

                                <select class="form-control digits" (change)="onChangeEstados($event)">
                                    <option value={{-1}}>
                                        {{'-'}}
                                    </option>
                                    <option value={{0}}>
                                        {{'ACTUALES'}}
                                    </option>
                                    <option *ngFor="let estados of arrEstados" value={{estados.idtblEstados}}>
                                        {{estados.ChrEstados}}
                                    </option>
                                </select>

                            </div>



                        </div>

                        <div class="row">
                            <div class="col-md-6 col-sm-3">
                                <label class="col-form-label pt-0" for="inputPassword3">Doctor:</label>
                                <input class="form-control" placeholder="Buscqueda por doctor"
                                    (keyup)='updateFilterOdontologo($event)' [(ngModel)]="ngmDoctor"
                                    [ngModelOptions]="{standalone: true}">
                            </div>

                            <div class="col-md-6 col-sm-3">
                                <label class="col-form-label pt-0" for="inputPassword3">Clinica:</label>
                                <input class="form-control" placeholder="Buscqueda por Clinica"
                                    (keyup)='updateFilterClinica($event)' [(ngModel)]="ngmClinica"
                                    [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                <!--   <div class="radio radio-primary">
                                    <input type="radio" name="radio1" id="radioinline1" [(ngModel)]="operacionSeleccionada" value= 2>
                                    <label for="radioinline1" class="mb-0">Entrada</label>
                                </div>
                                <div class="radio radio-primary">
                                    <input type="radio" name="radio1" id="radioinline2" [(ngModel)]="operacionSeleccionada" value=3>
                                    <label for="radioinline2" class="mb-0">Salida</label>
                                </div> -->
                                <div class="radio radio-primary">
                                    <input type="radio" name="radio1" id="radioinline3"
                                        [(ngModel)]="operacionSeleccionada" value=4>
                                    <label for="radioinline3" class="mb-0">Finalizar</label>
                                </div>
                                <div class="radio radio-primary">
                                    <input type="radio" name="radio1" id="radioinline4"
                                        [(ngModel)]="operacionSeleccionada" value=6>
                                    <label for="radioinline4" class="mb-0">Anular</label>
                                </div>
                                <div class="radio radio-primary">
                                    <input type="radio" name="radio1" id="radioinline5"
                                        [(ngModel)]="operacionSeleccionada" value=7>
                                    <label for="radioinline5" class="mb-0">Pendiente</label>
                                </div> --------------->

                            </div>

                            <div class="col">
                                <button class="btn btn-primary btn-sm btn-pill"
                                    (click)="updatePedidos()">Guardar</button>
                                <button class="btn btn-warning btn-sm btn-pill" (click)="borrarselect()">Borrar
                                    Seleccion</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body custom-datatable">
                        <!-- <div class="table-responsive"> -->
                        <!--    <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Seleccione</th>
                            <th scope="col">Caja</th>
                            <th scope="col">Odontologo</th>
                            <th scope="col">Paciente</th>
                            <th scope="col">Clincia</th>
                            <th scope="col">Sucursal</th>
                            <th scope="col">Trabajo</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Fecha Entrega o Recogida</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Accion</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pedido of arrPedidos">
                            <td align="center">
                                <input class="checkbox_animated" type="checkbox">
                            </td>
                            <td>{{pedido.ChrNumeCaja}}</td>
                            <td>{{pedido.Odonotologo}}</td>
                            <td>{{pedido.Pacientes}}</td>
                            <td>{{pedido.Establecimiento}}</td>
                            <td>{{pedido.chrNombre}}</td>
                            <td>{{pedido.ChrNombreTrabajo}}</td>
                            <td>{{pedido.ChrEstados}}</td>
                            <td>{{pedido.DtmFechaRetiro | date: 'mediumDate' }}</td>
                            <td align="center">{{pedido.ChrCantidadPedido}}</td>
                            <td>
                                <div class="btn-group">
                                    <button (click)="showModalHistorial(pedido.idtblDtllPedidos)"
                                        class="btn btn-secondary btn-sm" type="button" placement="top"><i
                                            class="fa fa-history"></i></button>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table> -->

                        <ngx-datatable class="bootstrap" [rows]="arrPedidos" [columnMode]="'force'"
                            [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="18"
                            [scrollbarH]="true" [selected]="arrSelected" [selectionType]="'checkbox'"
                            [selectAllRowsOnPage]="false" (select)='onSelectTable($event)'>

                            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                                [checkboxable]="true">
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="60" name="Caja" prop="ChrNumeCaja"></ngx-datatable-column>
                            <ngx-datatable-column [width]="120" name="Odontologo" prop="Odonotologo">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Pacientes"
                                prop="Pacientes"></ngx-datatable-column>
                            <ngx-datatable-column [width]="160" name="Establecimiento" prop="Establecimiento">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="100" name="Sucursal" prop="chrNombre"></ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Trabajo" prop="ChrNombreTrabajo">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="130" name="Estado" prop="ChrEstados"></ngx-datatable-column>


                            <ngx-datatable-column [width]="100" name="Fecha Entrega o Recogida" prop="DtmFechaRetiro">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.DtmFechaRetiro | date: 'mediumDate'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="40" name="Cantidad" prop="ChrCantidadPedido">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="60" name="Dias" prop="Dias_Vencidos"></ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="Acciones">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <button (click)="showModalHistorial(row.idtblDtllPedidos)" class="btngrilla"
                                        type="button" placement="top"><i class="fa fa-history"></i></button>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>



    </div>
</div>
<!-- Container-fluid Ends -->