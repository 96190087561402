import { Component, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {

  Cargando: string;
  elementType: string;
  value: string;
  errorCorrectionLevel: string;

  IdToken: string;
  IdEmpresa: string;

  botonGuardar: boolean;
  linkodo: string;

  constructor(private listarEmpresaServices: ListarEmpresasService,) {

    this.Cargando = 'none'
    this.elementType = NgxQrcodeElementTypes.URL
    this.errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH

    this.IdToken = sessionStorage.getItem('IdToken');
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));

    this.linkodo = `https://adentlab.com.co/orden/${this.IdEmpresa}`

  }

  ngOnInit(): void {
   
    if (this.IdToken == 'null') {
      this.botonGuardar = false
    } else {
      this.botonGuardar = true
      this.value = "otpauth://totp/Adentlab-Web?secret=" + this.IdToken
    }

  }

  async crearId() {
    try {
      this.Cargando = 'Block'

      await this.listarEmpresaServices.CreateTokenIdWeb({
        IdEmpresa: this.IdEmpresa
      }).then((res) => {
        if (res) {
          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });

          this.botonGuardar = true
          this.value = res.result.otpauth_url

          sessionStorage.setItem('IdToken',  res.result.base32);
          this.IdToken = res.result.base32

        }
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }


  }

  async ValidarToken(){
    try {
      this.Cargando = 'Block'

      Swal.fire({
        title: 'Token',
        html: '<p>Por favor Ingresa el numero de token: <b style="font-weight: bold"> <input class="form-control digits" type="text" id="text"> </b> </p>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado',
        preConfirm: () => {
          return [
            (document.getElementById('text') as HTMLInputElement).value
          ]
        }
      }).then(async (result) => {
        if (result.isConfirmed) {

          if (result.value[0] != '') {

            this.Cargando = 'Block';

            await this.listarEmpresaServices.ValidarTokenIdWeb({
              idsecret: this.IdToken,
              token: result.value[0]
            }).then((res) => {
            
                Swal.fire({
                  title: "Proceso completado correctamente",
                  icon: 'success',
                  text: res.mesage
                });    
                this.Cargando = 'none';  
              
            }, error =>{
              Swal.fire({
                title: "Error",
                html: '<div><p>' + error.error + '</p></div>',
                icon: 'error'
              });
            })
          }
        }
      })

      this.Cargando = 'none'

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

}
