<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">

                <div class="card">
                    <h5>Historial de Trabajo
                        <button type="button" class="btn-close close pull-right" aria-label="Close"
                            (click)="bsModalRef.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </h5>
                </div>

                <div class="card">
                    <div class="row">
                        <div class="card-body custom-datatable">
                            <ngx-datatable class="bootstrap" [rows]="arrHistorial" [columnMode]="'standard'"
                                [headerHeight]="50" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="10" [scrollbarH]="true">

                                <ngx-datatable-column [width]="150" name="Fecha" prop="DtmFecha">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{row.DtmFecha | date: 'medium'}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column [width]="150" name="Proceso" prop="ChrNombreProceso">
                                </ngx-datatable-column>
                                <ngx-datatable-column [width]="600" name="Descripcion" prop="ChrDetllhisto">
                                </ngx-datatable-column>
                                <ngx-datatable-column [width]="150" name="Responsable" prop="ChrNombreCompleto">
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                    </div>
                 </div>

                <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                    <div class="spinnerContent">
                        <div class="spinnerItem">
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                        </div>
                        <div class="MensajeDeEspera">Cargando...!!!</div>
                    </div>
                </div>
        </div>
    </div>
</div>