import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ListarHistorial, ListarHistorialRoot } from '../../../shared/models/pedidos';
import SignaturePad from 'signature_pad';
import { ListarDtllHistCalidadRoot, ListarDtllProCalidad, ListarDtllProCalidadRoot, ListarProcesosCalidad, ListarProcesosCalidadRoot, ListarProcesosDtllCalidad, ListarProcesosDtllCalidadRoot } from '../../../shared/models/quality';
import { PedidosService } from '../../../shared/services/pedidos.service'
import { QualityService } from '../../../shared/services/quality.service'
import Swal from 'sweetalert2';
import { ListarEmpresasService } from '../../../shared/services/listar-empresas.service';

@Component({
  selector: 'app-ingcalidad',
  templateUrl: './ingcalidad.component.html',
  styleUrls: ['./ingcalidad.component.scss']
})
export class IngcalidadComponent implements OnInit {

  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;

  Cargando: string
  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  IdProCalidad: number;
  IdProDtllCalidad: number;
  boton: any;

  UrlFirma: string;

  conteo: number;

  keywordProceso: string;
  keywordDtllProceso: string;
  isloading: boolean;

  FromTrabajos: FormGroup;
  FromCalidad: FormGroup;
  datos_get: any;
  signaturePad: any;

  arrHistorial: ListarHistorial[];
  arrDtllPrcoCalidad: ListarDtllProCalidad[];
  arrDtllProceso: ListarProcesosDtllCalidad[]
  temp: ListarHistorial[];

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions, private fb: FormBuilder, public pedidoService: PedidosService, public _QualityService: QualityService, private listarempresasservice: ListarEmpresasService) {

    this.conteo = 0
    this.Cargando = "none"
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa')!);
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal')!);
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero')!);

    this.keywordProceso = "ChrNombreProc"
    this.keywordDtllProceso = "ChrNombreDtllCalidad"
    this.isloading = false

    this.datos_get = this.options.initialState;

    this.FromTrabajos = new FormGroup({
      procesos: this.fb.array([])
    })

    this.FromCalidad = this.fb.group({
      DtllHisCalidad: this.fb.array([])
    })

  }

  async ngAfterViewInit(): Promise<void> {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)"
    });
  }

  async buildForm() {
    // const tiempoTranscurrido = Date.now();
    // const hoy = new Date(tiempoTranscurrido);
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    // Object.keys(this.datos_get).forEach(async (i) => {

    for (let i in this.datos_get) {
      controlArray.push(
        this.fb.group({
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Proceso: new FormControl(this.datos_get[i].Proceso),
          IdHistorial: new FormControl(this.datos_get[i].idtblDtllHistorico),
          IdUsuario: new FormControl(this.IdUsuario),
          UrlFima: new FormControl(),
          Observa: new FormControl(' '),
          IdHistorialG: new FormControl(this.datos_get[i].idtblDtllHistorico),
          IdProceso: new FormControl(this.datos_get[i].Fk_Procesos),
          DtllHisCalidad: new FormControl([await this.Cargar_Dtl_HisCalidad(this.datos_get[i].idtblDtllHistorico)])
        })
      )
    }
    // })
  }

  async ngOnInit() {
    await this.buildForm();
    await this.Insertar_Dtll_Automatico();
  }

  async Insertar_Dtll_Automatico() {
    try {
      const controlArray = this.datos_get
      for (let i = 0; i < controlArray.length; i++) {
        await this.CargarCaliDtllProce(controlArray[i].Fk_Procesos).then(async res => {
          if (this.arrDtllPrcoCalidad.length != 0) {
            for (let j = 0; j < this.arrDtllPrcoCalidad.length; j++) {
              await this.Ingresar_Proceso_Calidad_Automatico(i, this.arrDtllPrcoCalidad[j], this.datos_get)
            }
          }
        })
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async CargarCaliDtllProce(IdProceso: number) {
    try {
      this.Cargando = 'Block';

      const response: ListarDtllProCalidadRoot = await this._QualityService.ListarDtllProCalidad(
        {
          Query: 2,
          IdProceso: IdProceso
        })

      this.arrDtllPrcoCalidad = response.Listar_DtllProCalidad;

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Ingresar_Proceso_Calidad_Automatico(i: any, event: any, arrg: any) {
    try {

      this.conteo = this.conteo - 1

      if (event.length != 0) {

        var controlArray = this.FromTrabajos.get('procesos') as FormArray;
        const ctrArrayPuntos = this.FromCalidad.get('DtllHisCalidad') as FormArray;

        controlArray.controls[i].get("DtllHisCalidad").value[0].push({
          idtblDtllHistCalidad: this.conteo,
          ChrTipoCalidad: event.ChrTipoCalidad,
          ChrNombreProc: event.ChrNombreProc,
          ChrNombreDtllCalidad: event.ChrNombreDtllCalidad,
          BlnAprobado: 1,
          IdHistorial: arrg[i].idtblDtllHistorico,
          Iduser: this.IdUsuario,
          IdTipoCalidad: event.Fk_TipoCalidad,
          IdCalidadPro: event.Fk_CalidadPro,
          IdDtllCalidad: event.Fk_DtllCalidad
        })

        //  ctrArrayPuntos.push(
        //     this.fb.group({
        //       IdHistorial: new FormControl(arrg[i].idtblDtllHistorico),
        //       IdTipoCalidad: new FormControl(event.ChrTipoCalidad),
        //       IdCalidadPro: new FormControl(this.IdSucursal),
        //       IdDtllCalidad: new FormControl(event.DgIdProdu),
        //       Iduser: new FormControl(this.IdUsuario),
        //       idtblDtllHistCalidad: this.conteo
        //     })
        //   )

      } else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion al menos debes seleccionar un producto y asignar una cantidad, por favor verificar!",
          icon: 'info'
        });
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async deletelinea(i: any, event: any, j: any) {
    try {

      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      const ctrArrayInv = this.FromCalidad.get('DtllHisCalidad') as FormArray;

      const id = controlArray.value[i].DtllHisCalidad[0].findIndex(res => res.idtblDtllHistCalidad === event.idtblDtllHistCalidad)
      controlArray.value[i].DtllHisCalidad[0].splice(id, 1)
      ctrArrayInv.removeAt(j)

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async refelinea(i: any, dtllcalidad: any, j: any,) {
    try {

      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      //const ctrArrayInv = this.FromCalidad.get('DtllHisCalidad') as FormArray;

      dtllcalidad.BlnAprobado = 0
      controlArray.value[i].DtllHisCalidad[0][j].BlnAprobado = 0

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Dtl_HisCalidad(IdHistorial: number) {
    try {
      this.Cargando = 'Block';

      const resp: ListarDtllHistCalidadRoot = await this._QualityService.ListarDtllHistCalidad(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdHistorial: IdHistorial,
        })

      this.Cargando = 'none';
      return resp.Listar_DtllHistCalidad

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  // async CargarHistorial() {
  //   try {
  //     this.Cargando = 'Block';

  //     const res: ListarHistorialRoot = await this.pedidoService.getAllHistorial(
  //       {
  //         IdDtllPedido: this.datos_get[0].idtblDtllPedidos
  //       })

  //     this.temp = [...res.Listar_Historial]
  //     this.arrHistorial = res.Listar_Historial;

  //     this.Cargando = 'none';

  //   } catch (error) {
  //     this.Cargando = 'none';
  //     Swal.fire({
  //       title: "No se realizo el proceso...!!!",
  //       html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
  //       icon: 'error'
  //     });
  //   }

  // }

  // async CargarProcesosCalidad() {
  //   try {
  //     this.Cargando = 'Block';

  //     const res: ListarProcesosCalidadRoot = await this._QualityService.ListarProCalidad(
  //       {
  //         IdEmpresa: this.IdEmpresa,
  //         IdSucursal: this.IdSucursal
  //       })

  //     this.arrProcesoCalidad = res.Listar_Procesos_Calidad;

  //     this.Cargando = 'none';

  //   } catch (error) {
  //     this.Cargando = 'none';
  //     Swal.fire({
  //       title: "No se realizo el proceso...!!!",
  //       html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
  //       icon: 'error'
  //     });
  //   }

  // }

  // async selectEventProceos(item: ListarProcesosCalidad) {
  //   const controlArray = this.FromTrabajos.get("procesos") as FormArray;
  //   controlArray.controls[0].get("IdProcesoCalidad").setValue(item.idtblCalidadPro)
  //   this.IdProCalidad = item.idtblCalidadPro
  //   await this.CargarDtllProcesosCalidad(item.idtblCalidadPro)
  // }

  // selectEventDtll(item: ListarProcesosDtllCalidad) {
  //   const controlArray = this.FromTrabajos.get("procesos") as FormArray;
  //   controlArray.controls[0].get("IdDtllCalidad").setValue(item.idtblDtllCalidad)
  // }

  // onChangeSearch() {
  // }

  // onFocused() {
  // }

  // async CargarDtllProcesosCalidad(idProcesos: number) {
  //   try {
  //     this.Cargando = 'Block';

  //     const res: ListarProcesosDtllCalidadRoot = await this._QualityService.ListarDtllCalidad(
  //       {
  //         IdProCalidad: idProcesos
  //       })

  //     this.arrDtllProceso = res.Listar_Procesos_DtllCalidad;

  //     this.Cargando = 'none';

  //   } catch (error) {
  //     this.Cargando = 'none';
  //     Swal.fire({
  //       title: "No se realizo el proceso...!!!",
  //       html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
  //       icon: 'error'
  //     });
  //   }

  // }

  // async updateAprobado(row: ListarHistorial) {
  //   try {

  //     let ocupado;

  //     if (row.BlnAprobado == true) {
  //       ocupado = false;
  //     } else {
  //       ocupado = true;
  //     }

  //     this.Cargando = 'Block';

  //     await this.pedidoService.UpdateNoAproHist(
  //       {
  //         idtblDtllHistorico: row.idtblDtllHistorico,
  //         Ocupada: ocupado
  //       }).then((resp) => {
  //         if (resp) {
  //           Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
  //           this.Cargando = 'none';
  //         } else {
  //           Swal.fire({
  //             title: "NO se realizo el proceso...!!",
  //             html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + resp + '</p></div>', icon: 'error'
  //           });
  //         }
  //       })

  //   } catch (error) {
  //     Swal.fire({
  //       title: "NO se realizo el proceso...!!",
  //       html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
  //     });
  //   }


  // }

  Cancelaringreso() {
    this.bsModalRef.hide()
  }

  async guardarHistCalid() {
    try {

      if (this.signaturePad.isEmpty()) {
        Swal.fire({
          title: "La firma del documento es obligatorio segun Res. 214 de 15-02-2022 del MinSalud.",
          icon: 'warning'
        });
      }
      else {

        this.Cargando = 'block';

        const controlArray = this.FromTrabajos.get('procesos') as FormArray;

        const blob = this.URLtoBlob(this.signaturePad.toDataURL('image/jpeg', 0.5));
        const bodyfirma = new FormData();
        bodyfirma.append('imagen', blob);
        bodyfirma.append('NameBucket', this.IdEmpresa + '/Firmas');
        bodyfirma.append('extArchivo', 'jpg');
        bodyfirma.append('typeArchivo', 'image/jpeg');

        await this.listarempresasservice.Cargar_Firma_Calidad_S3(bodyfirma).then(async res => {
          this.UrlFirma = res.Cargar_Firma_Calidad_S3
        })

        for (let i = 0; i < controlArray.length; i++) {
          controlArray.controls[i].get("UrlFima").setValue(this.UrlFirma)
        }

        await this._QualityService.InsertarDtllCalHistorial(this.FromTrabajos.value).then(res => {
          this.Cargando = 'none';
          Swal.fire({
            title: "Proceso completado correctamente",
            icon: 'success'
          });

          this.bsModalRef.hide()


        }).catch(error => {
          this.Cargando = 'none';
          Swal.fire({
            title: "No se realizo el proceso...!!!",
            html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
            icon: 'error'
          });
        })

      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }
  ks
  URLtoBlob(dataURL: any) {
    const partes = dataURL.split(';base64,');
    const contentType = partes[0].split(':')[1];
    const raw = window.atob(partes[1]);
    const rawL = raw.length;
    const array = new Uint8Array(rawL);
    for (let i = 0; i < rawL; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return new Blob([array], { type: contentType })
  }


}
