import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PedidosService } from '../../../shared/services/pedidos.service'
import { ListarHistorialRoot, ListarHistorial } from '../../../shared/models/pedidos'
import { DatatableComponent } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {

  arrHistorial: ListarHistorial[];
  temp: ListarHistorial[];
  dtllPedido: any;
  Cargando: string;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(public bsModalRef: BsModalRef,
    public pedidoService: PedidosService,
    private toastaService: ToastrService,
    public options: ModalOptions) {

    this.dtllPedido = this.options.initialState;
    this.Cargando = 'none';

  }

  async ngOnInit() {
    try {
      this.Cargando = 'Block';

      const res: ListarHistorialRoot = await this.pedidoService.getAllHistorial(
        {
          IdDtllPedido: this.dtllPedido
        })

      this.temp = [...res.Listar_Historial]
      this.arrHistorial = res.Listar_Historial;

     this.Cargando = 'none';

    } catch (error) {
      this.toastaService.warning(error.error.message, 'Lo sentimos', { timeOut: 3000 });
    }


  }

}
