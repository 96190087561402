import { Component, OnInit, ViewChild } from '@angular/core';
import { TercerosService } from '../../../shared/services/terceros.service';
import { ListarTercero, ListarTerceroRoot } from '../../../shared/models/terceros';
import Swal from 'sweetalert2';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MensajeriaService } from 'src/app/shared/services/mensajeria.service';

@Component({
  selector: 'app-recogidas',
  templateUrl: './recogidas.component.html',
  styleUrls: ['./recogidas.component.scss']
})


export class RecogidasComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;


  Cargando: string;
  IdEmpresa: any;
  IdSucursal: any;

  arrAsignado: ListarTercero[];
  arrOdontologo: ListarTercero[];
  arrTemp: ListarTercero[];
  public arrSelected = [];

  keywordAsginado: string;
  IdAsginado: number;
  SelectionType = SelectionType;
  operacionSeleccionada: number;

  Dtll: FormGroup;
  validationForm: FormGroup;

  constructor(private terceroService: TercerosService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _mensajeriaService: MensajeriaService,) {

    this.Cargando = 'none'
    this.keywordAsginado = 'ChrNombreCompleto';
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.Dtll = this.fb.group({
      Dtll:[[
        this.validationForm = this.fb.group({
          IdEmpresa: [this.IdEmpresa],
          IdSucursal: [this.IdSucursal],
          IdDtllPedido: ['-1'],
          Mensajero: ['', Validators.required],
          ActivoMensajeria: ['1'],
          FechaDil: [formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"), Validators.required],
          Observaciones: ['', Validators.required],
          Cumplida: ['0'],
          Tipo: ['Recogida'],
        })
      ]]
    })

  }

  async ngOnInit() {
    try {

      await this.Cargar_Asginado();
      await this.Cargar_Odontologo();

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onChangeSearch(val: string) {
  }

  onFocusedAsginado(e) {
    console.log(e);
  }

  async selectEventAsginado(item) {
    this.IdAsginado = item.idTblTerceros
  }

  async Cargar_Asginado() {
    try {
      this.Cargando = 'Block';
      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "3",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })
      this.arrAsignado = resp.ListarTerceros;
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Odontologo() {
    try {

      this.Cargando = 'Block';

      const resp: ListarTerceroRoot = await this.terceroService.Cargar_Terceros(
        {
          IdEmpresa: this.IdEmpresa,
          IdSurcursal: this.IdSucursal,
          IdTipo: "5",
          ChrIdentifica: "",
          ChrNombre: "",
          TipoBusc: "3"
        })

      this.arrOdontologo = resp.ListarTerceros;
      this.arrTemp = [...resp.ListarTerceros];
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onSelectTable({ selected }) {
    this.arrSelected.splice(0, this.arrSelected.length);
    this.arrSelected.push(...selected);
  }

  updateFilterDoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.ChrNombreCompleto || '').toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrOdontologo = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async cancelar() {
    this.bsModalRef.hide()
  }

  async Guardar() {
    try {
      this.Cargando = 'Block';    
            
      this.validationForm.value.Mensajero = this.IdAsginado;
      // this.Dtll.value.Dtll = [this.validationForm.value]

      await this._mensajeriaService.Insertar_Mensajeria([this.validationForm.value]).then((res) => {        
        this.Cargando = 'none'
        Swal.fire({
          title: "Proceso completado correctamente",
          icon: 'success'
        });
        this.bsModalRef.hide()
      })
            
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onSelect({ selected }) {
    try {
      this.validationForm.get("Observaciones").setValue(selected[0].ChrNombreCompleto + " - " + selected[0].ChrDirreccion + " - " + selected[0].ChrTelefono);
    } catch (error) {
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onActivate(event) {
    /*  try {
       this.validationForm.get("Observaciones").setValue(event.row.ChrNombreCompleto + " - " + event.row.ChrDirreccion + " - " + event.row.ChrTelefono);
     } catch (error) {
       Swal.fire({
         title: "Error",
         html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
         icon: 'error'
       });
     } */


  }



}
