import { Component, OnInit, ViewChild } from '@angular/core';
import { MensajeriaService } from '../../../shared/services/mensajeria.service'
import Swal from 'sweetalert2';
import { ListarMensajeriaAllRoot, ListarMensajeriaAll } from 'src/app/shared/models/mensajeria';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RecogidasComponent } from '../../Mensajerias/recogidas/recogidas.component';
import { EntregasComponent } from '../../Mensajerias/entregas/entregas.component';
import * as moment from 'moment';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-programador',
  templateUrl: './programador.component.html',
  styleUrls: ['./programador.component.scss']
})
export class ProgramadorComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;
  tipoaccor: string;
  private bsModalRef: BsModalRef;

  arrMensajeria: ListarMensajeriaAll[];
  arrTemp: ListarMensajeriaAll[];
  name: string
  IdCumplidasFechas: number;

  datstar: any;
  dateend: any;


  constructor(private _mensajeriaService: MensajeriaService,
    private modalService: BsModalService) {

    this.Cargando = 'none';
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.tipoaccor = "light"

    this.name = "ExcelSheet.xlsx";

  }

  async ngOnInit() {
    try {

      await this.CargarMensajeriaAll(1, 0, 0, 0);

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async CargarMensajeriaAll(IdSql: number, IdCumplida: number, datstar: any, dateend: any) {
    try {
      this.Cargando = 'Block';

      const res: ListarMensajeriaAllRoot = await this._mensajeriaService.getAll(
        {
          IdSql: IdSql,
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          Cumplida: IdCumplida,
          dtmDesde: datstar,
          dtmHasta: dateend
        })

      this.Cargando = 'none';
      this.arrMensajeria = res.ListarMensajeriaAll;
      this.arrTemp = [...res.ListarMensajeriaAll];

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  updateFilterMensajero(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return d.Mensajero.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrMensajeria = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterDoctor(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Doctor || '').toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrMensajeria = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterClinica(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Clinica || '').toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrMensajeria = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  updateFilterPaciente(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTemp.filter(function (d) {
      return (d.Paciente || '').toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrMensajeria = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

  async abrirRecogida(Dato: any) {
    try {
      this.bsModalRef = this.modalService.show(RecogidasComponent, { backdrop: 'static', class: 'modal-xl', initialState: Dato });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content;
        this.CargarMensajeriaAll(1, 0, 0, 0);
      })
    } catch (error) {

    }
  }

  async abrirEntregas(Dato: any) {
    try {
      this.bsModalRef = this.modalService.show(EntregasComponent, { backdrop: 'static', class: 'modal-xl', initialState: Dato });
      this.modalService.onHide.subscribe(() => {
        this.bsModalRef.content;
        this.CargarMensajeriaAll(1, 0, 0, 0);
      })
    } catch (error) {

    }
  }

  async CargarCumplidas($event) {
    try {

      if (this.datstar != undefined){

      //Todas
      if ($event.target.value == 1) {
        await this.CargarMensajeriaAll(2, 0, this.datstar, this.dateend);
      }
      //Cumplidas
      if ($event.target.value == 2) {        
        await this.CargarMensajeriaAll(3, 1, this.datstar, this.dateend);
      }
      //Por Cumplir
      if ($event.target.value == 3) {
        await this.CargarMensajeriaAll(3, 0, this.datstar, this.dateend);
      }
    } else {
      Swal.fire({
        title: "Advertencia..!!",
        html: '<div><p> Debe elegir la fecha de inicio y de fin para hacer la busqueda, por favor verificar..!!  </p></div>',
        icon: 'warning'
      });
    }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async borrarDili(row: ListarMensajeriaAll) {
    try {
      this.Cargando = 'Block';

      Swal.fire({
        title: 'Estas Seguro?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Si, Borralo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this._mensajeriaService.Update_Diligencia(
            {
              IdMensajeria: row.idtblmensajeria
            }).then(async (res) => {
              this.Cargando = 'none';
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });
              await this.CargarMensajeriaAll(1, 0, 0, 0);
            })
        }
      })
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async valueChange($event) {
    try {
      
      if ($event != null) {
        this.datstar = (moment($event[0]).format(moment.HTML5_FMT.DATE))
        this.dateend = (moment($event[1]).format(moment.HTML5_FMT.DATE))
      } else {
        this.datstar = undefined
        this.dateend = undefined
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }



  }
  
  exportExcel() {
    try {

      this.Cargando = 'Block';

      const worksheet = xlsx.utils.json_to_sheet(this.arrMensajeria); // Sale Data
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "mensajeria");

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }


}
