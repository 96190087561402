import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { ListarProductoRoot, ListarProducto, ListarDtllInveProcRoot, ListarDtllInveProc } from '../../../shared/models/inventory'
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-ing-inventario',
  templateUrl: './ing-inventario.component.html',
  styleUrls: ['./ing-inventario.component.scss']
})
export class IngInventarioComponent implements OnInit {

  FromTrabajos: FormGroup;
  Inventario: FormGroup;
  DtllInventario: FormGroup;
  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  Cargando: string;
  conteo: number;

  RegInvima: string;

  Odontologo: string;
  Paciente: string;
  Trabajo: string;
  boton: any;

  datos_get: any;

  arrProductos: ListarProducto[];
  arrDtllInventario: ListarDtllInveProc[];
  isloading: boolean;
  keywordProduc: string;

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions, private fb: FormBuilder, public inventoryService: InventoryService) {

    this.conteo = 0
    this.Cargando = 'none';
    this.keywordProduc = 'ChrNombreProd';
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));

    this.datos_get = this.options.initialState;
        
    this.FromTrabajos = new FormGroup({
      procesos: this.fb.array([])
    })

    this.Inventario = this.fb.group({
      OperacionDtllInventory: this.fb.array([])
    })


    this.buildForm();
    this.Insertar_Dtll_Automatico();
  }

  async buildForm() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    Object.keys(this.datos_get).forEach(async (i) => {
      controlArray.push(
        this.fb.group({
          IdProducto: new FormControl(''),
          Cantidad: new FormControl(''),
          Lote: new FormControl(''),
          Invima: new FormControl(''),
          Referencia: new FormControl(''),
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Proceso: new FormControl(this.datos_get[i].Proceso),
          IdHistorial: new FormControl(this.datos_get[i].idtblDtllHistorico),
          ArrInventario: new FormControl([await this.Cargar_Dtt_Inventario(this.datos_get[i].idtblDtllHistorico)]),
          NumPedido: new FormControl(this.datos_get[i].ChrNumePedido)
        })
      )
    })
  }

  async ngOnInit(): Promise<void> {
    await this.Cargar_Productos();
  }

  async selectEventProduct(i: number, item: ListarProducto) {
    try {
      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      controlArray.controls[i].get("Invima").setValue(item.ChrRegInvima)

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async CargarProduDtllProce(IdProceso: number){
    try {
      this.Cargando = 'Block';

      const resp: ListarDtllInveProcRoot = await this.inventoryService.ListardtllInveProc(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdProceso: IdProceso
        })

       this.arrDtllInventario = resp.Listar_dtllInveProc;
      
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Insertar_Dtll_Automatico(){
    try {

      for (let i = 0; i < this.datos_get.length; i++) {
        await this.CargarProduDtllProce(this.datos_get[i].Fk_Procesos).then(async res =>{
          if(this.arrDtllInventario.length != 0){
            for (let j = 0; j < this.arrDtllInventario.length; j++) {
               await this.Ingresar_Inventario_Automatico(i, this.arrDtllInventario[j], this.datos_get)         
            }
          }
          
        })        
      }     

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  onChangeSearch(val: string) {
  }

  onFocused(e) {
  }

  async Cargar_Productos() {
    try {
      this.Cargando = 'Block';

      const resp: ListarProductoRoot = await this.inventoryService.getAllProductos(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          TipoBusqueda: 4,
          VariNombre: "N/A"
        })

      this.arrProductos = resp.Listar_Productos;
      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Cargar_Dtt_Inventario(IdHistorial: number) {
    try {
      this.Cargando = 'Block';

      const resp: ListarDtllInveProcRoot = await this.inventoryService.getDtllInventario(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          IdHistorial: IdHistorial,
        })

      this.Cargando = 'none';
      return resp.Listar_dtllInveProc

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Ingresar_Inventario_Automatico(i: any, event: any, arrg: any) {
    try {
         
      this.conteo = this.conteo - 1
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      const ctrArrayInv = this.Inventario.get('OperacionDtllInventory') as FormArray;

      if (event.length != 0) {

        controlArray.controls[i].get("ArrInventario").value[0].push({
          idtblinventarios: this.conteo,
          ChrNombreProd: event.ChrNombreProd,
          DblSalidaUni: event.dblCantidad,
          ChrLote: event.ChrLotes,
          ChrRegInvima: event.Invima,
          ChrReferencia: ""
        })

         ctrArrayInv.push(
           this.fb.group({
             IdEmpresa: new FormControl(this.IdEmpresa),
             IdSucursal: new FormControl(this.IdSucursal),
             IdProducto: new FormControl(event.DgIdProdu),
             IdTipoDoc: new FormControl(4),
             IdDocume: new FormControl(arrg[i].idtblDtllHistorico),
             Fecha: new FormControl(formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
             Descripcion: new FormControl('Pedido de Trabajo # ' + arrg[i].ChrNumePedido),
             UnidadesEntrada: new FormControl(0.0),
             UnidadesSalidas: new FormControl(event.dblCantidad),
             CostoUnitario: new FormControl(0.0),
             IdUsuario: new FormControl(this.IdUsuario),
             FechaRealiza: new FormControl(formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
             Lote: new FormControl(event.ChrLotes),
             refe: new FormControl(""),
             invima: new FormControl(event.Invima),
             idtblinventarios: new FormControl(this.conteo),
             IdLote: new FormControl(event.idlotes)
           })
         )

        // controlArray.controls[i].get("IdProducto").setValue("")
        // controlArray.controls[i].get("Cantidad").setValue("")
        // controlArray.controls[i].get("Lote").setValue("")
        // controlArray.controls[i].get("Invima").setValue("")
        // controlArray.controls[i].get("Referencia").setValue("")
 
      } else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion al menos debes seleccionar un producto y asignar una cantidad, por favor verificar!",
          icon: 'info'
        });
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Ingresar_Inventario(i: any, event: any) {
    try {
      
      console.log(event)         
      this.conteo = this.conteo - 1
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      const ctrArrayInv = this.Inventario.get('OperacionDtllInventory') as FormArray;

      if (controlArray.controls[i].get("IdProducto").value != '' && controlArray.controls[i].get("Cantidad").value != '') {

        controlArray.controls[i].get("ArrInventario").value[0].push({
          idtblinventarios: this.conteo,
          ChrNombreProd: event.procesos[i].IdProducto.ChrNombreProd,
          DblSalidaUni: event.procesos[i].Cantidad,
          ChrLote: event.procesos[i].Lote,
          ChrRegInvima: event.procesos[i].Invima,
          ChrReferencia: event.procesos[i].Referencia
        })

        ctrArrayInv.push(
          this.fb.group({
            IdEmpresa: new FormControl(this.IdEmpresa),
            IdSucursal: new FormControl(this.IdSucursal),
            IdProducto: new FormControl(event.procesos[i].IdProducto.idtblproductos),
            IdTipoDoc: new FormControl(4),
            IdDocume: new FormControl(event.procesos[i].IdHistorial),
            Fecha: new FormControl(formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
            Descripcion: new FormControl('Pedido de Trabajo # ' + event.procesos[i].NumPedido),
            UnidadesEntrada: new FormControl(0.0),
            UnidadesSalidas: new FormControl(event.procesos[i].Cantidad),
            CostoUnitario: new FormControl(0.0),
            IdUsuario: new FormControl(this.IdUsuario),
            FechaRealiza: new FormControl(formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500")),
            Lote: new FormControl(event.procesos[i].Lote),
            refe: new FormControl(event.procesos[i].Referencia),
            invima: new FormControl(event.procesos[i].Invima),
            idtblinventarios: new FormControl(this.conteo),
            IdLote: new FormControl(event.procesos[i].IdProducto.idlotes)
          })
        )

        controlArray.controls[i].get("IdProducto").setValue("")
        controlArray.controls[i].get("Cantidad").setValue("")
        controlArray.controls[i].get("Lote").setValue("")
        controlArray.controls[i].get("Invima").setValue("")
        controlArray.controls[i].get("Referencia").setValue("")
 
      } else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion al menos debes seleccionar un producto y asignar una cantidad, por favor verificar!",
          icon: 'info'
        });
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async guardarInventario() {
    try {
      this.boton = true

      console.log(this.Inventario.value)

     /*   if (this.Inventario.value.OperacionDtllInventory.length != 0) {

        Swal.fire({
          title: '¿Estas Seguro?',
          text: "¡Ingresar el inventario a todos estos pedidos, esto afectara el costo y el inventario de cada uno de los productos!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Si, Seguro!'
        }).then(async (result) => {
          if (result.isConfirmed) {

            this.Cargando = 'block';

            await this.inventoryService.InsertarInventario(this.Inventario.value).then(res => {
              this.Cargando = 'none';
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });

              this.bsModalRef.hide()


            }).catch(error => {
              this.Cargando = 'none';
              Swal.fire({
                title: "No se realizo el proceso...!!!",
                html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
                icon: 'error'
              });
            })

          } else {
            this.boton = false
            Swal.fire({
              title: "No ha realizado ninguna accion..!!",
              icon: 'warning'
            });
          }

        })
      } else {
        this.boton = false
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accion al menos un pedido tiene que tener inventario nuevo, por favor verificar!",
          icon: 'info'
        });
      } */

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async Cancelaringreso() {
    this.bsModalRef.hide()
  }

  async deletelinea(i: any, event: any) {
    try {

      const controlArray = this.FromTrabajos.get('procesos') as FormArray;
      const ctrArrayInv = this.Inventario.get('OperacionDtllInventory') as FormArray;
      
      const id = controlArray.value[i].ArrInventario[0].findIndex(res => res.idtblinventarios === event.idtblinventarios)
      controlArray.value[i].ArrInventario[0].splice(id, 1)
      ctrArrayInv.removeAt(i)

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }



}
