<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>MENSAJERIA</h5>
        </div>
        <div class="card-body">
          <div class="my-gallery row gallery-with-description" id="aniimated-thumbnials">
            <div class="my-app-custom-plain-container-with-desc row">
              <ng-container *ngFor="let mensajeria of arrMensajeria">
                <figure class="my-app-custom-image-with-desc col-xl-3 col-sm-6">
                  <div class="cuadro">
                    <h4 [ngClass]="{verde: (mensajeria.BlnCumplida === 1)}"> <b> Tipo: {{mensajeria.ChrNota}} </b> </h4>
                    <h6> <b>Mensajero: </b> {{mensajeria.Mensajero}} </h6>
                    <div>
                      <table class="table">
                        <tr>
                          <b> Doctor: </b> {{mensajeria.Doctor}}
                        </tr>
                        <tr>
                          <b> Clinica: </b> {{mensajeria.Clinica}}
                        </tr>
                        <tr>
                          <b> Sucursal: </b> {{mensajeria.Sucursal}}
                        </tr>
                        <tr>
                          <b> Trabajo: </b> {{mensajeria.ChrNombreTrabajo}}
                        </tr>
                        <tr>
                          <b> Cantidad: </b> {{mensajeria.ChrCantidadPedido}}
                        </tr>
                        <tr>
                          <b> Descripcion: </b> {{mensajeria.ChrObservaciones}}
                        </tr>
                        <tr>
                          <b> Caja: </b> {{mensajeria.ChrNumeCaja}}
                        </tr>
                        <tr>
                          <b> Ingreso: </b> {{mensajeria.DtmFecha}}
                        </tr>
                        <b> Retiro: </b> {{mensajeria.DtmFechaRetiro}}

                      </table>
                    </div>
                  </div>
                </figure>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="error-wrapper maintenance-bg">
  <div class="container">
    <ul class="maintenance-icons">
      <li><i class="fa fa-cog"></i></li>
      <li><i class="fa fa-cog"></i></li>
      <li><i class="fa fa-cog"></i></li>
    </ul>
    <div class="maintenance-heading">
      <h2 class="headline">MANTENIMIENTO</h2>
    </div>
    <h4 class="sub-content">Este sitio se encuentra en mantenimiento pronto estara listo<br> Gracias por tu paciencia
    </h4>
    <div><a class="btn btn-info-gradien btn-lg text-light" [routerLink]="'/home'">VOLVER AL INICIO</a>
    </div>
  </div>
</div> -->
<!-- Container-fluid starts -->
  <div id="map"></div>

  

<!-- Container-fluid Ends -->