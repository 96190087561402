<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">

            <div class="card">
                <div class="card-header">
                    <h5>Ingreso de Paciente</h5>

                    <button type="button" class="btn-close close pull-right" aria-label="Close"
                        (click)="bsModalRef.hide()">
                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button>
                </div>

                <div class="card-body">
                    <form class="theme-form">
                        <div class="form-group row">
                            <label class="col-sm-1 col-form-label" for="validationCustom03">Tipo: </label>
                            <div class="col-sm-6">
                                <form class="was-validated">
                                    <select class="custom-select" required
                                        [formControl]="FormIngPacientes.controls['IdTipoDoc']">
                                        <option *ngFor="let TipoDoc of arrTipoDoc" value={{TipoDoc.idTblTipoDocumento}}>
                                            {{TipoDoc.ChrTipoDocu}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Debes seleccionar el tipo de documento</div>
                                </form>
                            </div>
                            <label class="col-sm-3 col-form-label">{{consecutivo}}</label>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label" for="inputPassword3">Identificacion: </label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" placeholder="Identificación..!!"
                                    [formControl]="FormIngPacientes.controls['Identi']">
                            </div>
                            <div *ngIf="FormIngPacientes.controls.Identi.touched && FormIngPacientes.controls.Identi.errors?.required"
                                class="text text-danger">
                                La identifiacion es requerida
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label" for="inputPassword3">Nombre: </label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" placeholder="Nombre..!!"
                                    [formControl]="FormIngPacientes.controls['Nombre']">
                            </div>
                            <div *ngIf="FormIngPacientes.controls.Nombre.touched && FormIngPacientes.controls.Nombre.errors?.required"
                                class="text text-danger">
                                El nombre es requerido
                            </div>
                        </div>

                        <div class="form-group row">
                            <button *ngIf="!boton" class="btn btn-primary"
                                [disabled]="(!FormIngPacientes.valid)" type="submit"
                                (click)="GuardarPedido()">Guardar</button>
                            <button *ngIf="boton" class="btn btn-primary" disabled
                                type="submit">Guardando...!!!</button>
                        </div>

                    </form>
                </div>

            </div>



            <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                <div class="spinnerContent">
                    <div class="spinnerItem">
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                        <div class="line bg-primary"></div>
                    </div>
                    <div class="MensajeDeEspera">Cargando...!!!</div>
                </div>
            </div>



        </div>
    </div>
</div>